import { ArrowBackIos } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <IconButton
            color='secondary'
            data-testid='back-button'
            onClick={goBack}
            sx={{
                bgcolor: theme.palette.black?.['15'],
                height: '44px',
                width: '44px',
                fontSize: '12px',
            }}
        >
            <ArrowBackIos fontSize='inherit' color='action' sx={{ ml: '4px' }} />
        </IconButton>
    );
};
