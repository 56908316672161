import { Alert, Box, Breadcrumbs, Grid, Snackbar } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Permission,
    PermissionConfiguration,
    User,
    getPermissionConfiguration,
    getUser,
    toggleUserPermission,
    toggleUserPermissionGroup,
} from '../../apis/users';
import { useFetch } from '../../hooks/useFetch';
import { FetchWrapper } from '../../components/FetchWrapper';
import UserInfoSection from './UserInfoSection';
import PermissionSection from './PermissionSection';
import GroupSection from './GroupSection';
import { getCurrentUsername, hasAnyPermission } from '../../helpers/permission';

interface UserDetailsDataProps {
    user: User;
    permissionConfiguration: PermissionConfiguration;
    currentUsername: string;
    hasUserManagementPermission: boolean;
}

const UserDetails = () => {
    const { uuid } = useParams<{ uuid?: string }>();

    const userDetailsState = useFetch(
        async () => {
            const user = await getUser(uuid as string);
            const permissionConfiguration = await getPermissionConfiguration();
            const currentUsername = await getCurrentUsername();
            const hasUserManagementPermission = await hasAnyPermission(Permission.USER_MANAGEMENT);

            return { user, permissionConfiguration, currentUsername, hasUserManagementPermission };
        },
        [uuid],
        { canFetch: () => !!uuid }
    );

    const [togglingUserPermissionGroup, setTogglingUserPermissionGroup] = useState<string | undefined>(undefined);
    const [togglingUserPermission, setTogglingUserPermission] = useState<Permission | undefined>(undefined);
    const [togglingErrorMessage, setTogglingErrorMessage] = useState<string | undefined>(undefined);
    const [updatedUser, setUpdatedUser] = useState<User | undefined>(undefined);

    const SuccessComponent = useCallback(
        ({ data }: { data: UserDetailsDataProps }) => {
            const { permissionConfiguration, currentUsername, hasUserManagementPermission } = data;
            const user = updatedUser ?? data.user;

            const onToggleUserPermissionGroup = (
                event: ChangeEvent<HTMLInputElement>,
                checked: boolean,
                permissionGroupUuid: string
            ): void => {
                event.preventDefault();
                setTogglingUserPermissionGroup(permissionGroupUuid);
                setTogglingErrorMessage(undefined);
                toggleUserPermissionGroup(uuid as string, permissionGroupUuid)
                    .then(setUpdatedUser)
                    .catch(() =>
                        setTogglingErrorMessage('Failed to toggle user permission group. Retry or contact Simfuni.')
                    )
                    .finally(() => setTogglingUserPermissionGroup(undefined));
            };

            const onToggleUserPermission = (
                event: ChangeEvent<HTMLInputElement>,
                checked: boolean,
                permission: Permission
            ): void => {
                event.preventDefault();
                setTogglingUserPermission(permission);
                setTogglingErrorMessage(undefined);
                toggleUserPermission(uuid as string, permission)
                    .then(setUpdatedUser)
                    .catch(() => setTogglingErrorMessage('Failed to toggle user permission. Retry or contact Simfuni.'))
                    .finally(() => setTogglingUserPermission(undefined));
            };

            return (
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link to={'/users'}>Users</Link>
                            <Box>{user.name}</Box>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item md={12}>
                        <UserInfoSection user={user} />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <GroupSection
                            user={user}
                            permissionConfiguration={permissionConfiguration}
                            currentUsername={currentUsername}
                            hasUserManagementPermission={hasUserManagementPermission}
                            onToggleUserPermissionGroup={onToggleUserPermissionGroup}
                            togglingUserPermissionGroup={togglingUserPermissionGroup}
                        />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <PermissionSection
                            user={user}
                            permissionConfiguration={permissionConfiguration}
                            currentUsername={currentUsername}
                            hasUserManagementPermission={hasUserManagementPermission}
                            onToggleUserPermission={onToggleUserPermission}
                            togglingUserPermission={togglingUserPermission}
                        />
                    </Grid>
                    {togglingErrorMessage !== undefined && (
                        <Grid item md={12}>
                            <Snackbar
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                open={togglingErrorMessage !== undefined}
                                onClose={() => setTogglingErrorMessage(undefined)}
                                autoHideDuration={6000}
                            >
                                <Alert severity='error'>{togglingErrorMessage}</Alert>
                            </Snackbar>
                        </Grid>
                    )}
                </Grid>
            );
        },
        [togglingErrorMessage, togglingUserPermission, togglingUserPermissionGroup, updatedUser, uuid]
    );

    return <FetchWrapper state={userDetailsState} SuccessComponent={SuccessComponent} />;
};

export default UserDetails;
