import { useTheme } from '@mui/material';

export default () => {
    const theme = useTheme();

    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            data-testid='classcode-person'
        >
            <g clipPath='url(#clip0_11_89)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M24.6019 11.5202C20.09 11.5202 16.417 15.1042 16.417 19.5473C16.417 22.6671 18.2196 26.5515 20.8326 28.6372L22.1501 29.6888L20.4675 29.9724C15.6463 30.785 12.017 33.532 10.4379 37L9 36.3871C10.7025 32.6481 14.3339 29.7497 18.701 28.7678C16.3519 26.296 14.8458 22.6355 14.8458 19.5473C14.8458 14.2824 19.204 10 24.6019 10C29.9978 10 34.358 14.2851 34.358 19.5473C34.358 22.6348 32.8532 26.2947 30.5049 28.7665C34.7032 29.7112 38.2215 32.4951 40 35.9691L38.592 36.6438C36.9346 33.4065 33.3924 30.7563 28.7388 29.9709L27.0574 29.6871L28.3737 28.6359C30.9859 26.5498 32.7868 22.6665 32.7868 19.5473C32.7868 15.1068 29.1117 11.5202 24.6019 11.5202Z'
                    fill={theme.palette.primary.main}
                />
            </g>
            <defs>
                <clipPath id='clip0_11_89'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
