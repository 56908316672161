import { ExpandMore, NavigateNext } from '@mui/icons-material';
import { Box, Button, Divider, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { InvoiceAction, InvoiceDetailsResponse, InvoiceStatus, LoanSubStatus } from '../../../apis/invoice';
import { useAppSelector } from '../../../store/reducer/Hooks';
import CreateArrangementSteps from '../Arrangements/CreatePaymentArrangement/CreateArrangementSteps';
import CreateCancellationSteps from '../Cancellation/CreateCancellationSteps';
import CreateEndorsementSteps from '../Endorsements/CreateEndorsementSteps';
import CreateRenewalSteps from '../Renewal/CreateRenewalSteps';
import CreateAddPaymentSteps from '../Arrangements/AddPaymentSteps/CreateAddPaymentSteps';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
};

export default function InvoicePostPaidActions({ invoiceDetails }: Readonly<Props>) {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { accountUpdateAllowed, addingTransactionAllowed } = permissions;

    const [openEndorsementModal, setOpenEndorsementModal] = useState(false);
    const [openArrangementModal, setOpenArrangementModal] = useState(false);
    const [openRenewalModal, setOpenRenewalModal] = useState(false);
    const [openLoanCancellationModal, setOpenLoanCancellationModal] = useState(false);
    const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const actionMenuOpen = Boolean(anchorEl);

    const invoiceRenewed = invoiceDetails.invoice.renewals.some(
        (renewal) => renewal.status !== InvoiceStatus.CANCELLED
    );
    const cancellationRequested = cancellationRequestedStatuses.includes(invoiceDetails.loan.subStatus);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeActionMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box>
                <Button
                    data-testid='invoice-actions'
                    sx={{ width: 160, justifyContent: 'space-between' }}
                    id='invoice-action'
                    aria-controls={actionMenuOpen ? 'action-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={actionMenuOpen ? 'true' : undefined}
                    onClick={handleOpenMenu}
                    variant='contained'
                    endIcon={actionMenuOpen ? <ExpandMore /> : <NavigateNext />}
                >
                    Actions
                </Button>
            </Box>
            <Menu
                id='post-paid-action-menu'
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={closeActionMenu}
                MenuListProps={{
                    'aria-labelledby': 'invoice-post-paid-action',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ActionItemWrapper
                    disabled={!allowed(invoiceDetails, InvoiceAction.ENDORSE)}
                    closeActionMenu={closeActionMenu}
                    openModal={setOpenEndorsementModal}
                    text='Endorse'
                    disabledTip='Endorsements are not available for this invoice'
                />

                <ActionItemWrapper
                    disabled={!allowed(invoiceDetails, InvoiceAction.RENEWAL)}
                    closeActionMenu={closeActionMenu}
                    openModal={setOpenRenewalModal}
                    text='Renew'
                    disabledTip={
                        invoiceRenewed
                            ? 'Invoice has already been renewed'
                            : 'Renewal is not available for this invoice'
                    }
                />

                {accountUpdateAllowed && (
                    <ActionItemWrapper
                        disabled={!allowed(invoiceDetails, InvoiceAction.CANCEL)}
                        closeActionMenu={closeActionMenu}
                        openModal={setOpenLoanCancellationModal}
                        text='Cancel'
                        disabledTip={
                            cancellationRequested
                                ? 'Cancellation is already in progress for this invoice'
                                : 'Cancellation is not available for this invoice'
                        }
                    />
                )}

                {accountUpdateAllowed && <Divider variant='middle' />}

                {accountUpdateAllowed && (
                    <ActionItemWrapper
                        disabled={!allowed(invoiceDetails, InvoiceAction.PAYMENT_VARIATION)}
                        closeActionMenu={closeActionMenu}
                        openModal={setOpenArrangementModal}
                        text='Create payment arrangement'
                        disabledTip='Arrangements are not available for this invoice'
                    />
                )}

                {addingTransactionAllowed && invoiceDetails.loan.paymentFrequency && (
                    <ActionItemWrapper
                        disabled={!allowed(invoiceDetails, InvoiceAction.ADD_PAYMENT)}
                        closeActionMenu={closeActionMenu}
                        openModal={setOpenAddPaymentModal}
                        text='Add payment'
                        disabledTip='Adding payments is not available for this invoice'
                    />
                )}
            </Menu>
            <CreateEndorsementSteps open={openEndorsementModal} setClosed={() => setOpenEndorsementModal(false)} />
            <CreateArrangementSteps open={openArrangementModal} setClosed={() => setOpenArrangementModal(false)} />
            <CreateRenewalSteps open={openRenewalModal} setClosed={() => setOpenRenewalModal(false)} />
            <CreateCancellationSteps
                open={openLoanCancellationModal}
                setClosed={() => setOpenLoanCancellationModal(false)}
            />
            <CreateAddPaymentSteps open={openAddPaymentModal} setClosed={() => setOpenAddPaymentModal(false)} />
        </>
    );
}

type ActionItemWrapperProps = ActionItemProps & {
    disabledTip: string;
};

const ActionItemWrapper = ({ disabledTip, disabled, ...props }: Readonly<ActionItemWrapperProps>) => {
    if (disabled) {
        return (
            <Tooltip title={disabledTip} arrow placement='left'>
                <span>
                    <ActionItem {...props} disabled={disabled} />
                </span>
            </Tooltip>
        );
    }

    return <ActionItem {...props} disabled={disabled} />;
};

type ActionItemProps = {
    disabled: boolean;
    closeActionMenu: () => void;
    openModal: (open: boolean) => void;
    text: string;
};

const ActionItem = ({ disabled, closeActionMenu, openModal, text }: Readonly<ActionItemProps>) => {
    return (
        <MenuItem
            disabled={disabled}
            sx={buttonStyle}
            onClick={() => {
                closeActionMenu();
                openModal(true);
            }}
        >
            <ListItemText>{text}</ListItemText>
        </MenuItem>
    );
};

const allowed = (invoiceDetails: InvoiceDetailsResponse, action: InvoiceAction): boolean => {
    return !!invoiceDetails.allowedActions?.includes(action);
};

const cancellationRequestedStatuses = [
    LoanSubStatus.PENDING_CANCELLATION,
    LoanSubStatus.CANCELLED_PENDING_SETTLEMENT,
    LoanSubStatus.CANCELLED,
];

const buttonStyle = { minWidth: 160 };
