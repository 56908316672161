import { Box, SxProps, Theme } from '@mui/material';
import { common, grey } from '@mui/material/colors';

export default ({ children, sx }: { children?: React.ReactNode; sx?: SxProps<Theme> }) => {
    return (
        <Box
            sx={{
                border: 'solid',
                borderWidth: '1px',
                borderRadius: 3,
                borderColor: grey['400'],
                backgroundColor: common.white,
                py: { xs: 3, sm: 4 },
                px: { xs: 2, sm: 3 },
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};
