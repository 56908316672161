import { useEffect, useState } from 'react';
import { ClientLeadSummary, getClientLeadSummary } from '../../apis/clientLead';
import { ClassCode, fetchClassCodes, fetchProviders } from '../../apis/invoiceConfig';
import {
    FetchState,
    FetchStateType,
    LoadingState,
    SuccessState,
    isError,
    isPending,
    isUnavailable,
    loadingState,
    useFetch,
} from '../../hooks/useFetch';
import { setClientLeadSummaryState } from '../../store/reducer/ClientLeadReducer';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setClassCodesState, setProvidersState } from '../../store/reducer/SellerConfigReducer';
import { AnyAction } from 'redux';

const useSetter = <K extends keyof AllInitialDataStates, T>(
    key: K,
    setInitialDataState: React.Dispatch<React.SetStateAction<AllInitialDataStates>>,
    fetchState: FetchState<T>,
    reduxState: FetchState<T>,
    defaultValue: FetchState<T>,
    setter: (state: FetchState<T>) => AnyAction
) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isError(fetchState) || isUnavailable(fetchState)) {
            dispatch(setter(defaultValue));
        } else {
            dispatch(setter(fetchState));
        }
    }, [fetchState]);

    useEffect(() => {
        setInitialDataState((prev) => ({ ...prev, [key]: reduxState }));
    }, [reduxState]);
};

export default function useLoadInitialData(): InitialDataState {
    const [initialDataState, setInitialDataState] = useState<AllInitialDataStates>({ ...initialState });
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);

    const { state: clientLeadSummaryState } = useAppSelector((root) => root.ClientLeadReducer);
    const clientLeadSummaryFetchState = useFetch(getClientLeadSummary, [permissions.paymentMatchingAllowed], {
        canFetch: () => isPending(clientLeadSummaryState) && permissions.paymentMatchingAllowed,
    });
    useSetter(
        'clientLeadSummary',
        setInitialDataState,
        clientLeadSummaryFetchState,
        clientLeadSummaryState,
        defaultClientLeadSummary,
        setClientLeadSummaryState
    );

    const { classCodesState, providersState } = useAppSelector((root) => root.SellerConfigReducer);
    const classCodesFetchState = useFetch(fetchClassCodes);
    useSetter(
        'classCodes',
        setInitialDataState,
        classCodesFetchState,
        classCodesState,
        defaultClassCodes,
        setClassCodesState
    );

    const providersFetchState = useFetch(fetchProviders);
    useSetter(
        'providers',
        setInitialDataState,
        providersFetchState,
        providersState,
        defaultProviders,
        setProvidersState
    );

    if (Object.values(initialDataState).every((state) => state.type === FetchStateType.SUCCESS)) {
        return {
            type: FetchStateType.SUCCESS,
            value: {
                clientLeadSummary: (initialDataState.clientLeadSummary as SuccessState<ClientLeadSummary>).value,
                classCodes: (initialDataState.classCodes as SuccessState<ClassCode[]>).value,
                providers: (initialDataState.providers as SuccessState<string[]>).value,
            },
        };
    }

    return loadingState;
}

const defaultClientLeadSummary = {
    value: { enabled: false },
    type: FetchStateType.SUCCESS,
} as FetchState<ClientLeadSummary>;

const defaultClassCodes = {
    value: [],
    type: FetchStateType.SUCCESS,
} as FetchState<ClassCode[]>;

const defaultProviders = {
    value: [],
    type: FetchStateType.SUCCESS,
} as FetchState<string[]>;

const initialState = {
    clientLeadSummary: loadingState,
    classCodes: loadingState,
    providers: loadingState,
};

type AllInitialDataStates = {
    clientLeadSummary: FetchState<ClientLeadSummary>;
    classCodes: FetchState<ClassCode[]>;
    providers: FetchState<string[]>;
};

type InitialDataState = SuccessState<InitialData> | LoadingState;

export type InitialData = {
    clientLeadSummary: ClientLeadSummary;
    classCodes: ClassCode[];
    providers: string[];
};
