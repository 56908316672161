import { useCallback } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Moment from 'react-moment';
import { InvoicePage, InvoiceSearchResult } from '../apis/invoice';
import { FetchState } from '../hooks/useFetch';
import { useOpenRow } from '../hooks/useOpenRow';
import { DATE_FRIENDLY } from '../util/dateUtils';
import { FetchWrapper } from './FetchWrapper';
import InvoiceStatusChip from './InvoiceStatusChip';
import { Box } from '@mui/material';
import NoSearchResults from './NoSearchResults';

type InvoiceListProps = {
    state: FetchState<InvoicePage>;
    page: number;
    rowsPerPage: number;
    changePage: (page: number) => void;
    changeRowsPerPage: (rows: number) => void;
};

const InvoiceList = ({ state, changePage, changeRowsPerPage, page, rowsPerPage }: InvoiceListProps) => {
    const { rowClick, setUrl } = useOpenRow();

    const currencyFormatter = new Intl.NumberFormat('en-nz', {
        style: 'currency',
        currency: 'NZD',
        maximumFractionDigits: 2,
    });
    const columns: TableColumn<InvoiceSearchResult>[] = [
        {
            name: 'Invoice #',
            selector: (row) => row.invoiceNumber,
        },
        {
            name: 'Date issued',
            cell: IssuedDate,
        },
        {
            name: 'Customer',
            selector: (row) => row.customerName,
        },
        {
            name: 'Premiums',
            cell: (row) => currencyFormatter.format(row.premiums),
        },
        {
            name: 'Status',
            cell: InvoiceNumber,
        },
    ];

    const SuccessComponent = useCallback(
        ({ data }: { data: InvoicePage }) => {
            return (
                <DataTable
                    data={data.records}
                    columns={columns}
                    onRowClicked={(row: InvoiceSearchResult, e: React.MouseEvent) => {
                        rowClick('/invoices/' + row.identifier, e);
                    }}
                    onRowMouseEnter={(row: InvoiceSearchResult) => {
                        setUrl('/invoices/' + row.identifier);
                    }}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    pagination
                    paginationServer
                    onChangePage={changePage}
                    onChangeRowsPerPage={changeRowsPerPage}
                    paginationPerPage={rowsPerPage}
                    paginationDefaultPage={page}
                    paginationTotalRows={data.totalRecords}
                    customStyles={customStyles}
                    noDataComponent={
                        <Box width='100%' p={2}>
                            <NoSearchResults />
                        </Box>
                    }
                />
            );
        },
        [page, rowsPerPage, rowClick, setUrl]
    );

    return <FetchWrapper state={state} SuccessComponent={SuccessComponent} />;
};

const customStyles = {
    headCells: {
        style: {
            fontSize: '14px',
            fontWeight: 600,
            color: '#666',
        },
        cells: {
            style: {
                fontSize: '14px',
            },
        },
    },
};

const InvoiceNumber = (row: InvoiceSearchResult) => (
    <InvoiceStatusChip status={row.status} paymentFrequency={row.paymentFrequency} />
);
const IssuedDate = (row: InvoiceSearchResult) => <Moment format={DATE_FRIENDLY}>{row.issuedDate}</Moment>;

export default InvoiceList;
