import { Link, AutoFixNormal, ErrorOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { amber } from '@mui/material/colors';
import { BaseClient, CreateBusinessFields, CreateIndividualFields, CreateTrustFields } from '../../../apis/clients';
import { getInsuredDisplayName } from '../../../util/insuredUtils';

type Props = {
    client?: CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient;
    suggestedInsured?: BaseClient;
};

export default function ClientDetails({ client, suggestedInsured }: Readonly<Props>) {
    if (client && (client as BaseClient).sellerConfirmationDate) {
        return (
            <Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Link color='inherit' fontSize='small' sx={{ color: amber[600] }}></Link>
                    <Typography variant='subtitle1'>Linked</Typography>
                </Box>
                <Typography variant='caption'>{getInsuredDisplayName(client)}</Typography>
            </Box>
        );
    }

    if (suggestedInsured) {
        return (
            <Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'end' }}>
                    <AutoFixNormal color='inherit' fontSize='small' sx={{ color: amber[600] }}></AutoFixNormal>
                    <Typography variant='subtitle1'>Suggested</Typography>
                </Box>
                <Typography variant='caption'>{suggestedInsured.displayName}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <ErrorOutline fontSize='large' color='disabled'></ErrorOutline>
            <Box>
                <Typography variant='caption'>More info required</Typography>
                <Typography variant='subtitle1'>Expand to edit</Typography>
            </Box>
        </Box>
    );
}
