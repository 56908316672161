import { yupResolver } from '@hookform/resolvers/yup';
import { HelpOutlined } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { round } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { EndorsementPolicyStatus } from '../../../../apis/endorsement';
import { InvoiceDetailsResponse, Loan } from '../../../../apis/invoice';
import { getPaymentConfiguration, OndemandPaymentType, PaymentConfiguration } from '../../../../apis/variations';
import StepCard from '../../../../components/StepCard';
import PaymentMethodDetails from '../../../MatchClientLeads/ClientLeadCell/PaymentMethodDetails';
import { OndemandPaymentFields } from '../types';
import { getInvoicePolicyDescription } from '../utils';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    ondemandPaymentFields?: OndemandPaymentFields;
    setOndemandPaymentFields: (fields: OndemandPaymentFields) => void;
    loan: Loan;
    details: InvoiceDetailsResponse;
    overdueAmount: number;
    isOverdue: boolean;
};

export default function OndemandPaymentDetailsStep({
    cancel,
    handleBack,
    ondemandPaymentFields,
    setOndemandPaymentFields,
    loan,
    details,
    overdueAmount,
    isOverdue,
}: Readonly<Props>) {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [paymentType, setPaymentType] = useState<OndemandPaymentType>(
        ondemandPaymentFields?.paymentType ?? OndemandPaymentType.OUTSTANDING
    );
    const [paymentConfiguration, setPaymentConfiguration] = useState<PaymentConfiguration>();

    const theme = useTheme();

    const futureEndorsementAdjustment =
        details.invoice.endorsements
            ?.flatMap(({ policyEndorsements }) => policyEndorsements ?? [])
            .filter(({ status }) => status === EndorsementPolicyStatus.ACCEPTED)
            .map(({ premiumsAdjustment }) => premiumsAdjustment)
            .reduce((total, policyEndorsement) => total + policyEndorsement, 0) ?? 0;

    const cancellationSkippedPremiums = loan.cancellationRequest?.cancellationSkippedPremiums ?? 0;
    const outstandingSettlementAmount =
        (loan.outstandingSettlementAmount ?? 0) + futureEndorsementAdjustment - cancellationSkippedPremiums;
    const disabledOutstandingPayment = outstandingSettlementAmount <= 0;

    useEffect(() => {
        getPaymentConfiguration(details.invoice.uuid)
            .then(setPaymentConfiguration)
            .catch((errorMessage) => setErrorMsg(errorMessage));
    }, []);

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        resetField,
    } = useForm<OndemandPaymentFields>({
        resolver: yupResolver(getSchema(overdueAmount, paymentType)),
        defaultValues: {
            ...ondemandPaymentFields,
        },
    });

    const onSubmit: SubmitHandler<OndemandPaymentFields> = (data) => {
        setErrorMsg(undefined);
        console.log(data);
        const ondemandPaymentFields = {
            ...data,
            paymentType,
            paymentConfiguration,
        };

        setOndemandPaymentFields(ondemandPaymentFields);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <StepCard>
                <Typography variant='h5'>Charge client's payment method:</Typography>
                <Typography variant='caption'>
                    For {details.client.displayName}'s insurance #{details.invoice.number} (
                    {getInvoicePolicyDescription(details.invoice)})
                </Typography>
                <FormControl required>
                    <FormLabel htmlFor='paymentType' sx={{ mb: 1 }}>
                        Payment type
                    </FormLabel>
                    <Controller
                        rules={{ required: true }}
                        control={control}
                        name='paymentType'
                        render={({ field }) => (
                            <RadioGroup {...field} row name='paymentType' id='paymentType' defaultValue={paymentType}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Tooltip
                                        title={
                                            disabledOutstandingPayment
                                                ? 'Only available if there is an oustanding balance'
                                                : ''
                                        }
                                        followCursor
                                    >
                                        <FormControlLabel
                                            value='OUTSTANDING'
                                            control={<Radio size='small' />}
                                            disabled={disabledOutstandingPayment}
                                            onChange={() => {
                                                resetField('amount');
                                                setPaymentType(OndemandPaymentType.OUTSTANDING);
                                            }}
                                            label={
                                                <Box>
                                                    <Typography
                                                        color={
                                                            disabledOutstandingPayment
                                                                ? theme.palette.black[40]
                                                                : theme.palette.black[100]
                                                        }
                                                    >
                                                        Outstanding balance
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                        {`Charge the entire outstanding balance of ${currencyFormat.format(outstandingSettlementAmount)}`}
                                                        {futureEndorsementAdjustment > 0 && (
                                                            <Tooltip
                                                                title={`includes future endorsements of ${currencyFormat.format(futureEndorsementAdjustment)}`}
                                                            >
                                                                <IconButton>
                                                                    <HelpOutlined
                                                                        fontSize='small'
                                                                        style={{ fontSize: '16px' }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        title={!isOverdue ? 'Only available if there is an overdue balance' : ''}
                                        followCursor
                                    >
                                        <FormControlLabel
                                            value='OVERDUE'
                                            control={<Radio size='small' />}
                                            disabled={!isOverdue}
                                            onChange={() => {
                                                resetField('amount');
                                                setPaymentType(OndemandPaymentType.OVERDUE);
                                            }}
                                            label={
                                                <Box>
                                                    <Typography
                                                        color={
                                                            !isOverdue
                                                                ? theme.palette.black[40]
                                                                : theme.palette.black[100]
                                                        }
                                                    >
                                                        Overdue balance ({currencyFormat.format(overdueAmount)})
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                        {`Charge up to the total overdue amount of ${currencyFormat.format(overdueAmount)}`}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </Tooltip>
                                </Box>
                            </RadioGroup>
                        )}
                    />
                </FormControl>
                {paymentConfiguration?.paymentMethod && (
                    <Paper variant='outlined' sx={{ p: 2, gap: 1 }}>
                        <Typography color={theme.palette.black[100]} sx={{ pb: 1 }}>
                            Client's payment method
                        </Typography>
                        <PaymentMethodDetails paymentMethod={paymentConfiguration.paymentMethod} />
                    </Paper>
                )}
                <Typography variant='body2'>
                    The client can update this invoice payment method from their account.
                </Typography>
                {paymentType === OndemandPaymentType.OUTSTANDING && (
                    <Typography variant='body1'>
                        Outstanding balance: {currencyFormat.format(outstandingSettlementAmount)}
                    </Typography>
                )}
                {paymentType === OndemandPaymentType.OVERDUE && (
                    <>
                        <Typography variant='body1'>Overdue balance: {currencyFormat.format(overdueAmount)}</Typography>
                        <Box>
                            <FormControl required>
                                <FormLabel htmlFor='amount' sx={{ mb: 1 }}>
                                    Payment amount
                                </FormLabel>
                                <Controller
                                    name={'amount'}
                                    control={control}
                                    render={(field) => (
                                        <TextField
                                            {...field}
                                            {...register('amount')}
                                            type='number'
                                            data-testid={'amount'}
                                            inputProps={{ step: 0.01 }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                                            }}
                                            error={!!errors?.amount}
                                            helperText={errors?.amount?.message}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Box>
                    </>
                )}
                <FormControl required>
                    <FormLabel htmlFor='note' sx={{ mb: 1 }}>
                        Note
                    </FormLabel>
                    <Controller
                        name='note'
                        control={control}
                        defaultValue={ondemandPaymentFields?.note ?? ''}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id='note'
                                size='small'
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 1024 }}
                                error={!!errors?.note}
                                helperText={errors?.note?.message}
                            />
                        )}
                    />
                </FormControl>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

                <Grid container>
                    <Grid item xs={6}>
                        <Button onClick={handleBack} variant='outlined'>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                        <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                            Cancel
                        </Button>
                        <Button type='submit' variant='contained' size='large' sx={{ minWidth: '160px' }}>
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </StepCard>
        </form>
    );
}

const getSchema = (overdueAmount: number, paymnetType: OndemandPaymentType) => {
    return yup.object({
        amount: yup
            .number()
            .when({
                is: paymnetType === OndemandPaymentType.OVERDUE,
                then: yup.number().required('Amount required'),
                otherwise: yup.number().optional(),
            })
            .transform((num) => (isNaN(num) ? undefined : num))
            .positive()
            .max(round(overdueAmount, 2))
            .test('2-digit-decimals', 'max to 2 digits fractions', (value) => {
                if (value == null) {
                    return true;
                }

                return /^\d+(\.\d{0,2})?$/.test(value.toString());
            }),
        note: yup.string().required('Note required'),
    });
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
