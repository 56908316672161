import { ChangeEvent } from 'react';
import { Permission, PermissionConfiguration, User } from '../../apis/users';
import {
    Checkbox,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { translatePermission } from '../../helpers/permission';
import { Check, HelpOutlined } from '@mui/icons-material';
import { isEmpty } from 'lodash';

interface PermissionSectionProps {
    user: User;
    permissionConfiguration: PermissionConfiguration;
    currentUsername: string;
    hasUserManagementPermission: boolean;
    onToggleUserPermission: (event: ChangeEvent<HTMLInputElement>, checked: boolean, permission: Permission) => void;
    togglingUserPermission: Permission | undefined;
}

const PermissionSection = ({
    user,
    permissionConfiguration,
    currentUsername,
    hasUserManagementPermission,
    onToggleUserPermission,
    togglingUserPermission,
}: PermissionSectionProps) => {
    const { permissions } = permissionConfiguration;
    const permissionsFromGroup = new Set();

    user.groups
        .filter((group) => isEmpty(group.deletedDate))
        .forEach((group) =>
            group.permissions
                .filter((groupPermission) => isEmpty(groupPermission.deletedDate))
                .map((groupPermission) => groupPermission.permission)
                .forEach((permission) => permissionsFromGroup.add(permission))
        );

    return (
        <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
            <Typography variant='h2'>
                Permissions
                <Tooltip title='Additional permissions can also be granted on top of those granted through groups.'>
                    <IconButton>
                        <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
                    </IconButton>
                </Tooltip>
            </Typography>
            <List dense sx={{ position: 'relative', overflow: 'auto' }}>
                {permissions
                    .map((permission) => ({ permission, ...translatePermission(permission) }))
                    .sort((p1, p2) => p1.permissionName.localeCompare(p2.permissionName))
                    .map(({ permission, permissionName, permissionDescription }) => {
                        const indirectlyAssigned =
                            !user.permissions.includes(permission) && permissionsFromGroup.has(permission);
                        const isLastUserManagementPermission =
                            user.username === currentUsername &&
                            permission === Permission.USER_MANAGEMENT &&
                            !permissionsFromGroup.has(permission) &&
                            user.permissions.includes(permission);
                        const checkBoxShouldBeDisabled =
                            !hasUserManagementPermission ||
                            togglingUserPermission !== undefined ||
                            isLastUserManagementPermission;

                        let secondaryAction;
                        if (permission === togglingUserPermission) {
                            secondaryAction = <CircularProgress size={24} color='primary' sx={{ ml: 1, mr: 2 }} />;
                        } else if (indirectlyAssigned) {
                            secondaryAction = <Check color='secondary' sx={{ ml: 1, mr: 2 }} />;
                        } else {
                            secondaryAction = (
                                <Checkbox
                                    sx={{ mr: '6px' }}
                                    checked={user.permissions.includes(permission)}
                                    onChange={(event, checked) => onToggleUserPermission(event, checked, permission)}
                                    disabled={checkBoxShouldBeDisabled}
                                />
                            );
                        }

                        let permissionTitleNode;
                        if (hasUserManagementPermission && isLastUserManagementPermission) {
                            permissionTitleNode = (
                                <Typography>
                                    {permissionName}
                                    <Tooltip title='You cannot remove your user management permission by yourself.'>
                                        <IconButton>
                                            <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            );
                        } else {
                            permissionTitleNode = <>{permissionName}</>;
                        }

                        return (
                            <ListItem key={permission}>
                                {secondaryAction}
                                <ListItemText primary={permissionTitleNode} secondary={permissionDescription} />
                            </ListItem>
                        );
                    })}
            </List>
        </Paper>
    );
};

export default PermissionSection;
