import { CheckCircle } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { Loan, LoanSubStatus } from '../../../apis/invoice';
import StepCard from '../../../components/StepCard';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';

type Props = {
    handleClose: () => void;
    loan: Loan;
};

export default function DoneStep({ handleClose, loan }: Readonly<Props>) {
    const effectiveDate = moment(loan.cancellationRequest?.effectiveDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY);

    return (
        <StepCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CheckCircle color='success' fontSize='large' />
                    <Typography variant='h5' component='h2'>
                        {loan.subStatus === LoanSubStatus.CANCELLED ? 'Completed' : 'Cancellation scheduled'}
                    </Typography>
                </Box>
                <Typography textAlign='center'>
                    {loan.subStatus === LoanSubStatus.CANCELLED
                        ? 'The cancellation has been processed.'
                        : `The cancellation has been scheduled for ${effectiveDate}.`}
                </Typography>
                <Button onClick={handleClose} variant='contained'>
                    Close
                </Button>
            </Box>
        </StepCard>
    );
}
