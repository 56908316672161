import { OpenInNew } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, Link } from '@mui/material';

export default function IQumulateBanner() {
    const iqEdgeLink = 'https://edge.iqumulate.com/edgev3/#/login';

    return (
        <Alert
            severity='info'
            action={
                <Link
                    href={iqEdgeLink}
                    target='_blank'
                    rel='noreferrer'
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        textDecoration: 'none',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button endIcon={<OpenInNew sx={{ ml: 1 }} />}>Visit IQEdge</Button>
                    </Box>
                </Link>
            }
        >
            <AlertTitle>Managed by IQumulate</AlertTitle>
            This invoice is managed by the premium finance provider (IQumulate). For the latest information, visit the
            IQumulate portal.
        </Alert>
    );
}
