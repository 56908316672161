import { Box, Card, CardActionArea, Tooltip, Typography, useTheme } from '@mui/material';
import { common, grey } from '@mui/material/colors';

type Props = {
    selected: boolean;
    handleSelected: () => void;
    disabled?: boolean;
    disabledHints?: string;
    children?: React.ReactNode;
};

export default ({ selected, handleSelected, disabled, disabledHints, children }: Readonly<Props>) => {
    const theme = useTheme();

    let backgroundColor;
    if (disabled) {
        backgroundColor = theme.palette.black[3];
    } else {
        backgroundColor = selected ? theme.palette.blue[10] : common.white;
    }

    return (
        <Card
            variant='outlined'
            sx={{
                border: disabled ? 'dashed' : 'solid',
                borderWidth: '1px',
                borderRadius: 1,
                borderColor: selected ? theme.palette.primary.main : grey['400'],
                backgroundColor,
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100%',
                height: '100%',
                position: 'relative',
            }}
        >
            {disabled && (
                <Box
                    sx={{
                        position: 'absolute',
                        width: '80px',
                        top: 4,
                        left: 0,
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Typography variant='caption'>Unavailable</Typography>
                </Box>
            )}

            <Tooltip title={disabled ? disabledHints : ''} followCursor>
                <Box sx={{ minHeight: '100%', height: '100%' }}>
                    <CardActionArea
                        sx={{
                            py: { xs: 3, sm: 4 },
                            px: { xs: 2, sm: 3 },
                            gap: 2,
                            minHeight: '100%',
                        }}
                        disabled={disabled}
                        onClick={handleSelected}
                    >
                        {children}
                    </CardActionArea>
                </Box>
            </Tooltip>
        </Card>
    );
};
