import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { Invoice, Policy } from '../../../../apis/invoice';
import { InvoiceRenewalPreview } from '../../../../apis/renewal';
import { grey } from '@mui/material/colors';
import PolicyRow from './PolicyRow';
import { tallyPremiums } from '../../../CreateInvoice/invoiceValidation';

type Props = {
    invoice: Invoice;
    renewalPreview: InvoiceRenewalPreview;
};

export default function PaymentSchedule({ invoice, renewalPreview }: Readonly<Props>) {
    const theme = useTheme();
    const currentPremiumTotal = tallyPremiums(invoice.portfolio.policies);
    const newPremiumTotal = tallyPremiums(renewalPreview.invoice.portfolio.policies);

    return (
        <Box>
            <Typography variant='subtitle2' mb={3}>
                New invoice payable
            </Typography>

            <Typography>Invoice number: {renewalPreview.invoice.number}</Typography>

            <Box
                sx={{
                    p: 1,
                    pl: 2,
                    display: 'flex',
                    bgcolor: grey[50],
                    border: `1px solid ${grey[300]}`,
                    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                    mt: 1,
                }}
            >
                <Grid container spacing={2}>
                    {['Policy', 'Current Premium', 'New Premium'].map((header) => (
                        <Grid key={header} item xs={4}>
                            <Typography variant='subtitle2'>{header}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {invoice.portfolio.policies.map((policy) => {
                    const newPolicy: Policy | undefined = renewalPreview.invoice.portfolio.policies.find(
                        (p) =>
                            p.currentPolicyVersion.renewFromPolicyVersionIdentifier === policy.currentPolicyVersion.uuid
                    );
                    return (
                        <PolicyRow
                            key={policy.uuid}
                            policy={newPolicy ?? policy}
                            currentPremiums={policy.currentPolicyVersion.premiums}
                            policyRemoved={newPolicy == null}
                        />
                    );
                })}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                <Box>
                    <Typography variant='caption'>Current total premium</Typography>
                    <Typography variant='h6' component='p'>
                        {currencyFormat.format(currentPremiumTotal)}
                    </Typography>
                </Box>
                <Divider orientation='vertical' variant='middle' flexItem />
                <Box>
                    <Typography variant='caption'>New total premium</Typography>
                    <Typography variant='h6' component='p'>
                        {currencyFormat.format(newPremiumTotal)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
