import { AddCircleOutlined, RemoveCircleOutlined } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import moment from 'moment/moment';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form/dist/types/fieldArray';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Policy } from '../../apis/invoice';
import { ClassCode } from '../../apis/invoiceConfig';
import { FetchState, FetchStateType } from '../../hooks/useFetch';
import { useAppSelector } from '../../store/reducer/Hooks';
import { InvoiceFormFields } from './InvoiceForm';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DATE_COMPACT, DATE_SERVER_FORMAT, handleDatePickerChange } from '../../util/dateUtils';

interface Props {
    control: Control<InvoiceFormFields>;
    fields: FieldArrayWithId[];
    append: UseFieldArrayAppend<InvoiceFormFields, 'policies'>;
    remove: UseFieldArrayRemove;
    errors: FieldErrors<InvoiceFormFields>;
    register: UseFormRegister<InvoiceFormFields>;
    setValue: UseFormSetValue<InvoiceFormFields>;
}

const autoSetDates = () => {
    return process.env.REACT_APP_AUTO_SET_POLICY_DATES === 'true';
};

const PortfolioDetails = (props: Props) => {
    const { setValue, fields, append, remove, control, errors, register } = props;

    const { classCodesState, providersState } = useAppSelector((root) => root.SellerConfigReducer);
    const startDate = autoSetDates() ? moment().format('YYYY-MM-DD') : '';
    const endDate = autoSetDates() ? moment().add(1, 'year').format('YYYY-MM-DD') : '';

    useEffect(() => {
        if (fields.length == 0) {
            addPolicy();
        }
    }, []);

    const addPolicy = () => {
        append({
            number: '',
            provider: '',
            currentPolicyVersion: {
                premiums: '' as unknown as number,
            },
            classCode: '',
            startDate,
            endDate,
        } as Policy);
    };

    const providerOnSelect = (val: string | null, index: number) => {
        setValue(`policies.${index}.provider`, val ?? '');
    };

    const classCodeOnSelect = (val: string | null, index: number) => {
        setValue(`policies.${index}.classCode`, val ?? '');
    };

    const renderPolicyDetails = () => {
        return fields.map((field, index) => {
            const error = errors.policies?.[index];
            const rowId = field.id;

            return (
                <Paper key={rowId} variant='outlined' sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth required>
                                <FormLabel htmlFor={`number_${rowId}`}>Number</FormLabel>
                                <Controller
                                    control={control}
                                    name={`policies.${index}.number`}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register(`policies.${index}.number`)}
                                            id={`number_${rowId}`}
                                            data-testid={`policies.${index}.number`}
                                            fullWidth
                                            autoComplete='no'
                                            size='small'
                                            error={!!error?.number}
                                            helperText={error?.number?.message}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth required>
                                <FormLabel htmlFor={`provider_${rowId}`}>Provider</FormLabel>
                                <Controller
                                    name={`policies.${index}.provider`}
                                    control={control}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            {...register(`policies.${index}.provider`)}
                                            id={`provider_${rowId}`}
                                            data-testid={`policies.${index}.provider`}
                                            disablePortal
                                            fullWidth
                                            options={
                                                providersState.type === FetchStateType.SUCCESS
                                                    ? providersState.value
                                                    : []
                                            }
                                            loading={providersState.type === FetchStateType.LOADING}
                                            size='small'
                                            onChange={(event, value) => providerOnSelect(value, index)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error?.provider}
                                                    helperText={error?.provider?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth required>
                                <FormLabel htmlFor={`classCode_${rowId}`}>Class Code</FormLabel>
                                <Controller
                                    control={control}
                                    name={`policies.${index}.classCode`}
                                    defaultValue=''
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            id={`classCode_${rowId}`}
                                            data-testid={`policies.${index}.classCode`}
                                            disablePortal
                                            fullWidth
                                            options={getClassCodes(classCodesState)}
                                            loading={classCodesState.type === FetchStateType.LOADING}
                                            size='small'
                                            onChange={(event, value) => classCodeOnSelect(value, index)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error?.classCode}
                                                    helperText={
                                                        error?.classCode?.message ??
                                                        getClassCodeHelper(field?.value, classCodesState)
                                                    }
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth required>
                                <FormLabel htmlFor={`premium_${rowId}`}>Premium</FormLabel>
                                <Controller
                                    name={`policies.${index}.currentPolicyVersion.premiums`}
                                    control={control}
                                    defaultValue={undefined}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            {...register(`policies.${index}.currentPolicyVersion.premiums`)}
                                            id={`premium_${rowId}`}
                                            data-testid={`policies.${index}.currentPolicyVersion.premiums`}
                                            fullWidth
                                            size='small'
                                            error={!!error?.currentPolicyVersion?.premiums}
                                            helperText={error?.currentPolicyVersion?.premiums?.message}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth required data-testid={`policies.${index}.startDate`}>
                                <FormLabel htmlFor={`startDate_${rowId}`}>Start date</FormLabel>
                                <Controller
                                    name={`policies.${index}.startDate`}
                                    control={control}
                                    defaultValue={undefined}
                                    render={({ field }) => (
                                        <DatePicker
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={moment(field.value, DATE_SERVER_FORMAT)}
                                            inputRef={field.ref}
                                            slotProps={{
                                                textField: {
                                                    id: `startDate_${rowId}`,
                                                    fullWidth: true,
                                                    size: 'small',
                                                    variant: 'outlined',
                                                    helperText: error?.startDate?.message,
                                                    error: !!error?.startDate,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth required data-testid={`policies.${index}.endDate`}>
                                <FormLabel htmlFor={`endDate_${rowId}`}>End date</FormLabel>
                                <Controller
                                    name={`policies.${index}.endDate`}
                                    control={control}
                                    defaultValue={undefined}
                                    render={({ field }) => (
                                        <DatePicker
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={moment(field.value, DATE_SERVER_FORMAT)}
                                            inputRef={field.ref}
                                            slotProps={{
                                                textField: {
                                                    id: `endDate_${rowId}`,
                                                    fullWidth: true,
                                                    size: 'small',
                                                    variant: 'outlined',
                                                    helperText: error?.endDate?.message,
                                                    error: !!error?.endDate,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {fields.length > 1 && (
                                <IconButton
                                    onClick={() => remove(index)}
                                    color='error'
                                    data-testid={`policies.${index}.removePolicy`}
                                >
                                    <RemoveCircleOutlined />
                                </IconButton>
                            )}

                            {index == fields.length - 1 && (
                                <IconButton
                                    onClick={addPolicy}
                                    color='primary'
                                    data-testid={`policies.${index}.addPolicy`}
                                >
                                    <AddCircleOutlined />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            );
        });
    };

    return (
        <Box sx={{ pt: 2 }}>
            <Typography variant='h6'>Policies</Typography>
            <Box sx={{ my: 2.5, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>{renderPolicyDetails()}</LocalizationProvider>
            </Box>
        </Box>
    );
};

const getClassCodes = (state: FetchState<ClassCode[]>): string[] => {
    return state.type === FetchStateType.SUCCESS ? state.value.map((c) => c.code) : [];
};

const getClassCodeHelper = (classCode: string, state: FetchState<ClassCode[]>): string | undefined => {
    const cancellable =
        state.type === FetchStateType.SUCCESS
            ? state.value.find((c) => c.code === classCode)?.cancellable ?? true
            : true;
    if (cancellable) {
        return;
    }
    return 'Customer will only be able to pay in full';
};

export default PortfolioDetails;
