import { Alert, AlertTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Invoice } from '../../../apis/invoice';
import { getStartDate } from '../../../util/policyUtils';
import { DATE_COMPACT } from '../../../util/dateUtils';

type Props = {
    renewalInvoice: Invoice;
};

export default function RenewalAlertParent({ renewalInvoice }: Readonly<Props>) {
    const navigate = useNavigate();

    return (
        <Alert
            severity='info'
            action={
                <Button color='inherit' size='small' onClick={() => navigate('/invoices/' + renewalInvoice.uuid)}>
                    View new invoice
                </Button>
            }
        >
            <AlertTitle>Pending renewal</AlertTitle>
            This invoice will be renewed and replaced with a new invoice on{' '}
            {getStartDate(renewalInvoice.portfolio.policies).format(DATE_COMPACT)}.
        </Alert>
    );
}
