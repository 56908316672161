import { Alert, AlertTitle, Box } from '@mui/material';
import moment from 'moment';
import { Loan } from '../../../apis/invoice';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';

type Props = {
    loan: Loan;
};

export default function CancelledLoanAlert({ loan }: Readonly<Props>) {
    return (
        <Box>
            <Alert severity='error'>
                <AlertTitle>Policies cancelled</AlertTitle>
                <Box>
                    This insurance was cancelled on{' '}
                    {moment(loan.cancellationRequest!.effectiveDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)}. No
                    further payments are due.
                </Box>
            </Alert>
        </Box>
    );
}
