import { CheckCircleOutline, HelpOutline, HelpOutlined } from '@mui/icons-material';
import {
    Alert,
    Box,
    Chip,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { reduce } from 'lodash';
import replace from 'lodash/replace';
import size from 'lodash/size';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Invoice, InvoiceDetailsResponse, PaymentFrequency, Term, requestApproval } from '../../../apis/invoice';
import LendingConditionDenied from '../../../components/LendingConditionDenied';
import LendingConditionPendingApproval from '../../../components/LendingConditionPendingApproval';
import { LoadingButton } from '../../../components/LoadingButton';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { DATE_FRIENDLY } from '../../../util/dateUtils';
import {
    detectLendingConditionsDenied,
    detectLendingConditionsPendingApproval,
    hasLendingCondition,
    isLendingConditionDenied,
    isLendingConditionPendingApproval,
} from '../common';

interface RepaymentTermsProps {
    invoiceDetails: InvoiceDetailsResponse;
    onApprovalRequestSent: () => void;
}

const RepaymentTerms = ({ invoiceDetails, onApprovalRequestSent }: RepaymentTermsProps) => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { invoiceUpdateAllowed } = permissions;

    const { invoice } = invoiceDetails;
    const lendingConditionsDetected = hasLendingCondition(invoice);
    const lendingConditionPendingApproval = isLendingConditionPendingApproval(invoice);
    const lendingConditionDenied = isLendingConditionDenied(invoice);
    const [requestingApproval, setRequestingApproval] = useState(false);
    const repaymentTerms = getRepaymentTerms(invoice);

    const onRequestApproval = () => {
        setRequestingApproval(true);
        requestApproval(invoice.uuid)
            .then(onApprovalRequestSent)
            .finally(() => setRequestingApproval(false));
    };

    const buildListItemApproved = ({
        uuid,
        statement,
        conditionApproved,
    }: {
        uuid: string;
        statement: string;
        conditionApproved: boolean;
    }) => {
        if (!conditionApproved) {
            return <LendingConditionPendingApproval key={uuid} statement={statement} />;
        }

        return (
            <ListItem key={uuid} disablePadding>
                <ListItemIcon>
                    <CheckCircleOutline color='success' />
                </ListItemIcon>
                <ListItemText primary={statement} style={{ textDecoration: 'line-through' }} />
                <Chip label='Approved' color='success' sx={{ ml: 1 }} />
            </ListItem>
        );
    };

    const calculateTolerantedFirstPaymentDate = (term: Term): Moment => {
        const earliestPolicyEndDate = reduce(
            invoice.portfolio.policies,
            (endDate, policy) => {
                return policy.endDate < endDate ? (policy.endDate as string) : endDate;
            },
            '9999-12-31'
        );

        let loanEndDate: Moment;
        if (term.paymentFrequency === PaymentFrequency.MONTHLY) {
            loanEndDate = moment(term.finalPaymentDate).add(1, 'months');
        } else if (term.paymentFrequency === PaymentFrequency.FORTNIGHTLY) {
            loanEndDate = moment(term.finalPaymentDate).add(14, 'days');
        } else if (term.paymentFrequency === PaymentFrequency.WEEKLY) {
            loanEndDate = moment(term.finalPaymentDate).add(7, 'days');
        } else {
            // for completeness only
            loanEndDate = moment(term.finalPaymentDate);
        }

        const maxBufferDays = moment(earliestPolicyEndDate).diff(loanEndDate, 'days');
        return moment(term.firstPaymentDate).add(maxBufferDays, 'days');
    };

    const hasRecurringPaymentOptions =
        repaymentTerms.find((term) => term.paymentFrequency !== PaymentFrequency.IN_FULL) !== undefined;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Customer payment options
            </Typography>
            <Paper variant='flat' sx={{ display: 'flex', gap: 2, flexDirection: 'column', p: 2 }}>
                {size(repaymentTerms) === 0 && (
                    <Box sx={{ display: 'block' }}>
                        <Typography variant='h2' sx={{ display: 'block' }}>
                            Unfortunately your customer cannot use Simfuni to pay for their insurance at this time.
                            Please contact Simfuni for more details.
                        </Typography>
                    </Box>
                )}
                {lendingConditionsDetected && !lendingConditionDenied && (
                    <Alert severity={'info'}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Box>
                                {lendingConditionPendingApproval
                                    ? 'Recurring payment options are not available unless the following lending conditions are approved:'
                                    : 'Lending conditions were approved.'}
                            </Box>
                            {invoiceUpdateAllowed && (
                                <Box style={{ textAlign: 'right' }}>
                                    {lendingConditionPendingApproval && (
                                        <Tooltip
                                            title={
                                                'Lending conditions approval requires manual process. Call the premium funder directly to accelerate the process.'
                                            }
                                        >
                                            <span style={{ textAlign: 'center' }}>
                                                <LoadingButton
                                                    variant={invoice.lastLcaRequestedDate ? 'outlined' : 'contained'}
                                                    fullWidth
                                                    loading={requestingApproval}
                                                    endIcon={<HelpOutline />}
                                                    onClick={onRequestApproval}
                                                >
                                                    Request Approval
                                                </LoadingButton>
                                                {invoice.lastLcaRequestedDate && (
                                                    <Typography
                                                        sx={{
                                                            fontWeight: 600,
                                                            fontSize: '12px',
                                                            color: 'gray',
                                                            fontStyle: 'italic',
                                                        }}
                                                    >{`Requested ${moment.utc(invoice.lastLcaRequestedDate).local().fromNow()}`}</Typography>
                                                )}
                                            </span>
                                        </Tooltip>
                                    )}
                                </Box>
                            )}
                        </Box>

                        <List>{detectLendingConditionsPendingApproval(invoice).map(buildListItemApproved)}</List>
                    </Alert>
                )}
                {lendingConditionsDetected && lendingConditionDenied && (
                    <Alert severity={'info'}>
                        Recurring payment options are not available. Lending conditions were denied.
                        <List>
                            {detectLendingConditionsDenied(invoice).map(({ uuid, statement }) => (
                                <LendingConditionDenied key={uuid} statement={statement} />
                            ))}
                        </List>
                    </Alert>
                )}
                {!hasRecurringPaymentOptions && !lendingConditionsDetected && (
                    <Alert severity={'info'}>
                        No recurring payment options are available for the policy details provided.
                    </Alert>
                )}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {repaymentTerms.map((term) => {
                        const isNotInFullPayment = term.paymentFrequency !== PaymentFrequency.IN_FULL;
                        const termNeedsAttention = lendingConditionPendingApproval && isNotInFullPayment;
                        const termMayVariant = term.numberOfPayments > 1;
                        const tooltipRequired = termNeedsAttention || termMayVariant;

                        const color = termNeedsAttention ? 'lightgray' : undefined;
                        const inFullPaymentDescription = replace(term.paymentFrequency, '_', ' ');
                        const recurringPaymentDescription = `${term.numberOfPayments} ${replace(term.paymentFrequency, '_', ' ')} payments`;
                        const termNeedsAttentionTooltip =
                            'Recurring payment options are not available unless the lending conditions are approved';
                        const termsMayVariantTooltip = `The number of payments may be reduced if the first payment cannot be made on or before ${calculateTolerantedFirstPaymentDate(term).format(DATE_FRIENDLY)}`;

                        const paymentDescription = isNotInFullPayment
                            ? recurringPaymentDescription
                            : inFullPaymentDescription;
                        const tooltip = termNeedsAttention ? termNeedsAttentionTooltip : termsMayVariantTooltip;

                        return (
                            <Box key={term.paymentFrequency} sx={{ flex: '1 1 0px', minWidth: 350 }}>
                                <Paper variant='outlined' sx={{ p: 2, borderRadius: '12px' }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '30px',
                                                lineHeight: '38px',
                                                color: color,
                                            }}
                                        >
                                            {currencyFormat.format(term.instalmentAmount)}
                                        </Typography>
                                        <Typography sx={{ fontSize: '16px', color: color }}>*</Typography>
                                    </Box>
                                    <Typography sx={{ color, height: 32 }}>
                                        &nbsp;/ {paymentDescription}
                                        {tooltipRequired && (
                                            <Tooltip title={tooltip}>
                                                <IconButton>
                                                    <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Typography>
                                </Paper>
                            </Box>
                        );
                    })}
                </Box>
                {size(repaymentTerms) > 0 && (
                    <Typography variant='subtitle2'> * Additional setup and processing fees apply </Typography>
                )}
            </Paper>
        </Box>
    );
};

const getRepaymentTerms = (invoice: Invoice) => {
    if (invoice.renewFromInvoice == null) {
        return invoice.repaymentTerms;
    }

    if (invoice.renewFromInvoice.paymentFrequency === PaymentFrequency.IN_FULL) {
        return invoice.repaymentTerms;
    }

    // if any of the lending rules are not approved, then we don't need to do anything
    // as the repayment terms on the invoice are already correct
    if (invoice.ruleEvaluationResults?.some((res) => !res.conditionApproved)) {
        return invoice.repaymentTerms;
    }

    return invoice.repaymentTerms.filter((t) => t.paymentFrequency === invoice.renewFromInvoice?.paymentFrequency);
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

export default RepaymentTerms;
