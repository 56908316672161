import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '../../../components/LoadingButton';
import { completeLoanCancellation, Invoice, Loan } from '../../../apis/invoice';
import { SnackState } from '../../../components/SnackAlert';
type Props = {
    open: boolean;
    handleClose: (loan?: Loan) => void;
    loan: Loan;
    invoice: Invoice;
    setSnack: (state: SnackState) => void;
};

export default function CompleteCancellationRequest({ open, handleClose, loan, invoice, setSnack }: Readonly<Props>) {
    const [processing, setProcessing] = useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setProcessing(true);
        completeLoanCancellation(invoice.uuid)
            .then((updatedLoan) => {
                setSnack({
                    severity: 'success',
                    msg: 'The cancellation has been marked as completed.',
                    anchorOriginVertical: 'bottom',
                    anchoreOriginHorizontal: 'right',
                });
                handleClose(updatedLoan);
            })
            .catch(() =>
                setSnack({
                    severity: 'error',
                    msg: 'There was an error when trying to mark the cancellation as complete!',
                    anchorOriginVertical: 'bottom',
                    anchoreOriginHorizontal: 'right',
                })
            )
            .finally(() => {
                setProcessing(false);
            });
    };

    return (
        <Dialog
            onClose={() => handleClose()}
            open={open}
            fullWidth
            maxWidth='sm'
            PaperProps={{ component: 'form', onSubmit }}
        >
            <DialogTitle variant='h2'>Mark cancellation as complete?</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                    <DialogContentText>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='body1'>
                                This action will mark the cancellation as completed.
                            </Typography>
                            <Typography variant='body1'>
                                {`The outstanding balance of ${currencyFormat.format(loan.balance)} will remain unchanged.`}
                            </Typography>
                        </Box>
                    </DialogContentText>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Close</Button>
                <LoadingButton variant='contained' loading={processing} color='primary' type='submit'>
                    Mark as complete
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
