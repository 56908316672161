import { Typography } from '@mui/material';
import { Client } from '../../../../apis/clients';
import { Invoice, PaymentFrequency } from '../../../../apis/invoice';

type Props = { invoice: Invoice; client: Client };

export default function Notification({ invoice, client }: Readonly<Props>) {
    const includesPaymentLink = invoice.term.paymentFrequency === PaymentFrequency.IN_FULL;
    return (
        <Typography variant='subtitle2'>
            An email {includesPaymentLink ? 'with a payment link ' : ''}will be sent to {client.displayName}
        </Typography>
    );
}
