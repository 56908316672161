import { AddCircleOutline } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, Paper, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { Client } from '../../apis/clients';
import NoRecords from '../../components/NoRecords';
import PageLoading from '../../components/PageLoading';
import { useAppSelector } from '../../store/reducer/Hooks';
import { Note } from '../../types/Types';
import { DATE_MONTH_DAY_YEAR_TIME } from '../../util/dateUtils';
import NoteForm from './NoteForm';

interface ClientNotesProps {
    client: Client;
}

const ClientNotes = (props: ClientNotesProps) => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { clientUpdateAllowed } = permissions;

    const { client } = props;

    const [notes, setNotes] = useState([] as Note[]);
    const [addNote, setAddNote] = useState(false);
    const [ready, setReady] = useState(false);

    const addNoteCallback = (note: Note) => {
        setNotes((existingNotes) => [...existingNotes, note]);
        setAddNote(false);
    };

    const fetchClientNotes = () => {
        const noteRequestPromise = async (): Promise<Note[]> => {
            return await axios
                .get(process.env.REACT_APP_BROKER_PORTAL_HOST + '/clients/' + client.uuid + '/notes')
                .then((response) => {
                    return response.data;
                });
        };

        noteRequestPromise()
            .then((response) => {
                setNotes(response);
                setReady(true);
            })
            .catch(console.log);
    };

    const renderNotes = () => {
        return notes.map((note) => {
            return (
                <>
                    <Box sx={{ m: '10px 0' }}>
                        <Typography>{note.contents}</Typography>
                        <Typography variant='subtitle1'>
                            {note.user} &#x2022; {moment.utc(note.createdDate).local().format(DATE_MONTH_DAY_YEAR_TIME)}
                        </Typography>
                    </Box>
                    <Divider />
                </>
            );
        });
    };

    useEffect(() => {
        fetchClientNotes();
    }, []);

    return (
        <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
            <Typography variant='h6'>Notes</Typography>
            {ready ? (
                <>
                    {notes.length == 0 ? <NoRecords /> : renderNotes()}
                    {clientUpdateAllowed && (
                        <Box sx={{ m: '10px 0' }}>
                            <Button
                                fullWidth
                                variant='contained'
                                startIcon={<AddCircleOutline />}
                                onClick={() => setAddNote(true)}
                            >
                                Add Note
                            </Button>
                        </Box>
                    )}
                    <Drawer
                        anchor='right'
                        open={addNote}
                        onClose={() => setAddNote(false)}
                        PaperProps={{
                            sx: { width: '50%', maxWidth: '1200px' },
                        }}
                    >
                        <NoteForm client={client} callback={addNoteCallback} />
                    </Drawer>
                </>
            ) : (
                <PageLoading />
            )}
        </Paper>
    );
};

export default ClientNotes;
