import { yupResolver } from '@hookform/resolvers/yup';
import { Save } from '@mui/icons-material';
import { Box, FormControl, FormLabel, Grid, TextField } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { setLocale } from 'yup';
import { Client, CreateIndividualFields, IndividualClient, createIndividual } from '../../apis/clients';
import AddressFields from '../../components/AddressFields';
import { LoadingButton } from '../../components/LoadingButton';
import { DATE_COMPACT, DATE_SERVER_FORMAT, handleDatePickerChange, invalidDate } from '../../util/dateUtils';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

setLocale({
    number: {
        min: 'Must be greater than ${min}',
        max: 'Must be less than ${max}',
    },
});

const schema = yup.object({
    firstName: yup.string().required('First name required'),
    lastName: yup.string().required('Last name required'),
    email: yup.string().required('Email required').email('Valid email required'),
    mobileNumber: yup
        .string()
        .required('Mobile number required')
        .matches(/(^(\+?64|0)2[0-2,6-9](\s|-|)\d{3,4}(\s|-|)\d{3,4}$)/, 'Valid mobile number required'),
    addressLine1: yup.string().required('Address line 1 required'),
    addressLine2: yup.string().optional(),
    suburb: yup.string().optional(),
    cityTown: yup.string().optional(),
    postcode: yup.string().optional(),
    dateOfBirth: yup
        .date()
        .nullable()
        .transform((curr) => (invalidDate(curr) ? null : curr))
        .min(moment().subtract(120, 'years'), 'Invalid birth year')
        .max(moment().subtract(18, 'years'), 'Must be at least 18 years old'),
});

interface CreateClientProps {
    callback?: (client: Client) => void;
    client?: IndividualClient;
    saveCounter?: number;
    handleSave?: (fields: CreateIndividualFields) => void;
    inputSize?: 'small' | 'medium';
}

const CreateIndividualClient = ({ callback, client, saveCounter, handleSave, inputSize }: CreateClientProps) => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm<CreateIndividualFields>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (saveCounter) {
            handleSubmit(onSubmit)();
        }
    }, [saveCounter]);

    const onSubmit: SubmitHandler<CreateIndividualFields> = (data) => {
        setSubmitting(true);
        const validData = {
            ...data,
            dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).format('YYYY-MM-DD') : undefined,
        };

        if (handleSave) {
            handleSave(validData);
            return;
        }

        createIndividual(validData, client?.uuid)
            .then((clientResponse) => {
                if (callback) {
                    callback(clientResponse);
                } else {
                    navigate('/clients/' + clientResponse.uuid);
                }
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <Box sx={{ height: '100%' }}>
            <form
                autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='firstName'>First name</FormLabel>
                            <Controller
                                name='firstName'
                                control={control}
                                defaultValue={client?.personalDetails.givenName}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='firstName'
                                        autoComplete='no'
                                        error={errors.firstName !== undefined}
                                        helperText={errors.firstName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='lastName'>Last name</FormLabel>
                            <Controller
                                name='lastName'
                                control={control}
                                defaultValue={client?.personalDetails.surname}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='lastName'
                                        autoComplete='no'
                                        error={errors.lastName !== undefined}
                                        helperText={errors.lastName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='dateOfBirth'>Date of birth</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Controller
                                    name='dateOfBirth'
                                    control={control}
                                    defaultValue={
                                        client?.personalDetails.dateOfBirth == null
                                            ? ''
                                            : moment(client?.personalDetails.dateOfBirth).format(DATE_SERVER_FORMAT)
                                    }
                                    render={({ field }) => (
                                        <DatePicker
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={moment(field.value, DATE_SERVER_FORMAT)}
                                            inputRef={field.ref}
                                            slotProps={{
                                                textField: {
                                                    id: 'dateOfBirth',
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    helperText: errors?.dateOfBirth?.message,
                                                    error: !!errors?.dateOfBirth,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}></Grid>

                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <Controller
                                name='email'
                                control={control}
                                defaultValue={client?.contactDetails.email.address}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='email'
                                        fullWidth
                                        autoComplete='no'
                                        error={errors.email !== undefined}
                                        helperText={errors.email?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='mobileNumber'>Mobile number</FormLabel>
                            <Controller
                                name='mobileNumber'
                                control={control}
                                defaultValue={client?.contactDetails.preferredPhoneNumber.number}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='mobileNumber'
                                        fullWidth
                                        type='tel'
                                        autoComplete='no'
                                        error={errors.mobileNumber !== undefined}
                                        helperText={errors.mobileNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <AddressFields
                            errors={errors}
                            control={control}
                            setValue={setValue}
                            address={client?.contactDetails.physicalAddress}
                            clearErrors={clearErrors}
                            inputSize={inputSize}
                        />
                    </Grid>

                    <Grid item md={6} sm={12}></Grid>

                    {!handleSave && (
                        <Grid item md={6} sm={12} pb={2}>
                            <LoadingButton
                                data-testid='saveClient'
                                type='submit'
                                variant='contained'
                                fullWidth
                                size={'large'}
                                startIcon={<Save />}
                                loading={submitting}
                            >
                                Save client
                            </LoadingButton>
                        </Grid>
                    )}
                </Grid>
            </form>
        </Box>
    );
};

export default CreateIndividualClient;
