import { Box, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { capitalize } from 'lodash';
import moment from 'moment';
import { ChangePaymentFrequencyAction } from '../../../../apis/variations';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';

type Props = {
    data: ChangePaymentFrequencyAction;
};

export default function ChangePaymentFrequencyActionDetails({ data }: Readonly<Props>) {
    const theme = useTheme();

    return (
        <Box>
            <Grid
                container
                sx={{
                    border: grey[400],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: theme.shape.borderRadius + 'px',
                }}
            >
                <StyledGridHeaderContainer>
                    <Grid item xs={6} pr={2}>
                        <Typography variant='subtitle2'>New payment frequency</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='subtitle2'>Start date</Typography>
                    </Grid>
                </StyledGridHeaderContainer>
                <StyledGridItemContainer>
                    <Grid item xs={6}>
                        <Typography variant='caption'>{capitalize(data.paymentFrequency)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='caption'>{moment(data.nextPaymentDate).format(DATE_FRIENDLY)}</Typography>
                    </Grid>
                </StyledGridItemContainer>
            </Grid>
        </Box>
    );
}
