import { Box, Typography } from '@mui/material';

export default function ErrorMessage() {
    return (
        <Box
            sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '230px',
            }}
        >
            <Typography>There was an error. Please refresh your page or contact Simfuni.</Typography>
        </Box>
    );
}
