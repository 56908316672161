import { Alert } from '@mui/material';
import { Invoice } from '../../../apis/invoice';
import { isSuccess } from '../../../hooks/useFetch';
import { useAppSelector } from '../../../store/reducer/Hooks';

type Props = { invoice: Invoice };

export default function PartiallyPaidAlert({ invoice }: Readonly<Props>) {
    const loanState = useAppSelector((root) => root.LoanReducer).state;

    if (!isSuccess(loanState)) {
        return null;
    }

    const amountPaid = loanState.value.paymentTransactions
        .filter((t) => t.dishonourDate == null)
        .map((t) => -t.amount - t.feeAmount)
        .reduce((accumulator, value) => accumulator + value, 0);
    const remainingBalance = invoice.totalPremiums - amountPaid;

    return (
        <Alert color='info'>
            Recurring payments have been declined. The client will have to pay the remaining balance of{' '}
            {currencyFormatter.format(remainingBalance)} (+ transaction fees) by logging into the policyholder portal.
        </Alert>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: 2,
});
