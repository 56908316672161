import { Box, Breadcrumbs, Button, Drawer, Grid, InputAdornment, Paper, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import ClientList from '../components/ClientList';
import { AddCircleOutline, Search } from '@mui/icons-material';
import CreateClient from './CreateClient';
import { useAppSelector } from '../store/reducer/Hooks';
import React from 'react';

const ClientSearch = () => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { clientUpdateAllowed } = permissions;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [query, setQuery] = useState<string | undefined>('');
    const searchBar = useRef<HTMLInputElement>(null);

    const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        const newValue = searchBar.current?.value;
        if (e.key === 'Enter' && newValue !== query) {
            setQuery(newValue);
        }
    };

    return (
        <>
            <Grid container>
                <Grid item sm={10}>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
                        <Box>Clients</Box>
                    </Breadcrumbs>
                </Grid>
                {clientUpdateAllowed && (
                    <Grid item sm={2} style={{ color: '#FFF', textAlign: 'right' }}>
                        <div>
                            <Button variant='contained' startIcon={<AddCircleOutline />} onClick={handleOpen}>
                                New Client
                            </Button>
                        </div>
                    </Grid>
                )}
            </Grid>
            <Paper variant='flat'>
                <Box sx={{ m: '15px' }}>
                    <Grid container>
                        <Grid item sm={8} />
                        <Grid item sm={4}>
                            <TextField
                                placeholder='by name, email, or phone #'
                                onKeyDown={handleKeyPress}
                                fullWidth
                                inputRef={searchBar}
                                size='small'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <ClientList query={query} />
            </Paper>

            <Drawer
                anchor='right'
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: '50%', maxWidth: '1200px' },
                }}
            >
                <CreateClient />
            </Drawer>
        </>
    );
};

export default ClientSearch;
