import { Search } from '@mui/icons-material';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import { Box, useTheme } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { ClientLeadResult, getClientLeadSummary, getClientLeads } from '../../apis/clientLead';
import { FetchWrapper } from '../../components/FetchWrapper';
import { FetchState, FetchStateType, isSuccess } from '../../hooks/useFetch';
import { setClientLeadSummaryState } from '../../store/reducer/ClientLeadReducer';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import ClientLeadList from './ClientLeadList';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { DATE_COMPACT, DATE_SERVER_FORMAT, getValidateDates } from '../../util/dateUtils';

const pageSize = 10;
const minDate = moment().subtract(5, 'years');
const maxDate = moment();

export default function MatchClientLeads() {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const clientLeadSummary = useAppSelector((root) => root.ClientLeadReducer).state;
    const [loadState, setLoadState] = useState(FetchStateType.LOADING);
    const [clientLeads, setClientLeads] = useState<ClientLeadResult[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [matchedLeads, setMatchedLeads] = useState<string[]>([]);
    const [page, setPage] = useState(1);

    const searchBar = useRef<HTMLInputElement>(null);
    const [searchValue, setSearchValue] = useState<string | undefined>('');
    const [paidStartDate, setPaidStartDate] = useState<string>();
    const [paidEndDate, setPaidEndDate] = useState<string>();

    useEffect(() => {
        let nextPage = page - 1;
        if (matchedLeads.length > 0) {
            const unmatchLeadNo = clientLeads.length - matchedLeads.length;
            nextPage = Math.floor(unmatchLeadNo / pageSize);
        }

        setLoadState(FetchStateType.LOADING);
        getClientLeads({ page: nextPage, pageSize, searchValue, paidStartDate, paidEndDate })
            .then((value) => {
                if (page === 1) {
                    setTotalRecords(value.totalRecords);
                    setClientLeads(value.records);
                } else {
                    const leads = value.records.filter(
                        (record) => !clientLeads.some((lead) => lead.clientLead.uuid === record.clientLead.uuid)
                    ); // filter already returned values
                    setClientLeads((prev) => [...prev, ...leads]);
                    if (value.totalRecords > totalRecords) {
                        setTotalRecords(value.totalRecords);
                    }
                }
                setLoadState(FetchStateType.SUCCESS);
            })
            .catch(() => {
                setLoadState(FetchStateType.ERROR);
            });

        refreshSummary();
    }, [page, searchValue, paidStartDate, paidEndDate]);

    const removeMatchedLead = (uuid: string) => {
        setMatchedLeads([...matchedLeads, uuid]);
        refreshSummary();
    };

    const refreshSummary = () => {
        getClientLeadSummary().then((value) => {
            dispatch(setClientLeadSummaryState({ type: FetchStateType.SUCCESS, value }));
        });
    };

    const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        const newValue = searchBar.current?.value;
        if (e.key === 'Enter' && newValue !== searchValue) {
            setSearchValue(newValue);
            setPage(1);
            setMatchedLeads([]);
        }
    };

    const validDates = getValidateDates(minDate, maxDate);

    const handleStartDateChange = (startDateInput: Moment | null) => {
        if (!validDates(startDateInput, paidEndDate ? moment(paidEndDate, DATE_SERVER_FORMAT) : maxDate)) {
            return;
        }
        setPage(1);
        setPaidStartDate(startDateInput!.format(DATE_SERVER_FORMAT));
    };

    const handleEndDateChange = (endDateInput: Moment | null) => {
        if (!validDates(paidStartDate ? moment(paidStartDate, DATE_SERVER_FORMAT) : minDate, endDateInput)) {
            return;
        }
        setPage(1);
        setPaidEndDate(endDateInput!.format(DATE_SERVER_FORMAT));
    };

    if (!isSuccess(clientLeadSummary) || !clientLeadSummary.value.enabled) {
        return null;
    }

    const getLoadMore = () => {
        if (loadState === FetchStateType.SUCCESS && totalRecords > clientLeads.length) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mt: 4 }}>
                    <Typography variant='caption'>
                        Viewing {clientLeads.length} of {totalRecords} results
                    </Typography>
                    <Button variant='contained' onClick={() => setPage(page + 1)}>
                        Load more
                    </Button>
                </Box>
            );
        }

        return <Box sx={{ mt: 4, height: '72.5px' }}></Box>;
    };

    return (
        <Box>
            <Typography variant='h3' component='h1' mb={1}>
                Match payments
            </Typography>
            <Typography mb={4}>Match client payments with Simfuni invoices.</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 4, gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                    <Box sx={{ bgcolor: common.white, borderRadius: `${theme.shape.borderRadius}px`, width: '220px' }}>
                        <TextField
                            placeholder='Search'
                            onKeyDown={handleKeyPress}
                            inputRef={searchBar}
                            size='small'
                            fullWidth
                            style={{ borderRadius: `${theme.shape.borderRadius}px` }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label='Start date'
                            onChange={handleStartDateChange}
                            slotProps={{
                                textField: {
                                    id: 'startDate',
                                    size: 'small',
                                    sx: {
                                        '& label': {
                                            marginTop: '4px',
                                        },
                                    },
                                },
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            format={DATE_COMPACT}
                        />

                        <DatePicker
                            label='End date'
                            onChange={handleEndDateChange}
                            slotProps={{
                                textField: {
                                    id: 'endDate',
                                    size: 'small',
                                    sx: {
                                        '& label': {
                                            marginTop: '4px',
                                        },
                                    },
                                },
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            format={DATE_COMPACT}
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <Typography component='span'>Total payments to match: </Typography>
                    <Typography variant='h6' component='span'>
                        {currencyFormat.format(clientLeadSummary.value.unmatchedInvoiceSummary.totalUnmatchedAmount)}
                    </Typography>
                </Box>
            </Box>

            {(loadState === FetchStateType.SUCCESS || page !== 1) && (
                <ClientLeadList
                    totalRecords={totalRecords}
                    records={clientLeads}
                    search={searchValue}
                    removeMatchedLead={removeMatchedLead}
                />
            )}
            <FetchWrapper state={{ type: loadState } as FetchState<void>} SuccessComponent={() => null} />

            {getLoadMore()}
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
