import { DependencyList, useEffect, useRef, useState } from 'react';
import { AnyAction, Dispatch } from 'redux';

export enum FetchStateType {
    UNAVAILABLE = 'UNAVAILABLE',
    PENDING = 'PENDING',
    LOADING = 'LOADING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

export type FetchState<T> = SuccessState<T> | LoadingState | ErrorState | UnavailableState | PendingState;

/**
 * See {@link https://simfuni.atlassian.net/wiki/spaces/ENGINEERIN/pages/240517167/99.0.13+useFetch+and+FetchWrapper#useFetch Confluence}
 * for more details, examples, and use cases.
 *
 * Updates return value during API request lifecycle.
 *
 * @param fetch An asynchronous function which returns data from the server
 * @param dependencyList A list of dependencies for the fetch method
 * @param options Options for fetching
 * @returns @see FetchState
 */
export const useFetch = <T>(
    fetch: () => Promise<T>,
    dependencyList?: DependencyList,
    options?: FetchOptions
): FetchState<T> => {
    const [state, setState] = useState<FetchState<T>>(pendingState);
    /* Use a fetch id for each fetch so we only set the state for the most recent fetch */
    const fetchId = useRef<number | undefined>();

    const cannotFetch = () => !!options?.canFetch && !options.canFetch();

    useEffect(() => {
        if (cannotFetch()) {
            setState(unavailableState);
            return;
        }

        const thisFetchId = Math.random();
        fetchId.current = thisFetchId;
        setState(loadingState);
        fetch()
            .then((value) => {
                if (fetchId.current != thisFetchId) {
                    return;
                }

                setState({
                    value,
                    type: FetchStateType.SUCCESS,
                });
            })
            .catch((error) => {
                if (fetchId.current != thisFetchId) {
                    return;
                }

                setState({
                    error,
                    type: FetchStateType.ERROR,
                });
            });
    }, dependencyList ?? []);

    return state;
};

type FetchOptions = {
    canFetch: () => boolean;
};

export type SuccessState<T> = {
    type: FetchStateType.SUCCESS;
    value: T;
};

export type PendingState = {
    type: FetchStateType.PENDING;
};
export const pendingState: PendingState = {
    type: FetchStateType.PENDING,
};

export type LoadingState = {
    type: FetchStateType.LOADING;
};
export const loadingState: LoadingState = {
    type: FetchStateType.LOADING,
};

type UnavailableState = {
    type: FetchStateType.UNAVAILABLE;
};
const unavailableState: UnavailableState = {
    type: FetchStateType.UNAVAILABLE,
};

type ErrorState = {
    type: FetchStateType.ERROR;
    error: Error;
};

export const isSuccess = <T>(toBeDetermined?: FetchState<T>): toBeDetermined is SuccessState<T> => {
    return toBeDetermined?.type === FetchStateType.SUCCESS;
};

export const isLoading = <T>(toBeDetermined?: FetchState<T>): toBeDetermined is LoadingState => {
    return toBeDetermined?.type === FetchStateType.LOADING;
};

export const isError = <T>(toBeDetermined?: FetchState<T>): toBeDetermined is ErrorState => {
    return toBeDetermined?.type === FetchStateType.ERROR;
};

export const isPending = <T>(toBeDetermined?: FetchState<T>): toBeDetermined is PendingState => {
    return toBeDetermined?.type === FetchStateType.PENDING;
};

export const isUnavailable = <T>(toBeDetermined?: FetchState<T>): toBeDetermined is UnavailableState => {
    return toBeDetermined?.type === FetchStateType.UNAVAILABLE;
};

export const simpleStoreFetch = async <T>({
    dispatch,
    setState,
    fetch,
}: {
    dispatch: Dispatch<AnyAction>;
    setState: (state: FetchState<T>) => AnyAction;
    fetch: () => Promise<T>;
}) => {
    dispatch(setState(loadingState));
    await fetch()
        .then((value) => dispatch(setState({ value, type: FetchStateType.SUCCESS })))
        .catch((error) => dispatch(setState({ error, type: FetchStateType.ERROR })));
};
