import { HelpOutlined } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';

type Props = {
    amount: number;
    paidAmount?: number;
    feeAmount?: number;
};

export default function PaymentAmount(payment: Readonly<Props>) {
    const paidAmount = payment.paidAmount ?? 0;
    const feeAmount = payment.feeAmount ?? 0;
    const isPartiallyPaid = paidAmount > 0 && payment.amount > paidAmount;

    return (
        <>
            {isPartiallyPaid && (
                <>
                    <Typography variant='caption' sx={{ textDecorationLine: 'line-through' }}>
                        {currencyFormat.format(paidAmount)}
                    </Typography>
                    <Typography variant='caption' sx={{ ml: 1 }}>
                        + {currencyFormat.format(payment.amount - paidAmount + feeAmount)}
                    </Typography>
                </>
            )}
            {!isPartiallyPaid && (
                <Typography variant='caption'>{currencyFormat.format(payment.amount + feeAmount)}</Typography>
            )}
            {feeAmount > 0 && (
                <Tooltip title={`includes transaction fee of ${currencyFormat.format(feeAmount)}`}>
                    <IconButton>
                        <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
