import { ArrowUpward, SwapVert } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { parseInt } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { Direction, defaultPageSize, sortOrderToDirection } from '../../apis/common';
import { LoanArrangementType, OverdueLoanResult, OverdueLoanSortProperty, searchOverdueLoans } from '../../apis/loan';
import ErrorMessage from '../../components/ErrorMessage';
import NoSearchResults from '../../components/NoSearchResults';
import PageLoading from '../../components/PageLoading';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { isError, isSuccess, useFetch } from '../../hooks/useFetch';
import { useOpenRow } from '../../hooks/useOpenRow';
import { getWorklistStyles } from '../../style/theme';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../util/dateUtils';

type Props = {
    inArrangements: LoanArrangementType[];
    dpdStartString?: string;
    dpdEndString?: string;
};
const DEBOUNCE_MS = 500;

export default function List({ inArrangements, dpdStartString, dpdEndString }: Readonly<Props>) {
    const { rowClick, setUrl } = useOpenRow();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [sortProperty, setSortProperty] = useState(OverdueLoanSortProperty.DAYS_PAST_DUE);
    const [direction, setDirection] = useState(Direction.DESC);

    const debouncedInArrangements = useDebouncedValue(inArrangements, DEBOUNCE_MS);
    const [dpdStart, setDpdStart] = useState<number | undefined>();
    const debouncedDpdStart = useDebouncedValue(dpdStart, DEBOUNCE_MS);
    const [dpdEnd, setDpdEnd] = useState<number | undefined>();
    const debouncedDpdEnd = useDebouncedValue(dpdEnd, DEBOUNCE_MS);

    useEffect(() => {
        setIfPositiveInt(setDpdStart, dpdStartString);
    }, [dpdStartString]);

    useEffect(() => {
        setIfPositiveInt(setDpdEnd, dpdEndString);
    }, [dpdEndString]);

    const state = useFetch(
        () =>
            searchOverdueLoans({
                page: page - 1,
                pageSize,
                sortProperty,
                direction,
                inArrangements: debouncedInArrangements,
                dpdStart: debouncedDpdStart,
                dpdEnd: debouncedDpdEnd,
            }),
        [page, pageSize, sortProperty, direction, debouncedInArrangements, debouncedDpdStart, debouncedDpdEnd],
        {
            canFetch: () => debouncedDpdStart != null && debouncedDpdEnd != null,
        }
    );

    const handleSort = (column: TableColumn<OverdueLoanResult>, sortOrder: SortOrder) => {
        setSortProperty(column.id as OverdueLoanSortProperty);
        setDirection(sortOrderToDirection(sortOrder));
    };

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <DataTable
            data={isSuccess(state) ? state.value.records : []}
            columns={columns}
            onRowClicked={(row: OverdueLoanResult, e: React.MouseEvent) => {
                rowClick('/invoices/' + row.invoiceIdentifier, e);
            }}
            onRowMouseEnter={(row: OverdueLoanResult) => {
                setUrl('/invoices/' + row.invoiceIdentifier);
            }}
            pointerOnHover={true}
            highlightOnHover={true}
            pagination
            paginationServer
            onSort={handleSort}
            defaultSortAsc={false}
            defaultSortFieldId={OverdueLoanSortProperty.DAYS_PAST_DUE}
            sortIcon={<SortIcons />}
            sortServer
            onChangePage={setPage}
            onChangeRowsPerPage={setPageSize}
            paginationPerPage={pageSize}
            paginationDefaultPage={page}
            paginationTotalRows={isSuccess(state) ? state.value.totalRecords : 0}
            progressPending={!isSuccess(state)}
            progressComponent={<PageLoading />}
            noDataComponent={
                <Box width='100%'>
                    <NoSearchResults />
                </Box>
            }
            customStyles={getWorklistStyles(sortProperty)}
        />
    );
}

const columns: TableColumn<OverdueLoanResult>[] = [
    {
        name: 'Invoice',
        selector: (row) => row.invoiceNumber,
    },
    {
        name: 'Client',
        selector: (row) => row.insuredName,
    },
    {
        name: 'Last attempt',
        selector: (row) => moment(row.lastAttemptDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY),
        sortable: true,
        id: OverdueLoanSortProperty.LAST_ATTEMPT_DATE,
    },
    {
        name: 'Next attempt',
        selector: (row) =>
            row.nextAttemptDate ? moment(row.nextAttemptDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY) : '-',
        sortable: true,
        id: OverdueLoanSortProperty.NEXT_ATTEMPT_DATE,
    },
    {
        name: 'Days past due',
        cell: (row) => <Chip label={row.daysPastDue} data-tag='allowRowEvents' />,
        sortable: true,
        id: OverdueLoanSortProperty.DAYS_PAST_DUE,
    },
    {
        name: 'Overdue',
        selector: (row) => currencyFormatter.format(row.overdueAmount),
        sortable: true,
        id: OverdueLoanSortProperty.OVERDUE_AMOUNT,
        right: true,
    },
    {
        name: 'Promise to pay',
        cell: (row) => {
            if (!row.inArrangement) return '-';
            return <Chip label='Active' data-tag='allowRowEvents' color='success' variant='outlined' />;
        },
        sortable: true,
        id: OverdueLoanSortProperty.IN_ARRANGEMENT,
    },
];

const SortIcons = () => (
    <Box sx={{ display: 'flex' }}>
        <Box sx={{ color: grey[600] }}>
            <ArrowUpward className='selectedSort' />
        </Box>
        <Box sx={{ color: grey[400] }}>
            <SwapVert className='unselectedSort' />
        </Box>
    </Box>
);

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: 2,
});

const setIfPositiveInt = (setter: (int?: number) => void, asString?: string) => {
    if (!asString) {
        setter(undefined);
        return;
    }
    const asInt = parseInt(asString);
    if (!isNaN(asInt) && asInt >= 0) {
        setter(asInt);
    }
};
