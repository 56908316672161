import { Box, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { RecordExternalTransactionAction } from '../../../../apis/variations';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';

type Props = {
    data: RecordExternalTransactionAction;
};

export default function AddTransactionActionDetails({ data }: Readonly<Props>) {
    const theme = useTheme();

    return (
        <Box>
            <Grid
                container
                sx={{
                    border: grey[400],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: theme.shape.borderRadius + 'px',
                }}
            >
                <StyledGridHeaderContainer>
                    <Grid item xs={4} pr={2}>
                        <Typography variant='subtitle2'>Transaction Type</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle2'>Transaction Date</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='subtitle2'>Amount</Typography>
                    </Grid>
                </StyledGridHeaderContainer>
                <StyledGridItemContainer>
                    <Grid item xs={4}>
                        <Typography variant='caption'>{data.amount < 0 ? 'Payment' : 'Refund'}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='caption'>{moment(data.transactionDate).format(DATE_FRIENDLY)}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='caption'>{currencyFormat.format(Math.abs(data.amount))}</Typography>
                    </Grid>
                </StyledGridItemContainer>
            </Grid>
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
