import { createSlice } from '@reduxjs/toolkit';

type CheckoutOriginAction = {
    type: string;
    payload: string;
};

const initialState: { checkoutOrigin?: string | null } = {
    checkoutOrigin: undefined,
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setCheckoutOrigin: (state, action: CheckoutOriginAction) => {
            state.checkoutOrigin = action.payload;
        },
        clearConfig: (state) => {
            state.checkoutOrigin = null;
        },
    },
});

export const { setCheckoutOrigin, clearConfig } = configSlice.actions;

export default configSlice.reducer;
