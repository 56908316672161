import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { Communication } from '../../apis/communications';
import { DATE_VERBOSE } from '../../util/dateUtils';
import DayLine from './DayLine';

type Props = {
    communications: Communication[];
    invoiceIdentifier?: string;
};

export default function Timeline({ communications, invoiceIdentifier }: Readonly<Props>) {
    const groupedComms = groupComms(communications);

    if (groupedComms == null) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', p: 3, pb: 4 }}>
                <Typography variant='body2'>
                    No records found.
                    <br />
                    Automated messages sent to the client will appear here.
                </Typography>
            </Box>
        );
    }

    return (
        <Box mb={4}>
            {Object.entries(groupedComms).map(([key, comms], index) => {
                return (
                    <Box key={key}>
                        <DayLine
                            dateTime={key}
                            communications={comms}
                            hasTail={index < Object.keys(groupedComms).length - 1}
                            invoiceIdentifier={invoiceIdentifier}
                        />
                    </Box>
                );
            })}
        </Box>
    );
}

type GroupedComms = Record<string, Communication[]>;

const groupComms = (communications: Communication[]): GroupedComms | null => {
    if (communications == null || communications.length === 0) {
        return null;
    }

    const groupedComms: GroupedComms = {};
    communications.forEach((comm) => {
        const submittedAt = getSubmittedKey(comm);
        if (!(submittedAt in groupedComms)) {
            groupedComms[submittedAt] = [];
        }
        groupedComms[submittedAt].push(comm);
    });
    return groupedComms;
};

const getSubmittedKey = (communication: Communication) => {
    const submittedAt = moment.utc(communication.submittedAt).local();
    if (moment().isSame(submittedAt, 'day')) {
        return `Today - ${submittedAt.format(DATE_VERBOSE)}`;
    }

    return submittedAt.format(DATE_VERBOSE);
};
