import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Fab, useTheme } from '@mui/material';
import { sortBy } from 'lodash';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Moment from 'react-moment';
import { ConsolidatedPayment, ExpectedPayment, ExpectedPaymentStatus, Loan } from '../../../apis/invoice';
import DetailedPaymentAmount from '../../../components/DetailedPaymentAmount';
import ExpectedPaymentStatusChip from '../../../components/ExpectedPaymentStatusChip';
import { getConsolidatedPaymentsBeforeCancellation } from '../common';
import { customStyles, initialDisplayAmount } from './tableStyles';
import NoRecords from '../../../components/NoRecords';

type Props = {
    loan: Loan;
};

export default function UpcomingPayments({ loan }: Readonly<Props>) {
    const theme = useTheme();
    const [showMore, setShowMore] = useState<boolean>(false);
    const upcomingPayments = getUpcomingPayments(getConsolidatedPaymentsBeforeCancellation(loan));

    return (
        <Box>
            <DataTable
                data={showMore ? upcomingPayments : upcomingPayments.slice(0, initialDisplayAmount)}
                columns={columns}
                striped={true}
                customStyles={customStyles(theme)}
                noDataComponent={<NoRecords />}
            />
            {upcomingPayments.length > initialDisplayAmount && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                    <Fab size='small' onClick={() => setShowMore(!showMore)} data-testid='show-more-payments'>
                        {showMore ? <ExpandLess /> : <ExpandMore />}
                    </Fab>
                </Box>
            )}
        </Box>
    );
}

const columns: TableColumn<UpcomingPayment>[] = [
    {
        name: 'Due Date',
        cell: (expectedPayment) => {
            return (
                <>
                    <Moment format='DD/MM/YYYY' style={{ marginRight: '8px' }}>
                        {expectedPayment.dueDate}
                    </Moment>
                    <ExpectedPaymentStatusChip expectedPaymentStatus={expectedPayment.status} />
                </>
            );
        },
    },
    {
        name: 'Amount',
        cell: (expectedPayment) => <DetailedPaymentAmount {...expectedPayment} />,
        right: true,
    },
];

type UpcomingPayment = Omit<ConsolidatedPayment, 'consolidatedExpectedPayments'> & {
    expectedPayments: ExpectedPayment[];
};

const PAID_STATUSES = [ExpectedPaymentStatus.PAID, ExpectedPaymentStatus.WAIVED];
const getUpcomingPayments = (consolidatedPayments: ConsolidatedPayment[]): UpcomingPayment[] => {
    const consolidated = consolidatedPayments
        .filter(({ status }) => !PAID_STATUSES.includes(status))
        .map(({ consolidatedExpectedPayments, ...rest }) => ({
            ...rest,
            expectedPayments: consolidatedExpectedPayments.flatMap((consolidated) => consolidated.expectedPayments),
        }));

    return sortBy(consolidated, 'dueDate');
};
