import { Box, Grid, Typography } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form/dist/types/fieldArray';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Invoice } from '../../../apis/invoice';
import { BaseInvoiceFormFields, tallyPremiums } from '../../CreateInvoice/invoiceValidation';
import PolicyDetailFields from '../../../components/PolicyDetailFields';

type Props = {
    control: Control<BaseInvoiceFormFields>;
    fields: FieldArrayWithId[];
    remove: UseFieldArrayRemove;
    errors: FieldErrors<BaseInvoiceFormFields>;
    register: UseFormRegister<BaseInvoiceFormFields>;
    setValue: UseFormSetValue<BaseInvoiceFormFields>;
    invoice: Invoice;
};

export default function PortfolioDetails({
    setValue,
    fields,
    remove,
    control,
    errors,
    register,
    invoice,
}: Readonly<Props>) {
    const [totalPremiums, setTotalPremiums] = useState(0);
    const [premiumDifference, setPremiumDifference] = useState(0);
    const policies = useWatch({ control, name: 'policies' });

    useEffect(() => {
        const premiums = tallyPremiums(policies);
        setTotalPremiums(premiums);
        setPremiumDifference(premiums - invoice.totalPremiums);
    }, [policies]);

    return (
        <Box>
            <Typography variant='subtitle2'>Policy details</Typography>
            <Box sx={{ p: 1, display: 'flex', bgcolor: blueGrey[50], border: `1px solid ${grey[400]}`, mt: 1 }}>
                <Grid container spacing={2}>
                    {['Number', 'Provider', 'Classcode', 'Start date', 'End date', 'Premium'].map((header) => (
                        <Grid key={header} item xs={2}>
                            <Typography variant='caption'>{header}</Typography>
                        </Grid>
                    ))}
                </Grid>
                <Box width={40}></Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PolicyDetailFields
                    control={control}
                    fields={fields}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    remove={remove}
                    readonlyProvider
                    readonlyClassCode
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}>
                    <Typography variant='caption'>Total premiums</Typography>
                    <Typography variant='caption'>{currencyFormat.format(totalPremiums)}</Typography>
                </Box>
                {premiumDifference !== 0 && (
                    <Typography variant='body2'>
                        {premiumDifference > 0 ? 'Increase' : 'Decrease'} of{' '}
                        {currencyFormat.format(Math.abs(premiumDifference))}
                    </Typography>
                )}
            </Box>
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
