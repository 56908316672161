import moment from 'moment';
import { Policy } from '../apis/invoice';
import { DATE_SERVER_FORMAT } from './dateUtils';

export const getEndDate = (policies: Policy[]) => {
    const endDates = policies.map((policy) => moment(policy.endDate, DATE_SERVER_FORMAT));
    return moment.max(endDates).endOf('day');
};

export const getStartDate = (policies: Policy[]) => {
    const startDates = policies.map((policy) => moment(policy.startDate, DATE_SERVER_FORMAT));
    return moment.min(startDates).endOf('day');
};
