import { Box } from '@mui/system';
import { ClientLeadResult } from '../../apis/clientLead';
import ClientLeadCell from './ClientLeadCell';
import UpToDate from './UpToDate';
import NoSearchResults from '../../components/NoSearchResults';

export type OtherProps = {
    removeMatchedLead: (uuid: string) => void;
    search?: string;
};

type Props = OtherProps & {
    totalRecords: number;
    records: ClientLeadResult[];
};

export default function ClientLeadList({ totalRecords, records, removeMatchedLead, search }: Readonly<Props>) {
    if (totalRecords === 0) {
        if (search) {
            return <NoSearchResults />;
        }

        return <UpToDate />;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {records.map((result) => (
                <ClientLeadCell key={result.clientLead.uuid} result={result} removeMatchedLead={removeMatchedLead} />
            ))}
        </Box>
    );
}
