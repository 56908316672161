import { Chip, Grid, Paper, Typography } from '@mui/material';

import Moment from 'react-moment';
import { AuthorizedIndividual, Client, isBusiness, isTrust } from '../../apis/clients';
import { DATE_FRIENDLY } from '../../util/dateUtils';

interface ClientSummaryProps {
    client: Client;
}

const ClientSummary = (props: ClientSummaryProps) => {
    const { client } = props;

    const detail = (title: string, description: string | React.ReactElement) => {
        return (
            <>
                <Grid item sm={5}>
                    <Typography variant='body2'>{title}</Typography>
                </Grid>
                <Grid item sm={7}>
                    <Typography variant='subtitle2'>{description}</Typography>
                </Grid>
            </>
        );
    };

    const authorizedIndividual = (authorizedIndividual: AuthorizedIndividual) => {
        return (
            <>
                <Grid item sm={12} sx={{ mt: 2 }}>
                    <Typography variant='subtitle2'>Authorized inidividual</Typography>
                </Grid>
                {detail('First name', authorizedIndividual.personalDetails.givenName)}
                {detail('Last name', authorizedIndividual.personalDetails.surname)}
                {detail('Email', authorizedIndividual.contactDetails.email.address)}
                {detail('Mobile', authorizedIndividual.contactDetails.preferredPhoneNumber.number)}
            </>
        );
    };

    return (
        <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
            <Typography variant='h6'>Summary</Typography>

            <Grid container style={{ textAlign: 'left' }} sx={{ mt: '5px' }}>
                <Grid item sm={5}>
                    <Typography variant='body2'>Status</Typography>
                </Grid>
                <Grid item sm={7}>
                    <Chip color='success' label='Active' />
                </Grid>
                {detail('Created', <Moment format={DATE_FRIENDLY}>{client.createdDate}</Moment>)}
                {isBusiness(client) && (
                    <>
                        {detail('Business name', client.businessName)}
                        {detail('Business type', client.entityType)}
                        {detail('Business number', client.businessNumber)}
                        {detail('Companies office number', client.companiesOfficeNumber)}
                        {authorizedIndividual(client.authorizedIndividual)}
                    </>
                )}
                {isTrust(client) && (
                    <>
                        {detail('Business number', client.businessNumber)}
                        {authorizedIndividual(client.authorizedIndividual)}
                    </>
                )}
            </Grid>
        </Paper>
    );
};

export default ClientSummary;
