import { useTheme } from '@mui/material';

export default () => {
    const theme = useTheme();

    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            data-testid='classcode-glass'
        >
            <g clipPath='url(#clip0_9_34)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.7184 9.60026L32.2816 9.60022L32.5482 10.6581C33.4081 14.071 33.7678 17.6194 32.9058 20.6368C32.0893 23.4948 30.2 25.777 26.9421 26.9698L26.6414 34.4945C27.0117 34.5265 27.4254 34.5703 27.8337 34.6358C28.348 34.7185 28.9459 34.8493 29.4626 35.0843C29.9232 35.2937 30.7217 35.7729 30.8963 36.7549L31.1889 38.4H17.8111L18.1036 36.7549C18.281 35.7572 19.107 35.2818 19.5616 35.0795C20.083 34.8475 20.6871 34.7176 21.2059 34.6353C21.6017 34.5726 22.0011 34.5298 22.3588 34.4982L22.0578 26.9697C18.8 25.7769 16.9107 23.4948 16.0942 20.6368C15.2322 17.6194 15.5918 14.071 16.4518 10.6581L16.7184 9.60026ZM23.4177 25.9362L23.8112 35.783C23.8036 35.784 23.7956 35.7851 23.7875 35.7862C23.6439 35.8048 23.4265 35.8205 23.1642 35.8395C21.9138 35.93 19.643 36.0943 19.482 37H29.518C29.3554 36.0856 27.1093 35.927 25.8348 35.837C25.5806 35.819 25.3651 35.8038 25.2125 35.7858C25.2044 35.7849 25.1965 35.7839 25.1887 35.783L25.5823 25.9362C32.3714 24.0554 32.8633 17.6384 31.1906 11.0002H26.0742L24.4999 15.3151L26.5661 15.0939L23.4176 21.732L24.4015 16.4214L22.4336 16.6426L23.516 11L17.8094 11.0002C16.1367 17.6384 16.6287 24.0553 23.4177 25.9362ZM28.366 37.6642C28.3809 37.6726 28.3862 37.6774 28.3862 37.6774C28.3862 37.6774 28.378 37.6733 28.366 37.6642ZM20.6071 37.6868C20.6071 37.6868 20.613 37.6813 20.6303 37.6717C20.6166 37.6822 20.6071 37.6868 20.6071 37.6868Z'
                    fill={theme.palette.primary.main}
                />
            </g>
            <defs>
                <clipPath id='clip0_9_34'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
