import { AccountBalance, Payment } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { PaymentMethod, isBankAccount, isBankTransfer, isCard } from '../../../apis/clientLead';

// @ts-ignore
import anzLogo from '../../../images/bankLogos/anz-logo.png';
// @ts-ignore
import asbLogo from '../../../images/bankLogos/asb-logo.png';
// @ts-ignore
import bnzLogo from '../../../images/bankLogos/bnz-logo.png';
// @ts-ignore
import bocLogo from '../../../images/bankLogos/boc-logo.png';
// @ts-ignore
import ccbLogo from '../../../images/bankLogos/ccb-logo.png';
// @ts-ignore
import citiLogo from '../../../images/bankLogos/citi-logo.svg';
// @ts-ignore
import hsbcLogo from '../../../images/bankLogos/hsbc-logo.svg';
// @ts-ignore
import icbcLogo from '../../../images/bankLogos/icbc-logo.jpg';
// @ts-ignore
import kiwibankLogo from '../../../images/bankLogos/kiwibank-logo.png';
// @ts-ignore
import tsbLogo from '../../../images/bankLogos/tsb-logo.svg';
// @ts-ignore
import westpacLogo from '../../../images/bankLogos/westpac-logo.png';

// @ts-ignore
import visaLogo from '../../../images/visa.svg';
// @ts-ignore
import mcLogo from '../../../images/mastercard.svg';

export default ({ paymentMethod }: { paymentMethod: PaymentMethod }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                flexDirection: 'row',
                justifyContent: 'flex-start',
            }}
        >
            <Box sx={{ display: 'flex' }}>{getPaymentIcon(paymentMethod)}</Box>
            <Typography variant='body1' fontWeight={300}>
                {getPaymentMethodNumber(paymentMethod)}
            </Typography>
        </Box>
    );
};

const getPaymentMethodNumber = (data: PaymentMethod) => {
    if (isBankAccount(data) || isBankTransfer(data)) {
        return data.bankAccountNumber;
    }
    if (isCard(data)) {
        return data.maskedCardNumber;
    }
};

const getPaymentIcon = (data: PaymentMethod) => {
    if (isBankAccount(data) || isBankTransfer(data)) {
        switch (data.bankName) {
            case 'ANZ Bank New Zealand':
                return <img src={anzLogo} height={20} alt='ANZ Bank New Zealand logo' />;
            case 'Bank of New Zealand':
                return <img src={bnzLogo} height={20} alt='Bank of New Zealand logo' />;
            case 'Westpac':
                return <img src={westpacLogo} height={16} alt='Westpac logo' />;
            case 'China Construction Bank NZ Ltd':
                return <img src={ccbLogo} height={20} alt='China Construction Bank NZ Ltd logo' />;
            case 'Industrial and Commercial Bank of China (New Zealand) Ltd':
                return (
                    <img
                        src={icbcLogo}
                        height={20}
                        alt='Industrial and Commercial Bank of China (New Zealand) Ltd logo'
                    />
                );
            case 'ASB Bank':
                return <img src={asbLogo} height={20} alt='ASB Bank logo' />;
            case 'TSB Bank':
                return <img src={tsbLogo} height={20} alt='TSB Bank logo' />;
            case 'HSBC New Zealand':
                return <img src={hsbcLogo} height={20} alt='HSBC New Zealand logo' />;
            case 'Citibank N.A.':
                return <img src={citiLogo} height={20} alt='Citibank N.A. logo' />;
            case 'Kiwibank':
                return <img src={kiwibankLogo} height={20} alt='Kiwibank logo' />;
            case 'Bank of China NZ Ltd':
                return <img src={bocLogo} height={20} alt='Bank of China NZ Ltd logo' />;
            default:
                return <AccountBalance fontSize='small' sx={{ color: grey['600'] }} data-testid='unknown-bank-icon' />;
        }
    }
    if (isCard(data)) {
        switch (data.maskedCardNumber[0]) {
            case '2':
            case '5':
                return <img src={mcLogo} height={20} alt='Mastercard logo' />;
            case '4':
                return <img src={visaLogo} height={20} alt='Visa logo' />;
            default:
                return <Payment fontSize='small' sx={{ color: grey['600'] }} data-testid='unknown-card-icon' />;
        }
    }
    return null;
};
