import { useTheme } from '@mui/material';

export default () => {
    const theme = useTheme();

    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            data-testid='classcode-caravan'
        >
            <g clipPath='url(#clip0_8_74)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.8 18C13.8059 18 13 18.8059 13 19.8V22.2C13 23.1941 13.8059 24 14.8 24H23.2C24.1941 24 25 23.1941 25 22.2V19.8C25 18.8059 24.1941 18 23.2 18H14.8ZM23.2 19.5H14.8C14.6343 19.5 14.5 19.6343 14.5 19.8V22.2C14.5 22.3657 14.6343 22.5 14.8 22.5H23.2C23.3657 22.5 23.5 22.3657 23.5 22.2V19.8C23.5 19.6343 23.3657 19.5 23.2 19.5Z'
                    fill={theme.palette.primary.main}
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M21.874 33H36.2C37.1941 33 38 32.1941 38 31.2V20C38 16.6863 35.3137 14 32 14H14C10.6863 14 8 16.6863 8 20V27C8 30.3137 10.6863 33 14 33H14.126C14.5701 34.7252 16.1362 36 18 36C19.8638 36 21.4299 34.7252 21.874 33ZM32 15.5H14C11.5147 15.5 9.5 17.5147 9.5 20V27C9.5 29.4853 11.5147 31.5 14 31.5H14.0309C14.277 29.5268 15.9602 28 18 28C20.0398 28 21.723 29.5268 21.9691 31.5H28.25V21C28.25 20.0335 29.0335 19.25 30 19.25H33C33.9665 19.25 34.75 20.0335 34.75 21V31.5H36.2C36.3657 31.5 36.5 31.3657 36.5 31.2V20C36.5 17.5147 34.4853 15.5 32 15.5ZM20.6 32C20.6 33.4359 19.4359 34.6 18 34.6C16.5641 34.6 15.4 33.4359 15.4 32C15.4 30.5641 16.5641 29.4 18 29.4C19.4359 29.4 20.6 30.5641 20.6 32ZM29.75 21C29.75 20.8619 29.8619 20.75 30 20.75H33C33.1381 20.75 33.25 20.8619 33.25 21V31.25H29.75V21Z'
                    fill={theme.palette.primary.main}
                />
            </g>
            <defs>
                <clipPath id='clip0_8_74'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
