import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import Steps from './Steps';

type Props<T extends number> = {
    open: boolean;
    setClosed: () => void;
    step: T;
    stepNames: string[];
    children: React.ReactNode;
    size?: 'medium' | 'large';
} & DialogProps;

export default <T extends number>({ open, setClosed, step, stepNames, children, size, ...rest }: Props<T>) => {
    const closeButtonStyles = {
        backgroundColor: grey['300'],
        width: '44px',
        height: '44px',
        borderRadius: 30,
        // mobile
        alignSelf: 'flex-end',
        // md and up
        position: { md: 'absolute' },
        top: { md: 0 },
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={setClosed}
            TransitionComponent={Transition}
            scroll='paper'
            sx={{ '.MuiDialog-paper': { bgcolor: '#FCFCFE' } }}
            {...rest}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    flexDirection: 'column-reverse',
                    gap: 2,
                    my: 4,
                    mx: { xs: 2, md: 4 },
                }}
            >
                <Box
                    sx={{
                        maxWidth: size !== 'large' ? '900px' : '1100px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: { xs: 2, sm: 5 },
                    }}
                >
                    <Steps selectedStep={step} stepNames={stepNames} />
                    {children}
                </Box>
                <IconButton sx={closeButtonStyles} onClick={setClosed}>
                    <Close color='action' />
                </IconButton>
            </Box>
        </Dialog>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction='up' ref={ref} {...props} />;
});
