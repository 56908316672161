import { Box, Button, Collapse, Divider, Grid, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { TIME_FRIENDLY } from '../../util/dateUtils';
import { Communication, DeliveryType } from '../../apis/communications';
import { grey } from '@mui/material/colors';
import { ExpandLess, ExpandMore, MailOutline, SmsOutlined } from '@mui/icons-material';
import { readableNotificationType } from './utils';
import { useState } from 'react';
import Details from './Details';

type Props = {
    invoiceIdentifier?: string;
    communication: Communication;
    isFirst: boolean;
    hasTail: boolean;
};

export default function LineItem({ communication, isFirst, hasTail, invoiceIdentifier }: Readonly<Props>) {
    const [showDetails, setShowDetails] = useState(false);
    const theme = useTheme();

    const submittedAt = moment.utc(communication.submittedAt).local();
    const time = submittedAt.format(TIME_FRIENDLY);

    return (
        <Grid container>
            {isFirst && <DividerRow />}

            <Grid item xs={1}>
                <Box sx={{ display: 'flex', mt: 1 }}>
                    <Typography>{time}</Typography>
                </Box>
            </Grid>
            <Grid item xs={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                    <Box
                        sx={{
                            width: '41px',
                            height: '41px',
                            borderRadius: '25px',
                            backgroundColor: grey[200],
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color:
                                communication.template.templateType === DeliveryType.EMAIL
                                    ? theme.palette.primary.dark
                                    : theme.palette.green[120],
                        }}
                    >
                        {communication.template.templateType === DeliveryType.EMAIL ? <MailOutline /> : <SmsOutlined />}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                        <Divider orientation='vertical' />
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={10} gridRow={2}>
                <Box>
                    <Box ml={1}>
                        <Typography>{readableNotificationType(communication.template.notificationType)}</Typography>
                    </Box>
                    <Button
                        endIcon={showDetails ? <ExpandLess /> : <ExpandMore />}
                        onClick={() => setShowDetails(!showDetails)}
                        sx={{ my: 1 }}
                    >
                        {showDetails ? 'Hide' : 'Show'} details
                    </Button>
                    <Collapse in={showDetails} sx={{ ml: 1 }}>
                        <Details communication={communication} invoiceIdentifier={invoiceIdentifier} />
                    </Collapse>
                </Box>
            </Grid>

            {hasTail && <DividerRow />}
        </Grid>
    );
}

const DividerRow = () => {
    return (
        <>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Divider orientation='vertical' sx={{ minHeight: '44px' }} />
                </Box>
            </Grid>
            <Grid item xs={10}></Grid>
        </>
    );
};
