import { styled, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, Ref } from 'react';

const CovertNumberTextField = forwardRef(({ ...rest }: TextFieldProps, ref) => {
    return (
        <Field
            ref={ref as Ref<HTMLDivElement>}
            type='number'
            onWheel={(e) => (e.target as HTMLElement).blur()}
            {...rest}
        />
    );
});

export default CovertNumberTextField;

const Field = styled(TextField)({
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
});
