import { AddCircleOutline } from '@mui/icons-material';
import { Box, Button, Drawer, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Direction } from '../../../apis/common';
import { InvoiceNoteDto, InvoiceNoteSortProperty, searchInvoiceNotes } from '../../../apis/invoice';
import { FetchWrapper } from '../../../components/FetchWrapper';
import { FetchState, FetchStateType } from '../../../hooks/useFetch';
import { useAppSelector } from '../../../store/reducer/Hooks';
import CreateInvoiceNote from './CreateInvoiceNote';
import InvoiceNote from './InvoiceNote';
import SnackAlert, { SnackState } from '../../../components/SnackAlert';

type Props = {
    invoiceIdentifier: string;
};

const DEFAULT_PAGE_SIZE = 15;

export default function InvoiceNotes({ invoiceIdentifier }: Readonly<Props>) {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { invoiceUpdateAllowed } = permissions;

    const [snackOpen, setSnackOpen] = useState(false);
    const [snackState, setSnackState] = useState<SnackState | undefined>();

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [loadState, setLoadState] = useState(FetchStateType.LOADING);
    const [totalRecords, setTotalRecords] = useState(0);
    const [invoiceNotes, setInvoiceNotes] = useState<InvoiceNoteDto[]>([]);

    const [page, setPage] = useState(0);

    useEffect(() => {
        setLoadState(FetchStateType.LOADING);

        searchInvoiceNotes(invoiceIdentifier, {
            page: page,
            pageSize: DEFAULT_PAGE_SIZE,
            invoiceNoteSortProperty: InvoiceNoteSortProperty.CREATED_DATE,
            direction: Direction.DESC,
        })
            .then((result) => {
                setTotalRecords(result.totalRecords);
                if (page === 0) {
                    setInvoiceNotes(result.records);
                } else {
                    setInvoiceNotes((prev) => [...prev, ...result.records]);
                }
                setLoadState(FetchStateType.SUCCESS);
            })
            .catch(() => {
                setLoadState(FetchStateType.ERROR);
            });
    }, [invoiceIdentifier, page]);

    const getLoadMore = () => {
        if (loadState === FetchStateType.SUCCESS && totalRecords > invoiceNotes.length) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mt: 4 }}>
                    <Button variant='contained' onClick={() => setPage(page + 1)}>
                        Load more
                    </Button>
                </Box>
            );
        }

        return null;
    };

    const handleOpenDrawer = () => setOpenDrawer((prev) => !prev);

    const addNewNoteToList = (newInvoiceNote: InvoiceNoteDto) => {
        setInvoiceNotes((prev) => {
            if (prev.length >= DEFAULT_PAGE_SIZE) {
                prev = prev.slice(0, prev.length - 1);
            }
            return [newInvoiceNote, ...prev];
        });
    };

    const closeSnackbar = () => {
        setSnackOpen(false);
    };

    const handleSetSnack = (state: SnackState) => {
        setSnackState(state);
        setSnackOpen(true);
    };

    const renderAddingNote = () => {
        if (!invoiceUpdateAllowed) return;

        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        gap: 2,
                        alignItems: 'center',
                        p: 2,
                        pb: 0,
                    }}
                >
                    <Button
                        variant='outlined'
                        sx={{ backgroundColor: 'white' }}
                        startIcon={<AddCircleOutline />}
                        onClick={handleOpenDrawer}
                    >
                        Add note
                    </Button>
                </Box>
                <Drawer
                    anchor='right'
                    open={openDrawer}
                    onClose={handleOpenDrawer}
                    PaperProps={{
                        sx: { width: '50%', maxWidth: '1200px', overflowY: 'hidden' },
                    }}
                >
                    <CreateInvoiceNote
                        addNewNote={addNewNoteToList}
                        setSnack={handleSetSnack}
                        invoiceIdentifier={invoiceIdentifier}
                        handleOpenDrawer={handleOpenDrawer}
                    />
                </Drawer>
                <SnackAlert state={snackState} open={snackOpen} closeSnackbar={closeSnackbar} />
            </>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Paper variant='flat'>
                {renderAddingNote()}
                <Box sx={{ p: 3 }}>
                    {(loadState === FetchStateType.SUCCESS || page !== 0) && (
                        <InvoiceNote invoiceNotes={invoiceNotes} />
                    )}

                    <FetchWrapper state={{ type: loadState } as FetchState<void>} SuccessComponent={() => null} />

                    {getLoadMore()}
                </Box>
            </Paper>
        </Box>
    );
}
