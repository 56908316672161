import { Box, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { capitalize } from 'lodash';
import { OnDemandPaymentAction, OndemandPaymentType } from '../../../../apis/variations';
import { StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';

type Props = {
    data: OnDemandPaymentAction;
};

export default function OnDemandPaymentActionDetails({ data }: Readonly<Props>) {
    const theme = useTheme();

    return (
        <Box>
            <Grid
                container
                sx={{
                    border: grey[400],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: theme.shape.borderRadius + 'px',
                }}
            >
                <StyledGridHeaderContainer>
                    <Grid item xs={6} pr={2}>
                        <Typography variant='subtitle2'>Payment Type</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='subtitle2'>Amount</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='subtitle2'>Fee Amount</Typography>
                    </Grid>
                    <Grid item xs={2} container direction='row' justifyContent='flex-end'>
                        <Typography variant='subtitle2'>Status</Typography>
                    </Grid>
                </StyledGridHeaderContainer>
                <StyledGridItemContainer>
                    <Grid item xs={6}>
                        <Typography variant='caption'>
                            {data.onDemandPaymentType === OndemandPaymentType.OVERDUE
                                ? 'Pay overdue'
                                : 'Pay outstanding balance'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='caption'>{currencyFormat.format(Math.abs(data.amount))}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='caption'>
                            {currencyFormat.format(Math.abs(data.transactionFeeAmount))}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} container direction='row' justifyContent='flex-end'>
                        <Typography variant='caption'>{capitalize(data.status)}</Typography>
                    </Grid>
                </StyledGridItemContainer>
            </Grid>
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
