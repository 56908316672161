import { useTheme } from '@mui/material';

export default () => {
    const theme = useTheme();

    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            data-testid='classcode-generic'
        >
            <g clipPath='url(#clip0_8_48)'>
                <path
                    d='M22.9882 37.6081C23.2166 37.7061 23.4452 37.804 23.6736 37.9021L23.692 37.9076C23.8907 37.9672 24.1042 37.9536 24.2938 37.8693V37.8693C24.5222 37.7713 24.7508 37.6734 24.9792 37.5754C31.0831 34.9625 35 28.8221 35 21.9959V18.5121C35 17.7979 34.6192 17.1379 34.0009 16.7805L25.0008 11.5785C24.3816 11.2206 23.6184 11.2206 22.9992 11.5785L13.9991 16.7805C13.3808 17.1379 13 17.7979 13 18.5121V21.9959C13 28.8221 16.9169 34.9625 22.9882 37.6081ZM14.6647 19.5419C14.6647 18.836 15.0368 18.1824 15.6438 17.8221L22.9791 13.4678C23.6085 13.0942 24.3916 13.0942 25.0209 13.4678L32.3562 17.8221C32.9632 18.1824 33.3353 18.836 33.3353 19.5419V21.996C33.3353 28.169 29.8101 33.6888 24.3264 36.073V36.073C24.1098 36.1452 23.8902 36.1452 23.6736 36.073V36.073C18.1899 33.6888 14.6647 28.169 14.6647 21.996V19.5419Z'
                    fill={theme.palette.primary.main}
                    stroke={theme.palette.primary.main}
                    strokeWidth='0.2'
                />
            </g>
            <defs>
                <clipPath id='clip0_8_48'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
