import { SearchOff } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function NoSearchResults() {
    return (
        <Paper variant='outlined' sx={{ p: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <SearchOff sx={{ color: grey[600] }} fontSize='large' />
                    <Typography variant='h5' component='h2'>
                        No results found
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
}
