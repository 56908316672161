import { useEffect, useState } from 'react';
import StepsDrawer from '../../../components/StepsDrawer';
import { useAppDispatch } from '../../../store/reducer/Hooks';
import { FetchStateType, isSuccess, useFetch } from '../../../hooks/useFetch';
import PageLoading from '../../../components/PageLoading';
import InputRenewalDetailsStep from './InputRenewalDetailsStep';
import { InvoiceRenewalPreview, editRenewal, previewEditRenewal } from '../../../apis/renewal';
import ReviewStep from './ReviewStep';
import { Invoice, InvoiceDetailsResponse, fetchInvoice } from '../../../apis/invoice';
import { cloneDeep } from 'lodash';
import { setInvoiceDetailsState } from '../../../store/reducer/InvoiceDetailsReducer';
import RenewalEditedDoneStep from './RenewalEditedDoneStep';
import { BaseInvoiceFormFields, getBaseInvoiceRequest } from '../../CreateInvoice/invoiceValidation';

type Props = {
    open: boolean;
    setClosed: () => void;
    invoiceDetails: InvoiceDetailsResponse;
};

export default function EditRenewalSteps({ open, setClosed, invoiceDetails }: Readonly<Props>) {
    const [step, setStep] = useState<Steps>(Steps.EDIT);
    const [renewalPreview, setRenewalPreview] = useState<InvoiceRenewalPreview>();
    const [renewalInvoice, setRenewalInvoice] = useState<Invoice>();
    const renewalParentState = useFetch(
        () => fetchInvoice(invoiceDetails.invoice.renewFromInvoice!.uuid),
        [invoiceDetails]
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        setRenewalPreview({
            invoice: invoiceDetails.invoice,
            renewalNotificationDays: 0, // this will be recalculated
        });
    }, [invoiceDetails]);

    const handleClose = () => {
        if (renewalInvoice != null) {
            const updatedInvoiceDetails = cloneDeep(invoiceDetails);
            updatedInvoiceDetails.invoice = renewalInvoice;
            dispatch(setInvoiceDetailsState({ type: FetchStateType.SUCCESS, value: updatedInvoiceDetails }));
        }

        setStep(Steps.EDIT);
        setClosed();
    };

    const handleSetRenewalPreview = (preview: InvoiceRenewalPreview) => {
        setRenewalPreview(preview);
        setStep(Steps.REVIEW);
    };

    const handleConfirmRenewal = (renewal: Invoice) => {
        setRenewalInvoice(renewal);
        setStep(Steps.DONE);
    };

    const getPreview = async (data: BaseInvoiceFormFields): Promise<InvoiceRenewalPreview> => {
        const request = getBaseInvoiceRequest(data);
        return previewEditRenewal(invoiceDetails.invoice.uuid, request);
    };

    const putEditRenewal = async (renewalInvoice: Invoice) => {
        return editRenewal(invoiceDetails.invoice.uuid, renewalInvoice);
    };

    if (!isSuccess(renewalParentState)) {
        return (
            <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
                <PageLoading />
            </StepsDrawer>
        );
    }

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames} size='large'>
            {step === Steps.EDIT && (
                <InputRenewalDetailsStep
                    invoice={renewalParentState.value.invoice}
                    renewalPreview={renewalPreview}
                    setRenewalPreview={handleSetRenewalPreview}
                    cancel={handleClose}
                    sendData={getPreview}
                />
            )}
            {step === Steps.REVIEW && (
                <ReviewStep
                    invoice={renewalParentState.value.invoice}
                    client={invoiceDetails.client}
                    renewalPreview={renewalPreview!}
                    back={() => setStep(Steps.EDIT)}
                    handleConfirmRenewal={handleConfirmRenewal}
                    sendData={putEditRenewal}
                />
            )}
            {step === Steps.DONE && <RenewalEditedDoneStep handleClose={handleClose} />}
        </StepsDrawer>
    );
}

const stepNames = ['Edit', 'Review', 'Done'];
enum Steps {
    EDIT,
    REVIEW,
    DONE,
}
