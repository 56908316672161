import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Page, PageType } from '.';
import { Logout } from '@mui/icons-material';
import PageButton from './PageButton';

type Props = {
    selectedPage: PageType;
    handlePageClick: (page: Page) => void;
    logout: () => void;
    pages: Page[];
};

export default ({ selectedPage, handlePageClick, logout, pages }: Props) => {
    return (
        <Box sx={{ mx: 1, my: 2 }} component='nav'>
            <List>
                {pages.map((page) => (
                    <PageButton
                        key={page.type}
                        page={page}
                        selectedPage={selectedPage}
                        handlePageClick={handlePageClick}
                    />
                ))}
                <Divider variant='middle' component='li' sx={{ my: 1 }} />
                <ListItemButton data-testid='mobile-logout' onClick={logout}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText primary='Log out' />
                </ListItemButton>
            </List>
        </Box>
    );
};
