import { Policy } from '../../apis/invoice';

export const hasPolicyDurationIssue = (policies?: Policy[]) => {
    const endDates = policies
        ?.map((p) => (p.endDate ? new Date(p.endDate) : null))
        .filter(isValidDate)
        .sort((a, b) => (a as unknown as number) - (b as unknown as number)) as Date[];

    const length = endDates?.length;
    if (length == null || length < 2) {
        return false;
    }

    const today = new Date();

    const lengthOfLoan = (endDates[0] as unknown as number) - (today as unknown as number);
    const longestPolicy = (endDates[length - 1] as unknown as number) - (today as unknown as number);
    const diff = longestPolicy - lengthOfLoan;

    return diff > lengthOfLoan;
};

const isValidDate = (d: unknown) => {
    return d != null && d instanceof Date && !isNaN(d as unknown as number);
};
