import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material';
import { LoadingButton } from './LoadingButton';
import { UseAuthenticator } from '@aws-amplify/ui-react';
import { useAppDispatch, useAppSelector } from '../store/reducer/Hooks';
import { updateExpirationDate } from '../store/reducer/UserSessionReducer';

const PROMPT_TIMER = 60_000; // show warning for 60 seconds
export const SESSION_TIME = 29 * 60 * 1_000; // 29 minutes

interface SessionTimerProps {
    signOut?: UseAuthenticator['signOut'];
}

const SessionTimer = ({ signOut }: SessionTimerProps) => {
    const dispatch = useAppDispatch();
    const expirationDate = useAppSelector((root) => root.UserSessionReducer).expirationDate;
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(SESSION_TIME);
    const [refreshing, setRefreshing] = useState(false);

    useEffect(() => {
        fetchExpiration().catch(handleSessionFail);
    }, []);

    useEffect(() => {
        setSnackbarOpen(false);
        if (expirationDate == null) {
            return;
        }

        const interval = setInterval(() => {
            const timeTillLogout = expirationDate.getTime() - Date.now();
            const timeTillOpen = timeTillLogout - PROMPT_TIMER;
            if (timeTillLogout <= 0) {
                clearInterval(interval);
                handleSessionTimeout();
                return;
            }

            if (timeTillOpen <= 0) {
                setSnackbarOpen(true);
            }

            setTimeRemaining(Math.ceil(timeTillLogout / 1_000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, [expirationDate]);

    async function fetchExpiration(): Promise<void> {
        await Auth.currentSession();
        dispatch(updateExpirationDate());
        setSnackbarOpen(false);
    }

    function handleSessionTimeout(): void {
        if (signOut) {
            signOut();
        } else {
            Auth.signOut();
        }
    }

    function handleSessionFail(): void {
        window.location.reload();
    }

    /*
     * Refresh the user's token silently
     * The docs say that fetching Auth.currentSession() should refresh the tokens, but that doesn't happen
     * See https://github.com/aws-amplify/amplify-js/issues/2560 for more details
     */
    async function refresh(): Promise<void> {
        const user = await Auth.currentAuthenticatedUser();
        const refreshToken = user.signInUserSession.refreshToken;
        await user.refreshSession(refreshToken, fetchExpiration);
    }

    function handleRefresh(): void {
        setRefreshing(true);
        refresh()
            .catch(handleSessionFail)
            .finally(() => setRefreshing(false));
    }

    if (expirationDate == null) {
        return null;
    }

    return (
        <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)}>
            <Alert onClose={() => setSnackbarOpen(false)} severity='warning' elevation={6}>
                <InternalAlert>
                    You will be logged out in {timeRemaining} seconds
                    <LoadingButton
                        onClick={handleRefresh}
                        size='small'
                        color='info'
                        variant='outlined'
                        loading={refreshing}
                    >
                        Keep me logged in
                    </LoadingButton>
                </InternalAlert>
            </Alert>
        </Snackbar>
    );
};

const InternalAlert = styled('div')(({ theme }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    };
});

export default SessionTimer;
