import { Box, Divider, Typography } from '@mui/material';
import moment from 'moment';
import { Invoice } from '../../apis/invoice';
import { BREAKPOINT } from '../../store/reducer/BreakpointReducer';
import { useAppSelector } from '../../store/reducer/Hooks';
import { DATE_FRIENDLY } from '../../util/dateUtils';

type Props = {
    invoice: Invoice;
};

export default function InvoiceFields({ invoice }: Readonly<Props>) {
    const { down } = useAppSelector((state) => state.BreakpointReducer);

    return (
        <Box sx={{ display: 'flex', gap: { xs: 1, md: 2 }, flexDirection: { xs: 'column', md: 'row' } }}>
            <Box>
                <Typography variant='caption'>Invoice number</Typography>
                <Typography variant='body2'>{invoice.number}</Typography>
            </Box>
            <Box>
                <Divider orientation={down(BREAKPOINT.md) ? 'horizontal' : 'vertical'} />
            </Box>
            {invoice.customerInvoiceReference && (
                <>
                    <Box>
                        <Typography variant='caption'>Customer reference</Typography>
                        <Typography variant='body2'>{invoice.customerInvoiceReference}</Typography>
                    </Box>
                    <Box>
                        <Divider orientation={down(BREAKPOINT.md) ? 'horizontal' : 'vertical'} />
                    </Box>
                </>
            )}
            <Box>
                <Typography variant='caption'>Issued date</Typography>
                <Typography variant='body2'>{moment(invoice.issuedDate).format(DATE_FRIENDLY)}</Typography>
            </Box>
            <Box>
                <Divider orientation={down(BREAKPOINT.md) ? 'horizontal' : 'vertical'} />
            </Box>
            <Box>
                <Typography variant='caption'>Due date</Typography>
                <Typography variant='body2'>{moment(invoice.dueDate).format(DATE_FRIENDLY)}</Typography>
            </Box>
        </Box>
    );
}
