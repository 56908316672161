import { createSlice } from '@reduxjs/toolkit';
import { ClientLeadSummary } from '../../apis/clientLead';
import { FetchState, pendingState } from '../../hooks/useFetch';
import { Action } from './Hooks';

const initialState: { state: FetchState<ClientLeadSummary> } = {
    state: pendingState,
};

export const clientLeadSlice = createSlice({
    name: 'clientLeads',
    initialState,
    reducers: {
        setClientLeadSummaryState: (state, action: Action<FetchState<ClientLeadSummary>>) => {
            state.state = action.payload;
        },
        clearClientLeadSummaryState: (state) => {
            state.state = pendingState;
        },
    },
});

export const { setClientLeadSummaryState, clearClientLeadSummaryState } = clientLeadSlice.actions;

export default clientLeadSlice.reducer;
