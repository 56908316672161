import { useCallback } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { getInvoiceReport, Report } from '../../apis/reports';
import { FetchWrapper } from '../../components/FetchWrapper';
import Totals from './Totals';
import { Grid } from '@mui/material';
import ReportDisplay from './ReportDisplay';

interface InvoiceReportProps {
    startDate: string;
    endDate: string;
}

export default (props: InvoiceReportProps) => {
    const { startDate, endDate } = props;
    const state = useFetch(async () => getInvoiceReport(startDate, endDate), [startDate, endDate]);
    const Chart = useCallback(({ data }: { data: Report }) => {
        return (
            <>
                <Totals totals={data.totals} />

                <Grid container spacing={2}>
                    <Grid item lg={6} md={12}>
                        <ReportDisplay reportData={data.data[0]} />
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <ReportDisplay reportData={data.data[1]} />
                    </Grid>
                </Grid>
            </>
        );
    }, []);

    return <FetchWrapper state={state} SuccessComponent={Chart} />;
};
