import { useState } from 'react';
import StepsDrawer from '../../../components/StepsDrawer';
import { useAppDispatch, useAppSelector } from '../../../store/reducer/Hooks';
import { FetchStateType, isSuccess } from '../../../hooks/useFetch';
import PageLoading from '../../../components/PageLoading';
import InputRenewalDetailsStep from './InputRenewalDetailsStep';
import { InvoiceRenewalPreview, createRenewal, previewRenewal } from '../../../apis/renewal';
import ReviewStep from './ReviewStep';
import { Invoice, InvoiceAction } from '../../../apis/invoice';
import { cloneDeep } from 'lodash';
import { setInvoiceDetailsState } from '../../../store/reducer/InvoiceDetailsReducer';
import RenewalCreatedDoneStep from './RenewalCreatedDoneStep';
import { BaseInvoiceFormFields, getBaseInvoiceRequest } from '../../CreateInvoice/invoiceValidation';

type Props = {
    open: boolean;
    setClosed: () => void;
};

export default function CreateRenewalSteps({ open, setClosed }: Readonly<Props>) {
    const [step, setStep] = useState<Steps>(Steps.RENEW);
    const [renewalPreview, setRenewalPreview] = useState<InvoiceRenewalPreview>();
    const [renewalInvoice, setRenewalInvoice] = useState<Invoice>();
    const { state: invoiceState } = useAppSelector((root) => root.InvoiceDetailsReducer);
    const dispatch = useAppDispatch();

    const handleClose = () => {
        if (isSuccess(invoiceState) && renewalInvoice != null) {
            const updatedInvoiceDetails = cloneDeep(invoiceState.value);
            updatedInvoiceDetails.invoice.renewals = [renewalInvoice];
            updatedInvoiceDetails.invoice.canRenew = false;
            updatedInvoiceDetails.allowedActions = updatedInvoiceDetails.allowedActions?.filter(
                (action) => action !== InvoiceAction.RENEWAL
            );
            dispatch(setInvoiceDetailsState({ type: FetchStateType.SUCCESS, value: updatedInvoiceDetails }));
        }

        setStep(Steps.RENEW);
        setClosed();
    };

    const handleSetRenewalPreview = (preview: InvoiceRenewalPreview) => {
        setRenewalPreview(preview);
        setStep(Steps.REVIEW);
    };

    const handleConfirmRenewal = (renewal: Invoice) => {
        setRenewalInvoice(renewal);
        setStep(Steps.DONE);
    };

    const getPreview = async (invoiceUuid: string, data: BaseInvoiceFormFields): Promise<InvoiceRenewalPreview> => {
        const request = getBaseInvoiceRequest(data);
        return previewRenewal(invoiceUuid, request);
    };

    const postRenewal = async (invoiceUuid: string, renewalInvoice: Invoice): Promise<InvoiceRenewalPreview> => {
        return createRenewal(invoiceUuid, renewalInvoice);
    };

    if (!isSuccess(invoiceState)) {
        return (
            <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
                <PageLoading />
            </StepsDrawer>
        );
    }

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames} size='large'>
            {step === Steps.RENEW && (
                <InputRenewalDetailsStep
                    invoice={invoiceState.value.invoice}
                    renewalPreview={renewalPreview}
                    setRenewalPreview={handleSetRenewalPreview}
                    cancel={handleClose}
                    sendData={(data) => getPreview(invoiceState.value.invoice.uuid, data)}
                />
            )}
            {step === Steps.REVIEW && (
                <ReviewStep
                    invoice={invoiceState.value.invoice}
                    client={invoiceState.value.client}
                    renewalPreview={renewalPreview!}
                    back={() => setStep(Steps.RENEW)}
                    handleConfirmRenewal={handleConfirmRenewal}
                    sendData={(data) => postRenewal(invoiceState.value.invoice.uuid, data)}
                />
            )}
            {step === Steps.DONE && <RenewalCreatedDoneStep handleClose={handleClose} />}
        </StepsDrawer>
    );
}

const stepNames = ['Renew', 'Review', 'Done'];
enum Steps {
    RENEW,
    REVIEW,
    DONE,
}
