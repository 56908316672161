import { Box, Paper } from '@mui/material';
import { InvoiceDetailsResponse, Policy } from '../../apis/invoice';
import NoRecords from '../NoRecords';
import QuoteTile from './QuoteTile';
import { SellerProduct } from '../../apis/sellerProduct';

type Props = {
    policies: Policy[];
    sellerProduct: SellerProduct;
    invoiceDetails: InvoiceDetailsResponse;
};

export default function Quotes({ policies, sellerProduct, invoiceDetails }: Readonly<Props>) {
    const policyVersions = policies
        .map((policy) => policy.policyVersions ?? [])
        .flat()
        .filter((policyVersion) => policyVersion.quote);
    if (policyVersions.length === 0) {
        return (
            <Paper variant='flat'>
                <NoRecords />
            </Paper>
        );
    }

    return (
        <Box>
            {policies
                .filter((policy) => (policy.policyVersions?.length ?? 0) > 0)
                .map((policy) => (
                    <Box key={policy.uuid}>
                        {policy.policyVersions
                            ?.filter((policyVersion) => policyVersion.quote)
                            .map((policyVersion) => (
                                <QuoteTile
                                    key={policyVersion.uuid}
                                    policy={policy}
                                    policyVersion={policyVersion}
                                    invoiceDetails={invoiceDetails}
                                    sellerProduct={sellerProduct}
                                />
                            ))}
                    </Box>
                ))}
        </Box>
    );
}
