import { Alert, Typography } from '@mui/material';

const NoPermission = () => (
    <>
        <Typography variant='h2' sx={{ mb: '15px' }}>
            Welcome to Simfuni!
        </Typography>
        <Typography>
            <Alert severity='info'>It looks like you don't have permission to view this account yet.</Alert>
        </Typography>
        <Typography>
            <Alert severity='info'>
                Check back again soon, or contact your Simfuni account admin to have permissions added to your account.
            </Alert>
        </Typography>
    </>
);

export default NoPermission;
