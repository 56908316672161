import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { InvoiceAction, Loan, LoanStatus } from '../../../apis/invoice';
import PageLoading from '../../../components/PageLoading';
import StepsDrawer from '../../../components/StepsDrawer';
import { FetchStateType, isSuccess } from '../../../hooks/useFetch';
import { useAppDispatch, useAppSelector } from '../../../store/reducer/Hooks';
import { setInvoiceDetailsState } from '../../../store/reducer/InvoiceDetailsReducer';
import { setLoanState } from '../../../store/reducer/LoanReducer';
import DoneStep from './DoneStep';
import InputDetailsStep from './InputDetailsStep';
import ReviewStep from './ReviewStep';
import { CancellationPreview } from './types';

type Props = {
    open: boolean;
    setClosed: () => void;
};

export default function CreateCancellationSteps({ open, setClosed }: Readonly<Props>) {
    const [step, setStep] = useState<Steps>(Steps.INPUT_DETAILS);
    const [cancellationPreview, setCancellationPreview] = useState<CancellationPreview>();
    const [loan, setLoan] = useState<Loan>();

    const { state: invoiceState } = useAppSelector((root) => root.InvoiceDetailsReducer);
    const { state: loanState } = useAppSelector((root) => root.LoanReducer);
    const dispatch = useAppDispatch();

    const handleBackToEditStep = () => {
        setStep(Steps.INPUT_DETAILS);
    };

    const handleSetCancellationPreview = (preview: CancellationPreview) => {
        setCancellationPreview(preview);
        setStep(Steps.REVIEW);
    };

    const setUpdatedLoan = async (updatedLoan: Loan) => {
        setLoan(updatedLoan);
        setStep(Steps.DONE);
    };

    const handleClose = () => {
        if (isSuccess(invoiceState) && loan) {
            const updatedStateValue = cloneDeep(invoiceState);
            updatedStateValue.value.loan = loan;
            updatedStateValue.value.allowedActions =
                loan.status !== LoanStatus.CLOSED
                    ? [InvoiceAction.VIEW, InvoiceAction.ADD_PAYMENT, InvoiceAction.PAYMENT_VARIATION]
                    : [InvoiceAction.VIEW, InvoiceAction.ADD_PAYMENT];
            dispatch(setInvoiceDetailsState(updatedStateValue));
            dispatch(setLoanState({ value: loan, type: FetchStateType.SUCCESS }));
        }
        setCancellationPreview(undefined);
        setLoan(undefined);
        setStep(Steps.INPUT_DETAILS);
        setClosed();
    };

    if (!isSuccess(invoiceState) || !isSuccess(loanState)) {
        return (
            <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
                <PageLoading />
            </StepsDrawer>
        );
    }

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {step === Steps.INPUT_DETAILS && (
                <InputDetailsStep
                    details={invoiceState.value}
                    loan={loanState.value}
                    cancellationPreview={cancellationPreview}
                    setCancellationPreview={handleSetCancellationPreview}
                    cancel={handleClose}
                />
            )}
            {step === Steps.REVIEW && (
                <ReviewStep
                    cancel={handleClose}
                    handleBack={handleBackToEditStep}
                    cancellationPreview={cancellationPreview!}
                    setUpdatedLoan={setUpdatedLoan}
                    details={invoiceState.value}
                />
            )}
            {step === Steps.DONE && <DoneStep handleClose={handleClose} loan={loan!} />}
        </StepsDrawer>
    );
}

const stepNames = ['Configure', 'Review', 'Done'];
enum Steps {
    INPUT_DETAILS,
    REVIEW,
    DONE,
}
