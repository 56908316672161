import { Paper, Typography, Link as MuiLink, Box } from '@mui/material';
import { Invoice } from '../../../apis/invoice';
import { Link } from 'react-router-dom';
import { Receipt } from '@mui/icons-material';
import { getStartDate } from '../../../util/policyUtils';
import { DATE_COMPACT } from '../../../util/dateUtils';

type Props = {
    renewalInvoice: Invoice;
};

export default function RenewalDetailsCardParent({ renewalInvoice }: Readonly<Props>) {
    const startDate = getStartDate(renewalInvoice.portfolio.policies);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Linked invoice
            </Typography>
            <Paper variant='flat' sx={{ p: 2 }}>
                <MuiLink
                    to={'/invoices/' + renewalInvoice.uuid}
                    component={Link}
                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                >
                    <Receipt />
                    {renewalInvoice.number}
                </MuiLink>

                <Typography variant='subtitle1' mt={1}>
                    Renewal scheduled for {startDate.format(DATE_COMPACT)}
                </Typography>
            </Paper>
        </Box>
    );
}
