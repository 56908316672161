import { CheckCircle } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import StepCard from '../StepCard';
import { ActionType } from './common';
import { Client } from '../../apis/clients';

type Props = {
    client: Client;
    actionType: ActionType;
    handleClose: () => void;
};

export default function DoneStep({ client, actionType, handleClose }: Readonly<Props>) {
    const content = getContent(actionType, client);

    return (
        <StepCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CheckCircle color='success' fontSize='large' />
                    <Typography variant='h5' component='h2'>
                        {content.title}
                    </Typography>
                </Box>
                <Typography textAlign='center'>{content.description}</Typography>
                <Button onClick={handleClose} variant='contained'>
                    Close
                </Button>
            </Box>
        </StepCard>
    );
}

type Content = {
    title: string;
    description: string;
};
const getContent = (actionType: ActionType, client: Client): Content => {
    switch (actionType) {
        case ActionType.SEND_QUOTE:
            return {
                title: 'Quote sent to client',
                description: `The quote has been saved and emailed to ${client.contactDetails.email.address}.`,
            };
        case ActionType.CREATE_ENDORSEMENT:
            return {
                title: 'Endorsement created',
                description: `${client.displayName} has been emailed the updated payment and policy schedule.`,
            };
        case ActionType.SAVE_DRAFT:
            return { title: 'Draft saved', description: 'The draft quote is available on the quotes tab.' };
    }
};
