import { useState } from 'react';
import { ExpectedPaymentStatus, Loan, LoanStatus, LoanSubStatus } from '../../../../apis/invoice';
import PageLoading from '../../../../components/PageLoading';
import StepsDrawer from '../../../../components/StepsDrawer';
import { FetchStateType, isSuccess } from '../../../../hooks/useFetch';
import { useAppDispatch, useAppSelector } from '../../../../store/reducer/Hooks';
import { setLoanState } from '../../../../store/reducer/LoanReducer';
import { OndemandPaymentFields, RecordExternalTransactionRequestFields, Step } from '../types';
import AddPaymentTypeStep from './AddPaymentTypeStep';
import AddExternalTransactionDetailsStep from './ExternalTransactionDetailsStep';
import ExternalTransactionDoneStep from './ExternalTransactionDoneStep';
import ExternalTransactionReviewStep from './ExternalTransactionReviewStep';
import OndemandPaymentDetailsStep from './OndemandPaymentDetailsStep';
import OndemandPaymentDoneStep from './OndemandPaymentDoneStep';
import OndemandPaymentReviewStep from './OndemandPaymentReviewStep';

type Props = {
    open: boolean;
    setClosed: () => void;
    startingStep?: Step;
    startingPaymentType?: PaymentType;
};

export default function CreateAddPaymentSteps({
    open,
    setClosed,
    startingStep = Step.TYPE,
    startingPaymentType = PaymentType.UNDEFINED,
}: Readonly<Props>) {
    const [step, setStep] = useState<Step>(startingStep);
    const [loan, setLoan] = useState<Loan>();
    const [configureType, setConfigureType] = useState<PaymentType>(startingPaymentType);
    const [externalTransactionRequestFields, setExternalTransactionRequestFields] =
        useState<RecordExternalTransactionRequestFields>({} as RecordExternalTransactionRequestFields);
    const [ondemandPaymentFields, setOndemandPaymentFields] = useState<OndemandPaymentFields>(
        {} as OndemandPaymentFields
    );

    const { state: invoiceState } = useAppSelector((root) => root.InvoiceDetailsReducer);
    const { state: loanState } = useAppSelector((root) => root.LoanReducer);
    const dispatch = useAppDispatch();

    const handleBackToConfigureStep = () => {
        setStep(Step.CONFIGURE);
    };

    const handleBackToTypeStep = () => {
        setStep(Step.TYPE);
    };

    const handleConfigureTypeUpdate = (configureType: PaymentType) => {
        setConfigureType(configureType);
        setStep(Step.CONFIGURE);
    };

    const handleExternalTransactionConfigurationUpdate = (fields: RecordExternalTransactionRequestFields) => {
        setExternalTransactionRequestFields(fields);
        setStep(Step.REVIEW);
    };

    const handleOndemandPaymentSetupConfigurationUpdate = (request: OndemandPaymentFields) => {
        setOndemandPaymentFields(request);
        setStep(Step.REVIEW);
    };

    const handleLoanUpdate = (loan: Loan) => {
        setLoan(loan);
        setStep(Step.DONE);
    };

    const handleClose = () => {
        if (loan) {
            dispatch(setLoanState({ value: loan, type: FetchStateType.SUCCESS }));
        }

        setLoan(undefined);
        setExternalTransactionRequestFields({} as RecordExternalTransactionRequestFields);
        setOndemandPaymentFields({} as OndemandPaymentFields);

        setStep(Step.TYPE);
        setConfigureType(PaymentType.UNDEFINED);
        setClosed();
    };

    if (!isSuccess(loanState) || !isSuccess(invoiceState)) {
        return (
            <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
                <PageLoading />
            </StepsDrawer>
        );
    }

    const overdueAmount = loanState.value.consolidatedExpectedPayments
        .filter((expectedPayment) => expectedPayment.status === ExpectedPaymentStatus.OVERDUE)
        .reduce((total, expectedPayment) => total + expectedPayment.amount - (expectedPayment.paidAmount ?? 0), 0);

    const hasOutstandingBalance =
        loanState.value.outstandingSettlementAmount != null && loanState.value.outstandingSettlementAmount > 0;
    const isPaymentScheduledToday = loanState.value.isPaymentScheduledToday ?? false;
    const isOverdue = loanState.value.subStatus === LoanSubStatus.OVERDUE && overdueAmount > 0;
    const isClosed = loanState.value.status === LoanStatus.CLOSED;
    const isInterestBearingOfCancelledLoan =
        loanState.value.isInterestsBearing && loanState.value.cancellationRequest != null;

    const disableOndemandPaymentHints = buildDisableOnDemandPaymentHints(
        isClosed,
        isInterestBearingOfCancelledLoan,
        isPaymentScheduledToday
    );

    const getStep = (step: Step, paymentType: PaymentType) => {
        switch (step) {
            case Step.TYPE:
                return (
                    <AddPaymentTypeStep
                        cancel={handleClose}
                        configureType={configureType}
                        setConfigureType={handleConfigureTypeUpdate}
                        details={invoiceState.value}
                        disableOndemandPayment={
                            (!isOverdue && !hasOutstandingBalance) ||
                            isPaymentScheduledToday ||
                            isInterestBearingOfCancelledLoan ||
                            isClosed
                        }
                        disableOndemandPaymentHints={disableOndemandPaymentHints}
                    />
                );
            case Step.CONFIGURE:
                return paymentType === PaymentType.EXTERNAL_TRANSACTION ? (
                    <AddExternalTransactionDetailsStep
                        cancel={handleClose}
                        handleBack={handleBackToTypeStep}
                        externalTransactionRequestFields={externalTransactionRequestFields}
                        setExternalTransactionRequestFields={handleExternalTransactionConfigurationUpdate}
                        loan={loanState.value}
                        details={invoiceState.value}
                    />
                ) : (
                    <OndemandPaymentDetailsStep
                        cancel={handleClose}
                        handleBack={handleBackToTypeStep}
                        loan={loanState.value}
                        details={invoiceState.value}
                        ondemandPaymentFields={ondemandPaymentFields}
                        setOndemandPaymentFields={handleOndemandPaymentSetupConfigurationUpdate}
                        overdueAmount={overdueAmount}
                        isOverdue={isOverdue}
                    />
                );
            case Step.REVIEW:
                return paymentType === PaymentType.EXTERNAL_TRANSACTION ? (
                    <ExternalTransactionReviewStep
                        cancel={handleClose}
                        handleBack={handleBackToConfigureStep}
                        externalTransactionFields={externalTransactionRequestFields}
                        loan={loanState.value}
                        setLoan={handleLoanUpdate}
                        details={invoiceState.value}
                        overdueAmount={overdueAmount}
                    />
                ) : (
                    <OndemandPaymentReviewStep
                        cancel={handleClose}
                        handleBack={handleBackToConfigureStep}
                        ondemandPaymentFields={ondemandPaymentFields}
                        loan={loanState.value}
                        setLoan={handleLoanUpdate}
                        details={invoiceState.value}
                        overdueAmount={overdueAmount}
                    />
                );
            case Step.DONE:
                return paymentType === PaymentType.EXTERNAL_TRANSACTION ? (
                    <ExternalTransactionDoneStep close={handleClose} />
                ) : (
                    <OndemandPaymentDoneStep
                        paymentMethodType={ondemandPaymentFields?.paymentConfiguration?.paymentMethod.type}
                        close={handleClose}
                    />
                );
        }
    };

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {getStep(step, configureType)}
        </StepsDrawer>
    );
}

const buildDisableOnDemandPaymentHints = (
    isClosed: boolean,
    isInterestBearingOfCancelledLoan: boolean,
    isPaymentScheduledToday: boolean
): string => {
    if (isClosed) {
        return 'Unavailable as the loan is closed';
    } else if (isInterestBearingOfCancelledLoan) {
        return 'Unavailable as cancellation is scheduled for the loan';
    } else if (isPaymentScheduledToday) {
        return 'Unavailable as there is a pending payment scheduled for today';
    } else {
        return 'Only available when customer has an outstanding balance or there is an overdue amount';
    }
};

const stepNames = ['Type', 'Configure', 'Review', 'Done'];

export enum PaymentType {
    UNDEFINED = 'UNDEFINED',
    ONDEMAND_PAYMENT = 'ONDEMAND_PAYMENT',
    EXTERNAL_TRANSACTION = 'EXTERNAL_TRANSACTION',
}
