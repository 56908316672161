import { Box, Chip, Divider, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { ChargeType, Invoice, InvoiceDetailsResponse, InvoiceStatus, Loan, LoanSubStatus } from '../../../apis/invoice';
import { Lender } from '../../../apis/lenders';
import { DATE_FRIENDLY } from '../../../util/dateUtils';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
    lender?: Lender;
    loan?: Loan;
};

export default function PremiumDetailsCard({ invoiceDetails, lender, loan }: Readonly<Props>) {
    const { invoice } = invoiceDetails;

    const { interest, setupFee, totalAmount } = getPremiumFees(invoice);

    const paymentSource = getPaymentSource(lender, loan, interest);
    const paymentStatus = getInvoicePaymentStatus(invoiceDetails);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                {getTitle(interest)}
            </Typography>
            <Paper variant='flat' sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box>
                        {totalAmount === invoice.totalPremiums ? (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='subtitle2'>Total premiums</Typography>
                                <Typography variant='subtitle2'>{currencyFormat.format(totalAmount)}</Typography>
                            </Box>
                        ) : (
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant='body2'>Premiums</Typography>
                                    <Typography variant='body2'>
                                        {currencyFormat.format(invoice.totalPremiums)}
                                    </Typography>
                                </Box>
                                {setupFee > 0 && (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant='body2'>Setup fee</Typography>
                                        <Typography variant='body2'>{currencyFormat.format(setupFee)}</Typography>
                                    </Box>
                                )}
                                {interest > 0 && (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant='body2'>Interest</Typography>
                                        <Typography variant='body2'>{currencyFormat.format(interest)}</Typography>
                                    </Box>
                                )}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant='subtitle2'>Total</Typography>
                                    <Typography variant='subtitle2'>{currencyFormat.format(totalAmount)}</Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                    {(paymentSource || paymentStatus) && <Divider />}
                    {paymentSource != null && paymentSource}
                    {paymentStatus != null && <Box>{paymentStatus}</Box>}
                </Box>
            </Paper>
        </Box>
    );
}

type PremiumFees = {
    interest: number;
    setupFee: number;
    totalAmount: number;
};

const getPremiumFees = (invoice: Invoice): PremiumFees => {
    if (invoice.status === InvoiceStatus.CANCELLED) {
        return {
            interest: 0,
            setupFee: 0,
            totalAmount: invoice.totalPremiums,
        };
    }

    const interest = invoice?.term?.totalInterestAmount || 0;
    const setupFee = invoice?.term?.feeCharges?.find((charge) => charge.type === ChargeType.SETUP_FEE)?.amount || 0;
    const termTotal = invoice?.term?.totalAmount;

    return {
        interest,
        setupFee,
        totalAmount: (termTotal || invoice.totalPremiums) + setupFee + interest,
    };
};

const getPaymentSource = (lender: Lender | undefined, loan: Loan | undefined, interest: number): JSX.Element | null => {
    if (loan && loan.paidUntilDate && lender && interest > 0) {
        return (
            <Box>
                <Typography variant='caption'>Payment Source</Typography>
                <Typography variant='body2'>{lender.displayName}</Typography>
            </Box>
        );
    }
    return null;
};

const getInvoicePaymentStatus = (invoiceDetails: InvoiceDetailsResponse): JSX.Element | null => {
    const isDeferredStart =
        invoiceDetails.invoice.term && moment(invoiceDetails.invoice.term.firstPaymentDate).isAfter(moment());

    if (invoiceDetails.invoice.status === InvoiceStatus.CANCELLED) {
        return <Chip color='error' label='Voided' />;
    }

    if (invoiceDetails.loan == null || isDeferredStart) {
        return <Chip color='error' label={`Due on ${moment(invoiceDetails.invoice.dueDate).format(DATE_FRIENDLY)}`} />;
    }

    if (invoiceDetails.loan?.subStatus === LoanSubStatus.PARTIALLY_PAID) {
        return <Chip color='warning' label='Partially paid' />;
    }

    return null;
};

const getTitle = (interest: number) => {
    if (interest > 0) {
        return 'Premium funding';
    }

    return 'Premium summary';
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
