import { chain, find } from 'lodash';
import moment, { Moment } from 'moment';
import {
    ConsolidatedExpectedPayment,
    ConsolidatedPayment,
    Invoice,
    InvoiceLoanAnnotation,
    Loan,
    PaymentFrequency,
    RuleEvaluationOutcome,
    RuleEvaluationReason,
} from '../../apis/invoice';
import { QuoteGeneratorType, SellerProduct } from '../../apis/sellerProduct';
import { DATE_FULL, DATE_SERVER_FORMAT } from '../../util/dateUtils';

export const hasLendingCondition = (invoice: Invoice): boolean => {
    const result = find(
        invoice.ruleEvaluationResults,
        ({ outcome }) => outcome === RuleEvaluationOutcome.REFER || outcome === RuleEvaluationOutcome.DENIED
    );
    return result !== undefined;
};

export const isLendingConditionPendingApproval = (invoice: Invoice): boolean => {
    const result = find(invoice.ruleEvaluationResults, {
        outcome: RuleEvaluationOutcome.REFER,
        conditionApproved: false,
    });
    return result !== undefined;
};

export const isLendingConditionDenied = (invoice: Invoice): boolean => {
    const result = find(invoice.ruleEvaluationResults, { outcome: RuleEvaluationOutcome.DENIED });
    return result !== undefined;
};

export type LendingCondition = {
    uuid: string;
    statement: string;
    conditionApproved: boolean;
};

export const detectLendingConditionsPendingApproval = (invoice: Invoice): LendingCondition[] => {
    return chain(invoice.ruleEvaluationResults ?? [])
        .filter({ outcome: RuleEvaluationOutcome.REFER })
        .map(({ uuid, reason, hints, conditionApproved, ruleIdentifier }) => {
            return {
                uuid: uuid ?? (ruleIdentifier as string),
                statement: buildLendingCondition(reason, hints),
                conditionApproved,
            };
        })
        .value();
};

export const detectLendingConditionsDenied = (invoice: Invoice): LendingCondition[] => {
    return chain(invoice.ruleEvaluationResults ?? [])
        .filter({ outcome: RuleEvaluationOutcome.DENIED })
        .map(({ uuid, reason, hints, conditionApproved, ruleIdentifier }) => {
            return {
                uuid: uuid ?? (ruleIdentifier as string),
                statement: buildLendingCondition(reason, hints),
                conditionApproved,
            };
        })
        .value();
};

const buildLendingCondition = (reason: RuleEvaluationReason | undefined, hints: string | undefined): string => {
    switch (reason) {
        case RuleEvaluationReason.CLASS_CODE_NOT_ACTIVE:
            return `The class code '${hints}' is deactivated`;
        case RuleEvaluationReason.CLASS_CODE_NOT_CANCELLABLE:
            return `The class code '${hints}' is not refundable`;
        case RuleEvaluationReason.CLASS_CODE_NOT_SUPPORTED:
            return `The class code '${hints}' is not supported by the premium funder`;
        case RuleEvaluationReason.PROVIDER_NOT_ACTIVE:
            return `The provider '${hints}' is deactivated`;
        case RuleEvaluationReason.PROVIDER_NOT_SUPPORTED:
            return `The provider '${hints}' is not supported by the premium funder`;
        case RuleEvaluationReason.MIN_PREMIUM_VALUE_NOT_REACHED:
            return `Total premium value is less than ${safeFormatPremiumValueBoundary(hints)}`;
        case RuleEvaluationReason.MAX_PREMIUM_VALUE_EXCEEDED:
            return `Total premium value is more than ${safeFormatPremiumValueBoundary(hints)}`;
        case RuleEvaluationReason.MIN_TERM_NOT_MET:
            return `Loans duration is less than ${hints} months`;
        case RuleEvaluationReason.MAX_TERM_EXCEEDED:
            return `Loans duration exceeds ${hints} months`;
        default:
            return `A lending condition was declined with the reason: ${reason}, and with hints: ${hints}`;
    }
};

const safeFormatPremiumValueBoundary = (hints: string | undefined): string | undefined => {
    if (hints === undefined) {
        return undefined;
    }

    try {
        return currencyFormat.format(parseFloat(hints));
    } catch (err) {
        return hints;
    }
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

export const calculateDaysDifference = (paidUntilDate: string) => {
    const a = moment(paidUntilDate, DATE_SERVER_FORMAT);
    const b = moment();
    return a.endOf('day').diff(b.endOf('day'), 'days');
};

export const getDaysDiffDesc = (days: number, aheadMsg = 'ahead'): string => {
    if (days === 0) {
        return 'today';
    }

    const daysToDisplay = Math.abs(days);
    const dayTranslation = daysToDisplay === 1 ? ' day ' : ' days ';
    const frameTranslation = days >= 0 ? aheadMsg : 'ago';

    return `${daysToDisplay}${dayTranslation}${frameTranslation}`;
};

export const getDateDescription = (date: Moment): string => {
    const asDate = date.endOf('day');
    const today = moment().endOf('day');
    if (asDate.isSameOrBefore(today)) {
        return 'Today';
    }

    if (asDate.isSame(today.add(1, 'day'))) {
        return 'Tomorrow';
    }

    return asDate.format(DATE_FULL);
};

export const addKeyDate = <T>(dates: { [x: string]: T[] }, dataDescription: string, element: T) => {
    dates[dataDescription] = dates[dataDescription] || [];
    dates[dataDescription].push(element);
};

export const getLoanCancellationEffectiveDate = (loan: Loan): Moment => {
    return moment(loan.cancellationRequest?.effectiveDate ?? '9999-12-31', DATE_SERVER_FORMAT);
};

export const getConsolidatedPaymentsBeforeCancellation = (loan: Loan): ConsolidatedPayment[] => {
    const loanCancellationEffectiveDate = getLoanCancellationEffectiveDate(loan);

    return loan.consolidatedPayments.filter(({ dueDate }) =>
        moment(dueDate, DATE_SERVER_FORMAT).isBefore(loanCancellationEffectiveDate)
    );
};

export const getConsolidatedExpectedPaymentsBeforeCancellation = (loan: Loan): ConsolidatedExpectedPayment[] => {
    const loanCancellationEffectiveDate = getLoanCancellationEffectiveDate(loan);

    return loan.consolidatedExpectedPayments.filter(({ dueDate }) =>
        moment(dueDate, DATE_SERVER_FORMAT).isBefore(loanCancellationEffectiveDate)
    );
};

export const quoteableInvoice = (sellerProduct: SellerProduct, loan?: InvoiceLoanAnnotation) => {
    return (
        sellerProduct.quoteGeneratorType === QuoteGeneratorType.INTERNAL &&
        (sellerProduct.covers?.length ?? 0) > 0 &&
        loan?.paymentFrequency !== PaymentFrequency.IN_FULL
    );
};
