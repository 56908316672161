import DataTable, { TableColumn } from 'react-data-table-component';
import { ReportData, ReportValue } from '../../apis/reports';
import { Box, Button, Collapse } from '@mui/material';
import CsvDownloadButton from 'react-json-to-csv';
import { Download } from '@mui/icons-material';
import { useState } from 'react';
import moment from 'moment';
import { camelCase } from 'lodash';
import { DATE_SERVER_FORMAT } from '../../util/dateUtils';

interface ReportTableProps {
    reportData: ReportData;
    tableColumns: TableColumn<ReportValue>[];
}

export default (props: ReportTableProps) => {
    const { reportData, tableColumns } = props;

    const [expandReportData, setExpandReportData] = useState(false);

    return (
        <Box sx={{ mt: 4, pt: 2, display: 'flex', flexDirection: 'column', borderTop: '1px solid #ccc' }}>
            <Collapse in={expandReportData}>
                <CsvDownloadButton
                    data={reportData.values}
                    delimiter=','
                    filename={camelCase(reportData.title) + '_' + moment().format(DATE_SERVER_FORMAT) + '.csv'}
                    className='csvDownloadButton'
                    style={{
                        background: 'none',
                        border: 'none',
                        display: 'flex',
                        cursor: 'pointer',
                        fontSize: '15px',
                        padding: '6px 24px',
                        color: '#008BCE',
                        textDecoration: 'none',
                        float: 'right',
                    }}
                >
                    <Download />
                    Download csv
                </CsvDownloadButton>
                <DataTable data={reportData.values} columns={tableColumns} pagination={reportData.values.length > 10} />
            </Collapse>
            <Button sx={{ alignSelf: 'center' }} onClick={() => setExpandReportData(!expandReportData)}>
                {expandReportData ? 'Hide' : 'Show'} Data
            </Button>
        </Box>
    );
};
