import { HelpOutline, Send } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { Invoice } from '../../../apis/invoice';
import { LoadingButton } from '../../../components/LoadingButton';

type Props = {
    invoice: Invoice;
    confirmationDialogOpened: boolean;
    setConfirmationDialogOpened: (opened: boolean) => void;
    requestingApproval: boolean;
    handleRequestApproval: () => void;
    handleSendInvoice: () => void;
};

export default function SendInvoiceConfirmationDialog({
    invoice,
    confirmationDialogOpened,
    setConfirmationDialogOpened,
    requestingApproval,
    handleRequestApproval,
    handleSendInvoice,
}: Readonly<Props>) {
    return (
        <Dialog open={confirmationDialogOpened} onClose={() => setConfirmationDialogOpened(false)}>
            <DialogTitle>Send invoice with pending approval conditions?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Recurring payment options are not available before the lending conditions are approved. Do you still
                    want to continue sending the invoice? ONLY the In-Full payment option is available now.
                </DialogContentText>
                <DialogActions>
                    <Tooltip
                        title={
                            'Lending conditions approval requires manual process. Call the premium funder directly to accelerate the process.'
                        }
                    >
                        <span style={{ position: 'relative' }}>
                            <LoadingButton
                                onClick={handleRequestApproval}
                                loading={requestingApproval}
                                endIcon={<HelpOutline />}
                                variant={invoice.lastLcaRequestedDate ? 'outlined' : 'contained'}
                                autoFocus={invoice.lastLcaRequestedDate == null}
                            >
                                Request Approval
                            </LoadingButton>
                            {invoice.lastLcaRequestedDate && (
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: '12px',
                                        color: 'gray',
                                        fontStyle: 'italic',
                                        position: 'absolute',
                                        bottom: '-24px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >{`Requested ${moment.utc(invoice.lastLcaRequestedDate).local().fromNow()}`}</Typography>
                            )}
                        </span>
                    </Tooltip>
                    <Button
                        onClick={handleSendInvoice}
                        sx={{ width: 160, ml: 1 }}
                        startIcon={<Send />}
                        variant={invoice.lastLcaRequestedDate ? 'contained' : 'outlined'}
                        autoFocus={invoice.lastLcaRequestedDate != null}
                    >
                        Send Invoice
                    </Button>
                    <Button
                        onClick={() => setConfirmationDialogOpened(false)}
                        sx={{ width: 160, ml: 1 }}
                        variant='outlined'
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
