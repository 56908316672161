import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    Box,
    Chip,
    Grid,
    Paper,
    Typography,
    styled,
    useTheme,
} from '@mui/material';
import { Endorsement, EndorsementPolicy, EndorsementPolicyStatus } from '../../../apis/endorsement';
import { ExpandMore } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { DATE_COMPACT, DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';
import { Policy } from '../../../apis/invoice';
import NoRecords from '../../../components/NoRecords';

type Props = { endorsements?: Endorsement[]; invoicePolicies: Policy[] };

export default function InvoiceEndorsements({ endorsements, invoicePolicies }: Readonly<Props>) {
    const theme = useTheme();

    if (!endorsements || endorsements.length === 0) {
        return (
            <Paper variant='flat'>
                <NoRecords />
            </Paper>
        );
    }

    return (
        <Paper variant='flat' sx={{ p: 2 }}>
            {endorsements.map((endorsement) => {
                const alignCentreSx = { display: 'flex', flexDirection: 'column', justifyContent: 'center' };
                const unchangedPolicies = invoicePolicies.filter((policy) => {
                    return endorsement.policyEndorsements.findIndex((pe) => pe.policyNumber === policy.number) === -1;
                });

                return (
                    <StyledAccordion key={endorsement.uuid}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls={`${endorsement.uuid}-content`}
                            id={`${endorsement.uuid}-header`}
                        >
                            <Typography>Endorsement #{endorsement.number}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Box>
                                    <Typography variant='subtitle2'>Policies</Typography>

                                    <Box>
                                        <Grid
                                            container
                                            sx={{
                                                border: grey[400],
                                                borderWidth: '1px',
                                                borderStyle: 'solid',
                                                borderRadius: theme.shape.borderRadius + 'px',
                                            }}
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                sx={{
                                                    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                                                    backgroundColor: grey[200],
                                                }}
                                                p={2}
                                            >
                                                <Grid item xs={2}>
                                                    <Typography variant='subtitle2'>Policy</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant='subtitle2'>Current premium</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant='subtitle2'>Adjustment</Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant='subtitle2'>New premium</Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant='subtitle2'>Status</Typography>
                                                </Grid>
                                            </Grid>
                                            {endorsement.policyEndorsements.map((endorsementPolicy) => {
                                                const policy = findPolicy(endorsementPolicy, invoicePolicies);
                                                const policyVersion = policy.policyVersions.find(
                                                    (version) =>
                                                        version.uuid === endorsementPolicy.policyVersionIdentifier
                                                );

                                                return (
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        key={endorsementPolicy.uuid}
                                                        p={2}
                                                        sx={{
                                                            border: grey[400],
                                                            borderWidth: '0',
                                                            borderTopWidth: '1px',
                                                            borderStyle: 'solid',
                                                        }}
                                                    >
                                                        <Grid item xs={2} sx={alignCentreSx}>
                                                            <Typography variant='subtitle2'>
                                                                #{endorsementPolicy.policyNumber}
                                                            </Typography>
                                                            <Typography variant='subtitle1'>
                                                                {moment(policy.startDate).format(DATE_FRIENDLY)} -{' '}
                                                                {moment(policy.endDate).format(DATE_FRIENDLY)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} sx={{ ...alignCentreSx, pl: 1 }}>
                                                            <Typography variant='caption'>
                                                                {currencyFormat.format(
                                                                    policyVersion!.premiums -
                                                                        endorsementPolicy.premiumsAdjustment
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} sx={alignCentreSx}>
                                                            <Typography variant='caption'>
                                                                {currencyFormat.format(
                                                                    endorsementPolicy.premiumsAdjustment
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} sx={alignCentreSx}>
                                                            <Typography variant='caption'>
                                                                {currencyFormat.format(policyVersion!.premiums)}
                                                            </Typography>
                                                            <Typography variant='caption'>
                                                                from{' '}
                                                                {moment(
                                                                    endorsementPolicy.effectiveDate,
                                                                    DATE_SERVER_FORMAT
                                                                ).format(DATE_COMPACT)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} sx={alignCentreSx}>
                                                            <Box>{statusBadges[endorsementPolicy.status]()}</Box>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                            {unchangedPolicies.map((policy) => {
                                                return (
                                                    <Grid
                                                        container
                                                        item
                                                        xs={12}
                                                        key={policy.uuid}
                                                        p={2}
                                                        sx={{
                                                            border: grey[400],
                                                            borderWidth: '0',
                                                            borderTopWidth: '1px',
                                                            borderStyle: 'solid',
                                                        }}
                                                    >
                                                        <Grid item xs={2} sx={alignCentreSx}>
                                                            <Typography variant='subtitle2'>
                                                                #{policy.number}
                                                            </Typography>
                                                            <Typography variant='subtitle1'>
                                                                {moment(policy.startDate).format(DATE_FRIENDLY)} -{' '}
                                                                {moment(policy.endDate).format(DATE_FRIENDLY)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} sx={{ ...alignCentreSx, pl: 1 }}>
                                                            <Typography variant='caption'>
                                                                {currencyFormat.format(
                                                                    policy.currentPolicyVersion.premiums
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2} sx={alignCentreSx}>
                                                            <Typography variant='caption'>-</Typography>
                                                        </Grid>
                                                        <Grid item xs={3} sx={alignCentreSx}>
                                                            <Typography variant='caption'>-</Typography>
                                                        </Grid>
                                                        <Grid item xs={2} sx={alignCentreSx}>
                                                            <Typography variant='caption'>-</Typography>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Box>
                                </Box>

                                {endorsement.reason && (
                                    <Box>
                                        <Typography variant='subtitle2'>Endorsement reason</Typography>
                                        <Typography variant='caption'>{endorsement.reason}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </AccordionDetails>
                    </StyledAccordion>
                );
            })}
        </Paper>
    );
}

const StyledAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
        },
    })
);

const statusBadges = {
    [EndorsementPolicyStatus.PENDING]: () => <Chip label='Pending' color='warning' size='medium' />,
    [EndorsementPolicyStatus.ACCEPTED]: () => <Chip label='Accepted' color='secondary' size='medium' />,
    [EndorsementPolicyStatus.ACTIONED]: () => <Chip label='Actioned' color='primary' size='medium' />,
    [EndorsementPolicyStatus.REJECTED]: () => <Chip label='Rejected' color='error' size='medium' />,
    [EndorsementPolicyStatus.REPLACED]: () => <Chip label='Replaced' color='warning' size='medium' />,
};

const findPolicy = (endorsementPolicy: EndorsementPolicy, policies: Policy[]): Policy => {
    return policies.find((p) => p.uuid === endorsementPolicy.policyUuid)!;
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
