import { ExtendableFetchWrapper, FetchStateProps } from './ExtendableFetchWrapper';

/**
 * See {@link https://simfuni.atlassian.net/wiki/spaces/ENGINEERIN/pages/240517167/99.0.13+useFetch+and+FetchWrapper#FetchWrapper Confluence}
 * for more details, examples, and use cases.
 *
 * Takes a @see FetchState, and a @see React.FC
 *
 * When state is:
 * @see FetchStateType.PENDING @see FetchStateType.LOADING → show a loading spinner
 * @see FetchStateType.UNAVAILABLE → show a not found message
 * @see FetchStateType.ERROR → show an error message
 * @see FetchStateType.SUCCESS → show the SuccessComponent @param {React.FC}
 *
 * @returns JSX Element
 */
export const FetchWrapper = <T,>({ state, SuccessComponent }: FetchStateProps<T>) => {
    return <ExtendableFetchWrapper<T, object> state={state} SuccessComponent={SuccessComponent} />;
};
