import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { InvoiceDetailsResponse, LoanStatus, Policy } from '../../../apis/invoice';
import {
    Beneficiary,
    Cover,
    CoverCharge,
    CoverOption,
    Item,
    PolicyVersion,
    PolicyVersionReason,
    UnderwritingDetails,
} from '../../../apis/quotes';
import { SellerProduct } from '../../../apis/sellerProduct';
import CreateQuoteSteps from '../../../components/Quotes/CreateQuoteSteps';
import { DATE_SERVER_FORMAT } from '../../../util/dateUtils';

type Props = {
    policy: Policy;
    sellerProduct: SellerProduct;
    invoiceDetails: InvoiceDetailsResponse;
};

export default function PolicyActionMenu({ policy, sellerProduct, invoiceDetails }: Readonly<Props>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openQuoteModal, setOpenQuoteModal] = useState(false);
    const open = Boolean(anchorEl);
    const canQuote =
        moment(policy.endDate).startOf('day').isAfter(moment().startOf('day')) &&
        invoiceDetails?.loan?.status === LoanStatus.OPEN &&
        invoiceDetails.invoice.canEndorse;

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpenModal = () => {
        handleClose();
        setOpenQuoteModal(true);
    };

    return (
        <>
            <IconButton onClick={handleOpenMenu}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {canQuote ? (
                    <MenuItem onClick={handleOpenModal}>New quote</MenuItem>
                ) : (
                    <Tooltip title='Unabailable as policy is not active' arrow placement='left'>
                        <span>
                            <MenuItem disabled>New quote</MenuItem>
                        </span>
                    </Tooltip>
                )}
            </Menu>
            <CreateQuoteSteps
                policy={policy}
                sellerProduct={sellerProduct}
                open={openQuoteModal}
                setClosed={() => setOpenQuoteModal(false)}
                invoiceDetails={invoiceDetails}
                policyVersion={stripIdentifiers(policy.currentPolicyVersion)}
            />
        </>
    );
}

const stripIdentifiers = (policyVersion: PolicyVersion): PolicyVersion => {
    const clone = cloneDeep(policyVersion) as Partial<PolicyVersion>;

    clone.uuid = undefined;
    clone.quote = undefined;
    clone.version = undefined;
    clone.reason = PolicyVersionReason.QUOTE;
    clone.status = undefined;
    clone.effectiveDate = moment().format(DATE_SERVER_FORMAT);
    clone.activeDate = undefined;
    clone.createdDate = undefined;
    clone.lastModifiedDate = undefined;
    clone.premiums = undefined;
    clone.renewFromPolicyVersionIdentifier = undefined;
    clone.items?.forEach((item: Partial<Item>) => {
        item.uuid = undefined;
        item.covers?.forEach((cover: Partial<Cover>) => {
            cover.uuid = undefined;
            cover.amount = undefined;
            cover.outcome = undefined;
            cover.coverOptions?.forEach((option: Partial<CoverOption>) => {
                option.uuid = undefined;
                option.coverCharges?.forEach((charge: Partial<CoverCharge>) => {
                    charge.uuid = undefined;
                });
            });
        });
        item.beneficiaries?.forEach((beneficiary: Partial<Beneficiary>) => {
            beneficiary.uuid = undefined;
        });
        (item.underwritingDetails as Partial<UnderwritingDetails>).uuid = undefined;
    });

    return clone as PolicyVersion;
};
