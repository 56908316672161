import { Box, Breadcrumbs, Grid, Paper } from '@mui/material';

import UserList from '../components/UserList';

const UserSearch = () => {
    return (
        <>
            <Grid container>
                <Grid item sm={10}>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
                        <Box>Users</Box>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Paper variant='flat'>
                <UserList />
            </Paper>
        </>
    );
};

export default UserSearch;
