import { ReportData, ReportValue } from '../../apis/reports';
import { Paper, Typography, useTheme } from '@mui/material';
import ReportTable from './ReportTable';
import { TableColumn } from 'react-data-table-component';
import { map } from 'lodash';
import { Pie } from 'react-chartjs-2';

interface ClientTypeReportDisplayProps {
    reportData: ReportData;
}

export default (props: ClientTypeReportDisplayProps) => {
    const { reportData } = props;
    const theme = useTheme();

    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
    };

    const chartData = {
        labels: map(Object.values(reportData.values.map((x: ReportValue) => x.insuredType)), (type) => type),
        datasets: [
            {
                label: 'Count',
                backgroundColor: [
                    theme.palette.blue?.['60'],
                    theme.palette.green?.['60'],
                    theme.palette.orange?.['60'],
                ],
                borderColor: [theme.palette.blue?.['100'], theme.palette.green?.['100'], theme.palette.orange?.['100']],
                borderWidth: 1,
                data: Object.values(reportData.values.map((x) => x.count)),
            },
        ],
    };

    const tableColumns: TableColumn<ReportValue>[] = [
        {
            name: 'Type',
            selector: (row) => row.insuredType,
        },
        {
            name: 'Count',
            selector: (row) => row.count,
        },
    ];

    return (
        <Paper variant='flat' sx={{ mb: 2, p: 2 }}>
            <Typography variant={'subtitle2'}>{reportData.title}</Typography>
            <Pie datasetIdKey='id' data={chartData} style={{ maxHeight: '184px', width: '100%' }} options={options} />
            <ReportTable reportData={reportData} tableColumns={tableColumns} />
        </Paper>
    );
};
