import { Authenticator } from '@aws-amplify/ui-react';
import { Alert } from '@mui/material';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, Auth, I18n } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { FederatedSsoConfiguration, getFederatedSsoConfiguration } from './apis/sellerContext';
import PageLoading from './components/PageLoading';
import { FetchState, isError, isLoading, isSuccess, isUnavailable, useFetch } from './hooks/useFetch';
import Header from './layout/SignInHeader';
import AppPage from './pages/AppPage';
import { setFederatedSsoConfigurationState } from './store/reducer/FederatedSsoConfigurationReducer';
import { useAppDispatch, useAppSelector } from './store/reducer/Hooks';
import { setUserSession } from './store/reducer/UserSessionReducer';
import { applyAmplifyCssVariables } from './style/theme';

const hostnameOfDefaultUrls = process.env.REACT_APP_WEB_CLIENT_DEFAULT_URL?.split(',')
    .map((url) => new URL(url.toLowerCase()))
    .map((url) => url.hostname);

const providerUrlLowerCase = window.location.hostname.toLowerCase();
const federated = !hostnameOfDefaultUrls?.includes(providerUrlLowerCase);

const authConfigBase = {
    region: process.env.REACT_APP_USER_POOL_REGION,

    authenticationFlowType: 'USER_PASSWORD_AUTH', // to allow user pool migration

    // Cognito Hosted UI configuration
    oauth: {
        scope: ['openid'],
        responseType: 'code',
        redirectSignIn: `${window.location.origin}/`,
        redirectSignOut: `${window.location.origin}/`,
    },
};

const setupAmplifyConfiguration = (federatedSsoConfigurationState: FetchState<FederatedSsoConfiguration>) => {
    if (federated) {
        if (isSuccess(federatedSsoConfigurationState)) {
            Amplify.configure({
                Auth: {
                    ...authConfigBase,
                    userPoolId: federatedSsoConfigurationState.value.federatedUserPoolId,
                    userPoolWebClientId: federatedSsoConfigurationState.value.federatedUserPoolWebClientId,

                    oauth: {
                        ...authConfigBase.oauth,
                        domain: federatedSsoConfigurationState.value.federatedUserPoolAuthDomain,
                    },
                },
            });
        }
    } else {
        Amplify.configure({
            Auth: {
                ...authConfigBase,
                userPoolId: process.env.REACT_APP_USER_POOL_ID_LOCAL,
                userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID_LOCAL,

                oauth: {
                    ...authConfigBase.oauth,
                    domain: process.env.REACT_APP_WEB_CLIENT_DOMAIN_LOCAL,
                },
            },
        });
    }
};

I18n.putVocabulariesForLanguage('en', {
    Username: 'Email',
    'Enter your Username': 'Email',
    'Enter your username': 'Email',
    'Enter your Password': 'Password',
});

const App = () => {
    const dispatch = useAppDispatch();
    const [federatedSignedIn, setFederatedSignedIn] = useState<boolean>(false);
    const { state: federatedSsoConfigurationState } = useAppSelector((root) => root.FederatedSsoConfigurationReducer);

    const state = useFetch(getFederatedSsoConfiguration, [], {
        canFetch: () => federated && !isSuccess(federatedSsoConfigurationState),
    });
    if (!isUnavailable(state)) {
        dispatch(setFederatedSsoConfigurationState(state));
    }

    useEffect(() => {
        applyAmplifyCssVariables();

        setupAmplifyConfiguration(federatedSsoConfigurationState);

        if (federated && isSuccess(federatedSsoConfigurationState)) {
            Auth.currentAuthenticatedUser()
                .then(({ signInUserSession }) => {
                    setFederatedSignedIn(true);
                    dispatch(setUserSession(signInUserSession));
                })
                .catch(() =>
                    Auth.federatedSignIn({ customProvider: federatedSsoConfigurationState.value.federatedLoginIdp })
                );
        }
    }, [dispatch, federatedSsoConfigurationState]);

    if (isError(federatedSsoConfigurationState)) {
        return <Alert severity='error'>{federatedSsoConfigurationState.error.message}</Alert>;
    }

    if (isLoading(federatedSsoConfigurationState)) {
        return <PageLoading />;
    }

    if (!federated) {
        return (
            <Authenticator.Provider>
                <Authenticator hideSignUp={true} components={{ Header }}>
                    {({ signOut, user }) => {
                        const signInUserSession = user?.getSignInUserSession() as CognitoUserSession;
                        dispatch(setUserSession(signInUserSession));
                        return <AppPage signOut={signOut} />;
                    }}
                </Authenticator>
            </Authenticator.Provider>
        );
    }

    if (!federatedSignedIn) {
        return <></>; // SSO in progress
    }

    return <AppPage />;
};

export default App;
