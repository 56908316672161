import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Policy } from '../../../../apis/invoice';
import { grey } from '@mui/material/colors';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../../util/dateUtils';
import moment from 'moment';
import NewPremiumValue from './NewPremiumValue';

type Props = {
    policy: Policy;
    currentPremiums: number;
    policyRemoved?: boolean;
};

export default function PolicyRow({ policy, currentPremiums, policyRemoved }: Readonly<Props>) {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                p: 2,
                border: `1px solid ${grey[300]}`,
                borderTop: 'none',
                '&:last-child': {
                    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
                },
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Typography variant='subtitle2'>#{policy.number}</Typography>
                    <Typography>{policy.classCodeDescription ?? policy.classCode}</Typography>
                    <Typography variant='body2'>
                        {moment(policy.startDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)} -{' '}
                        {moment(policy.endDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY)}
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{ alignSelf: 'center' }}>
                    <Typography variant='caption'>{currencyFormat.format(currentPremiums)}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ alignSelf: 'center' }}>
                    <NewPremiumValue policy={policy} currentPremiums={currentPremiums} policyRemoved={policyRemoved} />
                </Grid>
            </Grid>
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
