import { yupResolver } from '@hookform/resolvers/yup';
import {
    Alert,
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CalculatedOn, ChargeType, PaymentFrequency } from '../../../apis/invoice';
import { FeeConfig, getFeeConfig } from '../../../util/feeUtils';
import { lowerCase } from 'lodash';
import { ClientLeadResult } from '../../../apis/clientLead';

export enum MatchOutcomeType {
    APPROVE = 'APPROVE',
    DECLINE = 'DECLINE',
    VOID = 'VOID',
}

export type MatchFormFields = {
    type: MatchOutcomeType;
    note?: string;
};

type Props = {
    result: ClientLeadResult;
    saveCounter?: number;
    save: (fields: MatchFormFields) => void;
    setMatchType: (matchType: MatchOutcomeType) => void;
};

export default function MatchOutcome({ result, saveCounter, save, setMatchType }: Readonly<Props>) {
    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<MatchFormFields>({
        resolver: yupResolver(schema),
        defaultValues: {
            type: MatchOutcomeType.APPROVE,
        },
    });

    const { invoice, reasonsToDenyRecurringPayments } = result;
    const hasRecurringPayments = invoice.term.paymentFrequency !== PaymentFrequency.IN_FULL;
    const feeConfig = getFeeConfig(invoice.term.termPaymentMethod.paymentMethodCharges, ChargeType.TRANSACTION_FEE);
    const approvalOnly = (reasonsToDenyRecurringPayments?.length ?? 0) > 0;

    const matchType = watch('type');

    useEffect(() => {
        if (saveCounter) {
            handleSubmit(save)();
        }
    }, [saveCounter]);

    const handleTypeChange =
        (field: ControllerRenderProps<MatchFormFields, 'type'>) =>
        (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
            setMatchType(value as MatchOutcomeType);
            field.onChange(event);
        };

    return (
        <form onSubmit={handleSubmit(save)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant='subtitle2'>
                    {hasRecurringPayments ? '1. Recurring payment application' : '1. Payment application'}
                </Typography>
                {hasRecurringPayments && (
                    <Alert color='info'>
                        The client has opted for recurring monthly payments. They've already made an initial payment of{' '}
                        {getFeeDescription(feeConfig, invoice.term.initialPaymentAmount)}.
                    </Alert>
                )}
                <FormControl error={!!errors.type} variant='standard'>
                    <Controller
                        name='type'
                        control={control}
                        render={({ field }) => (
                            <RadioGroup
                                {...field}
                                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                                onChange={handleTypeChange(field)}
                            >
                                <FormControlLabel
                                    value={MatchOutcomeType.APPROVE}
                                    control={<Radio />}
                                    label={
                                        <Box>
                                            Approve
                                            <Typography variant='body2'>
                                                Continue and accept the clients request to pay{' '}
                                                {lowerCase(invoice.term.paymentFrequency)}.
                                            </Typography>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value={MatchOutcomeType.DECLINE}
                                    control={<Radio />}
                                    disabled={approvalOnly}
                                    label={
                                        <Tooltip title={disabledMsg(result, approvalOnly)} followCursor>
                                            <Box>
                                                Decline and request balance in full
                                                <Typography variant='body2'>
                                                    This will send a payment request email to the client for the
                                                    remaining balance.
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    }
                                />
                                <FormControlLabel
                                    value={MatchOutcomeType.VOID}
                                    control={<Radio />}
                                    disabled={approvalOnly}
                                    label={
                                        <Tooltip title={disabledMsg(result, approvalOnly)} followCursor>
                                            <Box>
                                                Decline and void
                                                <Typography variant='body2'>
                                                    Invoice will be voided. You will need to contact the client and
                                                    manually refund the initial payment of{' '}
                                                    {currencyFormat.format(invoice.term.initialPaymentAmount)}.
                                                </Typography>
                                            </Box>
                                        </Tooltip>
                                    }
                                />
                            </RadioGroup>
                        )}
                    />
                    {errors.type && <FormHelperText>{errors.type.message}</FormHelperText>}
                </FormControl>
                {matchType === MatchOutcomeType.VOID && (
                    <FormControl sx={{ width: 400 }}>
                        <FormLabel htmlFor='note'>Enter a reason</FormLabel>
                        <Controller
                            name='note'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id='note'
                                    size='small'
                                    variant='outlined'
                                    multiline
                                    rows={2}
                                    error={!!errors.note}
                                    helperText={errors.note?.message}
                                />
                            )}
                        />
                    </FormControl>
                )}
            </Box>
        </form>
    );
}

const disabledMsg = (result: ClientLeadResult, approvalOnly: boolean) => {
    if (!approvalOnly) {
        return '';
    }

    if (result.invoice.term.paymentFrequency === PaymentFrequency.IN_FULL) {
        return 'Not available for an in full payment';
    }

    return (
        <span>
            Not available as:
            {result.reasonsToDenyRecurringPayments.map((reason) => (
                <span key={reason}>
                    <br />
                    {reason}
                </span>
            ))}
        </span>
    );
};

const schema = yup.object({
    type: yup
        .mixed<MatchOutcomeType>()
        .oneOf([MatchOutcomeType.APPROVE, MatchOutcomeType.DECLINE, MatchOutcomeType.VOID], 'Match outcome required'),
    note: yup.string().when('type', (type, schema) => {
        if (type === MatchOutcomeType.VOID) {
            return schema.required('Note required');
        }
        return schema.notRequired();
    }),
});

const getFeeDescription = (feeConfig: FeeConfig, premiums: number): string => {
    if (feeConfig.charge?.initialAmount) {
        const feeDescription =
            feeConfig.charge?.calculatedOn === CalculatedOn.PERCENT
                ? `${feeConfig.charge.chargeValue}%`
                : currencyFormat.format(feeConfig.charge.initialAmount);

        return `${currencyFormat.format(feeConfig.upfrontPaymentFee + premiums)} (inc. ${feeDescription} fee)`;
    } else {
        return currencyFormat.format(premiums);
    }
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
