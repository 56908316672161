import { ExpectedPayment, ExpectedPaymentCausedByType, ExpectedPaymentStatus } from '../apis/invoice';
import { Box, Chip } from '@mui/material';
import ExpectedPaymentStatusChip from './ExpectedPaymentStatusChip';
import useDefaultFeeLabel from '../hooks/useDefaultFeeLabel';
import { upperFirst } from 'lodash';

type Props = {
    expectedPaymentStatus: ExpectedPaymentStatus;
    expectedPayments: ExpectedPayment[];
};

export default function DetailedExpectedPaymentStatusChips({
    expectedPaymentStatus,
    expectedPayments,
}: Readonly<Props>) {
    const defaultFeeLabel = useDefaultFeeLabel();

    const includesDefaultFees = expectedPayments.some((ep) => ep.causedBy === ExpectedPaymentCausedByType.DEFAULT_FEE);
    const includesUnexpectedInterest = expectedPayments.some(
        (ep) => ep.causedBy === ExpectedPaymentCausedByType.UNEXPECTED_INTEREST
    );

    return (
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <ExpectedPaymentStatusChip expectedPaymentStatus={expectedPaymentStatus} />
            {includesDefaultFees && <Chip label={upperFirst(defaultFeeLabel)} color='warning' variant='outlined' />}
            {includesUnexpectedInterest && <Chip label='Unexpected interest' color='warning' variant='outlined' />}
        </Box>
    );
}
