import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { ClientLead } from '../../../apis/clientLead';
import {
    BusinessClient,
    Client,
    CreateBusinessFields,
    CreateIndividualFields,
    CreateTrustFields,
    IndividualClient,
    InsuredType,
    TrustClient,
} from '../../../apis/clients';
import { castLeadToBusiness, castLeadToIndividual, castLeadToTrust } from '../../../util/insuredUtils';
import CreateBusinessClient from '../../CreateClient/CreateBusinessClient';
import CreateIndividualClient from '../../CreateClient/CreateIndividualClient';
import CreateTrustClient from '../../CreateClient/CreateTrustClient';

type Props = {
    callback?: (client: Client) => void;
    clientLead: ClientLead;
    saveCounter?: number;
    save: (fields: CreateIndividualFields | CreateBusinessFields | CreateTrustFields) => void;
};

export default function CreateClient({ callback, clientLead, saveCounter, save }: Readonly<Props>) {
    const [clientType, setClientType] = useState<InsuredType>(clientLead.insuredType);

    const handleType = (_event: React.MouseEvent<HTMLElement>, newType: InsuredType | null) => {
        if (newType != null) {
            setClientType(newType);
        }
    };

    const getCreateElement = (clientType: InsuredType): React.ReactElement => {
        switch (clientType) {
            case InsuredType.INDIVIDUAL:
                return (
                    <CreateIndividualClient
                        callback={callback}
                        saveCounter={saveCounter}
                        handleSave={(ind) => save({ ...ind, insuredType: InsuredType.INDIVIDUAL })}
                        client={castLeadToIndividual(clientLead) as IndividualClient}
                        inputSize='small'
                    />
                );
            case InsuredType.BUSINESS:
                return (
                    <CreateBusinessClient
                        callback={callback}
                        saveCounter={saveCounter}
                        handleSave={(bus) => save({ ...bus, insuredType: InsuredType.BUSINESS })}
                        client={castLeadToBusiness(clientLead) as BusinessClient}
                        inputSize='small'
                    />
                );
            case InsuredType.TRUST:
                return (
                    <CreateTrustClient
                        callback={callback}
                        saveCounter={saveCounter}
                        handleSave={(trust) => save({ ...trust, insuredType: InsuredType.TRUST })}
                        client={castLeadToTrust(clientLead) as TrustClient}
                        inputSize='small'
                    />
                );
        }
    };

    return (
        <Box maxWidth={560}>
            <Box mb={2}>
                <ToggleButtonGroup
                    value={clientType}
                    exclusive
                    onChange={handleType}
                    aria-label='client type'
                    color='primary'
                    size='small'
                >
                    <ToggleButton value={InsuredType.INDIVIDUAL} aria-label={InsuredType.INDIVIDUAL}>
                        Individual
                    </ToggleButton>
                    <ToggleButton value={InsuredType.BUSINESS} aria-label={InsuredType.BUSINESS}>
                        Business
                    </ToggleButton>
                    <ToggleButton value={InsuredType.TRUST} aria-label={InsuredType.TRUST}>
                        Trust
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {getCreateElement(clientType)}
        </Box>
    );
}
