import { createSlice } from '@reduxjs/toolkit';
import { FederatedSsoConfiguration } from '../../apis/sellerContext';
import { FetchState, pendingState } from '../../hooks/useFetch';
import { Action } from './Hooks';

const initialState: { state: FetchState<FederatedSsoConfiguration> } = {
    state: pendingState,
};

export const federatedSsoConfigurationSlice = createSlice({
    name: 'federated-sso-configuration',
    initialState,
    reducers: {
        setFederatedSsoConfigurationState: (state, action: Action<FetchState<FederatedSsoConfiguration>>) => {
            state.state = action.payload;
        },
        clearFederatedSsoConfigurationState: (state) => {
            state.state = pendingState;
        },
    },
});

export const { setFederatedSsoConfigurationState, clearFederatedSsoConfigurationState } =
    federatedSsoConfigurationSlice.actions;

export default federatedSsoConfigurationSlice.reducer;
