import axios from 'axios';
import { Direction, ListParams, assignDefaultListParams } from './common';
import { CancellationReason, LoanCancellationRequestStatus } from './invoice';

export enum OverdueLoanSortProperty {
    DAYS_PAST_DUE = 'DAYS_PAST_DUE',
    OVERDUE_AMOUNT = 'OVERDUE_AMOUNT',
    LAST_ATTEMPT_DATE = 'LAST_ATTEMPT_DATE',
    NEXT_ATTEMPT_DATE = 'NEXT_ATTEMPT_DATE',
    IN_ARRANGEMENT = 'IN_ARRANGEMENT',
}

export enum CancellationRequestSortProperty {
    REASON = 'REASON',
    EFFECTIVE_DATE = 'EFFECTIVE_DATE',
    STATUS = 'STATUS',
    BALANCE = 'BALANCE',
}

export enum LoanArrangementType {
    SETUP_ARRANGEMENT = 'SETUP_ARRANGEMENT',
    NONE = 'NONE',
}

export type OverdueLoanPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: OverdueLoanResult[];
};

export type OverdueLoanResult = {
    invoiceIdentifier: string;
    invoiceNumber: string;
    insuredName: string;
    daysPastDue: number;
    overdueAmount: number;
    lastAttemptDate: string;
    nextAttemptDate?: string;
    inArrangement: boolean;
};

type ListOverdueLoansParams = ListParams & {
    sortProperty: OverdueLoanSortProperty;
    direction: Direction;
    dpdStart?: number;
    dpdEnd?: number;
    inArrangements?: LoanArrangementType[];
};

export type CancellationRequestPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: CancellationRequestSummary[];
};

export type CancellationRequestSummary = {
    invoiceIdentifier: string;
    reason: CancellationReason;
    policyNumbers: string[];
    clientName: string;
    effectiveDate: string;
    status: LoanCancellationRequestStatus;
    balance: number;
};

type ListCancellationRequestParams = ListParams & {
    cancellationRequestSortProperty: CancellationRequestSortProperty;
    direction: Direction;
    statuses?: LoanCancellationRequestStatus[];
    reasons?: CancellationReason[];
    effectiveDateStart?: string;
    effectiveDateEnd?: string;
};

export const searchOverdueLoans = async (partial?: Partial<ListOverdueLoansParams>): Promise<OverdueLoanPage> => {
    const searchRequest = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/loans/_query/overdue`, searchRequest)
        .then(({ data }) => data);
};

export const searchCancellationRequests = async (
    partial?: Partial<ListCancellationRequestParams>
): Promise<CancellationRequestPage> => {
    const searchRequest = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/loans/_query/cancellation-request`, searchRequest)
        .then(({ data }) => data);
};
