import { Box, Breadcrumbs, Paper } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import InvoiceForm from './InvoiceForm';
import { Client } from '../../apis/clients';

type Props = {
    client?: Client;
};

const Index = (props: Props) => {
    const [client, setClient] = useState(props.client);

    return (
        <>
            <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
                <Link to={'/invoices'}>Invoices</Link>
                <Box>New invoice</Box>
            </Breadcrumbs>
            <Paper variant='elevation' sx={{ p: 2, mt: 2 }}>
                <InvoiceForm client={client} setClient={setClient} />
            </Paper>
        </>
    );
};

export default Index;
