import { Box, Divider, Typography } from '@mui/material';
import moment from 'moment';

export default () => {
    return (
        <Box sx={{ height: '80px', width: '100%' }} component='footer'>
            <Divider variant='middle' />
            <Typography variant='caption' fontWeight={300} marginTop={3} textAlign='center' display='block'>
                © Simfuni Limited {moment().format('YYYY')}
            </Typography>
        </Box>
    );
};
