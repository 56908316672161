import { Alert, AlertTitle, Box, Button, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/reducer/Hooks';
import { FetchStateType, isSuccess } from '../../../hooks/useFetch';
import { useState } from 'react';
import CompleteCancellationRequest from '../Cancellation/CompleteCancellationRequest';
import CreateAddPaymentSteps, { PaymentType } from '../Arrangements/AddPaymentSteps/CreateAddPaymentSteps';
import { Invoice, Loan } from '../../../apis/invoice';
import { Step } from '../Arrangements/types';
import { setLoanState } from '../../../store/reducer/LoanReducer';
import { SnackState } from '../../../components/SnackAlert';

type Props = {
    invoice: Invoice;

    setSnack: (state: SnackState) => void;
};

export default function CompleteCancellationRequestAlert({ invoice, setSnack }: Readonly<Props>) {
    const loanState = useAppSelector((root) => root.LoanReducer).state;
    const [openCompleteCancellationDialog, setOpenCompleteCancellationDialog] = useState(false);
    const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
    const dispatch = useAppDispatch();

    const handleClose = (updatedLoan?: Loan) => {
        if (updatedLoan) {
            dispatch(setLoanState({ value: updatedLoan, type: FetchStateType.SUCCESS }));
        }

        setOpenCompleteCancellationDialog(false);
    };

    if (!isSuccess(loanState)) {
        return null;
    }

    return (
        <Grid item xs={12} mt={2}>
            <Alert severity='error'>
                <AlertTitle>Cancellation review required</AlertTitle>
                {`This policy has been cancelled with an outstanding balance of ${currencyFormat.format(loanState.value.balance)}. You can either add a transaction or mark the cancellation as completed.`}
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                    <Button variant='outlined' onClick={() => setOpenCompleteCancellationDialog(true)}>
                        Mark as completed
                    </Button>
                    <Button variant='outlined' onClick={() => setOpenAddPaymentModal(true)}>
                        Add transaction
                    </Button>
                </Box>
            </Alert>

            <CompleteCancellationRequest
                open={openCompleteCancellationDialog}
                handleClose={handleClose}
                loan={loanState.value}
                setSnack={setSnack}
                invoice={invoice}
            />
            <CreateAddPaymentSteps
                open={openAddPaymentModal}
                setClosed={() => {
                    setOpenAddPaymentModal(false);
                }}
                startingStep={Step.CONFIGURE}
                startingPaymentType={PaymentType.EXTERNAL_TRANSACTION}
            />
        </Grid>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: 2,
});
