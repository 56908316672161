import { Box, Divider, Typography } from '@mui/material';
import moment from 'moment';
import { InvoiceNoteDto } from '../../../apis/invoice';
import NoRecords from '../../../components/NoRecords';
import { DATE_MONTH_DAY_YEAR_TIME } from '../../../util/dateUtils';

type Props = {
    invoiceNotes: InvoiceNoteDto[];
};

export default function InvoiceNote({ invoiceNotes }: Readonly<Props>) {
    return (
        <Box>
            {!invoiceNotes || invoiceNotes.length === 0 ? (
                <NoRecords />
            ) : (
                invoiceNotes.map((invoiceNote, index) => {
                    return (
                        <Box key={invoiceNote.uuid}>
                            <Box sx={{ pt: 1, pb: 1 }}>
                                <Typography sx={{ whiteSpace: 'pre-wrap', lineHeight: 1, pt: 1, pb: 1 }}>
                                    {invoiceNote.contents}
                                </Typography>
                                <Typography variant='subtitle1'>
                                    {invoiceNote.user} &#x2022;{' '}
                                    {moment.utc(invoiceNote.createdDate).local().format(DATE_MONTH_DAY_YEAR_TIME)}
                                </Typography>
                            </Box>
                            {invoiceNotes.length - 1 !== index && <Divider />}
                        </Box>
                    );
                })
            )}
        </Box>
    );
}
