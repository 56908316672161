import {
    Alert,
    Box,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isEmpty, round } from 'lodash';
import { ExpectedPaymentStatus, InvoiceDetailsResponse, Loan, LoanStatus } from '../../../../apis/invoice';
import { ExternalTransactionType } from '../../../../apis/variations';
import StepCard from '../../../../components/StepCard';
import { isSuccess } from '../../../../hooks/useFetch';
import { useAppSelector } from '../../../../store/reducer/Hooks';
import {
    asString,
    DATE_COMPACT,
    DATE_FRIENDLY,
    DATE_SERVER_FORMAT,
    handleDatePickerChange,
    invalidDate,
} from '../../../../util/dateUtils';
import { RecordExternalTransactionRequestFields } from '../types';

interface Props {
    details: InvoiceDetailsResponse;
    cancel: () => void;
    handleBack: () => void;
    externalTransactionRequestFields?: RecordExternalTransactionRequestFields;
    setExternalTransactionRequestFields: (fields: RecordExternalTransactionRequestFields) => void;
    loan: Loan;
}

export default function AddExternalTransactionDetailsStep({
    details,
    setExternalTransactionRequestFields,
    cancel,
    handleBack,
    loan,
}: Readonly<Props>) {
    const { invoice } = details;
    const [errorMsg, setErrorMsg] = useState<string>();
    const [externalTransactionType, setExternalTransactionType] = useState(ExternalTransactionType.PAYEMENT);
    const { state: loanState } = useAppSelector((root) => root.LoanReducer);

    const handleExternalTransactionTypeChange =
        (field: { onChange: (val: ExternalTransactionType) => void }) =>
        (event: SelectChangeEvent<ExternalTransactionType>) => {
            const externalTransactionType = event.target.value as ExternalTransactionType;
            setExternalTransactionType(externalTransactionType);
            field.onChange(externalTransactionType);
        };

    const minTransactionDate = moment(invoice.term.firstPaymentDate, DATE_SERVER_FORMAT);
    const activateExpectedPayments = (isSuccess(loanState) && loanState.value.activeExpectedPayments) || [];
    const isInterestsBearing = invoice.term.flatInterestRate > 0;

    const expectedPaymentStatusToIgnore = [
        ExpectedPaymentStatus.REPLACED,
        ExpectedPaymentStatus.WAIVED,
        ExpectedPaymentStatus.VOIDED,
    ];
    const totallyPaidAmount = activateExpectedPayments
        .filter(({ status }) => !expectedPaymentStatusToIgnore.includes(status))
        .map(({ paidAmount }) => paidAmount ?? 0)
        .reduce((a, b) => a + b, 0);

    const overdueExpectedPaymentStatus = [ExpectedPaymentStatus.OVERDUE, ExpectedPaymentStatus.ARRANGEMENT];
    const totallyOverdueAmount = activateExpectedPayments
        .filter(({ status }) => overdueExpectedPaymentStatus.includes(status))
        .map(({ amount, paidAmount }) => amount - (paidAmount ?? 0))
        .reduce((a, b) => a + b, 0);

    const totalUnpaidAmount = loan.balance;
    const isLoanClosed = loan.status === LoanStatus.CLOSED;

    const interestBearingPaymentNotAllowed =
        externalTransactionType === ExternalTransactionType.PAYEMENT &&
        isInterestsBearing &&
        !(totallyOverdueAmount > 0 || (isLoanClosed && totalUnpaidAmount > 0));
    const nonInterestBearingPaymentNotAllowed =
        externalTransactionType === ExternalTransactionType.PAYEMENT && !isInterestsBearing && totalUnpaidAmount <= 0;
    const refundNotAllowed = externalTransactionType === ExternalTransactionType.REFUND && totallyPaidAmount === 0;

    const paymentAllowedAfterLoanClosedMessage = `The transaction will be allocated to offset the balance. The maximum payment amount is ${currencyFormat.format(totalUnpaidAmount)}, the current amount underpaid for the loan.`;
    const paymentNotAllowedAfterLoanClosedMessage = 'The loan is not underpaid.';

    const interestBearingPaymentAllowedMessage = isLoanClosed
        ? paymentAllowedAfterLoanClosedMessage
        : `The transaction will be allocated to the overdue payments and pending arrangements. The maximum payment amount is ${currencyFormat.format(totallyOverdueAmount)}, the current amount owing on the loan.`;
    const interestBearingPaymentNotAllowedMessage = isLoanClosed
        ? paymentNotAllowedAfterLoanClosedMessage
        : 'No overdue payments or pending arrangements to pay.';
    const interestBearingAlertMsg = interestBearingPaymentNotAllowed
        ? interestBearingPaymentNotAllowedMessage
        : interestBearingPaymentAllowedMessage;

    const nonInterestBearingPaymentAllowedMessage = isLoanClosed
        ? paymentAllowedAfterLoanClosedMessage
        : `The transaction will be allocated to the unpaid payments and pending arrangements. The maximum payment amount is ${currencyFormat.format(totalUnpaidAmount)}, the remaining balance on the loan.`;
    const nonInterestBearingPaymentNotAllowedMessage = isLoanClosed
        ? paymentNotAllowedAfterLoanClosedMessage
        : 'No unpaid payments or pending arrangements to pay.';
    const nonInterestingBearingAlertMsg = nonInterestBearingPaymentNotAllowed
        ? nonInterestBearingPaymentNotAllowedMessage
        : nonInterestBearingPaymentAllowedMessage;

    const refundAllowedAlertMessage = isLoanClosed
        ? `The transaction will be deallocated to offset the balance. The maximum refundable amount is ${currencyFormat.format(totallyPaidAmount)}.`
        : `The transaction will be deallocated from the completed payments and arrangements. The maximum refundable amount is ${currencyFormat.format(totallyPaidAmount)}.`;
    const refundAlertMsg = refundNotAllowed
        ? 'No completed payments or arrangements to refund.'
        : refundAllowedAlertMessage;

    const {
        handleSubmit,
        control,
        register,
        formState: { errors: fieldErrors },
    } = useForm<RecordExternalTransactionRequestFields>({
        resolver: yupResolver(
            getSchema(
                minTransactionDate,
                totallyPaidAmount,
                totallyOverdueAmount,
                totalUnpaidAmount,
                isInterestsBearing,
                isLoanClosed
            )
        ),
        defaultValues: {
            externalTransactionType,
        },
    });

    const onSubmit: SubmitHandler<RecordExternalTransactionRequestFields> = ({
        amount,
        transactionDate,
        note,
        externalTransactionType,
    }) => {
        setErrorMsg(undefined);

        const recordExternalTransactionFields: RecordExternalTransactionRequestFields = {
            amount: externalTransactionType === ExternalTransactionType.PAYEMENT ? -amount : amount,
            transactionDate: moment(transactionDate).format(DATE_SERVER_FORMAT),
            note,
            externalTransactionType: externalTransactionType,
        };

        setExternalTransactionRequestFields(recordExternalTransactionFields);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <StepCard>
                <Typography variant='h5' component='h2'>
                    Add manual transaction
                </Typography>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        <Typography variant='caption'>
                            {externalTransactionType === ExternalTransactionType.PAYEMENT ? (
                                <Alert severity='info'>
                                    {isInterestsBearing ? interestBearingAlertMsg : nonInterestingBearingAlertMsg}
                                </Alert>
                            ) : (
                                <Alert severity='warning'>{refundAlertMsg}</Alert>
                            )}
                        </Typography>

                        <Box>
                            <Grid container>
                                <Grid item xs={4} pr={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControl fullWidth required>
                                        <FormLabel htmlFor='externalTransactionType'>Type</FormLabel>
                                        <Controller
                                            name={'externalTransactionType'}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    {...register('externalTransactionType')}
                                                    data-testid={'externalTransactionType'}
                                                    size='small'
                                                    variant='standard'
                                                    value={externalTransactionType}
                                                    label='Type'
                                                    onChange={handleExternalTransactionTypeChange(field)}
                                                >
                                                    <MenuItem value={ExternalTransactionType.PAYEMENT}>
                                                        Payment
                                                    </MenuItem>
                                                    <MenuItem value={ExternalTransactionType.REFUND}>Refund</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4} pr={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControl fullWidth required>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <FormLabel htmlFor='transactionDate'>Transaction date</FormLabel>
                                            <Controller
                                                name={'transactionDate'}
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        {...register('transactionDate')}
                                                        data-testid={'transactionDate'}
                                                        onChange={handleDatePickerChange(field)}
                                                        onAccept={handleDatePickerChange(field)}
                                                        value={
                                                            isEmpty(field.value)
                                                                ? null
                                                                : moment(field.value, DATE_SERVER_FORMAT)
                                                        }
                                                        slotProps={{
                                                            textField: {
                                                                id: 'transactionDate',
                                                                fullWidth: true,
                                                                size: 'small',
                                                                helperText: fieldErrors?.transactionDate?.message,
                                                                error: !!fieldErrors?.transactionDate,
                                                                placeholder: undefined,
                                                            },
                                                        }}
                                                        format={DATE_COMPACT}
                                                        minDate={minTransactionDate}
                                                        maxDate={moment()}
                                                        disabled={
                                                            interestBearingPaymentNotAllowed ||
                                                            nonInterestBearingPaymentNotAllowed ||
                                                            refundNotAllowed
                                                        }
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={4} pr={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormControl fullWidth required>
                                        <FormLabel htmlFor='amount'>Amount</FormLabel>
                                        <Controller
                                            control={control}
                                            name={'amount'}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    {...register('amount')}
                                                    data-testid={'amount'}
                                                    fullWidth
                                                    autoComplete='no'
                                                    size='small'
                                                    error={!!fieldErrors?.amount}
                                                    helperText={fieldErrors?.amount?.message}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='start'>$</InputAdornment>
                                                        ),
                                                    }}
                                                    disabled={
                                                        interestBearingPaymentNotAllowed ||
                                                        nonInterestBearingPaymentNotAllowed ||
                                                        refundNotAllowed
                                                    }
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                        <FormControl required>
                            <FormLabel htmlFor='note'>Note</FormLabel>
                            <Controller
                                name='note'
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        {...register('note')}
                                        data-testid={'note'}
                                        size='small'
                                        multiline
                                        rows={4}
                                        inputProps={{ maxLength: 1024 }}
                                        error={!!fieldErrors?.note}
                                        helperText={fieldErrors?.note?.message}
                                    />
                                )}
                            />
                        </FormControl>

                        {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid item xs={6}>
                            <Button onClick={handleBack} variant='outlined'>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                            <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                                Cancel
                            </Button>
                            <Button type='submit' variant='contained' size='large' sx={{ minWidth: '160px' }}>
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </StepCard>
        </form>
    );
}

const getSchema = (
    minTransactionDate: moment.Moment,
    totallyPaidAmount: number,
    totallyOverdueAmount: number,
    totalUnpaidAmount: number,
    isInterestsBearing: boolean,
    isLoanClosed: boolean
) => {
    return yup.object({
        note: yup.string().required('Note required'),
        amount: yup
            .number()
            .nullable()
            .transform((curr) => (isNaN(curr) ? null : curr))
            .required('Amount required')
            .positive('Amount must be positive')
            .test('2-digit-decimals', 'max to 2 digits fractions', (value) => {
                if (value == null) {
                    return true;
                }

                return /^\d+(\.\d{0,2})?$/.test(value.toString());
            })
            .test('checkAmount', 'Amount validation error', (value, context) => {
                const externalTransactionType = context.parent.externalTransactionType;

                if (
                    externalTransactionType === ExternalTransactionType.PAYEMENT &&
                    isInterestsBearing &&
                    !isLoanClosed &&
                    round(value!, 2) > round(totallyOverdueAmount, 2)
                ) {
                    return context.createError({
                        message: `Exceeds max value of ${currencyFormat.format(totallyOverdueAmount)}`,
                    });
                }

                if (
                    externalTransactionType === ExternalTransactionType.PAYEMENT &&
                    (!isInterestsBearing || isLoanClosed) &&
                    round(value!, 2) > round(totalUnpaidAmount, 2)
                ) {
                    return context.createError({
                        message: `Exceeds max value of ${currencyFormat.format(totalUnpaidAmount)}`,
                    });
                }

                if (
                    externalTransactionType === ExternalTransactionType.REFUND &&
                    round(value!, 2) > round(totallyPaidAmount, 2)
                ) {
                    return context.createError({
                        message: `Exceeds max value of ${currencyFormat.format(totallyPaidAmount)}`,
                    });
                }

                return true;
            }),
        transactionDate: yup
            .date()
            .nullable()
            .transform((curr) => (invalidDate(curr) ? null : curr))
            .required('Transaction date required')
            .test(
                'checkDate',
                `Transaction date must be between ${minTransactionDate.format(DATE_FRIENDLY)} and today`,
                (value) => {
                    const date = moment(value);
                    return date.isSameOrAfter(asString(minTransactionDate)) && date.isSameOrBefore(asString(moment()));
                }
            ),
    });
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
