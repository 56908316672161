import { Communication } from '../../apis/communications';
import LineItem from './LineItem';
import { Box, Chip } from '@mui/material';

type Props = {
    dateTime: string;
    communications: Communication[];
    hasTail: boolean;
    invoiceIdentifier?: string;
};

export default function DayLine({ dateTime, communications, hasTail, invoiceIdentifier }: Readonly<Props>) {
    return (
        <Box>
            <Chip label={dateTime} variant='outlined' sx={{ p: 1, height: 30 }} />
            <Box mx={1}>
                {communications.map((comm, index) => (
                    <LineItem
                        key={comm.uuid}
                        isFirst={index === 0}
                        communication={comm}
                        hasTail={hasTail || index < communications.length - 1}
                        invoiceIdentifier={invoiceIdentifier}
                    />
                ))}
            </Box>
        </Box>
    );
}
