import { CalendarToday } from '@mui/icons-material';
import { Alert, Box, Button, Grid, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { useState } from 'react';
import { confirmCancellation } from '../../../apis/cancellation';
import { Client } from '../../../apis/clients';
import { Invoice, InvoiceDetailsResponse, Loan } from '../../../apis/invoice';
import { LoadingButton } from '../../../components/LoadingButton';
import StepCard from '../../../components/StepCard';
import { addKeyDate, getDateDescription } from '../common';
import UpcomingPayments from '../Payments/UpcomingPayments';
import { CancellationPreview } from './types';

type Props = {
    cancellationPreview: CancellationPreview;
    setUpdatedLoan: (loan: Loan) => void;
    details: InvoiceDetailsResponse;
    handleBack: () => void;
    cancel: () => void;
};

export default function ReviewStep({
    handleBack,
    cancel,
    cancellationPreview,
    setUpdatedLoan,
    details,
}: Readonly<Props>) {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const keyDates = getKeyDates(cancellationPreview, details.invoice, details.client);

    const handleConfirm = () => {
        setLoading(true);
        setErrorMsg(undefined);
        confirmCancellation(details.invoice.uuid, cancellationPreview)
            .then(setUpdatedLoan)
            .catch(() => setErrorMsg('Something went wrong, please try again.'))
            .finally(() => setLoading(false));
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Review cancellation details
            </Typography>
            <Typography variant='caption'>
                Check the cancellation details below are correct before confirming the cancellation.
            </Typography>

            <Box>
                {Object.entries(keyDates).map(([dateDescription, steps]) => {
                    return (
                        <Box key={dateDescription} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', gap: 2, my: 1 }}>
                                <CalendarToday sx={{ color: grey[600] }} />
                                <Typography variant='h6'>{dateDescription}</Typography>
                            </Box>
                            <Box sx={{ borderLeft: `solid 1px ${grey[300]}`, ml: '11.5px', pl: '27.5px' }}>
                                {steps.map((step, index) => (
                                    <Box key={`${dateDescription}-${index}`} mb={2}>
                                        {step}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    );
                })}
            </Box>

            {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

            <Grid container>
                <Grid item xs={6}>
                    <Button variant='outlined' onClick={handleBack}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' color='primary' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        onClick={handleConfirm}
                        variant='contained'
                        color='primary'
                        type='submit'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        Confirm
                    </LoadingButton>
                </Grid>
            </Grid>
        </StepCard>
    );
}

const getKeyDates = (cancellationPreview: CancellationPreview, invoice: Invoice, client: Client) => {
    const cancellationClosingBalance =
        cancellationPreview.loanAfterCancellationPreview.cancellationRequest?.cancellationClosingBalance ?? 0;

    if (!moment(cancellationPreview.effectiveDate).isAfter(moment())) {
        const formattedAccountState =
            cancellationClosingBalance > 0
                ? `${cancellationClosingBalance} underpaid`
                : `${-cancellationClosingBalance} overpaid`;
        const noMorePaymentsMessage =
            cancellationClosingBalance > 0
                ? 'No more payments will be collected'
                : 'No more payments will be collected and the client may be due a refund';

        return {
            [getDateDescription(moment())]: [
                <Typography variant='subtitle2'>
                    An email will be sent to {client.displayName} to let them know about the cancellation.
                </Typography>,
                <Paper variant='flat' sx={{ backgroundColor: grey[100], paddingX: 2, borderRadius: 0 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mt: 2 }}>
                        <Typography variant='subtitle2'>Closing Balance</Typography>
                        <Typography variant='subtitle2'>{currencyFormat.format(cancellationClosingBalance)}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', mb: 2 }}>
                        <Typography variant='caption'>
                            The cancellation will be effective immediately. The account will be {formattedAccountState}.{' '}
                            {noMorePaymentsMessage}.
                        </Typography>
                    </Box>
                </Paper>,
            ],
        };
    }

    const dates = {
        [getDateDescription(moment())]: [
            <Typography variant='subtitle2'>
                An email will be sent to {client.displayName} to let them know about the cancellation.
            </Typography>,
            <Paper variant='flat' sx={{ backgroundColor: grey[100], paddingX: 2, borderRadius: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mt: 2 }}>
                    <Typography variant='subtitle2'>Projected closing balance at cancellation date:</Typography>
                    <Typography variant='subtitle2'>{currencyFormat.format(cancellationClosingBalance)}</Typography>
                </Box>
            </Paper>,
        ],
    };

    if (cancellationClosingBalance === 0) {
        addKeyDate(
            dates,
            'Remaing scheduled payments',
            <Paper variant='outlined' sx={{ p: 2 }}>
                <UpcomingPayments loan={cancellationPreview.loanAfterCancellationPreview} />
            </Paper>
        );
    }

    const effectiveDateDescription = getDateDescription(moment(cancellationPreview.effectiveDate));
    addKeyDate(dates, effectiveDateDescription, <Typography variant='subtitle2'>Cancellation completed</Typography>);
    addKeyDate(
        dates,
        effectiveDateDescription,
        <Paper variant='flat' sx={{ backgroundColor: grey[100], paddingX: 2, borderRadius: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, mt: 2 }}>
                <Typography variant='subtitle2'>Final balance calculated</Typography>
                <Typography variant='caption'>
                    After cancellation, the final balance will be available on the invoice and in the cancellation
                    work-list.
                </Typography>
            </Box>
        </Paper>
    );

    return dates;
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
