import { Alert, AlertTitle } from '@mui/material';

export default function MigratedInvoiceAlert() {
    return (
        <Alert severity='info' sx={{ py: 2 }}>
            <AlertTitle>Externally managed</AlertTitle>
            This invoice was created by an external system. The information below may not be up to date.
        </Alert>
    );
}
