import { Box, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { Loan } from '../../../apis/invoice';
import Transactions from './Transactions';
import UpcomingPayments from './UpcomingPayments';

type Props = {
    data: Loan;
};

enum PaidStatusFilter {
    UPCOMING,
    TRANSACTIONS,
}

export default function Payments({ data }: Readonly<Props>) {
    const [paidStatusFilter, setPaidStatusFilter] = useState<PaidStatusFilter>(PaidStatusFilter.UPCOMING);

    const handleStatusChange = (_evt: React.MouseEvent, newPaidStatusFilter?: PaidStatusFilter) => {
        if (newPaidStatusFilter != null) {
            setPaidStatusFilter(newPaidStatusFilter);
        }
    };

    return (
        <Paper variant='flat' sx={{ p: 2 }}>
            <Box>
                <ToggleButtonGroup value={paidStatusFilter} exclusive onChange={handleStatusChange}>
                    <ToggleButton value={PaidStatusFilter.UPCOMING} data-testid='show-upcoming'>
                        Upcoming
                    </ToggleButton>
                    <ToggleButton value={PaidStatusFilter.TRANSACTIONS} data-testid='show-transactions'>
                        Past
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {paidStatusFilter === PaidStatusFilter.UPCOMING && <UpcomingPayments loan={data} />}
            {paidStatusFilter === PaidStatusFilter.TRANSACTIONS && <Transactions loan={data} />}
        </Paper>
    );
}
