import { Alert, AlertColor, AlertTitle, Box, Grid } from '@mui/material';
import moment from 'moment';
import { LoanCancellationRequest, LoanSubStatus } from '../../../apis/invoice';
import { isSuccess } from '../../../hooks/useFetch';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';
import { calculateDaysDifference } from '../common';

export default function CancellationAlert() {
    const loanState = useAppSelector((root) => root.LoanReducer).state;
    if (!isSuccess(loanState)) {
        return null;
    }

    const loan = loanState.value;
    if (loan.cancellationRequest == null) {
        return null;
    }

    if (loan.cancellationRequest.effectiveDate == null) {
        return (
            <AlertDetails
                severity='warning'
                title='Pending cancellation'
                descriptions={['Contact Simfuni to finalise cancellation.']}
            />
        );
    }

    if (loan.subStatus === LoanSubStatus.CANCELLED) {
        const descriptions = [`Cancelled ${moment(loan.cancellationRequest.effectiveDate).format(DATE_FRIENDLY)}`];

        if (loan.isInterestsBearing) {
            descriptions.push(
                `The outstanding settlement amount was ${currencyFormat.format(loan.outstandingSettlementAmount!)}.`
            );
        }

        return <AlertDetails severity='error' title='Policies cancelled' descriptions={descriptions} />;
    }

    return (
        <AlertDetails
            severity='warning'
            title='Scheduled for cancellation'
            descriptions={[getEffectiveDate(loan.cancellationRequest)]}
        />
    );
}

const AlertDetails = ({
    severity,
    title,
    descriptions,
}: {
    severity: AlertColor;
    title: string;
    descriptions: string[];
}) => {
    return (
        <Grid item xs={12}>
            <Alert severity={severity}>
                <AlertTitle>{title}</AlertTitle>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {descriptions.map((description) => (
                        <Box key={description}>{description}</Box>
                    ))}
                </Box>
            </Alert>
        </Grid>
    );
};

const getEffectiveDate = (loanCancellationRequest: LoanCancellationRequest): string => {
    const effectiveDate = moment(loanCancellationRequest.effectiveDate, DATE_SERVER_FORMAT).endOf('day');
    const today = moment().endOf('day');

    let decoration = '';
    if (effectiveDate.isSame(today)) {
        decoration = ' (today)';
    } else if (effectiveDate.isSame(today.add(1, 'days'))) {
        decoration = ` (tomorrow)`;
    } else if (effectiveDate.isAfter(today)) {
        decoration = ` (in ${calculateDaysDifference(effectiveDate.format(DATE_SERVER_FORMAT))} days)`;
    }

    return `${effectiveDate.format(DATE_FRIENDLY)}${decoration}`;
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
