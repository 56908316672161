import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, AlertColor, Box, Button, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Invoice, Policy } from '../../../apis/invoice';
import { InvoiceRenewalPreview } from '../../../apis/renewal';
import { LoadingButton } from '../../../components/LoadingButton';
import StepCard from '../../../components/StepCard';
import { DATE_COMPACT, DATE_SERVER_FORMAT } from '../../../util/dateUtils';
import { getEndDate, getStartDate } from '../../../util/policyUtils';
import { hasPolicyDurationIssue } from '../../CreateInvoice/hasPolicyDurationIssue';
import { BaseInvoiceFormFields, baseSchema, getFriendlyErrorMsg } from '../../CreateInvoice/invoiceValidation';
import PortfolioDetails from './PortfolioDetails';

type Props = {
    invoice: Invoice;
    renewalPreview?: InvoiceRenewalPreview;
    setRenewalPreview: (renewal: InvoiceRenewalPreview) => void;
    cancel: () => void;
    sendData: (data: BaseInvoiceFormFields) => Promise<InvoiceRenewalPreview>;
};

export default function InputRenewalDetailsStep({
    invoice,
    renewalPreview,
    setRenewalPreview,
    cancel,
    sendData,
}: Readonly<Props>) {
    const [snackbar, setSnackbar] = useState<{ msg: string; severity: AlertColor }>();
    const [submitting, setSubmitting] = useState(false);
    const currentEndDate = getEndDate(invoice.portfolio.policies);
    const schemaOptions = {
        minStartDate: {
            value: moment(currentEndDate).add(1, 'day'),
            msg: 'Must start after current period ends',
        },
    };

    const {
        control,
        formState: { errors },
        register,
        setValue,
        handleSubmit,
    } = useForm<BaseInvoiceFormFields>({
        resolver: yupResolver(baseSchema(schemaOptions)),
        defaultValues: {
            invoiceNumber: renewalPreview?.invoice.number ?? invoice.number,
            policies:
                renewalPreview?.invoice.portfolio.policies ??
                invoice.portfolio.policies.map((policy) => ({
                    ...policy,
                    currentPolicyVersion: {
                        renewFromPolicyVersionIdentifier: policy.currentPolicyVersion.uuid,
                        premiums: policy.currentPolicyVersion.premiums,
                    },
                    startDate: moment(policy.endDate, DATE_SERVER_FORMAT).add(1, 'day').format(DATE_SERVER_FORMAT),
                    endDate: moment(policy.endDate, DATE_SERVER_FORMAT).add(1, 'year').format(DATE_SERVER_FORMAT),
                })),
        },
    });

    const { fields, remove } = useFieldArray({
        control,
        name: 'policies',
    });
    const policies = useWatch({ control, name: 'policies' });

    useEffect(() => {
        checkPolicyLength();
    }, [policies]);

    const checkPolicyLength = () => {
        if (hasPolicyDurationIssue(policies)) {
            setSnackbar({
                msg: 'The duration is much shorter than the total contract time. Consider splitting these policies for better alignment.',
                severity: 'warning',
            });
        }
    };

    const onSubmit = (data: BaseInvoiceFormFields) => {
        setSubmitting(true);

        sendData(data)
            .then(setRenewalPreview)
            .catch((e) => {
                setSnackbar({
                    msg: getFriendlyErrorMsg(e),
                    severity: 'error',
                });
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <StepCard>
                <Typography variant='h5' component='h1'>
                    {renewalPreview?.invoice.uuid != null ? 'Edit pending renewal' : 'Renew policies'}
                </Typography>
                <Box width={400}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='body2'>Current period</Typography>
                        <Typography variant='caption'>
                            {getCurrentPeriodInfo(invoice.portfolio.policies, currentEndDate)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='body2'>Current premiums</Typography>
                        <Typography variant='caption'>{currencyFormat.format(invoice.totalPremiums)}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant='h6' component='h2'>
                        New invoice details
                    </Typography>
                    <Box>
                        <FormControl required>
                            <FormLabel htmlFor='invoiceNumber'>Invoice number</FormLabel>
                            <Controller
                                name='invoiceNumber'
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id='invoiceNumber'
                                        size='small'
                                        variant='outlined'
                                        error={!!errors.invoiceNumber}
                                        helperText={errors.invoiceNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                    <PortfolioDetails
                        fields={fields}
                        remove={remove}
                        control={control}
                        errors={errors}
                        register={register}
                        setValue={setValue}
                        invoice={invoice}
                    />
                </Box>

                {snackbar && (
                    <Alert onClose={() => setSnackbar(undefined)} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.msg}
                    </Alert>
                )}
                <Box sx={{ width: '100%', textAlign: 'right' }}>
                    <Button onClick={cancel} sx={{ mr: 1, width: '100px' }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        loading={submitting}
                        sx={{ width: '220px' }}
                    >
                        Next
                    </LoadingButton>
                </Box>
            </StepCard>
        </form>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

const daysRemainingMsg = (daysRemaining: number) => {
    switch (true) {
        case daysRemaining === 1:
            return `(${daysRemaining} day left)`;
        case daysRemaining > 1:
            return `(${daysRemaining} days left)`;
        default:
            return '';
    }
};

const getCurrentPeriodInfo = (policies: Policy[], endDate: Moment) => {
    const startDate = getStartDate(policies);
    const today = moment().endOf('day');
    const daysRemaining = endDate.diff(today, 'days');

    return `${startDate.format(DATE_COMPACT)} - ${endDate.format(DATE_COMPACT)} ${daysRemainingMsg(daysRemaining)}`;
};
