import { useEffect, useState } from 'react';
import { InvoiceDetailsResponse, Policy } from '../../apis/invoice';
import {
    PolicyVersion,
    PolicyVersionPreview,
    PolicyVersionRequest,
    previewQuote,
    QuoteStatus,
} from '../../apis/quotes';
import { SellerProduct } from '../../apis/sellerProduct';
import StepsDrawer from '../StepsDrawer';
import DoneStep from './DoneStep';
import EditQuoteStep from './EditQuoteStep';
import ReviewQuoteStep from './ReviewQuoteStep';
import { ActionType } from './common';
import { useAppDispatch } from '../../store/reducer/Hooks';
import { setInvoiceDetailsState } from '../../store/reducer/InvoiceDetailsReducer';
import { FetchStateType } from '../../hooks/useFetch';
import PageLoading from '../PageLoading';

type Props = {
    open: boolean;
    setClosed: () => void;
    policy: Policy;
    sellerProduct: SellerProduct;
    invoiceDetails: InvoiceDetailsResponse;
    policyVersion?: PolicyVersion;
};

export default function CreateQuoteSteps({
    open,
    setClosed,
    sellerProduct,
    policy,
    invoiceDetails,
    policyVersion,
}: Readonly<Props>) {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState<Steps>(Steps.EDIT);
    const [policyVersionRequest, setPolicyVersionRequest] = useState<PolicyVersionRequest | undefined>(policyVersion);
    const [policyVersionPreview, setPolicyVersionPreview] = useState<PolicyVersionPreview>();
    const [updatedInvoiceDetails, setUpdatedInvoiceDetails] = useState<InvoiceDetailsResponse>();
    const [actionType, setActionType] = useState<ActionType>(ActionType.SEND_QUOTE);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setPolicyVersionRequest(policyVersion);
        if (policyVersion?.quote?.status === QuoteStatus.QUOTED) {
            setLoading(true);
            previewQuote(invoiceDetails.invoice.uuid, policy.uuid, policyVersion)
                .then((preview) => {
                    handleSetPolicyVersionPreview(policyVersion, preview);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [policyVersion, open]);

    const handleClose = () => {
        if (updatedInvoiceDetails) {
            dispatch(
                setInvoiceDetailsState({
                    type: FetchStateType.SUCCESS,
                    value: updatedInvoiceDetails,
                })
            );
        }

        setPolicyVersionRequest(policyVersion);
        setPolicyVersionPreview(undefined);
        setUpdatedInvoiceDetails(undefined);
        setStep(Steps.EDIT);
        setClosed();
    };

    const handleSetPolicyVersionPreview = (request: PolicyVersionRequest, preview: PolicyVersionPreview) => {
        setPolicyVersionRequest(request);
        setPolicyVersionPreview(preview);
        setStep(Steps.REVIEW);
    };

    const handleUpdateInvoice = (actionType: ActionType, invoiceDetails: InvoiceDetailsResponse) => {
        setUpdatedInvoiceDetails(invoiceDetails);
        setActionType(actionType);
        setStep(Steps.DONE);
    };

    const getStep = () => {
        if (loading) {
            return <PageLoading />;
        }

        if (step === Steps.EDIT) {
            return (
                <EditQuoteStep
                    sellerProduct={sellerProduct}
                    policy={policy}
                    handleSetPolicyVersionPreview={handleSetPolicyVersionPreview}
                    policyVersionRequest={policyVersionRequest}
                    cancel={handleClose}
                    invoice={invoiceDetails.invoice}
                />
            );
        }
        if (step === Steps.REVIEW) {
            return (
                <ReviewQuoteStep
                    policy={policy}
                    goBack={() => setStep(Steps.EDIT)}
                    updateInvoice={handleUpdateInvoice}
                    policyVersionPreview={policyVersionPreview!}
                    policyVersionRequest={policyVersionRequest!}
                    client={invoiceDetails.client}
                    invoice={invoiceDetails.invoice}
                />
            );
        }
        if (step === Steps.DONE) {
            return <DoneStep handleClose={handleClose} actionType={actionType} client={invoiceDetails.client} />;
        }
    };

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames} disableEscapeKeyDown>
            {getStep()}
        </StepsDrawer>
    );
}

const stepNames = ['Edit', 'Review', 'Done'];
enum Steps {
    EDIT,
    REVIEW,
    DONE,
}
