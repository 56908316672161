import { Alert, Paper, Typography } from '@mui/material';
import InvoiceList from '../components/InvoiceList';
import { useState } from 'react';
import Dashboard from '../components/Dashboard';
import { useFetch } from '../hooks/useFetch';
import { defaultPageSize } from '../apis/common';
import { listInvoices, fetchDashboardData } from '../apis/invoice';
import { useAppSelector } from '../store/reducer/Hooks';

const Home = () => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { invoiceAllowed } = permissions;

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);

    const listInvoicesState = useFetch(
        () => listInvoices({ page: page - 1, pageSize: rowsPerPage }),
        [page, rowsPerPage],
        { canFetch: () => invoiceAllowed }
    );

    const fetchDashboardDataState = useFetch(fetchDashboardData);

    return (
        <>
            <Dashboard state={fetchDashboardDataState} />

            <Paper variant='flat' sx={{ p: 2, mt: 3 }}>
                <Typography variant='h2'>Latest invoices</Typography>
                {invoiceAllowed && (
                    <InvoiceList
                        state={listInvoicesState}
                        changePage={setPage}
                        changeRowsPerPage={setRowsPerPage}
                        page={page}
                        rowsPerPage={rowsPerPage}
                    />
                )}
                {!invoiceAllowed && (
                    <Alert severity='info'>
                        Your account doesn't have permission to view invoices. If you need help, contact your account
                        admin.
                    </Alert>
                )}
            </Paper>
        </>
    );
};

export default Home;
