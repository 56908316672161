import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Page, PageType } from '.';

type Props = {
    page: Page;
    selectedPage: PageType;
    handlePageClick: (page: Page) => void;
};

export default ({ page, selectedPage, handlePageClick }: Props) => {
    const Icon = page.icon;
    const selected = selectedPage === page.type;
    return (
        <ListItemButton onClick={() => handlePageClick(page)}>
            <ListItemIcon>
                <Icon color={selected ? 'primary' : 'inherit'} />
            </ListItemIcon>
            <ListItemText primary={page.name} primaryTypographyProps={{ color: selected ? 'primary' : 'inherit' }} />
        </ListItemButton>
    );
};
