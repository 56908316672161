import { createSlice } from '@reduxjs/toolkit';
import { InvoiceDetailsResponse } from '../../apis/invoice';
import { FetchState, pendingState } from '../../hooks/useFetch';

type InvoiceDetailsAction = {
    type: string;
    payload: FetchState<InvoiceDetailsResponse>;
};

const initialState: { state: FetchState<InvoiceDetailsResponse> } = {
    state: pendingState,
};

export const invoiceDetailsSlice = createSlice({
    name: 'invoiceDetails',
    initialState,
    reducers: {
        setInvoiceDetailsState: (state, action: InvoiceDetailsAction) => {
            state.state = action.payload;
        },
        clearInvoiceDetailsState: (state) => {
            state.state = pendingState;
        },
    },
});

export const { setInvoiceDetailsState, clearInvoiceDetailsState } = invoiceDetailsSlice.actions;

export default invoiceDetailsSlice.reducer;
