import { Typography } from '@mui/material';
import { InvoiceRenewalPreview } from '../../../../apis/renewal';

type Props = {
    renewalPreview: InvoiceRenewalPreview;
};

export default function RenewalFirstStep({ renewalPreview }: Readonly<Props>) {
    if (renewalPreview.invoice.uuid) {
        return <Typography variant='subtitle2'>Invoice will be updated</Typography>;
    }

    return <Typography variant='subtitle2'>A new invoice will be created</Typography>;
}
