import { ChangeEvent } from 'react';
import { Permission, PermissionConfiguration, User } from '../../apis/users';
import {
    Checkbox,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import { HelpOutlined } from '@mui/icons-material';
import { isEmpty } from 'lodash';

interface GroupSectionProps {
    user: User;
    permissionConfiguration: PermissionConfiguration;
    currentUsername: string;
    hasUserManagementPermission: boolean;
    onToggleUserPermissionGroup: (
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean,
        permissionGroupUuid: string
    ) => void;
    togglingUserPermissionGroup: string | undefined;
}

const GroupSection = ({
    user,
    permissionConfiguration,
    currentUsername,
    hasUserManagementPermission,
    onToggleUserPermissionGroup,
    togglingUserPermissionGroup,
}: GroupSectionProps) => {
    const { groups } = permissionConfiguration;
    const userGroupUuids = user.groups.map((group) => group.uuid);

    const hasUserGrantedWithUserManagementPermissionDirectly = user.permissions.includes(Permission.USER_MANAGEMENT);
    const isGroupTheOnlyOneWithUserManagementPermission = (groupUuid: string) => {
        const groupsWithUserManagementPermission = user.groups
            .filter((group) => isEmpty(group.deletedDate))
            .filter((group) =>
                group.permissions
                    .filter((groupPermission) => isEmpty(groupPermission.deletedDate))
                    .map((groupPermission) => groupPermission.permission)
                    .includes(Permission.USER_MANAGEMENT)
            );

        return (
            groupsWithUserManagementPermission.length === 1 &&
            groupsWithUserManagementPermission.filter((group) => group.uuid === groupUuid).length !== 0
        );
    };

    return (
        <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
            <Typography variant='h2'>
                Groups
                <Tooltip title='Select one or more groups to enable all associated permissions.'>
                    <IconButton>
                        <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
                    </IconButton>
                </Tooltip>
            </Typography>
            <List dense sx={{ position: 'relative', overflow: 'auto' }}>
                {groups
                    .filter((group) => isEmpty(group.deletedDate))
                    .sort((g1, g2) => g1.name.toLowerCase().localeCompare(g2.name.toLowerCase()))
                    .map((group) => {
                        const isLastGroupWithUserManagementPermission =
                            user.username === currentUsername &&
                            !hasUserGrantedWithUserManagementPermissionDirectly &&
                            isGroupTheOnlyOneWithUserManagementPermission(group.uuid);
                        const checkBoxShouldBeDisabled =
                            !hasUserManagementPermission ||
                            togglingUserPermissionGroup !== undefined ||
                            isLastGroupWithUserManagementPermission;

                        const secondaryAction =
                            group.uuid === togglingUserPermissionGroup ? (
                                <CircularProgress size={24} color='primary' sx={{ ml: 1, mr: 2 }} />
                            ) : (
                                <Checkbox
                                    sx={{ mr: '6px' }}
                                    checked={userGroupUuids.includes(group.uuid)}
                                    onChange={(event, checked) =>
                                        onToggleUserPermissionGroup(event, checked, group.uuid)
                                    }
                                    disabled={checkBoxShouldBeDisabled}
                                />
                            );

                        let groupTitleNode;
                        if (hasUserManagementPermission && isLastGroupWithUserManagementPermission) {
                            groupTitleNode = (
                                <Typography>
                                    {group.name}
                                    <Tooltip title='You cannot remove your user management permission by yourself.'>
                                        <IconButton>
                                            <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            );
                        } else {
                            groupTitleNode = <>{group.name}</>;
                        }

                        return (
                            <ListItem key={group.uuid}>
                                {secondaryAction}
                                <ListItemText primary={groupTitleNode} secondary={group.description} />
                            </ListItem>
                        );
                    })}
            </List>
        </Paper>
    );
};

export default GroupSection;
