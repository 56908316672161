import { useState } from 'react';
import InvoiceReport from './InvoiceReport';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { Box, Breadcrumbs, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ClientReport from './ClientReport';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import SettlementReport from './SettlementReport';
import { DATE_COMPACT, DATE_SERVER_FORMAT, getValidateDates } from '../../util/dateUtils';
import PaymentsReceivedReport from './PaymentsReceivedReport';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

enum ReportType {
    PAYMENT = 'PAYMENT',
    PAYMENTS_RECEIVED = 'PAYMENTS_RECEIVED',
    SETTLEMENT = 'SETTLEMENT',
    CLIENT = 'CLIENT',
}

export default () => {
    const [startDate, setStartDate] = useState(moment().subtract(1, 'week').format(DATE_SERVER_FORMAT));
    const [endDate, setEndDate] = useState(moment().format(DATE_SERVER_FORMAT));
    const [selectedReport, setSelectedReport] = useState(ReportType.PAYMENT);
    const minDate = moment().subtract(5, 'years');
    const maxDate = moment();

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedReport(event.target.value as ReportType);
    };

    const renderReportType = () => {
        switch (selectedReport) {
            case ReportType.PAYMENT:
                return <InvoiceReport startDate={startDate} endDate={endDate} />;
            case ReportType.PAYMENTS_RECEIVED:
                return <PaymentsReceivedReport startDate={startDate} endDate={endDate} />;
            case ReportType.SETTLEMENT:
                return <SettlementReport startDate={startDate} endDate={endDate} />;
            case ReportType.CLIENT:
                return <ClientReport startDate={startDate} endDate={endDate} />;
        }
    };

    const validDates = getValidateDates(minDate, maxDate);

    const handleStartDateChange = (startDateInput: Moment | null) => {
        if (!validDates(startDateInput, moment(endDate, DATE_SERVER_FORMAT))) {
            return;
        }
        setStartDate(startDateInput!.format(DATE_SERVER_FORMAT));
    };

    const handleEndDateChange = (endDateInput: Moment | null) => {
        if (!validDates(moment(startDate, DATE_SERVER_FORMAT), endDateInput)) {
            return;
        }
        setEndDate(endDateInput!.format(DATE_SERVER_FORMAT));
    };

    return (
        <>
            <Grid container>
                <Grid item sm={2}>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
                        <Box>Reports</Box>
                    </Breadcrumbs>
                </Grid>
                <Grid item sm={10} style={{ color: '#FFF', textAlign: 'right' }}>
                    <FormControl sx={{ mb: 1 }}>
                        <InputLabel id='select-report-label'>Report type</InputLabel>
                        <Select
                            labelId='select-report-label'
                            value={selectedReport}
                            label='Report type'
                            onChange={handleChange}
                            size='small'
                            sx={{ mr: 1, width: '175px', textAlign: 'left' }}
                        >
                            <MenuItem value={ReportType.PAYMENT}>Payment</MenuItem>
                            <MenuItem value={ReportType.PAYMENTS_RECEIVED}>Payments Received</MenuItem>
                            <MenuItem value={ReportType.CLIENT}>Client</MenuItem>
                            <MenuItem value={ReportType.SETTLEMENT}>Settlement</MenuItem>
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label='Start date'
                            value={moment(startDate, DATE_SERVER_FORMAT)}
                            onChange={handleStartDateChange}
                            sx={{ mr: 1, width: '175px', mb: 1 }}
                            slotProps={{
                                textField: {
                                    id: 'startDate',
                                    size: 'small',
                                },
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            format={DATE_COMPACT}
                        />

                        <DatePicker
                            label='End date'
                            value={moment(endDate, DATE_SERVER_FORMAT)}
                            onChange={handleEndDateChange}
                            sx={{ mr: 1, width: '175px', mb: 1 }}
                            slotProps={{
                                textField: {
                                    id: 'endDate',
                                    size: 'small',
                                },
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            format={DATE_COMPACT}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>

            {renderReportType()}
        </>
    );
};
