import axios from 'axios';

export type FederatedSsoConfiguration = {
    federatedLogin: boolean;
    federatedLoginUrl: string;
    federatedLoginIdp: string;
    federatedUserPoolId: string;
    federatedUserPoolWebClientId: string;
    federatedUserPoolAuthDomain: string;
};

export const getFederatedSsoConfiguration = async (): Promise<FederatedSsoConfiguration> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/seller-context/federated-sso-configuration`)
        .then(({ data }) => data);
};
