import { Auth } from 'aws-amplify';
import { AxiosRequestConfig } from 'axios';
import { AppDispatch } from '../store/Store';
import { updateExpirationDate } from '../store/reducer/UserSessionReducer';

export const axiosRequestInterceptor = async (config: AxiosRequestConfig, dispatch: AppDispatch) => {
    try {
        const session = await Auth.currentSession();
        dispatch(updateExpirationDate());

        const token = session.getIdToken().getJwtToken();
        if (token) {
            config.headers!.Authorization! = 'Bearer ' + token;
        }
        return config;
    } catch {
        // TODO: log error
        Auth.federatedSignIn();
    }
};
