import { Box, Typography } from '@mui/material';
import { Policy } from '../../../../apis/invoice';

type Props = {
    policy: Policy;
    currentPremiums: number;
    policyRemoved?: boolean;
};

export default function NewPremiumValue({ policy, currentPremiums, policyRemoved }: Readonly<Props>) {
    const premiumDifference = policy.currentPolicyVersion.premiums - currentPremiums;

    if (policyRemoved) {
        return (
            <Box>
                <Typography variant='caption' component='p'>
                    N/A
                </Typography>
                <Typography variant='caption' component='p'>
                    Policy removed
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Typography variant='caption' component='p'>
                {currencyFormat.format(policy.currentPolicyVersion.premiums)}
            </Typography>
            {premiumDifference !== 0 && (
                <Typography variant='caption' component='p'>
                    {premiumDifference > 0 ? 'Increase' : 'Decrease'} of{' '}
                    {currencyFormat.format(Math.abs(premiumDifference))}
                </Typography>
            )}
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
