import { Menu } from '@mui/icons-material';
import { Box, Drawer, IconButton } from '@mui/material';
import { useState } from 'react';
import MobileMenu from './MobileMenu';
import { Page, PageType } from '.';
import BackButton from '../components/BackButton';
import { useLocation } from 'react-router-dom';

// @ts-ignore
import simfuniLogo from '../images/simfuni_logo.svg';

type Props = {
    children: React.ReactNode;
    selectedPage: PageType;
    handlePageClick: (page: Page) => void;
    logout: () => void;
    pages: Page[];
    allowBack: RegExp[];
};

export default ({ children, selectedPage, handlePageClick, logout, pages, allowBack }: Props) => {
    const [openMenu, setOpenMenu] = useState(false);
    const location = useLocation();
    const canGoBack = allowBack.some((regex) => regex.test(location.pathname));

    const toggleDrawer = (open: boolean) => {
        setOpenMenu(open);
    };

    const clickPage = (page: Page) => {
        handlePageClick(page);
        toggleDrawer(false);
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 6, mx: 1 }}>
                <IconButton data-testid='menu-button' onClick={() => toggleDrawer(true)}>
                    <Menu />
                </IconButton>
                <img alt='Simfuni Logo' src={simfuniLogo} width='115px' />
                <Box width='40px'></Box>
            </Box>
            <Drawer
                data-testid={`menu-drawer-${openMenu ? 'open' : 'closed'}`}
                anchor='top'
                open={openMenu}
                onClose={() => toggleDrawer(false)}
            >
                <MobileMenu selectedPage={selectedPage} handlePageClick={clickPage} logout={logout} pages={pages} />
            </Drawer>
            <Box sx={{ mt: 8, mb: 2, mx: 2 }}>
                {canGoBack && (
                    <Box sx={{ mb: 4 }}>
                        <BackButton />
                    </Box>
                )}
                <Box component='main'>{children}</Box>
            </Box>
        </Box>
    );
};
