import { useTheme } from '@mui/material';

export default () => {
    const theme = useTheme();

    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            data-testid='classcode-car'
        >
            <g clipPath='url(#clip0_8_17)'>
                <path
                    d='M40.2917 28.3043H36.75V26.8261H39.5834C39.5834 22.851 37.9642 22.3913 36.75 22.3913H31.7917C31.5692 22.3913 31.3567 22.2819 31.225 22.0957L27.6125 17.0696C27.3462 16.7 26.9226 16.4813 26.4792 16.4813H17.2709C16.8275 16.4813 16.4038 16.7 16.1361 17.0696L12.525 22.0957C12.3919 22.2819 12.1808 22.3913 11.9583 22.3913H8.41667V26.8261H11.25V28.3043H7.70833C7.31731 28.3043 7 27.9717 7 27.5652V21.6522C7 21.2457 7.31731 20.913 7.70833 20.913H11.6042L15.0042 16.1826C15.5383 15.442 16.3854 15 17.2708 15H26.4792C27.3646 15 28.2117 15.442 28.7458 16.1811L32.1458 20.913H36.75C38.6894 20.913 41 21.9374 41 26.8261V27.5652C41 27.9717 40.6813 28.3043 40.2917 28.3043L40.2917 28.3043Z'
                    fill={theme.palette.primary.main}
                />
                <path d='M16.9167 26.8261H31.0834V28.3043H16.9167V26.8261Z' fill={theme.palette.primary.main} />
                <path
                    d='M14.0834 32C12.1313 32 10.5417 30.3428 10.5417 28.3043C10.5417 26.2659 12.1313 24.6087 14.0834 24.6087C16.0355 24.6087 17.625 26.2659 17.625 28.3043C17.625 30.3428 16.0355 32 14.0834 32ZM14.0834 26.087C12.9117 26.087 11.9584 27.0818 11.9584 28.3043C11.9584 29.5269 12.9117 30.5217 14.0834 30.5217C15.255 30.5217 16.2084 29.5269 16.2084 28.3043C16.2084 27.0818 15.255 26.087 14.0834 26.087Z'
                    fill={theme.palette.primary.main}
                />
                <path
                    d='M33.9167 32C31.9646 32 30.375 30.3428 30.375 28.3043C30.375 26.2659 31.9646 24.6087 33.9167 24.6087C35.8688 24.6087 37.4584 26.2659 37.4584 28.3043C37.4584 30.3428 35.8688 32 33.9167 32ZM33.9167 26.087C32.7451 26.087 31.7917 27.0818 31.7917 28.3043C31.7917 29.5269 32.7451 30.5217 33.9167 30.5217C35.0883 30.5217 36.0417 29.5269 36.0417 28.3043C36.0417 27.0818 35.0883 26.087 33.9167 26.087Z'
                    fill={theme.palette.primary.main}
                />
                <path d='M11.25 20.913H32.5V22.3913H11.25V20.913Z' fill={theme.palette.primary.main} />
                <path d='M21.1667 16.4783H22.5834V20.913H21.1667V16.4783Z' fill={theme.palette.primary.main} />
            </g>
            <defs>
                <clipPath id='clip0_8_17'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
