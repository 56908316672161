import { useTheme } from '@mui/material';

export default () => {
    const theme = useTheme();

    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            data-testid='classcode-house'
        >
            <g clipPath='url(#clip0_11_54)'>
                <path
                    d='M37.787 21.4725L24.447 12.1425C24.177 11.9525 23.817 11.9525 23.547 12.1425L10.207 21.4725C9.84701 21.7225 9.60701 22.1025 9.52701 22.5425C9.44701 22.9825 9.54701 23.4125 9.79701 23.7825L10.797 25.2125C11.047 25.5725 11.427 25.8125 11.867 25.8925C12.287 25.9725 12.747 25.8725 13.107 25.6225L14.397 24.7225V33.9125C14.397 35.1825 15.437 36.2225 16.707 36.2225H21.107C21.537 36.2225 21.897 35.8725 21.897 35.4425V28.1825H26.117V35.4425C26.117 35.8725 26.467 36.2225 26.907 36.2225H31.307C32.577 36.2225 33.617 35.1925 33.617 33.9125V24.7225L34.907 25.6225C35.267 25.8725 35.707 25.9725 36.147 25.8925C36.587 25.8125 36.967 25.5725 37.217 25.2125L38.217 23.7825C38.737 23.0325 38.557 22.0025 37.807 21.4825L37.787 21.4725ZM26.887 26.5925H21.097C20.667 26.5925 20.307 26.9425 20.307 27.3825V34.6425H16.697C16.287 34.6425 15.957 34.3125 15.957 33.9025V23.6125L23.987 17.9925L32.017 23.6125V33.9025C32.017 34.3125 31.687 34.6425 31.277 34.6425H27.667V27.3825C27.667 26.9525 27.317 26.5925 26.877 26.5925H26.887ZM36.917 22.8125C36.917 22.8125 36.917 22.8525 36.907 22.8725L35.907 24.3025C35.907 24.3025 35.877 24.3325 35.857 24.3325C35.837 24.3325 35.817 24.3325 35.797 24.3225L24.447 16.3825C24.317 16.2925 24.157 16.2425 23.997 16.2425C23.837 16.2425 23.677 16.2925 23.547 16.3825L12.197 24.3225C12.197 24.3225 12.157 24.3425 12.137 24.3325C12.117 24.3325 12.097 24.3125 12.087 24.3025L11.087 22.8725C11.087 22.8725 11.067 22.8325 11.077 22.8125C11.077 22.7925 11.097 22.7725 11.107 22.7625L23.997 13.7425L36.887 22.7525C36.887 22.7525 36.917 22.7825 36.927 22.8025L36.917 22.8125Z'
                    fill={theme.palette.primary.main}
                />
            </g>
            <defs>
                <clipPath id='clip0_11_54'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
