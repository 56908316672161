import { useCallback, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Moment from 'react-moment';
import { defaultPageSize } from '../../apis/common';
import { InvoicePage, InvoiceSearchResult, listInvoices } from '../../apis/invoice';
import { FetchWrapper } from '../../components/FetchWrapper';
import InvoiceStatusChip from '../../components/InvoiceStatusChip';
import NoRecords from '../../components/NoRecords';
import { useFetch } from '../../hooks/useFetch';
import { useOpenRow } from '../../hooks/useOpenRow';
import { DATE_FRIENDLY } from '../../util/dateUtils';

type CustomerInvoiceProps = {
    clientUuid: string | undefined;
};

const CustomerInvoices = ({ clientUuid }: CustomerInvoiceProps) => {
    const { rowClick, setUrl } = useOpenRow();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);

    const state = useFetch(
        () => listInvoices({ page: page - 1, pageSize: rowsPerPage, clientUuid }),
        [page, rowsPerPage, clientUuid]
    );

    const SuccessComponent = useCallback(
        ({ data }: { data: InvoicePage }) => {
            return (
                <DataTable
                    data={data.records}
                    columns={columns}
                    onRowClicked={(row: InvoiceSearchResult, e: React.MouseEvent) => {
                        rowClick('/invoices/' + row.identifier, e);
                    }}
                    onRowMouseEnter={(row: InvoiceSearchResult) => {
                        setUrl('/invoices/' + row.identifier);
                    }}
                    pointerOnHover={true}
                    highlightOnHover={true}
                    pagination
                    paginationServer
                    onChangePage={setPage}
                    onChangeRowsPerPage={setRowsPerPage}
                    paginationPerPage={rowsPerPage}
                    paginationDefaultPage={page}
                    paginationTotalRows={data.totalRecords}
                    customStyles={customStyles}
                    noDataComponent={<NoRecords />}
                />
            );
        },
        [page, rowsPerPage, rowClick, setUrl]
    );

    return <FetchWrapper state={state} SuccessComponent={SuccessComponent} />;
};

const IssuedDate = (row: InvoiceSearchResult) => <Moment format={DATE_FRIENDLY}>{row.issuedDate}</Moment>;

const columns: TableColumn<InvoiceSearchResult>[] = [
    {
        name: 'Invoice #',
        selector: (row) => row.invoiceNumber,
    },
    {
        name: 'Date issued',
        cell: IssuedDate,
    },
    {
        name: 'Status',
        cell: (row) => <InvoiceStatusChip status={row.status} paymentFrequency={row.paymentFrequency} />,
    },
];

const customStyles = {
    headCells: {
        style: {
            fontSize: '14px',
            fontWeight: 600,
            color: '#666',
        },
        cells: {
            style: {
                fontSize: '14px',
            },
        },
    },
};

export default CustomerInvoices;
