import { createSlice } from '@reduxjs/toolkit';

export type BreakpointAction = {
    type: string;
    payload: BREAKPOINT;
};

export enum BREAKPOINT {
    xs,
    sm,
    md,
    lg,
    xl,
}

export const breakpointSlice = createSlice({
    name: 'breakpoint',
    initialState: {
        value: BREAKPOINT.xs,
        up: (_from: BREAKPOINT): boolean => true,
        down: (_from: BREAKPOINT): boolean => false,
    },
    reducers: {
        setBreakpoint: (state, action: BreakpointAction) => {
            state.value = action.payload;
            state.up = (from: BREAKPOINT) => {
                return from <= action.payload;
            };
            state.down = (from: BREAKPOINT) => {
                return from > action.payload;
            };
        },
    },
});

export const { setBreakpoint } = breakpointSlice.actions;

export default breakpointSlice.reducer;
