import axios from 'axios';
import moment from 'moment/moment';
import { UTC_DATE_TIME_FORMAT } from '../util/dateUtils';
import { InsuredType } from './clients';

export type Report = {
    name: string;
    data: [ReportData, ReportData];
    totals: ReportTotal[];
};

export type ReportTotal = {
    title: string;
    value: string;
};

export type ReportData = {
    name: string;
    title: string;
    columns: string[];
    values: ReportValue[];
};

export type ReportValue = {
    volume: number;
    value: string;
    date: Date;
    insuredType: InsuredType;
    count: string;
    reference: string;
    status: string;
};

export const getInvoiceReport = async (startDate: string, endDate: string): Promise<Report> => {
    return await getReport('invoice', startDate, endDate);
};

export const getPaymentsReceivedReport = async (startDate: string, endDate: string): Promise<Report> => {
    return await getReport('payments-received', startDate, endDate);
};

export const getInsuredReport = async (startDate: string, endDate: string): Promise<Report> => {
    return await getReport('insured', startDate, endDate);
};

export const getSettlementReport = async (startDate: string, endDate: string): Promise<Report> => {
    return await getReport('settlement', startDate, endDate);
};

const getReport = async (reportUrl: string, startDate: string, endDate: string): Promise<Report> => {
    const startDateTime = encodeURIComponent(moment(startDate).utc(false).format(UTC_DATE_TIME_FORMAT));
    const endDateTime = encodeURIComponent(moment(endDate).utc(false).format(UTC_DATE_TIME_FORMAT));
    return await axios
        .get(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/report/${reportUrl}?startDate=${startDateTime}&endDate=${endDateTime}`,
            {
                headers: {
                    'x-client-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            }
        )
        .then(({ data }) => data);
};
