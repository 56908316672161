import { useTheme } from '@mui/material';

export default () => {
    const theme = useTheme();

    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            data-testid='classcode-laptop'
        >
            <g clipPath='url(#clip0_12_175)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.6707 12.0545C10.6707 11.3225 11.3977 10.8 12.192 10.8H35.6931C36.4874 10.8 37.2144 11.3224 37.2144 12.0545V25.5367C37.2144 26.2688 36.4874 26.7912 35.6931 26.7912H12.192C11.3977 26.7912 10.6707 26.2688 10.6707 25.5367V12.0545ZM12.0233 11.9771C11.9849 12.0077 11.976 12.037 11.976 12.0545V25.5367C11.976 25.5552 11.9852 25.5843 12.0231 25.6145C12.0611 25.6448 12.1205 25.6684 12.192 25.6684H35.6931C35.7641 25.6684 35.8236 25.6447 35.8618 25.6141C35.9002 25.5835 35.9091 25.5543 35.9091 25.5367V12.0545C35.9091 12.036 35.9 12.0069 35.8621 11.9767C35.824 11.9464 35.7646 11.9228 35.6931 11.9228H12.192C12.121 11.9228 12.0616 11.9465 12.0233 11.9771ZM37.263 27.3088L41 33.0059C40.7872 33.5974 40.5425 34.1198 39.8791 34.2677H8.09518C7.40138 34.077 6.94114 33.5656 7.0061 32.8193L10.6203 27.309L37.263 27.3088ZM9.08654 31.294H38.7953L39.9855 33.1081C39.9289 33.209 39.8598 33.2961 39.763 33.361H8.22109C8.105 33.285 7.9972 33.1952 7.91289 33.0832L9.08654 31.294Z'
                    fill={theme.palette.primary.main}
                />
            </g>
            <defs>
                <clipPath id='clip0_12_175'>
                    <rect width='48' height='48' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
