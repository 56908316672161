import { Alert, Box, Button, Grid, Typography } from '@mui/material';

import moment from 'moment';
import { useState } from 'react';
import { InvoiceDetailsResponse, Loan, LoanStatus } from '../../../../apis/invoice';
import { ExternalTransactionType, recordExternalTransaction } from '../../../../apis/variations';
import { LoadingButton } from '../../../../components/LoadingButton';
import StepCard from '../../../../components/StepCard';
import { DATE_COMPACT } from '../../../../util/dateUtils';
import { RecordExternalTransactionRequestFields } from '../types';
import { getInvoicePolicyDescription } from '../utils';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    setLoan: (loan: Loan) => void;
    externalTransactionFields: RecordExternalTransactionRequestFields;
    loan: Loan;
    details: InvoiceDetailsResponse;
    overdueAmount: number;
};

export default function ExternalTransactionReviewStep({
    cancel,
    handleBack,
    setLoan,
    externalTransactionFields,
    details,
    loan,
    overdueAmount,
}: Readonly<Props>) {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [loading, setLoading] = useState(false);
    const isLoanClosed = loan.status === LoanStatus.CLOSED;

    const remainingAmount =
        overdueAmount +
        (externalTransactionFields.externalTransactionType === ExternalTransactionType.PAYEMENT
            ? externalTransactionFields.amount
            : 0);
    const overdueRemaining = remainingAmount < 0 ? 0 : remainingAmount;
    const outstandingSettlementAmount = remainingAmount - overdueAmount + (loan.outstandingSettlementAmount ?? 0);
    const remainBalance = loan.balance + externalTransactionFields.amount;

    const accountSummaryMessage = isLoanClosed
        ? `After this payment, the client will have ${currencyFormat.format(remainBalance)} balance remaining.`
        : `After this payment, the client will have ${currencyFormat.format(overdueRemaining)} overdue remaining and ${currencyFormat.format(outstandingSettlementAmount)} outstanding on their invoice.`;

    const onConfirmation = () => {
        setLoading(true);
        recordExternalTransaction(details.invoice.uuid, externalTransactionFields)
            .then(setLoan)
            .catch(setErrorMsg)
            .finally(() => setLoading(false));
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Review payment details
            </Typography>
            <Typography>{`For ${details.client.displayName}'s insurance ${details.invoice.number} #(${getInvoicePolicyDescription(details.invoice)})`}</Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Payment Type:
                    </Typography>
                    <Typography variant='caption' sx={{ ml: 1 }}>
                        {externalTransactionFields.externalTransactionType == ExternalTransactionType.PAYEMENT
                            ? 'Payment'
                            : 'Refund'}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Transaction date:
                    </Typography>
                    <Typography variant='caption' sx={{ ml: 1 }}>
                        {moment(externalTransactionFields.transactionDate).format(DATE_COMPACT)}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Amount:
                    </Typography>
                    <Typography variant='caption' sx={{ ml: 1 }}>
                        {currencyFormat.format(Math.abs(externalTransactionFields.amount))}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Note:
                    </Typography>
                    <Typography variant='caption' sx={{ ml: 1 }}>
                        {externalTransactionFields.note}
                    </Typography>
                </Box>
            </Box>

            <Typography variant='caption'>{accountSummaryMessage}</Typography>

            {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

            <Grid container>
                <Grid item xs={6}>
                    <Button onClick={handleBack} variant='outlined'>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        onClick={onConfirmation}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        Confirm
                    </LoadingButton>
                </Grid>
            </Grid>
        </StepCard>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
