import { EndorsementPolicy, EndorsementPolicyStatus } from '../../../apis/endorsement';
import { Invoice } from '../../../apis/invoice';

export type OverridePolicyEndorsement = {
    endorsementNumber: string;
    overrideEndorsementPolicy: EndorsementPolicy;
};

export const detectNotProcessedEndorsementPolicy = (
    policyNumber: string,
    invoice: Invoice
): OverridePolicyEndorsement | undefined => {
    return invoice.endorsements
        ?.map(({ number, policyEndorsements }) => ({
            endorsementNumber: number,
            overrideEndorsementPolicy: policyEndorsements.find(
                (p) => p.policyNumber === policyNumber && acceptedButNotProcessed.includes(p.status)
            ),
        }))
        .filter(({ overrideEndorsementPolicy }) => overrideEndorsementPolicy != null)
        .shift() as OverridePolicyEndorsement; // find first or return undefined
};

const acceptedButNotProcessed = [EndorsementPolicyStatus.ACCEPTED];
