import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Client, InsuredType, isBusiness, isIndividual, isTrust } from '../../apis/clients';
import CreateBusinessClient from './CreateBusinessClient';
import CreateIndividualClient from './CreateIndividualClient';
import CreateTrustClient from './CreateTrustClient';

interface CreateClientProps {
    callback?: (client: Client) => void;
    client?: Client;
}

const CreateClient = ({ client, callback }: CreateClientProps) => {
    const [clientType, setClientType] = useState<InsuredType>(InsuredType.INDIVIDUAL);

    const handleType = (_event: React.MouseEvent<HTMLElement>, newType: InsuredType | null) => {
        if (newType != null) {
            setClientType(newType);
        }
    };

    const getCreateElement = (clientType: InsuredType): React.ReactElement => {
        switch (clientType) {
            case InsuredType.INDIVIDUAL:
                return <CreateIndividualClient callback={callback} />;
            case InsuredType.BUSINESS:
                return <CreateBusinessClient callback={callback} />;
            case InsuredType.TRUST:
                return <CreateTrustClient callback={callback} />;
        }
    };

    const getUpdateElement = (client: Client): React.ReactElement | null => {
        if (isIndividual(client)) {
            return <CreateIndividualClient client={client} callback={callback} />;
        }
        if (isBusiness(client)) {
            return <CreateBusinessClient client={client} callback={callback} />;
        }
        if (isTrust(client)) {
            return <CreateTrustClient client={client} callback={callback} />;
        }
        return null;
    };

    if (client == null) {
        return (
            <>
                <Box p={2}>
                    <Typography variant={'h6'} sx={{ mb: '15px' }}>
                        Create new:
                    </Typography>
                    <ToggleButtonGroup
                        value={clientType}
                        exclusive
                        onChange={handleType}
                        aria-label='client type'
                        color='primary'
                        size='small'
                    >
                        <ToggleButton value={InsuredType.INDIVIDUAL} aria-label={InsuredType.INDIVIDUAL}>
                            Individual
                        </ToggleButton>
                        <ToggleButton value={InsuredType.BUSINESS} aria-label={InsuredType.BUSINESS}>
                            Business
                        </ToggleButton>
                        <ToggleButton value={InsuredType.TRUST} aria-label={InsuredType.TRUST}>
                            Trust
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box p={2}>{getCreateElement(clientType)}</Box>
            </>
        );
    }

    return (
        <>
            <Typography variant={'h6'} sx={{ pt: 2, pl: 2 }}>
                Update Client
            </Typography>
            <Box p={2}>{getUpdateElement(client)}</Box>
        </>
    );
};

export default CreateClient;
