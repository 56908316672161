import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { cloneDeep, truncate } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { EndorsementRequest, postEndorsement } from '../../../apis/endorsement';
import { Invoice, InvoiceDetailsResponse } from '../../../apis/invoice';
import { LoadingButton } from '../../../components/LoadingButton';
import StepCard from '../../../components/StepCard';
import { DATE_COMPACT, DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';
import { EndorsementFields } from './types';
import { detectNotProcessedEndorsementPolicy } from './utils';

type Props = {
    details: InvoiceDetailsResponse;
    endorsement: EndorsementFields;
    setUpdatedInvoice: (invoice: Invoice) => void;
    handleBack: () => void;
    cancel: () => void;
};

const confirmError = 'You must confirm the policy holder is aware of this endorsement.';

export default function ReviewStep({ endorsement, setUpdatedInvoice, details, handleBack, cancel }: Readonly<Props>) {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [confirmed, setConfirmed] = useState(false);
    const currentPremiumTotal = endorsement.policyEndorsements.reduce(
        (accumulator, endorsement) => accumulator + endorsement.premiums,
        0
    );
    const newPremiumTotalWithoutInheritedAdjustments =
        currentPremiumTotal +
        endorsement.policyEndorsements.reduce(
            (accumulator, endorsement) => accumulator + (endorsement.premiumsAdjustment ?? 0),
            0
        );
    let premiumAdjustmentTotalOfInherited = 0;

    const handleConfirm = () => {
        if (!confirmed) {
            setErrorMsg(confirmError);
            return;
        }

        const request: EndorsementRequest = cloneDeep({
            ...endorsement,
            policyEndorsements: endorsement.policyEndorsements.filter((e) => e.premiumsAdjustment),
        }) as EndorsementRequest;

        setLoading(true);
        postEndorsement(details.invoice.uuid, request)
            .then(setUpdatedInvoice)
            .catch(() => {
                setErrorMsg('Something went wrong, please try again.');
                setLoading(false);
            });
    };

    const handleCheckConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMsg(event.target.checked ? undefined : confirmError);
        setConfirmed(event.target.checked);
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Review
            </Typography>
            <Typography variant='caption'>
                Check the endorsement details below are correct and confirm if the policyholder has agreed to the
                endorsement.
            </Typography>

            <Box>
                <Grid
                    container
                    sx={{
                        border: grey[400],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: theme.shape.borderRadius + 'px',
                    }}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        sx={{
                            borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                            backgroundColor: grey[200],
                        }}
                        p={2}
                    >
                        <Grid item xs={3}>
                            <Typography variant='subtitle2'>Policy</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle2'>Current premium</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle2'>Adjustment</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle2'>New premium</Typography>
                        </Grid>
                    </Grid>
                    {endorsement.policyEndorsements.map((policy) => {
                        const alignCentreSx = { display: 'flex', flexDirection: 'column', justifyContent: 'center' };
                        const premiumAdjusted = !!policy.premiumsAdjustment;
                        const notProcessedEndorsementPolicy = detectNotProcessedEndorsementPolicy(
                            policy.policyNumber,
                            details.invoice
                        );

                        const premiumAdjustment = premiumAdjusted
                            ? policy.premiumsAdjustment
                            : notProcessedEndorsementPolicy?.overrideEndorsementPolicy.premiumsAdjustment;
                        const effectiveDate = premiumAdjusted
                            ? policy.effectiveDate
                            : notProcessedEndorsementPolicy?.overrideEndorsementPolicy.effectiveDate;

                        if (!premiumAdjusted) {
                            premiumAdjustmentTotalOfInherited += premiumAdjustment ?? 0;
                        }

                        const isNotProcessedEndorsementPolicyOverridden =
                            premiumAdjusted && notProcessedEndorsementPolicy;
                        const isNotProcessedEndorsementPolicyNotOverridden =
                            !premiumAdjusted && notProcessedEndorsementPolicy;

                        return (
                            <Grid
                                container
                                item
                                xs={12}
                                key={policy.uuid}
                                p={2}
                                sx={{
                                    border: grey[400],
                                    borderWidth: '0',
                                    borderTopWidth: '1px',
                                    borderStyle: 'solid',
                                }}
                            >
                                {isNotProcessedEndorsementPolicyOverridden && (
                                    <Alert
                                        severity='warning'
                                        sx={{ mb: 1, minWidth: '100%' }}
                                    >{`Overrides pending premium adjustment from endorsement '${truncate(notProcessedEndorsementPolicy.endorsementNumber, { length: 18 })}'`}</Alert>
                                )}
                                {isNotProcessedEndorsementPolicyNotOverridden && (
                                    <Alert
                                        severity='info'
                                        sx={{ mb: 1, minWidth: '100%' }}
                                    >{`An existing endorsement '${truncate(notProcessedEndorsementPolicy.endorsementNumber, { length: 18 })}' is pending on this policy`}</Alert>
                                )}

                                <Grid item xs={3} sx={alignCentreSx}>
                                    <Typography variant='subtitle2'>#{policy.policyNumber}</Typography>
                                    <Typography variant='subtitle1'>
                                        {moment(policy.startDate).format(DATE_FRIENDLY)} -{' '}
                                        {moment(policy.endDate).format(DATE_FRIENDLY)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sx={alignCentreSx}>
                                    <Typography variant='caption'>{currencyFormat.format(policy.premiums)}</Typography>
                                </Grid>
                                <Grid item xs={3} sx={alignCentreSx}>
                                    {premiumAdjustment ? (
                                        <Typography variant={premiumAdjusted ? 'caption' : 'subtitle1'}>
                                            {currencyFormat.format(premiumAdjustment)}
                                        </Typography>
                                    ) : (
                                        <Typography variant='caption'>-</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={3} sx={alignCentreSx}>
                                    {premiumAdjustment ? (
                                        <>
                                            <Typography variant={premiumAdjusted ? 'caption' : 'subtitle1'}>
                                                {currencyFormat.format(policy.premiums + premiumAdjustment)}
                                            </Typography>
                                            <Typography variant={premiumAdjusted ? 'caption' : 'subtitle1'}>
                                                from {moment(effectiveDate, DATE_SERVER_FORMAT).format(DATE_COMPACT)}
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography variant='caption'>-</Typography>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Box>
                    <Typography variant='caption'>Current total premium</Typography>
                    <Typography variant='h6' component='p'>
                        {currencyFormat.format(currentPremiumTotal)}
                    </Typography>
                </Box>
                <Divider orientation='vertical' variant='middle' flexItem />
                <Box>
                    <Typography variant='caption'>New total premium</Typography>
                    <Typography variant='h6' component='p'>
                        {currencyFormat.format(
                            newPremiumTotalWithoutInheritedAdjustments + premiumAdjustmentTotalOfInherited
                        )}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Reference:
                    </Typography>
                    <Typography variant='caption'> #{endorsement.number}</Typography>
                </Box>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Description:
                    </Typography>
                    <Typography variant='caption'> {endorsement.reason}</Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: grey[100], p: 2 }}>
                <FormControlLabel
                    control={<Checkbox id='confirm-endorsement' onChange={handleCheckConfirmation} size='small' />}
                    label='I confirm that the policyholder is aware of the endorsement adjustment and they have agreed to the new premium amount.'
                />
            </Box>

            <Box>
                <Typography>
                    {details.client.displayName} will receive an email notification with the endorsement details once
                    the endorsement is confirmed.
                </Typography>
            </Box>

            {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

            <Box sx={{ display: 'flex', gap: 1 }}></Box>

            <Grid container>
                <Grid item xs={6}>
                    <Button variant='outlined' onClick={handleBack}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' color='primary' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        onClick={handleConfirm}
                        variant='contained'
                        color='primary'
                        type='submit'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        Confirm
                    </LoadingButton>
                </Grid>
            </Grid>
        </StepCard>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
