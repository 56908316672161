import { User } from '../../apis/users';
import { Avatar, Box, Chip, Paper, Typography } from '@mui/material';
import { upperCase } from 'lodash';
import { Mail, Verified } from '@mui/icons-material';
import moment from 'moment';

interface UserInfoSectionProps {
    user: User;
}

const initials = (name: string): string[] => {
    const parts = name.split(' ');
    parts.length = Math.min(parts.length, 2);
    return parts.map((p) => upperCase(p.charAt(0)));
};

const UserInfoSection = ({ user }: UserInfoSectionProps) => {
    return (
        <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ textAlign: 'center', mr: 2, mt: '5px' }}>
                    <Avatar sx={{ bgcolor: '#068ed0', width: '60px', height: '60px' }}>{initials(user.name)}</Avatar>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Typography variant='h2'>{user.name}</Typography>
                    </Box>
                    <Box>
                        <Chip
                            variant='customerLink'
                            label={user.email}
                            icon={<Mail style={{ color: '#0B51FF', fontSize: '14px' }} />}
                            sx={{ mr: 2 }}
                        />
                    </Box>
                    <Box>
                        <Chip
                            variant='customerLink'
                            label={`Added since ${moment.utc(user.createdDate).local().format('DD MMM YYYY hh:mm a')}`}
                            icon={<Verified style={{ color: '#068ed0', fontSize: '14px' }} />}
                            sx={{ mr: 2 }}
                        />
                    </Box>
                </Box>
            </Box>
        </Paper>
    );
};

export default UserInfoSection;
