import {
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import List from './List';
import { CancellationReason, LoanCancellationRequestStatus } from '../../apis/invoice';
import { upperFirst } from 'lodash';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DATE_COMPACT, DATE_SERVER_FORMAT } from '../../util/dateUtils';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CalendarToday } from '@mui/icons-material';

export default function Cancellations() {
    const [cancellationRequestStatuses, setCancellationRequestStatuses] = useState<LoanCancellationRequestStatus[]>([
        LoanCancellationRequestStatus.UNDERPAID,
        LoanCancellationRequestStatus.OVERPAID,
    ]);
    const [cancellationRequestReasons, setCancellationRequestReasons] = useState<CancellationReason[]>([]);
    const [effectiveStartDate, setEffectiveStartDate] = useState<string>('');
    const [effectiveEndDate, setEffectiveEndDate] = useState<string>('');

    const handleCancellationStatusesChange = (event: SelectChangeEvent<typeof cancellationRequestStatuses>) => {
        const {
            target: { value },
        } = event;
        setCancellationRequestStatuses(
            (typeof value === 'string' ? value.split(',') : value) as LoanCancellationRequestStatus[]
        );
    };

    const handleCancellationReasonsChange = (event: SelectChangeEvent<typeof cancellationRequestReasons>) => {
        const {
            target: { value },
        } = event;
        setCancellationRequestReasons((typeof value === 'string' ? value.split(',') : value) as CancellationReason[]);
    };

    const handleEffectiveStartDateChange = (startDateInput: Moment | null) => {
        setEffectiveStartDate(startDateInput!.format(DATE_SERVER_FORMAT));
    };

    const handleEffectiveEndDateChange = (endDateInput: Moment | null) => {
        setEffectiveEndDate(endDateInput!.format(DATE_SERVER_FORMAT));
    };

    return (
        <Box>
            <Typography variant='h3' component='h1' mb={1}>
                Cancellations
            </Typography>
            <Typography mb={4}>View and manage cancelled policies</Typography>
            <Paper variant={'flat'} sx={{ py: 3, px: 2 }}>
                <Box sx={{ display: 'flex', gap: 3, pb: 4 }}>
                    <FormControl size='small' sx={{ minWidth: 280 }}>
                        <FormLabel htmlFor='cancellation-status-select' sx={{ mb: 1 }}>
                            Cancellation Status
                        </FormLabel>
                        <Select
                            multiple
                            value={cancellationRequestStatuses}
                            onChange={handleCancellationStatusesChange}
                            input={<OutlinedInput id='cancellation-status-select' />}
                            renderValue={(selected: LoanCancellationRequestStatus[]) =>
                                renderValues(
                                    selected,
                                    Object.values(LoanCancellationRequestStatus),
                                    getFriendlyCancellationStatus
                                )
                            }
                        >
                            {Object.values(LoanCancellationRequestStatus).map((type) => (
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={cancellationRequestStatuses.indexOf(type) > -1} />
                                    <ListItemText primary={getFriendlyCancellationStatus(type)} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl size='small' sx={{ minWidth: 280 }}>
                        <FormLabel htmlFor='cancellation-reason-select' sx={{ mb: 1 }}>
                            Cancellation reason
                        </FormLabel>
                        <Select
                            multiple
                            value={cancellationRequestReasons}
                            onChange={handleCancellationReasonsChange}
                            input={<OutlinedInput id='cancellation-reason-select' />}
                            renderValue={(selected: CancellationReason[]) =>
                                renderValues(selected, Object.values(CancellationReason), getFriendlyCancellationReason)
                            }
                        >
                            {Object.values(CancellationReason).map((type) => (
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={cancellationRequestReasons.indexOf(type) > -1} />
                                    <ListItemText primary={getFriendlyCancellationReason(type)} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <FormControl>
                            <FormLabel htmlFor='effective-date' sx={{ mb: 1 }}>
                                Effective date
                            </FormLabel>
                            <Box sx={{ display: 'flex' }}>
                                <DatePicker
                                    label='Start date'
                                    onChange={handleEffectiveStartDateChange}
                                    sx={{ mr: 1, width: '175px' }}
                                    slotProps={{
                                        textField: {
                                            id: 'startDate',
                                            size: 'small',
                                        },
                                    }}
                                    slots={{
                                        openPickerIcon: CalendarToday,
                                    }}
                                    format={DATE_COMPACT}
                                    maxDate={moment(effectiveEndDate)}
                                />

                                <DatePicker
                                    label='End date'
                                    onChange={handleEffectiveEndDateChange}
                                    sx={{ mr: 1, width: '175px' }}
                                    slotProps={{
                                        textField: {
                                            id: 'endDate',
                                            size: 'small',
                                        },
                                    }}
                                    slots={{
                                        openPickerIcon: CalendarToday,
                                    }}
                                    format={DATE_COMPACT}
                                    minDate={moment(effectiveStartDate)}
                                />
                            </Box>
                        </FormControl>
                    </LocalizationProvider>
                </Box>
                <List
                    cancellationStatuses={cancellationRequestStatuses}
                    cancellationReasons={cancellationRequestReasons}
                    effectiveStartDate={effectiveStartDate}
                    effectiveEndDate={effectiveEndDate}
                />
            </Paper>
        </Box>
    );
}

type Enum = CancellationReason | LoanCancellationRequestStatus;
const renderValues = <T extends Enum>(selected: T[], values: T[], transform: (t: T) => string) => {
    if (selected.length === values.length || selected.length === 0) {
        return 'All';
    }

    return selected.map(transform).join(', ');
};

const getFriendlyCancellationStatus = (cancellationStatus: LoanCancellationRequestStatus): string => {
    return upperFirst(cancellationStatus.toLowerCase().replace('_', ' '));
};

const getFriendlyCancellationReason = (cancellationReason: CancellationReason): string => {
    return upperFirst(cancellationReason.toLowerCase().replace('_', ' '));
};
