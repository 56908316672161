import { useCallback } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { getSettlementReport, Report } from '../../apis/reports';
import { FetchWrapper } from '../../components/FetchWrapper';
import Totals from './Totals';
import { Grid } from '@mui/material';
import SettlementReportDisplay from './SettlementReportDisplay';
import SettlementStatusReportDisplay from './SettlementStatusReportDisplay';

interface SettlementReportProps {
    startDate: string;
    endDate: string;
}

export default (props: SettlementReportProps) => {
    const { startDate, endDate } = props;
    const state = useFetch(async () => getSettlementReport(startDate, endDate), [startDate, endDate]);

    const Chart = useCallback(({ data }: { data: Report }) => {
        return (
            <>
                {/* This value is not currently accurate from the back end, and needs to be updated per https://simfuni.atlassian.net/browse/SIM-712 */}
                <Totals totals={data.totals.filter((total) => total.title !== 'Next settlement')} />

                <Grid container spacing={2}>
                    <Grid item lg={6} md={12}>
                        <SettlementReportDisplay reportData={data.data[0]} />
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <SettlementStatusReportDisplay reportData={data.data[1]} />
                    </Grid>
                </Grid>
            </>
        );
    }, []);

    return <FetchWrapper state={state} SuccessComponent={Chart} />;
};
