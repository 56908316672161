import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchInvoice, InvoiceDetailsResponse } from '../../apis/invoice';
import { ExtendableFetchWrapper } from '../../components/ExtendableFetchWrapper';
import { useFetch } from '../../hooks/useFetch';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import { setInvoiceDetailsState } from '../../store/reducer/InvoiceDetailsReducer';
import LoadedInvoicePage, { LoadedInvoicePageProps } from './LoadedInvoicePage';

export default function InvoiceDetails() {
    const [approvalRequestSentCount, setApprovalRequestSentCount] = useState<number>(0);

    const { uuid } = useParams<{ uuid?: string }>();
    const dispatch = useAppDispatch();
    const { state: invoiceState } = useAppSelector((root) => root.InvoiceDetailsReducer);

    const state = useFetch(() => fetchInvoice(uuid as string), [uuid, approvalRequestSentCount], {
        canFetch: () => !!uuid,
    });

    useEffect(() => {
        dispatch(setInvoiceDetailsState(state));
    }, [state]);

    return (
        <ExtendableFetchWrapper<InvoiceDetailsResponse, LoadedInvoicePageProps>
            state={invoiceState}
            SuccessComponent={LoadedInvoicePage}
            setApprovalRequestSentCount={setApprovalRequestSentCount}
            approvalRequestSentCount={approvalRequestSentCount}
        />
    );
}
