import axios from 'axios';
import { Address } from '../types/Types';
import { BaseClient, InsuredType } from './clients';
import { ListParams, assignDefaultListParams } from './common';
import { Invoice, Policy } from './invoice';
import { CardPaymentProcessor } from './variations';

export type ClientLeadSummaryDisabled = {
    enabled: false;
};

export type ClientLeadSummaryEnabled = {
    enabled: true;
    unmatchedInvoiceSummary: {
        totalUnmatchedAmount: number;
        totalUnmatched: number;
    };
};

export type ClientLeadSummary = ClientLeadSummaryDisabled | ClientLeadSummaryEnabled;

export const getClientLeadSummary = async (): Promise<ClientLeadSummary> => {
    return await axios.get(process.env.REACT_APP_BROKER_PORTAL_HOST + '/client-lead/summary').then(({ data }) => data);
};

export type ClientLead = {
    uuid: string;
    insuredType: InsuredType;
    reference: string;
    emailAddress: string;
    name?: string;
    mobileNumber?: string;
    address?: Address;
};

export enum PaymentMethodType {
    DIRECT_DEBIT = 'DIRECT_DEBIT',
    CREDIT_CARD = 'CREDIT_CARD',
    DIRECT_CREDIT = 'DIRECT_CREDIT',
}

export enum PaymentMethodStatus {
    CREATED = 'CREATED',
    VOID = 'VOID',
    VERIFIED = 'VERIFIED',
}

type BasePaymentMethod = {
    uuid: string;
    type: PaymentMethodType;
    accountIdentifier: string;
    isActive: boolean;
    status: PaymentMethodStatus;
};

export type CardPaymentMethod = BasePaymentMethod & {
    cardType: number;
    maskedCardNumber: string;
    cardPaymentProcessor: CardPaymentProcessor;
    expiryMonth: string;
    expiryYear: string;
};

export type BankAccountPaymentMethod = BasePaymentMethod & {
    accountHolder: string;
    bankAccountNumber: string;
    bankName: string;
};

export type BankTransferPaymentMethod = BasePaymentMethod & {
    accountHolder: string;
    bankAccountNumber: string;
    bankName: string;
    particulars?: string;
    code?: string;
    reference?: string;
};

export type PaymentMethod = CardPaymentMethod | BankAccountPaymentMethod | BankTransferPaymentMethod;

export type ClientLeadPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: ClientLeadResult[];
};

export type ClientLeadResult = ClientLeadBaseRes & {
    suggestedInsured?: BaseClient;
    client?: BaseClient;
};

type ClientLeadBaseRes = {
    invoice: Invoice;
    clientLead: ClientLead;
    paymentMethod: PaymentMethod;
    paymentDate: string;
    reasonsToDenyRecurringPayments: string[];
};

type ClientLeadResultFromServer = ClientLeadBaseRes & {
    suggestedInsureds: BaseClient[];
};

type ListClientLeadInvoiceParams = ListParams & {
    searchValue?: string;
    paidStartDate?: string;
    paidEndDate?: string;
};

export const getClientLeads = async (partial?: Partial<ListClientLeadInvoiceParams>): Promise<ClientLeadPage> => {
    const { pageSize, page, searchValue, paidStartDate, paidEndDate } = assignDefaultListParams(partial);

    const url = new URL(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/client-lead`);
    url.searchParams.append('pageSize', pageSize.toString());
    url.searchParams.append('page', page.toString());
    if (searchValue) {
        url.searchParams.append('search', searchValue);
    }
    if (paidStartDate) {
        url.searchParams.append('paidStartDate', paidStartDate);
    }
    if (paidEndDate) {
        url.searchParams.append('paidEndDate', paidEndDate);
    }

    return await axios.get(url.href).then(({ data }) => {
        return {
            ...data,
            records: data.records.map(({ suggestedInsureds, ...rest }: ClientLeadResultFromServer) => {
                return {
                    ...rest,
                    suggestedInsured: suggestedInsureds?.length ? suggestedInsureds[0] : undefined,
                };
            }),
        };
    });
};

type InvoiceUpdateRequest = {
    number: string;
    portfolio: {
        policies: Policy[];
    };
};

type ClientLeadMatch = {
    invoice: InvoiceUpdateRequest;
    insuredIdentifier: string;
};

export const matchClientLead = async (
    clientLeadIdentifier: string,
    matchRequest: ClientLeadMatch
): Promise<Invoice> => {
    return await axios
        .patch(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/client-lead/${clientLeadIdentifier}/match`, matchRequest)
        .then(({ data }) => data);
};

export const declineClientLead = async (
    clientLeadIdentifier: string,
    matchRequest: ClientLeadMatch
): Promise<Invoice> => {
    return await axios
        .patch(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/client-lead/${clientLeadIdentifier}/decline`, matchRequest)
        .then(({ data }) => data);
};

export const voidClientLead = async (clientLeadIdentifier: string, note: string): Promise<Invoice> => {
    return await axios
        .delete(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/client-lead/${clientLeadIdentifier}`, {
            data: { note },
        })
        .then(({ data }) => data);
};

// type guards

export const isBankAccount = (toBeDetermined?: PaymentMethod): toBeDetermined is BankAccountPaymentMethod => {
    return toBeDetermined?.type === PaymentMethodType.DIRECT_DEBIT;
};

export const isBankTransfer = (toBeDetermined?: PaymentMethod): toBeDetermined is BankTransferPaymentMethod => {
    return toBeDetermined?.type === PaymentMethodType.DIRECT_CREDIT;
};

export const isCard = (toBeDetermined?: PaymentMethod): toBeDetermined is CardPaymentMethod => {
    return toBeDetermined?.type === PaymentMethodType.CREDIT_CARD;
};
