import axios from 'axios';

export type ClassCode = {
    active: boolean;
    cancellable: boolean;
    code: string;
    description: string;
    type: string;
    uuid: string;
};

export const fetchClassCodes = async (): Promise<ClassCode[]> => {
    return await axios.get(process.env.REACT_APP_BROKER_PORTAL_HOST + '/class-codes').then(({ data }) => data);
};

export const fetchProviders = async (): Promise<string[]> => {
    return await axios.get(process.env.REACT_APP_BROKER_PORTAL_HOST + '/providers').then(({ data }) => data);
};
