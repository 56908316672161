import axios from 'axios';
import { ContactDetails, PersonalDetails } from '../types/Types';
import { ListParams, assignDefaultListParams } from './common';

export type ClientPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: ClientSearchResult[];
};

export type ClientSearchResult = {
    uuid: string;
    email: string;
    address: string;
    createdDate: Date;
    displayName: string;
    phoneNumber: string;
    clientIdentifier: string;
};

export type BaseClient = {
    uuid: string;
    identifier: string;
    createdDate: string;
    contactDetails: ContactDetails;
    insuredType: InsuredType;
    displayName: string;
    sellerConfirmationDate?: string;
};

export type AuthorizedIndividual = {
    personalDetails: Pick<PersonalDetails, 'givenName' | 'surname'>;
    contactDetails: Pick<ContactDetails, 'email' | 'preferredPhoneNumber'>;
};

export enum InsuredType {
    INDIVIDUAL = 'INDIVIDUAL',
    BUSINESS = 'BUSINESS',
    TRUST = 'TRUST',
}

export type IndividualClient = BaseClient & {
    personalDetails: PersonalDetails;
    insuredType: InsuredType.INDIVIDUAL;
};

export enum BusinessEntityType {
    SOLE_TRADER = 'SOLE_TRADER',
    LIMITED_LIABILITY = 'LIMITED_LIABILITY',
    REGISTERED_COMPANY = 'REGISTERED_COMPANY',
}

export type BusinessClient = BaseClient & {
    companiesOfficeNumber: string;
    businessNumber: string;
    entityType: BusinessEntityType;
    businessName: string;
    tradingName?: string;
    authorizedIndividual: AuthorizedIndividual;
};

export type TrustClient = BaseClient & {
    businessNumber: string;
    trustName: string;
    authorizedIndividual: AuthorizedIndividual;
};

export type Client = IndividualClient | BusinessClient | TrustClient;

type ListClientsParams = ListParams & { query: string };

export const listClients = async (partial?: Partial<ListClientsParams>): Promise<ClientPage> => {
    const { pageSize, page, query } = assignDefaultListParams(partial);

    const url = new URL(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/insureds`);
    url.searchParams.append('pageSize', pageSize.toString());
    url.searchParams.append('page', page.toString());
    if (query) {
        url.searchParams.append('query', query);
    }

    return await axios.get(url.href).then(({ data }) => data);
};

export const getClient = async (uuid: string): Promise<Client> => {
    return await axios.get(process.env.REACT_APP_BROKER_PORTAL_HOST + '/insureds/' + uuid).then(({ data }) => data);
};

export type CreateInsuredRequest =
    | CreateIndividualFields
    | CreateBusinessFields
    | (CreateTrustFields & {
          insuredType: InsuredType;
      });

export const createInsured = async <T extends Client>(data: CreateInsuredRequest, clientUuid?: string): Promise<T> => {
    let url = process.env.REACT_APP_BROKER_PORTAL_HOST + '/insureds';
    if (clientUuid) {
        url = url + '/' + clientUuid;
    }

    const requestConfig = {
        url: url,
        method: clientUuid ? 'PUT' : 'POST',
        data,
    };

    return await axios.request(requestConfig).then(({ data }) => data);
};

export type AddressFields = {
    suburb: string;
    cityTown: string;
    postcode: string;
    fullAddress: string;
    addressLine1: string;
    addressLine2: string;
};

export type CreateIndividualFields = AddressFields & {
    insuredType?: InsuredType.INDIVIDUAL;
    email: string;
    lastName: string;
    firstName: string;
    dateOfBirth?: string;
    mobileNumber: string;
};

export const createIndividual = async (
    data: CreateIndividualFields,
    clientUuid?: string
): Promise<IndividualClient> => {
    return createInsured({ ...data, insuredType: InsuredType.INDIVIDUAL }, clientUuid);
};

export type CreateBusinessFields = AddressFields & {
    insuredType?: InsuredType.BUSINESS;
    email: string;
    mobileNumber: string;
    companiesOfficeNumber: string;
    businessNumber: string;
    entityType: BusinessEntityType;
    businessName: string;
    tradingName: string;
    authorizedIndividual: {
        email: string;
        firstName: string;
        lastName: string;
        mobileNumber: string;
    };
};

export const createBusiness = async (data: CreateBusinessFields, clientUuid?: string): Promise<BusinessClient> => {
    return createInsured({ ...data, insuredType: InsuredType.BUSINESS }, clientUuid);
};

export type CreateTrustFields = AddressFields & {
    insuredType?: InsuredType.TRUST;
    email: string;
    mobileNumber: string;
    businessNumber: string;
    trustName: string;
    authorizedIndividual: {
        email: string;
        firstName: string;
        lastName: string;
        mobileNumber: string;
    };
};

export const createTrust = async (data: CreateTrustFields, clientUuid?: string): Promise<TrustClient> => {
    return createInsured({ ...data, insuredType: InsuredType.TRUST }, clientUuid);
};

// type guards

export const isIndividual = (toBeDetermined?: Client): toBeDetermined is IndividualClient => {
    return toBeDetermined?.insuredType === InsuredType.INDIVIDUAL;
};

export const isBusiness = (toBeDetermined?: Client): toBeDetermined is BusinessClient => {
    return toBeDetermined?.insuredType === InsuredType.BUSINESS;
};

export const isTrust = (toBeDetermined?: Client): toBeDetermined is TrustClient => {
    return toBeDetermined?.insuredType === InsuredType.TRUST;
};
