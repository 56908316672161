import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CancellationRequest, previewCancellation } from '../../../apis/cancellation';
import { CancellationReason, InvoiceDetailsResponse, Loan } from '../../../apis/invoice';
import PageLoading from '../../../components/PageLoading';
import StepCard from '../../../components/StepCard';
import {
    DATE_COMPACT,
    DATE_FRIENDLY,
    DATE_SERVER_FORMAT,
    asString,
    handleDatePickerChange,
    invalidDate,
} from '../../../util/dateUtils';
import { getInvoicePolicyDescription } from '../Arrangements/utils';
import { calculateDaysDifference, getDaysDiffDesc } from '../common';
import UpcomingPayments from '../Payments/UpcomingPayments';
import { CancellationPreview } from './types';

type Props = {
    details: InvoiceDetailsResponse;
    loan: Loan;
    cancellationPreview?: CancellationPreview;
    setCancellationPreview: (request: CancellationPreview) => void;
    cancel: () => void;
};

export default function InputDetailsStep({
    cancel,
    details,
    loan,
    setCancellationPreview,
    cancellationPreview,
}: Readonly<Props>) {
    const [previewing, setPreviewing] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [cancellationEffectiveDate, setCancellationEffectiveDate] = useState<Moment>();
    const [loanAfterCancellationPreview, setLoanAfterCancellationPreview] = useState<Loan>();
    const minEffectiveDate = moment(loan.loanStartDate, DATE_SERVER_FORMAT);
    const maxEffectiveDate = moment(loan.loanEndDate, DATE_SERVER_FORMAT);

    const previewLoanCancellation = (effectiveDate: moment.Moment) => {
        setPreviewing(true);
        setErrorMsg(undefined);
        setCancellationEffectiveDate(effectiveDate);
        setLoanAfterCancellationPreview(undefined);

        previewCancellation(details.invoice.uuid, effectiveDate.format(DATE_SERVER_FORMAT))
            .then((loan) => setLoanAfterCancellationPreview(loan))
            .catch(() => setErrorMsg('Something went wrong, please try again.'))
            .finally(() => setPreviewing(false));
    };

    useEffect(() => {
        if (!cancellationPreview) {
            previewLoanCancellation(moment());
        } else {
            setCancellationEffectiveDate(moment(cancellationPreview.effectiveDate, DATE_SERVER_FORMAT));
            setLoanAfterCancellationPreview(cancellationPreview.loanAfterCancellationPreview);
        }
    }, [cancellationPreview]);

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm<CancellationRequest>({
        resolver: yupResolver(getSchema(minEffectiveDate, maxEffectiveDate)),
        defaultValues: cancellationPreview ?? {
            effectiveDate: moment().format(DATE_SERVER_FORMAT),
            reason: CancellationReason.CLAIM,
        },
    });

    const onSubmit: SubmitHandler<CancellationRequest> = (data) => {
        setCancellationPreview({
            ...data,
            effectiveDate: cancellationEffectiveDate!.format(DATE_SERVER_FORMAT),
            loanAfterCancellationPreview: loanAfterCancellationPreview!,
        });
    };

    const onEffectiveDateChange = (effectiveDate: Moment | null): void => {
        if (effectiveDate == null) {
            setLoanAfterCancellationPreview(undefined);
            return;
        }

        previewLoanCancellation(effectiveDate);
    };

    const paidUntilDateDays = loan?.paidUntilDate ? calculateDaysDifference(loan.paidUntilDate) : 0;

    const buildCancellationTips = (cancellationClosingBalance: number, effectiveDate: Moment): string[] => {
        const formmatedEffectiveDate = !moment().isBefore(effectiveDate)
            ? 'immediately'
            : `on ${effectiveDate.format(DATE_FRIENDLY)}`;
        const formattedInvoiceState =
            cancellationClosingBalance > 0
                ? `${currencyFormat.format(cancellationClosingBalance)} underpaid`
                : `${currencyFormat.format(-cancellationClosingBalance)} overpaid`;
        const noMorePaymentsMessage =
            cancellationClosingBalance > 0
                ? 'No more payments will be collected'
                : 'No more payments will be collected and the client may be due a refund';

        if (cancellationClosingBalance !== 0) {
            return [
                `The cancellation will be effective ${formmatedEffectiveDate}. The invoice will be ${formattedInvoiceState}. ${noMorePaymentsMessage}.`,
            ];
        }

        return [
            'The projected balance at cancellation date is based on the payment schedule being paid successfully.',
            'The final scheduled payment will be reduced to reflect the number of days prorated until the cancellation date.',
        ];
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <StepCard>
                <Typography variant='h5' component='h2'>
                    Cancel policies
                </Typography>
                <Typography variant='caption'>
                    For {details.client.displayName}'s Insurance #{details.invoice.number} (
                    {getInvoicePolicyDescription(details.invoice)}).
                </Typography>

                <Typography variant='body1'>
                    Policy paid until {moment(loan.paidUntilDate).format(DATE_FRIENDLY)} (
                    {getDaysDiffDesc(paidUntilDateDays)})
                </Typography>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

                <Box>
                    <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item xs={8} pr={2}>
                            <FormControl required sx={{ width: '100%' }}>
                                <FormLabel htmlFor='effectiveDate' required={false}>
                                    Effective date
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Controller
                                        name='effectiveDate'
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                onChange={(newEffectiveDate) => {
                                                    handleDatePickerChange(field);
                                                    onEffectiveDateChange(newEffectiveDate);
                                                    field.onChange(newEffectiveDate);
                                                }}
                                                value={moment(field.value, DATE_SERVER_FORMAT)}
                                                inputRef={field.ref}
                                                slotProps={{
                                                    textField: {
                                                        id: 'effectiveDate',
                                                        fullWidth: true,
                                                        size: 'small',
                                                        variant: 'outlined',
                                                        helperText: errors?.effectiveDate?.message,
                                                        error: !!errors?.effectiveDate,
                                                    },
                                                }}
                                                minDate={minEffectiveDate}
                                                maxDate={maxEffectiveDate}
                                                format={DATE_COMPACT}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl required sx={{ width: '100%' }}>
                                <FormLabel htmlFor='projectedBalance' required={false}>
                                    Projected balance at cancellation
                                </FormLabel>
                                <TextField
                                    id='projectedBalance'
                                    size='small'
                                    disabled
                                    value={
                                        loanAfterCancellationPreview?.cancellationRequest?.cancellationClosingBalance !=
                                        null
                                            ? currencyFormat.format(
                                                  loanAfterCancellationPreview.cancellationRequest
                                                      ?.cancellationClosingBalance
                                              )
                                            : '-'
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                {loanAfterCancellationPreview?.cancellationRequest?.cancellationClosingBalance != null && (
                    <Alert severity='info'>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {buildCancellationTips(
                                loanAfterCancellationPreview.cancellationRequest.cancellationClosingBalance,
                                cancellationEffectiveDate!
                            ).map((line, index) => (
                                <Box key={line} sx={{ mt: index > 0 ? 1 : 0 }}>
                                    {line}
                                </Box>
                            ))}
                        </Box>
                    </Alert>
                )}

                <Typography variant='h6' component='h3'>
                    Remaining scheduled payments until cancellation
                </Typography>

                <Paper variant='outlined' sx={{ p: 2 }}>
                    {!loanAfterCancellationPreview && <PageLoading />}
                    {loanAfterCancellationPreview && <UpcomingPayments loan={loanAfterCancellationPreview} />}
                </Paper>

                <Typography variant='h6' component='h3'>
                    Additional details
                </Typography>

                <FormControl required sx={{ maxWidth: '260px' }}>
                    <FormLabel htmlFor='reason' required={false}>
                        Reason for cancelling
                    </FormLabel>
                    <Controller
                        name={'reason'}
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                {...register('reason')}
                                data-testid={'reason'}
                                size='small'
                                value={field.value}
                            >
                                <MenuItem value={CancellationReason.CLAIM}>Claim</MenuItem>
                                <MenuItem value={CancellationReason.ARREARS}>Arrears</MenuItem>
                                <MenuItem value={CancellationReason.CLIENT_REQUESTED}>Client requested</MenuItem>
                                <MenuItem value={CancellationReason.INSURER_REQUESTED}>Insurer requested</MenuItem>
                                <MenuItem value={CancellationReason.OTHER}>Other</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>

                <FormControl required>
                    <FormLabel htmlFor='note' required={false}>
                        Note (internal)
                    </FormLabel>
                    <Controller
                        name='note'
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id='note'
                                size='small'
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 1024 }}
                                error={!!errors?.note}
                                helperText={errors?.note?.message}
                            />
                        )}
                    />
                </FormControl>

                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                        <Button onClick={cancel} variant='text' color='primary' size='large' sx={{ mr: 1 }}>
                            Cancel
                        </Button>
                        <Button
                            disabled={previewing || loanAfterCancellationPreview == null}
                            variant='contained'
                            color='primary'
                            type='submit'
                            size='large'
                            sx={{ minWidth: '160px' }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </StepCard>
        </form>
    );
}

const getSchema = (minEffectiveDate: Moment, maxEffectiveDate: Moment) => {
    return yup.object({
        effectiveDate: yup
            .date()
            .nullable()
            .transform((curr) => (invalidDate(curr) ? null : curr))
            .test(
                'checkDate',
                `Effective date must be between ${minEffectiveDate.format(DATE_FRIENDLY)} and ${maxEffectiveDate.format(DATE_FRIENDLY)}`,
                (value) => {
                    if (invalidDate(value)) {
                        return false;
                    }

                    const date = moment(value);
                    return (
                        date.isSameOrAfter(asString(minEffectiveDate)) &&
                        date.isSameOrBefore(asString(maxEffectiveDate))
                    );
                }
            ),
        reason: yup.string().required('Reason required'),
        note: yup.string().required('Note required'),
    });
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
