import { Delete } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';

type Props = {
    remove: UseFieldArrayRemove;
    index: number;
    childDescription: string;
};

export default function RemoveChild({ remove, index, childDescription }: Readonly<Props>) {
    const [removeMode, setRemoveMode] = useState(false);

    if (removeMode) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant='caption'>
                    Remove {childDescription} from quote? This action is not reversible.
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button variant='contained' color='error' onClick={() => remove(index)}>
                        Yes, remove {childDescription}
                    </Button>
                    <Button variant='outlined' color='primary' onClick={() => setRemoveMode(false)}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Button startIcon={<Delete />} variant='text' color='error' onClick={() => setRemoveMode(true)}>
            Remove {childDescription}
        </Button>
    );
}
