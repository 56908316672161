import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

const PageLoading = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '230px',
            }}
        >
            <Typography data-testid='loading'>
                <CircularProgress size={40} style={{ color: theme.palette.primary.dark }} />
            </Typography>
        </Box>
    );
};

export default PageLoading;
