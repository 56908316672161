import { Alert, Grid, List } from '@mui/material';
import { Invoice } from '../../../../apis/invoice';
import LendingConditionDenied from '../../../../components/LendingConditionDenied';
import LendingConditionPendingApproval from '../../../../components/LendingConditionPendingApproval';
import {
    detectLendingConditionsDenied,
    detectLendingConditionsPendingApproval,
    hasLendingCondition,
    isLendingConditionDenied,
} from '../../common';

type Props = {
    invoice: Invoice;
};

export default function LendingConditions({ invoice }: Readonly<Props>) {
    const lendingConditionsDetected = hasLendingCondition(invoice);
    const lendingConditionDenied = isLendingConditionDenied(invoice);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
                {lendingConditionsDetected && !lendingConditionDenied && (
                    <Alert severity='info'>
                        <Grid container>
                            <Grid item sm={9}>
                                Recurring payment options requires manual approval, which can be requested after the
                                invoice renewal has been created. The client can still opt to pay in full. This is
                                necessary because:
                            </Grid>
                        </Grid>

                        <List>
                            {detectLendingConditionsPendingApproval(invoice).map(({ uuid, statement }) => (
                                <LendingConditionPendingApproval key={uuid} statement={statement} />
                            ))}
                        </List>
                    </Alert>
                )}
                {lendingConditionsDetected && lendingConditionDenied && (
                    <Alert severity='info'>
                        <Grid container>
                            <Grid item sm={9}>
                                Recurring payment options are unavailable as premium funding was declined. The client
                                can still pay in full. This is because:
                            </Grid>
                        </Grid>
                        <List>
                            {detectLendingConditionsDenied(invoice).map(({ uuid, statement }) => (
                                <LendingConditionDenied key={uuid} statement={statement} />
                            ))}
                        </List>
                    </Alert>
                )}
            </Grid>
        </Grid>
    );
}
