import { sortBy } from 'lodash';
import moment from 'moment';
import {
    ConsolidatedExpectedPayment,
    ExpectedPayment,
    ExpectedPaymentStatus,
    Invoice,
    Loan,
} from '../../../apis/invoice';
import { DATE_SERVER_FORMAT } from '../../../util/dateUtils';
import { getConsolidatedExpectedPaymentsBeforeCancellation } from '../common';
import { ArrangementOrExpectedPaymentFields } from './types';

export const combineExpectedPaymentsAndArrangements = (
    expectedPayments: ExpectedPayment[],
    arrangements?: { dueDate: string; amount: number; uuid?: string }[]
): ArrangementOrExpectedPaymentFields[] => {
    const combinedPayments: ArrangementOrExpectedPaymentFields[] = [];

    (arrangements ?? []).forEach(({ amount, dueDate, uuid }) =>
        combinedPayments.push({
            key: uuid ?? Math.random().toString(),
            amount: amount,
            dueDate: dueDate,
            isNewArrangement: true,
        })
    );

    expectedPayments.forEach((expectedPayment) =>
        combinedPayments.push({
            ...expectedPayment,
            key: expectedPayment.uuid,
            dueDate: moment(expectedPayment.dueDate).format(DATE_SERVER_FORMAT),
            isNewArrangement: false,
        })
    );

    return sortBy(combinedPayments, 'dueDate');
};

export const getExpectedPaymentsInStatuses = (
    loan: Loan,
    statues: ExpectedPaymentStatus[]
): ConsolidatedExpectedPayment[] => {
    return getConsolidatedExpectedPaymentsBeforeCancellation(loan).filter(({ status }) => statues.includes(status));
};

export const getInvoicePolicyDescription = (invoice: Invoice): string => {
    const firstPolicy = invoice.portfolio.policies[0];
    return firstPolicy.classCodeDescription ?? firstPolicy.classCode;
};
