import { Alert, AlertTitle } from '@mui/material';

export default function RenewalAlertChild() {
    return (
        <Alert severity='info'>
            <AlertTitle>Pending renewal</AlertTitle>
            This invoice was created as a renewal from another invoice.
        </Alert>
    );
}
