import axios from 'axios';
import { Invoice } from './invoice';

export type Endorsement = {
    policyEndorsements: EndorsementPolicy[];
    number: string; // reference
    reason?: string; // description
    uuid: string;
};

export type EndorsementPolicy = EndorsementPolicyRequest & {
    policyUuid: string;
    status: EndorsementPolicyStatus;
    policyVersionIdentifier: string;
};

export enum EndorsementPolicyStatus {
    PENDING = 'PENDING', // pending client acceptance
    ACCEPTED = 'ACCEPTED', // client accepted but pending action, e.g. effective date is in future
    ACTIONED = 'ACTIONED', // endorsement actioned on the policy, i.e. effective
    REJECTED = 'REJECTED', // client rejected the endorsement, or system rejected the endorsement, e.g. lending rule is not satisfied
    REPLACED = 'REPLACED', // replaced by a new endorsement on the same policy when the current one wasn't actioned (effective)
}

export type EndorsementRequest = {
    policyEndorsements: EndorsementPolicyRequest[];
    number: string; // reference
    reason: string; // description
};

export type EndorsementPolicyRequest = {
    uuid: string;
    policyNumber: string;
    premiumsAdjustment: number;
    effectiveDate: string;
};

export const postEndorsement = async (invoiceUuid: string, endorsement: EndorsementRequest): Promise<Invoice> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/endorsement`, endorsement)
        .then(({ data }) => data);
};
