import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Add } from '@mui/icons-material';
import { Alert, Box, Button, FormControl, FormLabel, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useState } from 'react';
import { Controller, FieldErrors, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Invoice, Policy } from '../../../apis/invoice';
import { PolicyVersionPreview, PolicyVersionRequest, previewQuote } from '../../../apis/quotes';
import { SellerProduct } from '../../../apis/sellerProduct';
import { DATE_COMPACT, DATE_SERVER_FORMAT, handleDatePickerChange } from '../../../util/dateUtils';
import { LoadingButton } from '../../LoadingButton';
import StepCard from '../../StepCard';
import {
    castPolicyVersionToFormFields,
    castFormFieldsToRequest,
    getEmptyItem,
    getEmptyQuote,
    getSchema,
    QuoteFields,
    validateQuestionnaire,
} from '../common';
import EditItem from './EditItem';

type Props = {
    policy: Policy;
    policyVersionRequest?: PolicyVersionRequest;
    handleSetPolicyVersionPreview: (request: PolicyVersionRequest, preview: PolicyVersionPreview) => void;
    cancel: () => void;
    invoice: Invoice;
    sellerProduct: SellerProduct;
};

export default function EditQuoteStep({
    cancel,
    policyVersionRequest,
    handleSetPolicyVersionPreview,
    invoice,
    policy,
    sellerProduct,
}: Readonly<Props>) {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const minEffectiveDate = moment(invoice.term.firstPaymentDate, DATE_SERVER_FORMAT);
    const maxEffectiveDate = moment(invoice.term.finalPaymentDate, DATE_SERVER_FORMAT);

    const {
        control,
        handleSubmit,
        register,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<QuoteFields>({
        resolver: yupResolver(getSchema(minEffectiveDate, maxEffectiveDate)),
        defaultValues: policyVersionRequest
            ? castPolicyVersionToFormFields(sellerProduct, policyVersionRequest)
            : getEmptyQuote(sellerProduct),
    });

    const {
        fields: itemFields,
        remove: removeItem,
        append: appendItem,
    } = useFieldArray({
        control,
        name: 'items',
    });

    const onValid: SubmitHandler<QuoteFields> = (data) => {
        if (!validateQuestionnaire(data, setValue)) {
            return setErrorMsg('Please fix the errors and submit again.');
        }

        setLoading(true);
        setErrorMsg(undefined);
        const request = castFormFieldsToRequest(data, sellerProduct, invoice);
        previewQuote(invoice.uuid, policy.uuid, request)
            .then((preview) => handleSetPolicyVersionPreview(request, preview))
            .catch(() => {
                setErrorMsg('Something went wrong, please try again later.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onInvalid = (errors: FieldErrors<QuoteFields>) => {
        console.log(errors);
        validateQuestionnaire(getValues(), setValue);
        setErrorMsg('Please fix the errors and submit again.');
    };

    return (
        <form onSubmit={handleSubmit(onValid, onInvalid)}>
            <StepCard>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant='h5' component='h2'>
                            Edit policy details
                        </Typography>
                        <Typography>Policy number {policy.number}</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box>
                            <Typography variant='h6'>Lives insured</Typography>
                        </Box>

                        {itemFields.map((field, index) => (
                            <EditItem
                                key={field.id}
                                initialValue={field}
                                control={control}
                                register={register}
                                errors={errors}
                                itemIndex={index}
                                setValue={setValue}
                                remove={index > 0 || itemFields.length > 1 ? removeItem : undefined}
                                handleQuestionnaireUpdate={() => validateQuestionnaire(getValues(), setValue)}
                            />
                        ))}

                        <Box>
                            <Button startIcon={<Add />} onClick={() => appendItem(getEmptyItem(sellerProduct))}>
                                Add item
                            </Button>
                        </Box>
                    </Box>

                    <Box width={220}>
                        <Typography variant='h6' mb={1}>
                            Effective from
                        </Typography>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='effectiveDate'>Effective date</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Controller
                                    name={'effectiveDate'}
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            {...register('effectiveDate')}
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={field.value ? moment(field.value, DATE_SERVER_FORMAT) : null}
                                            slotProps={{
                                                textField: {
                                                    id: 'effectiveDate',
                                                    fullWidth: true,
                                                    size: 'small',
                                                    helperText: errors?.effectiveDate?.message,
                                                    error: !!errors?.effectiveDate,
                                                    placeholder: undefined,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                            minDate={minEffectiveDate}
                                            maxDate={maxEffectiveDate}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Box>

                    {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button onClick={cancel} variant='text' color='primary'>
                            Cancel
                        </Button>
                        <LoadingButton variant='contained' type='submit' loading={loading}>
                            Continue to review
                        </LoadingButton>
                    </Box>
                </Box>
            </StepCard>
        </form>
    );
}
