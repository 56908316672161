import { ErrorOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { BaseInvoiceFormFields } from '../../CreateInvoice/invoiceValidation';

type Props = { invoiceFields?: BaseInvoiceFormFields };

export default function InvoiceDetails({ invoiceFields }: Readonly<Props>) {
    if (invoiceFields) {
        return (
            <Box>
                <Typography variant='caption'>{invoiceFields.invoiceNumber}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <ErrorOutline fontSize='large' color='disabled'></ErrorOutline>
            <Box>
                <Typography variant='caption'>More info required</Typography>
                <Typography variant='subtitle1'>Expand to edit</Typography>
            </Box>
        </Box>
    );
}
