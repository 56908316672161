import { yupResolver } from '@hookform/resolvers/yup';
import { Save } from '@mui/icons-material';
import { Box, FormControl, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CreateInvoiceNoteFields, InvoiceNoteDto, saveInvoiceNote } from '../../../apis/invoice';
import { LoadingButton } from '../../../components/LoadingButton';
import { SnackState } from '../../../components/SnackAlert';

const schema = yup.object({
    contents: yup.string().required('Note contents required'),
});

type Props = {
    addNewNote: (newInvoiceNoteDto: InvoiceNoteDto) => void;
    setSnack: (state: SnackState) => void;
    invoiceIdentifier: string;
    handleOpenDrawer: () => void;
};
export default function CreateInvoiceNote({
    addNewNote,
    setSnack,
    invoiceIdentifier,
    handleOpenDrawer,
}: Readonly<Props>) {
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<CreateInvoiceNoteFields>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<CreateInvoiceNoteFields> = (data) => {
        setSubmitting(true);

        saveInvoiceNote(invoiceIdentifier, data)
            .then((result) => {
                addNewNote(result);
                setSnack({ severity: 'success', msg: 'Note added.' });
            })
            .catch(() => {
                setSnack({
                    severity: 'error',
                    msg: 'Something went wrong, please try again',
                });
            })
            .finally(() => {
                setSubmitting(false);
                handleOpenDrawer();
            });
    };

    return (
        <Box p={2} height={'90%'} justifyContent={'center'} marginY={'auto'}>
            <Typography variant={'h2'}>Add Note</Typography>
            <Box sx={{ height: '100%' }}>
                <form
                    autoComplete='off'
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid container spacing={2} sx={{ height: '100%' }}>
                        <Grid item md={12} sm={12}>
                            <FormControl fullWidth required>
                                <FormLabel htmlFor='contents'>Note Details</FormLabel>
                                <Controller
                                    name='contents'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            size={'small'}
                                            id='contents'
                                            disabled={submitting}
                                            autoComplete='no'
                                            multiline={true}
                                            minRows={15}
                                            inputProps={{ maxLength: 1000 }}
                                            error={errors.contents !== undefined}
                                            helperText={errors.contents?.message}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid container pb={2} alignContent={'flex-end'}>
                            <Grid item sm={6} />
                            <Grid item sm={6} display={'flex'} justifyContent={'center'}>
                                <LoadingButton
                                    data-testid='saveNote'
                                    type='submit'
                                    variant='contained'
                                    fullWidth
                                    size={'large'}
                                    startIcon={<Save />}
                                    loading={submitting}
                                >
                                    Save note
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    );
}
