import { yupResolver } from '@hookform/resolvers/yup';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Alert, Box, Button, Fab, FormControl, FormLabel, Grid, TextField, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { ExpectedPaymentStatus, InvoiceDetailsResponse, Loan } from '../../../../apis/invoice';
import DetailedExpectedPaymentStatusChips from '../../../../components/DetailedExpectedPaymentStatusChips';
import PaymentAmount from '../../../../components/PaymentAmount';
import StepCard from '../../../../components/StepCard';
import {
    DATE_COMPACT,
    DATE_FRIENDLY,
    DATE_SERVER_FORMAT,
    asString,
    handleDatePickerChange,
    invalidDate,
} from '../../../../util/dateUtils';
import { getLoanCancellationEffectiveDate } from '../../common';
import { StyledGridFooterContainer, StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';
import { PaymentHolidyFields } from '../types';
import { getExpectedPaymentsInStatuses, getInvoicePolicyDescription } from '../utils';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    paymentHolidyFields?: PaymentHolidyFields;
    setPaymentHolidayFields: (fields: PaymentHolidyFields) => void;
    loan: Loan;
    details: InvoiceDetailsResponse;
};

export default function PaymentHolidayDetailsStep({
    cancel,
    handleBack,
    paymentHolidyFields,
    setPaymentHolidayFields,
    loan,
    details,
}: Readonly<Props>) {
    const theme = useTheme();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showMore, setShowMore] = useState<boolean>(false);

    const loanCancellationEffectiveDate = getLoanCancellationEffectiveDate(loan);
    const termFinalPaymentDate = moment(details.invoice.term.finalPaymentDate, DATE_SERVER_FORMAT);
    const finalPaymentDate = moment.min(loanCancellationEffectiveDate.subtract(1, 'days'), termFinalPaymentDate);

    const expectedPaymentsAllowDefer = useMemo(
        () =>
            getExpectedPaymentsInStatuses(loan, [
                ExpectedPaymentStatus.PENDING,
                ExpectedPaymentStatus.OVERDUE,
                ExpectedPaymentStatus.DEFERRED,
            ]),
        [loan]
    );

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm<PaymentHolidyFields>({
        resolver: yupResolver(getSchema(finalPaymentDate)),
        defaultValues: {
            expectedPayments: expectedPaymentsAllowDefer.map((expectedPayment) => ({ ...expectedPayment })),
            ...paymentHolidyFields,
        },
    });

    const { fields } = useFieldArray({
        control,
        name: 'expectedPayments',
    });

    const expectedPayments = useWatch({ control, name: 'expectedPayments' });

    const onSubmit: SubmitHandler<PaymentHolidyFields> = (data) => {
        setErrorMsg(undefined);

        const adjustedExpectedPayment = data.expectedPayments.find(({ newDueDate }) => newDueDate);
        if (!adjustedExpectedPayment) {
            setErrorMsg('You must adjust at least one scheduled payment.');
            return;
        }

        const paymentHoliday = {
            ...data,
            expectedPayments: data.expectedPayments.map((expectedPayment) => ({
                ...expectedPayment,
                newDueDate:
                    expectedPayment.newDueDate == null
                        ? undefined
                        : moment(expectedPayment.newDueDate).format(DATE_SERVER_FORMAT),
            })),
        };

        setPaymentHolidayFields(paymentHoliday);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <StepCard>
                <Typography variant='h5' component='h2'>
                    Payment holiday
                </Typography>
                <Typography variant='caption'>{`For ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>

                <Typography variant='caption'>
                    For any scheduled instalment below, enter new dates for payments you would like to add to the
                    payment holiday.
                </Typography>

                <Box>
                    <Grid
                        container
                        sx={{
                            border: grey[400],
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: theme.shape.borderRadius + 'px',
                        }}
                    >
                        <StyledGridHeaderContainer>
                            <Grid item xs={3} pr={2}>
                                <Typography variant='subtitle2'>Scheduled date</Typography>
                            </Grid>
                            <Grid item xs={3} pr={2}>
                                <Typography variant='subtitle2'>New date</Typography>
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={3} container direction='row' justifyContent='flex-end'>
                                <Typography variant='subtitle2'>Amount (excludes fees)</Typography>
                            </Grid>
                        </StyledGridHeaderContainer>

                        {isEmpty(expectedPaymentsAllowDefer) && (
                            <StyledGridItemContainer>
                                <Grid item xs={12} pr={2}>
                                    <Typography variant='subtitle2'>
                                        No schedule instalments can be added into the payment holiday.
                                    </Typography>
                                </Grid>
                            </StyledGridItemContainer>
                        )}

                        {fields.map(({ uuid, dueDate, originalDueDate, amount, paidAmount, status }, index) => {
                            const fieldErrors = errors?.expectedPayments?.[index];
                            const expectedPayment = expectedPayments[index];
                            const edited = !isEmpty(expectedPayment.newDueDate);
                            const alignCentreSx = {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            };

                            if (!showMore && index >= defaultNumberOfPaymentsBeforeShowMore && !edited) {
                                return null;
                            }

                            const originalDueDateMoment = moment(originalDueDate, DATE_SERVER_FORMAT);
                            const allowDefer = originalDueDateMoment.isBefore(finalPaymentDate);

                            return (
                                <StyledGridItemContainer key={uuid}>
                                    {!allowDefer && (
                                        <Alert severity='warning' sx={{ mb: 1, minWidth: '100%' }}>
                                            {'Scheduled date cannot be changed for the final payment'}
                                        </Alert>
                                    )}
                                    <Grid item xs={3} pr={2} sx={alignCentreSx}>
                                        <Typography variant='caption'>
                                            {moment(dueDate).format(DATE_FRIENDLY)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} pr={2} sx={alignCentreSx}>
                                        <FormControl required={edited}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Controller
                                                    name={`expectedPayments.${index}.newDueDate`}
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            {...register(`expectedPayments.${index}.newDueDate`)}
                                                            data-testid={`expectedPayments.${index}.newDueDate`}
                                                            disabled={!allowDefer}
                                                            onChange={handleDatePickerChange(field)}
                                                            onAccept={handleDatePickerChange(field)}
                                                            value={
                                                                isEmpty(field.value)
                                                                    ? null
                                                                    : moment(field.value, DATE_SERVER_FORMAT)
                                                            }
                                                            slotProps={{
                                                                textField: {
                                                                    id: 'newDueDate',
                                                                    fullWidth: true,
                                                                    size: 'small',
                                                                    helperText: fieldErrors?.newDueDate?.message,
                                                                    error: !!fieldErrors?.newDueDate,
                                                                    placeholder: undefined,
                                                                },
                                                            }}
                                                            format={DATE_COMPACT}
                                                            minDate={
                                                                moment().isBefore(originalDueDateMoment)
                                                                    ? originalDueDateMoment
                                                                    : moment()
                                                            }
                                                            maxDate={finalPaymentDate}
                                                            shouldDisableDate={(day) => day.isSame(moment(dueDate))}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} pr={2} sx={alignCentreSx}>
                                        <DetailedExpectedPaymentStatusChips
                                            expectedPaymentStatus={status}
                                            expectedPayments={expectedPayment.expectedPayments}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        container
                                        direction='row'
                                        justifyContent='flex-end'
                                        alignItems='center'
                                    >
                                        <PaymentAmount amount={amount} paidAmount={paidAmount} feeAmount={0} />
                                    </Grid>
                                </StyledGridItemContainer>
                            );
                        })}

                        {fields.length > defaultNumberOfPaymentsBeforeShowMore && (
                            <StyledGridFooterContainer>
                                <Grid item xs={12} pr={2}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Fab
                                            size='small'
                                            onClick={() => setShowMore(!showMore)}
                                            data-testid='show-more-scheduled-payments'
                                        >
                                            {showMore ? <ExpandLess /> : <ExpandMore />}
                                        </Fab>
                                    </Box>
                                </Grid>
                            </StyledGridFooterContainer>
                        )}
                    </Grid>
                </Box>

                <FormControl required>
                    <FormLabel htmlFor='note' required={false}>
                        Note
                    </FormLabel>
                    <Controller
                        name='note'
                        control={control}
                        defaultValue={paymentHolidyFields?.note ?? ''}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id='note'
                                size='small'
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 1024 }}
                                error={!!errors?.note}
                                helperText={errors?.note?.message}
                            />
                        )}
                    />
                </FormControl>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

                <Grid container>
                    <Grid item xs={6}>
                        <Button onClick={handleBack} variant='outlined'>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                        <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                            Cancel
                        </Button>
                        {!isEmpty(expectedPaymentsAllowDefer) && (
                            <Button
                                disabled={
                                    expectedPayments.filter(({ newDueDate }) => !isEmpty(newDueDate)).length === 0
                                }
                                type='submit'
                                variant='contained'
                                size='large'
                                sx={{ minWidth: '160px' }}
                            >
                                Next
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </StepCard>
        </form>
    );
}

const defaultNumberOfPaymentsBeforeShowMore = 6;

const getSchema = (maxEffectiveDate: moment.Moment) => {
    return yup.object({
        note: yup.string().required('Note required'),
        expectedPayments: yup.array().of(
            yup.object({
                uuid: yup.string(),
                newDueDate: yup
                    .date()
                    .nullable()
                    .transform((curr) => (invalidDate(curr) ? null : curr))
                    .test(
                        'checkDate',
                        `The new due date must be between today and ${maxEffectiveDate.format(DATE_FRIENDLY)}`,
                        (value, context) => {
                            if (value == null) {
                                return true;
                            }

                            const date = moment(value);
                            const today = moment().startOf('day');
                            const originalDueDateMoment = moment(context.parent.originalDueDate, DATE_SERVER_FORMAT);

                            const isWithinRange =
                                date.isSameOrAfter(today) &&
                                date.isSameOrAfter(originalDueDateMoment) &&
                                date.isSameOrBefore(asString(maxEffectiveDate));

                            if (!isWithinRange && today.isBefore(originalDueDateMoment)) {
                                return context.createError({
                                    message: `The new due date must be between ${originalDueDateMoment.format(DATE_FRIENDLY)} and ${maxEffectiveDate.format(DATE_FRIENDLY)}`,
                                });
                            }

                            return isWithinRange; // true, or false with default error message
                        }
                    ),
            })
        ),
    });
};
