import { lowerCase } from 'lodash';
import { ChargeType } from '../apis/invoice';
import { useAppSelector } from '../store/reducer/Hooks';
import { isSuccess } from './useFetch';

const defaultLabelName = 'default fee';

export default function useDefaultFeeLabel() {
    const state = useAppSelector((root) => root.InvoiceDetailsReducer).state;

    if (!isSuccess(state)) {
        return defaultLabelName;
    }

    const label =
        state.value.invoice.term.feeCharges.find((charge) => charge.type == ChargeType.DEFAULT_FEE)?.label ??
        defaultLabelName;

    return lowerCase(label);
}
