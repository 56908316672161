import { Chip } from '@mui/material';
import { AccessTime, Check } from '@mui/icons-material';

import { PaymentFrequency } from '../apis/invoice';

interface InvoiceStatusChipProps {
    status: string;
    paymentFrequency: PaymentFrequency | undefined;
}

const InvoiceStatusChipObj = {
    IN_PROGRESS: <Chip label='In progress' color='info' variant='outlined' icon={<AccessTime fontSize='small' />} />,
    PAID: <Chip label='Paid' color='success' variant='outlined' icon={<Check fontSize='small' />} />,
    FUNDED: <Chip label='Funded' color='success' variant='outlined' icon={<Check fontSize='small' />} />,
    DECLINED: <Chip label='Declined' color='error' variant='outlined' icon={<AccessTime fontSize='small' />} />,
    ATTENTION: <Chip label='Attention' color='warning' variant='outlined' icon={<AccessTime fontSize='small' />} />,
    SCHEDULED: <Chip label='Scheduled' color='success' variant='outlined' icon={<AccessTime fontSize='small' />} />,
};

const InvoiceStatusChip = (props: InvoiceStatusChipProps) => {
    const { status, paymentFrequency } = props;

    if (status === 'In progress') {
        return InvoiceStatusChipObj.IN_PROGRESS;
    } else if (status === 'Scheduled') {
        return InvoiceStatusChipObj.SCHEDULED;
    } else if (status === 'Paid') {
        if (paymentFrequency === PaymentFrequency.IN_FULL) {
            return InvoiceStatusChipObj.PAID;
        } else {
            return InvoiceStatusChipObj.FUNDED;
        }
    } else {
        return InvoiceStatusChipObj.DECLINED;
    }
};

export default InvoiceStatusChip;
