import { ContentCopy, ExpandMore, Launch, Lock, NavigateNext, Send } from '@mui/icons-material';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Invoice, getCheckoutOrigin, requestApproval, sendInvoiceToCustomer } from '../../../apis/invoice';
import { SnackState } from '../../../components/SnackAlert';
import { UndoButton } from '../../../components/UndoButton';
import { setCheckoutOrigin } from '../../../store/reducer/ConfigReducer';
import { useAppDispatch, useAppSelector } from '../../../store/reducer/Hooks';
import SendInvoiceConfirmationDialog from './SendInvoiceConfirmationDialog';
import { isLendingConditionPendingApproval } from '../common';

type Props = {
    invoice: Invoice;
    setSnack: (state: SnackState) => void;
    approvalRequestSentCount: number;
    setApprovalRequestSentCount: (count: number) => void;
};

export default ({ invoice, setSnack, approvalRequestSentCount, setApprovalRequestSentCount }: Props) => {
    const { checkoutOrigin } = useAppSelector((root) => root.persistedConfigReducer);
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const actionMenuOpen = Boolean(anchorEl);

    const [sendingInvoice, setSendingInvoice] = useState(false);
    const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false);
    const [requestingApproval, setRequestingApproval] = useState(false);

    const lendingConditionPendingApproval = isLendingConditionPendingApproval(invoice);
    const blockCheckout = lendingConditionPendingApproval && !invoice.checkoutAllowed;

    useEffect(() => {
        if (!checkoutOrigin) {
            getCheckoutOrigin().then((origin) =>
                dispatch(setCheckoutOrigin(origin ? origin : process.env.REACT_APP_CHECKOUT_HOST!))
            );
        }
    }, [checkoutOrigin]);

    const sendInvoice = useCallback(async () => {
        return sendInvoiceToCustomer(invoice.uuid)
            .then(() => {
                setSnack({ severity: 'success', msg: 'Invoice sent to customer' });
            })
            .catch(() => {
                setSnack({
                    severity: 'error',
                    msg: 'Invoice failed to send to the customer. Please try again or contact Simfuni for assistance.',
                });
            });
    }, [invoice.uuid]);

    const handleRequestApproval = useCallback(async () => {
        setRequestingApproval(true);
        return requestApproval(invoice.uuid)
            .then(() => {
                setApprovalRequestSentCount(approvalRequestSentCount + 1);
            })
            .finally(() => setRequestingApproval(false));
    }, [sendInvoiceToCustomer, approvalRequestSentCount, invoice.uuid]);

    const SendActionMenuButton = useCallback(() => {
        if (!lendingConditionPendingApproval) {
            return (
                <MenuItem onClick={handleSendInvoice} data-testid='send-invoice-menu-item'>
                    <ListItemIcon>
                        <Send fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Send Invoice</ListItemText>
                </MenuItem>
            );
        }

        if (blockCheckout) {
            return (
                <Tooltip title={blockedSendingInvoiceTooltipMsg} arrow placement='left'>
                    <span>
                        {' '}
                        {/* Add <span> to make tooltip work with disabled button. See https://mui.com/material-ui/react-tooltip/#disabled-elements for details. */}
                        <MenuItem disabled={true} data-testid='send-invoice-disabled-menu-item'>
                            <ListItemIcon>
                                <Lock fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Send Invoice</ListItemText>
                        </MenuItem>
                    </span>
                </Tooltip>
            );
        }

        return (
            <MenuItem
                onClick={() => {
                    setConfirmationDialogOpened(true);
                }}
                data-testid='open-confirmation-dialog-menu-item'
            >
                <ListItemIcon>
                    <Send fontSize='small' />
                </ListItemIcon>
                <ListItemText>Send Invoice</ListItemText>
            </MenuItem>
        );
    }, [lendingConditionPendingApproval, blockCheckout]);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeActionMenu = () => {
        setAnchorEl(null);
    };
    const handleSendInvoice = () => {
        setConfirmationDialogOpened(false);
        setSendingInvoice(true);
        closeActionMenu();
    };
    const handleCopyLink = () => {
        closeActionMenu();
        setSnack({
            severity: 'success',
            msg: 'Payment link copied to clipboard!',
        });
    };
    const paymentUrl = useMemo(() => checkoutOrigin + '/' + invoice.uuid, [checkoutOrigin]);

    return (
        <>
            {sendingInvoice && (
                <UndoButton
                    handleCancel={() => setSendingInvoice(false)}
                    delayedFunction={sendInvoice}
                    handleFinish={() => setSendingInvoice(false)}
                    startImmediately={true}
                    variant='contained'
                    sx={buttonStyle}
                >
                    Sending
                </UndoButton>
            )}
            <Box sx={sendingInvoice ? hiddenStyles : {}}>
                <Button
                    data-testid='invoice-actions'
                    sx={{ ...buttonStyle, justifyContent: 'space-between' }}
                    id='invoice-action'
                    aria-controls={actionMenuOpen ? 'action-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={actionMenuOpen ? 'true' : undefined}
                    onClick={handleOpenMenu}
                    variant='contained'
                    endIcon={actionMenuOpen ? <ExpandMore /> : <NavigateNext />}
                >
                    Actions
                </Button>
            </Box>
            <Menu
                id='action-menu'
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={closeActionMenu}
                MenuListProps={{
                    'aria-labelledby': 'invoice-action',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SendActionMenuButton />
                {!blockCheckout && (
                    <CopyToClipboard onCopy={handleCopyLink} text={paymentUrl}>
                        <MenuItem data-testid='copy-link-menu-item'>
                            <ListItemIcon>
                                <ContentCopy fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Copy payment link</ListItemText>
                        </MenuItem>
                    </CopyToClipboard>
                )}
                {openPaymentLink() && (
                    <MenuItem onClick={() => window.open(paymentUrl, '_blank')?.focus()}>
                        <ListItemIcon>
                            <Launch fontSize='small' />
                        </ListItemIcon>
                        <ListItemText>Open payment link</ListItemText>
                    </MenuItem>
                )}
            </Menu>
            <SendInvoiceConfirmationDialog
                invoice={invoice}
                confirmationDialogOpened={confirmationDialogOpened}
                setConfirmationDialogOpened={setConfirmationDialogOpened}
                requestingApproval={requestingApproval}
                handleRequestApproval={handleRequestApproval}
                handleSendInvoice={handleSendInvoice}
            />
        </>
    );
};

const openPaymentLink = () => {
    return process.env.REACT_APP_OPEN_PAYMENT_LINK === 'true';
};

const hiddenStyles = {
    visibility: 'hidden',
    height: 0,
    width: 0,
    padding: 0,
};
const blockedSendingInvoiceTooltipMsg =
    'Checkout is blocked unless the lending conditions are approved or checkout with in-full payment option only is explicitly unblocked.';
const buttonStyle = { width: 160 };
