import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    Snackbar,
    TextField,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { InvoiceDetailsResponse } from '../../../apis/invoice';
import { voidRenewal } from '../../../apis/renewal';
import { LoadingButton } from '../../../components/LoadingButton';
import { FetchStateType } from '../../../hooks/useFetch';
import { useAppDispatch } from '../../../store/reducer/Hooks';
import { setInvoiceDetailsState } from '../../../store/reducer/InvoiceDetailsReducer';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
    open: boolean;
    setClosed: () => void;
};

export default function VoidRenewalInvoice({ invoiceDetails, open, setClosed }: Readonly<Props>) {
    const { invoice } = invoiceDetails;
    const dispatch = useAppDispatch();
    const [processing, setProcessing] = useState(false);
    const [voidReason, setVoidReason] = useState<string>('');
    const [snackOpen, setSnackOpen] = useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setProcessing(true);
        voidRenewal(invoice.uuid, voidReason)
            .then((invoice) => {
                setSnackOpen(true);
                setClosed();

                const updatedDetails = cloneDeep(invoiceDetails);
                updatedDetails.invoice = invoice;
                dispatch(setInvoiceDetailsState({ value: updatedDetails, type: FetchStateType.SUCCESS }));
            })
            .finally(() => setProcessing(false));
    };

    return (
        <>
            <Dialog
                onClose={setClosed}
                open={open}
                fullWidth
                maxWidth='sm'
                PaperProps={{ component: 'form', onSubmit }}
            >
                <DialogTitle variant='h2'>Void renewal invoice?</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', gap: 1, my: 2, flexDirection: 'column' }}>
                        <FormControl>
                            <FormLabel htmlFor='reason'>Reason</FormLabel>
                            <TextField
                                id='reason'
                                fullWidth
                                size='small'
                                variant='outlined'
                                value={voidReason}
                                required
                                placeholder='Enter a reason'
                                onChange={(e) => setVoidReason(e.target.value)}
                                inputProps={{ maxLength: 250 }}
                            />
                        </FormControl>
                        <DialogContentText>
                            This will immediately void the renewal. After voiding, a new renewal can be created from the
                            original invoice.
                        </DialogContentText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setClosed}>Cancel</Button>
                    <LoadingButton variant='contained' loading={processing} color='error' type='submit'>
                        Void
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackOpen}
                onClose={() => setSnackOpen(false)}
                autoHideDuration={6000}
            >
                <Alert severity='success'>Renewal voided successfully</Alert>
            </Snackbar>
        </>
    );
}
