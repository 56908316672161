import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    DATE_COMPACT,
    DATE_FRIENDLY,
    DATE_SERVER_FORMAT,
    asString,
    handleDatePickerChange,
    invalidDate,
} from '../../../../util/dateUtils';
import { ArrangementFields } from '../types';

type Props = {
    maxDueDate: moment.Moment;
    maxAmount: number;
    onArrangementCreated: (arrangementFields: ArrangementFields) => void;
};

export default function CreateArrangement({ maxDueDate, maxAmount, onArrangementCreated }: Readonly<Props>) {
    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        reset,
    } = useForm<ArrangementFields>({
        resolver: yupResolver(getSchema(maxDueDate, maxAmount)),
    });
    const onSubmit: SubmitHandler<ArrangementFields> = (data) => {
        onArrangementCreated(data);
        reset();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 1, sm: 6 },
                    justifyContent: 'flex-start',
                    width: '100%',
                }}
            >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box>
                        <Typography variant='caption'>Payment amount</Typography>
                        <FormControl fullWidth required>
                            <Controller
                                control={control}
                                name={'amount'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        {...register('amount')}
                                        data-testid={'amount'}
                                        fullWidth
                                        autoComplete='no'
                                        size='small'
                                        error={!!errors?.amount}
                                        helperText={errors?.amount?.message}
                                        InputProps={{
                                            startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                                        }}
                                        disabled={maxAmount <= 0}
                                    />
                                )}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box>
                        <Typography variant='caption'>Payment date</Typography>
                        <FormControl fullWidth required>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Controller
                                    name={'dueDate'}
                                    control={control}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            {...register('dueDate')}
                                            data-testid={'dueDate'}
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={
                                                isEmpty(field.value) ? null : moment(field.value, DATE_SERVER_FORMAT)
                                            }
                                            slotProps={{
                                                textField: {
                                                    id: 'newDueDate',
                                                    fullWidth: true,
                                                    size: 'small',
                                                    helperText: errors?.dueDate?.message,
                                                    error: !!errors?.dueDate,
                                                    placeholder: undefined,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                            minDate={moment()}
                                            maxDate={maxDueDate}
                                            disabled={maxAmount <= 0}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', pt: 3 }}>
                    <Button disabled={maxAmount <= 0} type='submit' variant='contained'>
                        Add
                    </Button>
                </Box>
            </Box>
        </form>
    );
}

const getSchema = (maxDueDate: moment.Moment, maxAmount: number) => {
    return yup.object({
        amount: yup
            .number()
            .transform((num) => (isNaN(num) ? undefined : num))
            .positive()
            .max(maxAmount)
            .test('2-digit-decimals', 'max to 2 digits fractions', (value) => {
                if (value == null) {
                    return true;
                }

                return /^\d+(\.\d{0,2})?$/.test(value.toString());
            })
            .required('Payment amount is required'),
        dueDate: yup
            .date()
            .transform((curr) => (invalidDate(curr) ? undefined : curr))
            .test(
                'checkDate',
                `The due date must be between today and ${maxDueDate.format(DATE_FRIENDLY)}`,
                (value) => {
                    if (value == null) {
                        return true;
                    }

                    const date = moment(value);
                    return date.isSameOrAfter(asString(moment())) && date.isSameOrBefore(asString(maxDueDate));
                }
            )
            .required('The payment date is required'),
    });
};
