import { CalendarToday } from '@mui/icons-material';
import { Alert, AlertColor, Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Client } from '../../../../apis/clients';
import { Invoice } from '../../../../apis/invoice';
import { InvoiceRenewalPreview } from '../../../../apis/renewal';
import { LoadingButton } from '../../../../components/LoadingButton';
import StepCard from '../../../../components/StepCard';
import { getStartDate } from '../../../../util/policyUtils';
import { addKeyDate, getDateDescription } from '../../common';
import LendingConditions from './LendingConditions';
import Notification from './Notification';
import PaymentSchedule from './PaymentSchedule';
import RenewalFirstStep from './RenewalFirstStep';

type Props = {
    invoice: Invoice;
    client: Client;
    renewalPreview: InvoiceRenewalPreview;
    back: () => void;
    handleConfirmRenewal: (renewalInvoice: Invoice) => void;
    sendData: (renewalInvoice: Invoice) => Promise<InvoiceRenewalPreview>;
};

export default function ReviewStep({
    renewalPreview,
    invoice,
    client,
    back,
    handleConfirmRenewal,
    sendData,
}: Readonly<Props>) {
    const [snackbar, setSnackbar] = useState<{ msg: string; severity: AlertColor }>();
    const [submitting, setSubmitting] = useState(false);
    const keyDates = getKeyDates(renewalPreview, invoice, client);

    const handleConfirm = () => {
        setSubmitting(true);
        sendData(renewalPreview.invoice)
            .then((data) => handleConfirmRenewal(data.invoice))
            .catch(() => {
                setSnackbar({
                    msg: 'Something went wrong. Please try again later.',
                    severity: 'error',
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h1'>
                Review renewal policies
            </Typography>

            <Box>
                {Object.entries(keyDates).map(([dateDescription, steps]) => {
                    return (
                        <Box key={dateDescription} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', gap: 2, my: 1 }}>
                                <CalendarToday sx={{ color: grey[600] }} />
                                <Typography variant='h6'>{dateDescription}</Typography>
                            </Box>
                            <Box sx={{ borderLeft: `solid 1px ${grey[300]}`, ml: '11.5px', pl: '27.5px' }}>
                                {steps.map((step, index) => (
                                    <Box key={`${dateDescription}-${index}`} mb={4}>
                                        {step}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    );
                })}
            </Box>

            <LendingConditions invoice={renewalPreview.invoice} />

            {snackbar && (
                <Alert onClose={() => setSnackbar(undefined)} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.msg}
                </Alert>
            )}

            <Box sx={{ width: '100%', textAlign: 'right' }}>
                <Button onClick={back} sx={{ mr: 1, width: '100px' }}>
                    Back
                </Button>
                <LoadingButton
                    onClick={handleConfirm}
                    variant='contained'
                    color='primary'
                    loading={submitting}
                    sx={{ width: '220px' }}
                >
                    Next
                </LoadingButton>
            </Box>
        </StepCard>
    );
}

const getKeyDates = (renewalPreview: InvoiceRenewalPreview, invoice: Invoice, client: Client) => {
    const dates = {
        [getDateDescription(moment())]: [<RenewalFirstStep renewalPreview={renewalPreview} />],
    };

    const portfolioStartDate = getStartDate(renewalPreview.invoice.portfolio.policies);
    const notificationDate = calculateRenewalNotificationDate(
        renewalPreview.renewalNotificationDays,
        portfolioStartDate
    );
    addKeyDate(dates, getDateDescription(notificationDate), <Notification invoice={invoice} client={client} />);
    addKeyDate(
        dates,
        getDateDescription(moment.max([portfolioStartDate, moment()])),
        <PaymentSchedule invoice={invoice} renewalPreview={renewalPreview} />
    );

    return dates;
};

const calculateRenewalNotificationDate = (renewalNotificationDays: number, portfolioStartDate: Moment): Moment => {
    const today = moment().endOf('day');
    const daysTillEffective = portfolioStartDate.diff(today, 'days');

    if (daysTillEffective <= renewalNotificationDays) {
        return today;
    }

    return moment(portfolioStartDate).subtract(renewalNotificationDays, 'days');
};
