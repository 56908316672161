import { Avatar, Box, Chip, Grid, Paper, Typography } from '@mui/material';
import { useMemo } from 'react';
import FormattedAddress from '../../components/FormattedAddress';
import { Mail, Phone } from '@mui/icons-material';
import moment from 'moment/moment';
import { upperCase } from 'lodash';
import { Client, isIndividual } from '../../apis/clients';

interface PersonalDetailsSectionProps {
    client: Client;
}

const PersonalDetailsSection = ({ client }: PersonalDetailsSectionProps) => {
    const initials = useMemo(() => {
        const parts = client.displayName.split(' ');
        parts.length = Math.min(parts.length, 2);
        return parts.map((p) => upperCase(p.charAt(0)));
    }, [client]);

    return (
        <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ textAlign: 'center', mr: 2, mt: '5px' }}>
                            <Avatar sx={{ bgcolor: '#068ed0', width: '60px', height: '60px' }}>{initials}</Avatar>
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Chip label={client.insuredType} color='primary' variant='outlined' />
                                <Typography variant='h2' sx={{ paddingBottom: 0 }}>
                                    {client.displayName}
                                </Typography>
                            </Box>
                            {isIndividual(client) && client.personalDetails.dateOfBirth && (
                                <Typography variant='body2'>
                                    {moment(client.personalDetails.dateOfBirth).format('DD MMMM YYYY')}
                                </Typography>
                            )}
                            <Box>
                                <Chip
                                    variant='customerLink'
                                    label={client.contactDetails.email.address}
                                    icon={<Mail style={{ color: '#0B51FF', fontSize: '14px' }} />}
                                    sx={{ mr: 2 }}
                                />
                                <Chip
                                    variant='customerLink'
                                    label={client.contactDetails.preferredPhoneNumber.number}
                                    icon={<Phone style={{ color: '#0B51FF', fontSize: '14px' }} />}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{ textAlign: 'right' }}>
                        <Typography variant='body2'>
                            <FormattedAddress address={client.contactDetails.physicalAddress} />
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PersonalDetailsSection;
