import { Box } from '@mui/material';

// @ts-ignore
import simfuniLogo from '../images/simfuni_logo.svg';

export default () => {
    return (
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
            <img alt='Simfuni Logo' src={simfuniLogo} width='200px' />
        </Box>
    );
};
