import { Search } from '@mui/icons-material';
import {
    Avatar,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    TextField,
} from '@mui/material';
import { useState } from 'react';
import { Client, ClientSearchResult, getClient, listClients } from '../../../apis/clients';

type Props = {
    selectClient: (client: Client) => void;
    error?: string;
};

export default function SearchClients({ selectClient, error }: Readonly<Props>) {
    const [clients, setClients] = useState<ClientSearchResult[]>();

    const searchClients = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        if (query === '') {
            setClients([] as ClientSearchResult[]);
        } else {
            fetchClients(e.currentTarget.value);
        }
    };

    const fetchClient = async (uuid: string) => {
        getClient(uuid)
            .then((response) => {
                setClients(undefined);
                selectClient(response);
            })
            .catch(console.log);
    };

    const fetchClients = async (query: string) => {
        listClients({ query, pageSize: 5 })
            .then((response) => {
                setClients(response.records);
            })
            .catch(console.log);
    };

    const renderClientList = () => {
        return clients?.map((client) => {
            return (
                <ListItem disableGutters sx={{ padding: 0 }} key={client.uuid}>
                    <ListItemButton onClick={() => fetchClient(client.uuid)}>
                        <ListItemAvatar>
                            <Avatar />
                        </ListItemAvatar>
                        <ListItemText primary={client.displayName} secondary={client.email} />
                    </ListItemButton>
                </ListItem>
            );
        });
    };

    return (
        <div style={{ position: 'relative', maxWidth: '300px' }}>
            <TextField
                placeholder='Client search'
                size='small'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <Search />
                        </InputAdornment>
                    ),
                }}
                error={!!error}
                helperText={error}
                fullWidth
                onChange={searchClients}
            />
            {clients && (
                <Paper sx={{ position: 'absolute', zIndex: 9, width: '100%' }}>
                    <List sx={{ padding: 0 }}>{renderClientList()}</List>
                </Paper>
            )}
        </div>
    );
}
