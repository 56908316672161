import './UndoButton.css';
import { Box, Button, ButtonProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import RestoreIcon from '@mui/icons-material/Restore';
import { LoadingButton } from './LoadingButton';

const UNDO_TIME_SECONDS = 10;

export type UndoButtonProps<T> = ButtonProps & {
    delayedFunction: () => Promise<T>;
    startImmediately?: boolean;
    handleCancel?: () => void;
    handleFinish?: () => void;
};

/**
 * This button works like a {@link LoadingButton} but it gives the user 10 seconds
 * to cancel the asynchronous request before it is sent. If the window/tab/browser is closed
 * or the page is navigated away from within those 10 seconds the request is sent immediately before unmount.
 *
 * @param delayedFunction the asynchronous function to call at the end of the 10 second countdown.
 */
export const UndoButton = <T,>(props: UndoButtonProps<T>) => {
    const { delayedFunction, startIcon, endIcon: _unusedEndIcon, ...rest } = props;

    const [timeRemaining, setTimeRemaining] = useState(UNDO_TIME_SECONDS);
    const [loading, setLoading] = useState(false);
    const [undo, setUndo] = useState(false);
    const interval = useRef<NodeJS.Timer | undefined>();

    useEffect(() => {
        global.addEventListener('beforeunload', unloadComponent);

        return unloadComponent;
    }, []);

    useEffect(() => {
        if (props.startImmediately) {
            awaitDelayedFunction();
        }
    }, [props.startImmediately]);

    const unloadComponent = () => {
        if (interval.current) {
            delayedFunction();
        }
        cancelDelayedFunction();
    };

    const awaitDelayedFunction = () => {
        setUndo(true);
        const functionTime = Date.now() + UNDO_TIME_SECONDS * 1_000;
        interval.current = setInterval(() => {
            const timeTillFunction = functionTime - Date.now();
            if (timeTillFunction <= 0) {
                setLoading(true);
                endUndo();
                delayedFunction().finally(() => {
                    setLoading(false);
                    props.handleFinish?.();
                });
                return;
            }

            setTimeRemaining(Math.ceil(timeTillFunction / 1_000));
        }, 500);
    };

    const endUndo = () => {
        setTimeRemaining(UNDO_TIME_SECONDS);
        setUndo(false);
        clearInterval(interval.current as unknown as number);
        interval.current = undefined;
    };

    const cancelDelayedFunction = () => {
        endUndo();
        props.handleCancel?.();
    };

    if (undo) {
        return (
            <Button
                {...rest}
                sx={{ ...rest.sx, display: 'inline-flex', justifyContent: 'flex-start' }}
                onClick={cancelDelayedFunction}
                startIcon={<RestoreIcon />}
                data-testid='undoButton'
            >
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} aria-hidden='true'>
                    <span>Undo</span>
                    <span>({timeRemaining})</span>
                </Box>
                <span className='sr-only'>Undo {timeRemaining} seconds remaining</span>
            </Button>
        );
    }

    return (
        <LoadingButton
            {...rest}
            startIcon={startIcon}
            onClick={awaitDelayedFunction}
            loading={loading}
            data-testid='loadingButton'
        >
            {props.children}
        </LoadingButton>
    );
};
