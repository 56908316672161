import { useEffect } from 'react';
import { useAppDispatch } from '../store/reducer/Hooks';
import { BREAKPOINT, setBreakpoint } from '../store/reducer/BreakpointReducer';

/**
 * This provider listens for screen size changes, then finds the current breakpoint using a mobile first approach
 * @see https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Responsive/Mobile_first
 * and then sets it in the breakpoint reducer.
 */
export default ({ children }: { children: JSX.Element }) => {
    const dispatch = useAppDispatch();

    /**
     * Listens for changes to the match state of each {@link queries}
     * The match state will change if the user resizes the screen so much that it is in a different query band
     * When the match state changes, the new state is dispatched to the breakpoint reducer
     *
     * This also sets the initial breakpoint of the app on first load
     *
     * No cleanup method is required as there are no listeners, just onChange handlers
     */
    useEffect(() => {
        queries.forEach(([breakpoint, query]) => {
            const mediaQueryList = window.matchMedia(query);
            if (mediaQueryList.matches) {
                dispatch(setBreakpoint(breakpoint));
            }

            mediaQueryList.onchange = (event: MediaQueryListEvent) => {
                if (event.matches) {
                    dispatch(setBreakpoint(breakpoint));
                }
            };
        });
    });

    return children;
};

/**
 * The breakpoint values are the default breakpoint values set by MUI
 * https://mui.com/material-ui/customization/breakpoints/#default-breakpoints
 */
const breakpoints = {
    [BREAKPOINT.xs]: { min: Number.MIN_SAFE_INTEGER, max: 599 },
    [BREAKPOINT.sm]: { min: 600, max: 899 },
    [BREAKPOINT.md]: { min: 900, max: 1199 },
    [BREAKPOINT.lg]: { min: 1200, max: 1535 },
    [BREAKPOINT.xl]: { min: 1536, max: Number.MAX_SAFE_INTEGER },
};

const queries: [BREAKPOINT, string][] = [
    [BREAKPOINT.xs, `screen and (max-width: ${breakpoints[BREAKPOINT.xs].max}px)`],
    [
        BREAKPOINT.sm,
        `screen and (min-width: ${breakpoints[BREAKPOINT.sm].min}px) and (max-width: ${breakpoints[BREAKPOINT.sm].max}px)`,
    ],
    [
        BREAKPOINT.md,
        `screen and (min-width: ${breakpoints[BREAKPOINT.md].min}px) and (max-width: ${breakpoints[BREAKPOINT.md].max}px)`,
    ],
    [
        BREAKPOINT.lg,
        `screen and (min-width: ${breakpoints[BREAKPOINT.lg].min}px) and (max-width: ${breakpoints[BREAKPOINT.lg].max}px)`,
    ],
    [BREAKPOINT.xl, `screen and (min-width: ${breakpoints[BREAKPOINT.xl].min}px)`],
];
