import { Theme } from '@mui/material';

export const customStyles = (theme: Theme) => ({
    headRow: {
        style: {
            border: '0px !important',
        },
    },
    rows: {
        style: {
            border: '0px !important',
        },
    },
    headCells: {
        style: {
            ...theme.typography.body2,
            fontWeight: 600,
        },
    },
    cells: {
        style: theme.typography.body2,
    },
});

export const initialDisplayAmount = 6;
