import axios from 'axios';
import { Invoice, Policy } from './invoice';

export type InvoiceRenewalPreview = {
    invoice: Invoice;
    renewalNotificationDays: number;
};

type RenewalInvoiceRequest = {
    number: string;
    portfolio: { policies: Policy[] };
};

export const previewRenewal = async (
    invoiceUuid: string,
    renewalInvoice: RenewalInvoiceRequest
): Promise<InvoiceRenewalPreview> => {
    return await axios
        .post(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/_preview?renewFromInvoiceUuid=${invoiceUuid}`,
            renewalInvoice
        )
        .then(({ data }) => data);
};

export const createRenewal = async (invoiceUuid: string, renewalInvoice: Invoice): Promise<InvoiceRenewalPreview> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal?renewFromInvoiceUuid=${invoiceUuid}`, renewalInvoice)
        .then(({ data }) => data);
};

export const previewEditRenewal = async (
    invoiceUuid: string,
    renewalInvoice: RenewalInvoiceRequest
): Promise<InvoiceRenewalPreview> => {
    return await axios
        .put(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/${invoiceUuid}/_preview`, renewalInvoice)
        .then(({ data }) => data);
};

export const editRenewal = async (invoiceUuid: string, renewalInvoice: Invoice): Promise<InvoiceRenewalPreview> => {
    return await axios
        .put(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/${invoiceUuid}`, renewalInvoice)
        .then(({ data }) => data);
};

export const voidRenewal = async (invoiceUuid: string, note: string): Promise<Invoice> => {
    return await axios
        .delete(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/${invoiceUuid}`, { data: { note } })
        .then(({ data }) => data);
};

export const sendRenewalToCustomer = async (invoiceUuid: string): Promise<Invoice> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/${invoiceUuid}/_action/send-to-customer`)
        .then(({ data }) => data);
};
