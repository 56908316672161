import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Chip, Fab, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { ExpectedPaymentStatus, InvoiceDetailsResponse, Loan } from '../../../../apis/invoice';
import { SetupArrangementRequest, setupArrangement } from '../../../../apis/variations';
import ExpectedPaymentStatusChip from '../../../../components/ExpectedPaymentStatusChip';
import { LoadingButton } from '../../../../components/LoadingButton';
import PaymentAmount from '../../../../components/PaymentAmount';
import StepCard from '../../../../components/StepCard';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { getConsolidatedExpectedPaymentsBeforeCancellation } from '../../common';
import { StyledGridFooterContainer, StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';
import { combineExpectedPaymentsAndArrangements, getInvoicePolicyDescription } from '../utils';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    setupArrangementRequest: SetupArrangementRequest;
    loan: Loan;
    setLoan: (loan: Loan) => void;
    details: InvoiceDetailsResponse;
};

export default function PromiseToPayDetailsStep({
    cancel,
    handleBack,
    setupArrangementRequest,
    loan,
    setLoan,
    details,
}: Readonly<Props>) {
    const theme = useTheme();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showMore, setShowMore] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const pendingExpectedPayments = useMemo(
        () =>
            getConsolidatedExpectedPaymentsBeforeCancellation(loan).filter(({ status }) =>
                pendingExpectedPaymentStatuses.includes(status)
            ),
        [loan.consolidatedExpectedPayments, loan.cancellationRequest]
    );

    const arrangementsOrExpectedPayments = combineExpectedPaymentsAndArrangements(
        pendingExpectedPayments,
        setupArrangementRequest?.arrangements
    );

    const onConfirmation = () => {
        setLoading(true);

        setupArrangement(details.invoice.uuid, setupArrangementRequest)
            .then(setLoan)
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setLoading(false));
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Promise to pay
            </Typography>
            <Typography variant='caption'>{`For ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>

            <Box>
                <Grid
                    container
                    sx={{
                        border: grey[400],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: theme.shape.borderRadius + 'px',
                    }}
                >
                    <StyledGridHeaderContainer>
                        <Grid item xs={6} pr={2}>
                            <Typography variant='subtitle2'>Scheduled date</Typography>
                        </Grid>
                        <Grid item xs={6} container direction='row' justifyContent='flex-end'>
                            <Typography variant='subtitle2'>Amount</Typography>
                        </Grid>
                    </StyledGridHeaderContainer>

                    {arrangementsOrExpectedPayments.map(
                        ({ dueDate, amount, paidAmount, status, isNewArrangement, key }, index) => {
                            if (!showMore && index >= defaultNumberOfPaymentsBeforeShowMore && !isNewArrangement) {
                                return null;
                            }

                            return (
                                <StyledGridItemContainer key={key}>
                                    <Grid item xs={6} pr={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography variant='caption' sx={{ mr: 1 }}>
                                            {moment(dueDate).format(DATE_FRIENDLY)}
                                        </Typography>
                                        {isNewArrangement && <Chip label='Promise to pay' color='primary' />}
                                        {status && <ExpectedPaymentStatusChip expectedPaymentStatus={status} />}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        container
                                        direction='row'
                                        justifyContent='flex-end'
                                        alignItems='center'
                                    >
                                        <PaymentAmount amount={amount} paidAmount={paidAmount} feeAmount={0} />
                                    </Grid>
                                </StyledGridItemContainer>
                            );
                        }
                    )}

                    {arrangementsOrExpectedPayments.length > defaultNumberOfPaymentsBeforeShowMore && (
                        <StyledGridFooterContainer>
                            <Grid item xs={12} pr={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Fab
                                        size='small'
                                        onClick={() => setShowMore(!showMore)}
                                        data-testid='show-more-scheduled-payments'
                                    >
                                        {showMore ? <ExpandLess /> : <ExpandMore />}
                                    </Fab>
                                </Box>
                            </Grid>
                        </StyledGridFooterContainer>
                    )}
                </Grid>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Note:
                    </Typography>
                    <Typography variant='caption' sx={{ ml: 1 }}>
                        {setupArrangementRequest.note}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: grey[100], p: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox id='confirm-payment-holiday' onChange={() => setConfirmed(!confirmed)} size='small' />
                    }
                    label='I confirm that the policyholder is aware of the payment arrangement setup and they have agreed to the new scheduled payment dates'
                />
            </Box>

            <Typography variant='caption'>
                The payment schedule will no longer be editable until the payment arrangement is completed or cancelled.
            </Typography>
            <Typography variant='caption'>{`${details.client.displayName} will receive an email notification with the arrangement details.`}</Typography>

            {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

            <Grid container>
                <Grid item xs={6}>
                    <Button onClick={handleBack} variant='outlined'>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        disabled={!confirmed}
                        onClick={onConfirmation}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        Confirm
                    </LoadingButton>
                </Grid>
            </Grid>
        </StepCard>
    );
}

const pendingExpectedPaymentStatuses = [ExpectedPaymentStatus.PENDING, ExpectedPaymentStatus.DEFERRED];
const defaultNumberOfPaymentsBeforeShowMore = 6;
