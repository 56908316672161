import { Alert } from '@mui/material';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { isSuccess } from '../../../hooks/useFetch';

export default function VoidedInvoiceAlert() {
    const loanState = useAppSelector((root) => root.LoanReducer).state;

    if (!isSuccess(loanState)) {
        return null;
    }

    const paidAmount = loanState.value.paymentTransactions
        .map((t) => t.amount)
        .reduce((accumulator, value) => accumulator + value, 0);

    return (
        <Alert color='info'>
            The invoice has been voided. You will need to contact the client and manually refund{' '}
            {currencyFormatter.format(Math.abs(paidAmount))} that has already been paid.
        </Alert>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: 2,
});
