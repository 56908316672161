import { yupResolver } from '@hookform/resolvers/yup';
import { CheckCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { Alert, Box, Button, FormControl, FormLabel, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Loan } from '../../../../apis/invoice';
import {
    Action,
    ActionStatus,
    ActorType,
    isArrangementSetupAction,
    isChangePaymentFrequencyAction,
    isDeferPaymentAction,
    isOnDemandPaymentAction,
    isRecordExternalTransactionAction,
} from '../../../../apis/variations';
import { LoadingButton } from '../../../../components/LoadingButton';
import StepCard from '../../../../components/StepCard';
import { FetchStateType } from '../../../../hooks/useFetch';
import { useAppDispatch } from '../../../../store/reducer/Hooks';
import { setLoanState } from '../../../../store/reducer/LoanReducer';
import AddTransactionActionDetails from './AddTransactionActionDetails';
import ArrangementSetupActionDetails from './ArrangementSetupActionDetails';
import ChangePaymentFrequencyActionDetails from './ChangePaymentFrequencyActionDetails';
import DeferPaymentActionDetails from './DeferPaymentActionDetails';
import OnDemandPaymentActionDetails from './OnDemandPaymentActionDetails';

type Props = {
    data: CancellableAction;
};

export default function ActionDetails({ data }: Readonly<Props>) {
    const dispatch = useAppDispatch();
    const { note, status, cancellable, onCancel, actorType } = data;
    const [cancelInitiated, setCancelInitiated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm<CancelActionFields>({
        resolver: yupResolver(
            yup.object({
                note: yup.string().required('Reason required'),
            })
        ),
    });

    const onSubmit: SubmitHandler<CancelActionFields> = ({ note }) => {
        setLoading(true);
        setErrorMsg(undefined);

        onCancel(note)
            .then((loan) => dispatch(setLoanState({ value: loan, type: FetchStateType.SUCCESS })))
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setLoading(false));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginY: 2 }}>
            {isArrangementSetupAction(data) && <ArrangementSetupActionDetails data={data} />}
            {isDeferPaymentAction(data) && <DeferPaymentActionDetails data={data} />}
            {isChangePaymentFrequencyAction(data) && <ChangePaymentFrequencyActionDetails data={data} />}
            {isRecordExternalTransactionAction(data) && <AddTransactionActionDetails data={data} />}
            {isOnDemandPaymentAction(data) && <OnDemandPaymentActionDetails data={data} />}

            <Box>
                <Typography variant='subtitle2' component='span'>
                    Note:
                </Typography>
                <Typography variant='caption' sx={{ ml: 1 }}>
                    {actorType === ActorType.CLIENT_USER ? 'Client initiated' : note}
                </Typography>
            </Box>

            {cancellable && status === ActionStatus.ACTIVE && (
                <form onSubmit={handleSubmit(onSubmit, console.log)}>
                    <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={10} container direction='row' justifyContent='flex-end' alignItems='center'>
                            {!cancelInitiated && (
                                <Button
                                    onClick={() => setCancelInitiated(true)}
                                    startIcon={<RemoveCircleOutline />}
                                    variant='contained'
                                    color='error'
                                >
                                    Cancel
                                </Button>
                            )}
                            {cancelInitiated && (
                                <StepCard sx={{ width: '100%' }}>
                                    <FormControl required>
                                        <FormLabel htmlFor='note' required={false}>
                                            Reason to cancel
                                        </FormLabel>
                                        <Controller
                                            name='note'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    {...register('note')}
                                                    data-testid={'note'}
                                                    size='small'
                                                    multiline
                                                    disabled={loading}
                                                    rows={4}
                                                    inputProps={{ maxLength: 1024 }}
                                                    error={!!errors?.note}
                                                    helperText={errors?.note?.message}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                    {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}
                                    <Grid item container>
                                        <Grid item xs={6}>
                                            <Button onClick={() => setCancelInitiated(false)} variant='text'>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            direction='row'
                                            justifyContent='flex-end'
                                            alignItems='center'
                                        >
                                            <LoadingButton
                                                loading={loading}
                                                type='submit'
                                                startIcon={<CheckCircleOutline />}
                                                variant='contained'
                                                color='error'
                                            >
                                                Confirm
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                                </StepCard>
                            )}
                        </Grid>
                    </Grid>
                </form>
            )}
        </Box>
    );
}

export type CancellableAction = Action & {
    onCancel: (note: string) => Promise<Loan>;
};

type CancelActionFields = {
    note: string;
};
