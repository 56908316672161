import { Alert, Box, Button, Checkbox, Collapse, FormControlLabel, styled, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { Client } from '../../../apis/clients';
import { Invoice, InvoiceDetailsResponse, Policy } from '../../../apis/invoice';
import { putQuote, PolicyVersionPreview, PolicyVersionRequest, QuoteStatus } from '../../../apis/quotes';
import { LoadingButton } from '../../LoadingButton';
import StepCard from '../../StepCard';
import Actions from './Actions';
import ProRataAdjustmentRow from './ProRataAdjustmentRow';
import { ActionType } from '../common';
import { cloneDeep } from 'lodash';

type Props = {
    policy: Policy;
    goBack: () => void;
    updateInvoice: (actionType: ActionType, invoiceDetails: InvoiceDetailsResponse) => void;
    policyVersionPreview: PolicyVersionPreview;
    policyVersionRequest: PolicyVersionRequest;
    client: Client;
    invoice: Invoice;
};

const confirmError = 'You must confirm the policy holder is aware of this endorsement.';

export default function ReviewQuoteStep({
    goBack,
    policyVersionPreview,
    policy,
    client,
    updateInvoice,
    policyVersionRequest,
    invoice,
}: Readonly<Props>) {
    const [selectedAction, setSelectedAction] = useState<ActionType>(ActionType.SEND_QUOTE);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);
    const alreadyQuoted = policyVersionRequest?.quote?.status === QuoteStatus.QUOTED;

    const submitAction = () => {
        if (!confirmed && selectedAction === ActionType.CREATE_ENDORSEMENT) {
            setErrorMsg(confirmError);
            return;
        }

        setLoading(true);
        setErrorMsg(undefined);
        const request = cloneDeep(policyVersionRequest);

        switch (selectedAction) {
            case ActionType.SEND_QUOTE:
                request.quote.status = QuoteStatus.QUOTED;
                break;
            case ActionType.CREATE_ENDORSEMENT:
                request.quote.status = QuoteStatus.ACCEPTED;
                break;
            case ActionType.SAVE_DRAFT:
                request.quote.status = QuoteStatus.DRAFT;
                break;
        }

        putQuote(invoice.uuid, policy.uuid, request)
            .then((invoiceUpdate) => updateInvoice(selectedAction, invoiceUpdate))
            .catch(() => {
                setErrorMsg('Something went wrong, please try again.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCheckConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorMsg(event.target.checked ? undefined : confirmError);
        setConfirmed(event.target.checked);
    };

    const handleSelectAction = (actionType: ActionType) => {
        setSelectedAction(actionType);
        if (actionType !== ActionType.CREATE_ENDORSEMENT) {
            setErrorMsg(undefined);
            setConfirmed(false);
        }
    };

    return (
        <StepCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Typography variant='h5'>Review</Typography>
                <Box>
                    <Typography variant='h2' component='p'>
                        Policy premium impact
                    </Typography>
                    <DescriptionTable>
                        <DescriptionHeader>
                            <Typography variant='subtitle2'>Description</Typography>
                            <Typography variant='subtitle2'>Amount</Typography>
                        </DescriptionHeader>
                        <DescriptionRow>
                            <Typography variant='caption'>Current annual premium</Typography>
                            <Typography variant='caption'>
                                {currencyFormatter.format(policy.currentPolicyVersion.premiums)}
                            </Typography>
                        </DescriptionRow>
                        <DescriptionRow>
                            <Typography variant='caption'>New annual premium</Typography>
                            <Typography variant='caption'>
                                {currencyFormatter.format(policyVersionPreview.totalAnnualPremium)}
                            </Typography>
                        </DescriptionRow>
                        <ProRataAdjustmentRow policyVersionPreview={policyVersionPreview} />
                    </DescriptionTable>
                </Box>
                <Typography variant='caption'>
                    The increase amount will be spread over the remaining payments. Once the endorsement is confirmed,
                    the updated payment schedule will be available on the invoice page and the policyholder can view it
                    in the client portal.
                </Typography>
                <Box>
                    <Typography variant='h2' component='p'>
                        Select next action
                    </Typography>
                    <Actions
                        selectedAction={selectedAction}
                        setSelectedAction={handleSelectAction}
                        client={client}
                        alreadyQuoted={alreadyQuoted}
                    />
                    <Collapse in={selectedAction === ActionType.CREATE_ENDORSEMENT}>
                        <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: grey[100], p: 2, mt: 4 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id='confirm-endorsement'
                                        onChange={handleCheckConfirmation}
                                        size='small'
                                        checked={confirmed}
                                    />
                                }
                                label='I confirm that the policyholder is aware of the endorsement adjustment and they have agreed to the new premium amount.'
                            />
                        </Box>
                    </Collapse>
                </Box>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        {!alreadyQuoted && (
                            <Button onClick={goBack} variant='text' color='primary' sx={{ mr: 1 }}>
                                Back
                            </Button>
                        )}
                    </Box>
                    <LoadingButton variant='contained' onClick={submitAction} loading={loading}>
                        {submitMessage(selectedAction, alreadyQuoted)}
                    </LoadingButton>
                </Box>
            </Box>
        </StepCard>
    );
}

const submitMessage = (actionType: ActionType, alreadyQuoted: boolean) => {
    switch (actionType) {
        case ActionType.SEND_QUOTE:
            return alreadyQuoted ? 'Resend quote' : 'Send quote';
        case ActionType.CREATE_ENDORSEMENT:
            return 'Create endorsement';
        case ActionType.SAVE_DRAFT:
            return 'Save as draft';
    }
};

const DescriptionTable = styled(Box)(({ theme }) => ({
    border: grey[400],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius + 'px',
}));

const DescriptionHeader = styled(Box)(({ theme }) => ({
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    backgroundColor: grey[200],
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

const DescriptionRow = styled(Box)(({ theme }) => ({
    border: grey[400],
    borderWidth: '0',
    borderTopWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
}));

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
