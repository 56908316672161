import { CreditCardOutlined, ReceiptLongOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { InvoiceDetailsResponse } from '../../../../apis/invoice';
import ChoiceCard from '../../../../components/ChoiceCard';
import StepCard from '../../../../components/StepCard';
import { getInvoicePolicyDescription } from '../utils';
import { PaymentType } from './CreateAddPaymentSteps';

type Props = {
    cancel: () => void;
    configureType: PaymentType;
    setConfigureType: (configureType: PaymentType) => void;
    details: InvoiceDetailsResponse;
    disableOndemandPayment: boolean;
    disableOndemandPaymentHints: string;
};

const PAYMENT_TYPE_CARD_HEADER_HEIGHT = 52;

export default function AddPaymentTypeStep({
    cancel,
    configureType,
    setConfigureType,
    details,
    disableOndemandPayment,
    disableOndemandPaymentHints,
}: Readonly<Props>) {
    const theme = useTheme();
    const [chosenConfigureType, setChosenConfigureType] = useState<PaymentType>(PaymentType.UNDEFINED);

    useEffect(() => {
        setChosenConfigureType(configureType);
    }, [configureType]);

    const onNext = () => {
        setConfigureType(chosenConfigureType);
    };
    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Add payment
            </Typography>
            <Typography variant='caption'>{`Select a payment type for ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <ChoiceCard
                        selected={chosenConfigureType === PaymentType.ONDEMAND_PAYMENT}
                        handleSelected={() => setChosenConfigureType(PaymentType.ONDEMAND_PAYMENT)}
                        disabled={disableOndemandPayment}
                        disabledHints={disableOndemandPaymentHints}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: 2,
                                }}
                            >
                                <Avatar
                                    sx={{
                                        bgcolor: disableOndemandPayment
                                            ? theme.palette.black[10]
                                            : theme.palette.primary.light,
                                        width: 64,
                                        height: 64,
                                    }}
                                >
                                    <CreditCardOutlined
                                        color={disableOndemandPayment ? 'disabled' : 'primary'}
                                        sx={{ fontSize: 32 }}
                                    />
                                </Avatar>
                                <Box>
                                    <Typography
                                        variant='h6'
                                        component='h2'
                                        color={
                                            disableOndemandPayment ? theme.palette.black[40] : theme.palette.black[100]
                                        }
                                        style={{
                                            minHeight: PAYMENT_TYPE_CARD_HEADER_HEIGHT,
                                            textAlign: 'center',
                                            alignContent: 'center',
                                            marginBottom: 3,
                                        }}
                                    >
                                        Charge client's payment method
                                    </Typography>
                                    <Typography textAlign='center' variant='subtitle1'>
                                        Create an on-demand payment to charge the client's existing payment method.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </ChoiceCard>
                </Grid>
                <Grid item xs={4}>
                    <ChoiceCard
                        selected={chosenConfigureType === PaymentType.EXTERNAL_TRANSACTION}
                        handleSelected={() => setChosenConfigureType(PaymentType.EXTERNAL_TRANSACTION)}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                                <Avatar
                                    sx={{
                                        bgcolor: theme.palette.primary.light,
                                        width: 64,
                                        height: 64,
                                    }}
                                >
                                    <ReceiptLongOutlined color='primary' sx={{ fontSize: 32 }} />
                                </Avatar>
                                <Box>
                                    <Typography
                                        variant='h6'
                                        component='h2'
                                        color={theme.palette.black[100]}
                                        style={{
                                            minHeight: PAYMENT_TYPE_CARD_HEADER_HEIGHT,
                                            textAlign: 'center',
                                            alignContent: 'center',
                                            marginBottom: 3,
                                        }}
                                    >
                                        External transaction
                                    </Typography>
                                    <Typography textAlign='center' variant='subtitle1'>
                                        Add a record of a payment made externally. The client will not be charged
                                        through Simfuni.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </ChoiceCard>
                </Grid>
                <Grid item xs={2} />
            </Grid>

            <Grid container>
                <Grid item xs={6} />
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <Button
                        disabled={chosenConfigureType === PaymentType.UNDEFINED}
                        onClick={onNext}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </StepCard>
    );
}
