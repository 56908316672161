import { useCallback } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { getInsuredReport, Report } from '../../apis/reports';
import { FetchWrapper } from '../../components/FetchWrapper';
import Totals from './Totals';
import ClientReportDisplay from './ClientReportDisplay';
import { Grid } from '@mui/material';
import ClientTypeReportDisplay from './ClientTypeReportDisplay';

interface ClientReportProps {
    startDate: string;
    endDate: string;
}

export default (props: ClientReportProps) => {
    const { startDate, endDate } = props;

    const state = useFetch(async () => getInsuredReport(startDate, endDate), [startDate, endDate]);

    const Chart = useCallback(({ data }: { data: Report }) => {
        return (
            <>
                <Totals totals={data.totals} />

                <Grid container spacing={2}>
                    <Grid item lg={6} md={12}>
                        <ClientReportDisplay reportData={data.data[0]} />
                    </Grid>
                    <Grid item lg={6} md={12}>
                        <ClientTypeReportDisplay reportData={data.data[1]} />
                    </Grid>
                </Grid>
            </>
        );
    }, []);

    return <FetchWrapper state={state} SuccessComponent={Chart} />;
};
