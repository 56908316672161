import { createSlice } from '@reduxjs/toolkit';
import { ClassCode } from '../../apis/invoiceConfig';
import { FetchState, pendingState } from '../../hooks/useFetch';
import { Action } from './Hooks';

const initialState: { classCodesState: FetchState<ClassCode[]>; providersState: FetchState<string[]> } = {
    classCodesState: pendingState,
    providersState: pendingState,
};

export const sellerConfigSlice = createSlice({
    name: 'sellerConfig',
    initialState,
    reducers: {
        setClassCodesState: (state, action: Action<FetchState<ClassCode[]>>) => {
            state.classCodesState = action.payload;
        },
        setProvidersState: (state, action: Action<FetchState<string[]>>) => {
            state.providersState = action.payload;
        },
        clearSellerConfigSate: (state) => {
            state.classCodesState = pendingState;
            state.providersState = pendingState;
        },
    },
});

export const { setClassCodesState, clearSellerConfigSate, setProvidersState } = sellerConfigSlice.actions;

export default sellerConfigSlice.reducer;
