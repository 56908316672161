import InvoiceList from '../components/InvoiceList';
import {
    Box,
    Breadcrumbs,
    Button,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { AddCircleOutline, Search } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useFetch } from '../hooks/useFetch';
import { InvoiceStatus, listInvoices } from '../apis/invoice';
import { defaultPageSize } from '../apis/common';
import { useAppSelector } from '../store/reducer/Hooks';

const InvoiceSearch = () => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { invoiceUpdateAllowed } = permissions;

    const [searchValue, setSearchValue] = useState<string | undefined>('');
    const [statuses, setStatuses] = useState<InvoiceStatus[]>([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
    const searchBar = useRef<HTMLInputElement>(null);

    const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        const newValue = searchBar.current?.value;
        if (e.key === 'Enter' && newValue !== searchValue) {
            setSearchValue(newValue);
            resetPage();
        }
    };

    const state = useFetch(
        () => listInvoices({ searchValue, statuses, page: page - 1, pageSize: rowsPerPage }),
        [searchValue, statuses, page, rowsPerPage]
    );

    const handleStatusUpdate = (_event: React.MouseEvent<HTMLElement>, newStatuses: InvoiceStatus[]) => {
        setStatuses(newStatuses);
        resetPage();
    };

    const resetPage = () => {
        setPage(1);
    };

    return (
        <>
            <Grid container>
                <Grid item sm={2}>
                    <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
                        <Box>Invoices</Box>
                    </Breadcrumbs>
                </Grid>
                {invoiceUpdateAllowed && (
                    <Grid item sm={10} style={{ textAlign: 'right' }}>
                        <Button variant='contained' startIcon={<AddCircleOutline />} component={Link} to='new'>
                            New invoice
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Paper variant={'flat'}>
                <Box sx={{ m: '15px' }}>
                    <Grid container>
                        <Grid item sm={8}>
                            <ToggleButtonGroup
                                data-testid='statusFilter'
                                color='info'
                                value={statuses}
                                onChange={handleStatusUpdate}
                                size='small'
                            >
                                <ToggleButton value={InvoiceStatus.PAID}>Paid</ToggleButton>
                                <ToggleButton value={InvoiceStatus.IN_PROGRESS}>In progress</ToggleButton>
                                <ToggleButton value={InvoiceStatus.ATTENTION}>Declined</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                placeholder='Search by invoice #'
                                onKeyDown={handleKeyPress}
                                fullWidth
                                inputRef={searchBar}
                                size='small'
                                style={{ borderRadius: '8px !important' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <InvoiceList
                    state={state}
                    changePage={setPage}
                    changeRowsPerPage={setRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                />
            </Paper>
        </>
    );
};

export default InvoiceSearch;
