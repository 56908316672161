import { createSlice } from '@reduxjs/toolkit';
import { Loan } from '../../apis/invoice';
import { FetchState, pendingState } from '../../hooks/useFetch';

type LoanAction = {
    type: string;
    payload: FetchState<Loan>;
};

const initialState: { state: FetchState<Loan> } = {
    state: pendingState,
};

export const loanSlice = createSlice({
    name: 'loan',
    initialState,
    reducers: {
        setLoanState: (state, action: LoanAction) => {
            state.state = action.payload;
        },
        clearLoanState: (state) => {
            state.state = pendingState;
        },
    },
});

export const { setLoanState, clearLoanState } = loanSlice.actions;

export default loanSlice.reducer;
