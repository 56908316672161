import { Box, Button, Typography } from '@mui/material';
import StepCard from '../../../../components/StepCard';
import { CheckCircle } from '@mui/icons-material';
import { PaymentMethodType } from '../../../../apis/clientLead';

type Props = {
    paymentMethodType?: PaymentMethodType;
    close: () => void;
};

export default function OndemandPaymentDoneStep({ close, paymentMethodType }: Readonly<Props>) {
    return (
        <StepCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CheckCircle color='success' fontSize='large' />
                    <Typography variant='h5' component='h2'>
                        Payment added
                    </Typography>
                </Box>
                <Typography textAlign='center'>
                    {paymentMethodType === PaymentMethodType.CREDIT_CARD
                        ? `The payment has been successfully made. You can view more details under the payment variations section.`
                        : `The payment will be deducted within the next 24 hours from the client's bank account. You can view more details under the payment variations section.`}
                </Typography>
                <Button onClick={close} variant='contained'>
                    Close
                </Button>
            </Box>
        </StepCard>
    );
}
