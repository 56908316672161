import { useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Avatar, Box, Grid, Paper, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { HighlightOff, Send, TaskAlt, WarningAmberOutlined } from '@mui/icons-material';
import { FetchState } from '../hooks/useFetch';
import { FetchWrapper } from './FetchWrapper';
import { BrokerDashboardData } from '../apis/invoice';
import { DATE_FRIENDLY } from '../util/dateUtils';

type DashboardProps = {
    state: FetchState<BrokerDashboardData>;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const MIN_Y_AXIS_VALUE = 10;

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Invoices last 7 days',
        },
    },
};

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: 0,
});

const Dashboard = ({ state }: DashboardProps) => {
    const SuccessComponent = useCallback(({ data }: { data: BrokerDashboardData }) => {
        const yAxisMaxValue = _.max([MIN_Y_AXIS_VALUE, ...data.invoicesIssued, ...data.invoicesPaid]);
        const combinedOptions = {
            ...options,
            scales: {
                y: {
                    min: 0,
                    max: yAxisMaxValue,
                },
            },
        };

        const chartData = {
            labels: _.map(data.invoicesDates, (invoiceDate) => moment(invoiceDate).format(DATE_FRIENDLY)),
            datasets: [
                {
                    label: 'Issued',
                    data: data.invoicesIssued,
                    borderColor: '#00063f',
                    backgroundColor: '#00063f',
                },
                {
                    label: 'Paid',
                    data: data.invoicesPaid,
                    borderColor: '#2e7d32',
                    backgroundColor: '#2e7d32',
                },
            ],
        };

        return (
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <Paper
                                variant='flat'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100px',
                                }}
                            >
                                <Box sx={{ width: '50%', ml: 3 }}>
                                    <Typography variant='body2'>Issued</Typography>
                                    <Typography variant='h2' data-testid='dashboard-issued-volume'>
                                        {data.invoicesIssuedVolume}
                                    </Typography>
                                </Box>
                                <Box sx={{ float: 'right', ml: 3 }}>
                                    <Avatar
                                        sx={{
                                            backgroundColor: '#E6F7FF',
                                            color: '#048ECA',
                                            height: '64px',
                                            width: '64px',
                                            textAlign: 'center',
                                            transform: 'rotate(-45deg)',
                                        }}
                                    >
                                        <Send />
                                    </Avatar>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item sm={6}>
                            <Paper
                                variant='flat'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100px',
                                }}
                            >
                                <Box sx={{ width: '50%', ml: 3 }}>
                                    <Typography variant='body2'>Declined</Typography>
                                    <Typography variant='h2' data-testid='dashboard-at-risk-volume'>
                                        {data.invoicesAtRiskVolume}
                                    </Typography>
                                </Box>
                                <Box sx={{ float: 'right', ml: 3 }}>
                                    <Avatar
                                        sx={{
                                            backgroundColor: '#FEE4E2',
                                            color: '#D92D20',
                                            height: '64px',
                                            width: '64px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <HighlightOff />
                                    </Avatar>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item sm={6}>
                            <Paper
                                variant='flat'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100px',
                                }}
                            >
                                <Box sx={{ width: '50%', ml: 3 }}>
                                    <Typography variant='body2'>Paid</Typography>
                                    <Typography variant='h2' data-testid='dashboard-paid-value'>
                                        {currencyFormatter.format(data.invoicesPaidValue)}{' '}
                                    </Typography>
                                </Box>
                                <Box sx={{ float: 'right', ml: 3 }}>
                                    <Avatar
                                        sx={{
                                            backgroundColor: '#ECFDF3',
                                            color: '#027A48',
                                            height: '64px',
                                            width: '64px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <TaskAlt />
                                    </Avatar>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item sm={6}>
                            <Paper
                                variant='flat'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100px',
                                }}
                            >
                                <Box sx={{ width: '50%', ml: 3 }}>
                                    <Typography variant='body2'>Outstanding</Typography>
                                    <Typography variant='h2' data-testid='dashboard-in-progress-value'>
                                        {currencyFormatter.format(data.invoicesInProgressValue)}
                                    </Typography>
                                </Box>
                                <Box sx={{ float: 'right', ml: 3 }}>
                                    <Avatar
                                        sx={{
                                            backgroundColor: '#FEF0C7',
                                            color: '#DC6803',
                                            height: '64px',
                                            width: '64px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <WarningAmberOutlined />
                                    </Avatar>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={6}>
                    <Paper variant='flat' sx={{ p: 2 }}>
                        <Line
                            options={combinedOptions}
                            data={chartData}
                            style={{ maxHeight: '184px', width: '100%' }}
                            data-testid='dashboard-trend-chart'
                        />
                    </Paper>
                </Grid>
            </Grid>
        );
    }, []);

    return <FetchWrapper state={state} SuccessComponent={SuccessComponent} />;
};

export default Dashboard;
