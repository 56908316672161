import { Grid, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function EditingInExpandedView() {
    return (
        <Grid item xs={16} md={8} lg={7} display='flex' justifyContent='flex-end'>
            <Paper
                variant='outlined'
                sx={{
                    p: 1,
                    bgcolor: grey[50],
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '80px',
                    maxWidth: { md: '380px' },
                }}
            >
                <Typography variant='body2'>Editing in expanded view</Typography>
            </Paper>
        </Grid>
    );
}
