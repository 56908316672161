import { Grid, GridProps, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const StyledGridHeaderContainer = styled((props: GridProps) => <Grid container item xs={12} p={2} {...props} />)(
    ({ theme }) => ({
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
        backgroundColor: grey[200],
    })
);

export const StyledGridItemContainer = styled((props: GridProps) => <Grid container item xs={12} p={2} {...props} />)(
    () => ({
        border: grey[400],
        borderWidth: '0',
        borderTopWidth: '1px',
        borderStyle: 'solid',
    })
);

export const StyledGridFooterContainer = styled((props: GridProps) => <Grid container item xs={12} p={2} {...props} />)(
    ({ theme }) => ({
        borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
        backgroundColor: grey[200],
    })
);
