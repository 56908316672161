import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Fab, FormControlLabel, Grid, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { ExpectedPaymentStatus, InvoiceDetailsResponse, Loan } from '../../../../apis/invoice';
import { DeferPaymentRequest, deferPayment } from '../../../../apis/variations';
import { LoadingButton } from '../../../../components/LoadingButton';
import PaymentAmount from '../../../../components/PaymentAmount';
import StepCard from '../../../../components/StepCard';
import { DATE_FRIENDLY } from '../../../../util/dateUtils';
import { StyledGridFooterContainer, StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';
import { PaymentHolidyFields as PaymentHolidayFields } from '../types';
import { getInvoicePolicyDescription } from '../utils';
import DetailedExpectedPaymentStatusChips from '../../../../components/DetailedExpectedPaymentStatusChips';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    paymentHolidayFields: PaymentHolidayFields;
    loan: Loan;
    setLoan: (loan: Loan) => void;
    details: InvoiceDetailsResponse;
};

export default function PaymentHolidayReviewStep({
    cancel,
    handleBack,
    paymentHolidayFields,
    setLoan,
    details,
}: Readonly<Props>) {
    const theme = useTheme();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [showMore, setShowMore] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const sortedExpectedPayments = sortBy(
        paymentHolidayFields.expectedPayments,
        (ep) => `${ep.newDueDate ?? ep.dueDate}-${ep.dueDate}`
    );

    const onConfirmation = () => {
        setLoading(true);

        const deferPaymentRequest: DeferPaymentRequest = cloneDeep({
            ...paymentHolidayFields,
            expectedPayments: paymentHolidayFields.expectedPayments.filter(
                (expectedPayment) => !isEmpty(expectedPayment.newDueDate)
            ),
        } as DeferPaymentRequest);

        deferPayment(details.invoice.uuid, deferPaymentRequest)
            .then(setLoan)
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setLoading(false));
    };

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Review updated schedule
            </Typography>
            <Typography variant='caption'>{`For ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>

            <Box>
                <Grid
                    container
                    sx={{
                        border: grey[400],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: theme.shape.borderRadius + 'px',
                    }}
                >
                    <StyledGridHeaderContainer>
                        <Grid item xs={6} pr={2}>
                            <Typography variant='subtitle2'>Scheduled payment date</Typography>
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item xs={3} container direction='row' justifyContent='flex-end'>
                            <Typography variant='subtitle2'>Amount</Typography>
                        </Grid>
                    </StyledGridHeaderContainer>

                    {sortedExpectedPayments.map(
                        (
                            { uuid, dueDate, newDueDate, amount, paidAmount, feeAmount, status, expectedPayments },
                            index
                        ) => {
                            const alignCentreSx = {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            };
                            const edited = !isEmpty(newDueDate);

                            if (!showMore && index >= defaultNumberOfPaymentsBeforeShowMore && !edited) {
                                return <></>;
                            }

                            return (
                                <StyledGridItemContainer key={uuid}>
                                    <Grid item xs={6} pr={2} sx={{ display: 'flex', flexDirection: 'row' }}>
                                        {!edited && (
                                            <Typography variant='caption'>
                                                {moment(dueDate).format(DATE_FRIENDLY)}
                                            </Typography>
                                        )}
                                        {edited && (
                                            <>
                                                <Typography
                                                    variant='caption'
                                                    sx={{ textDecorationLine: 'line-through' }}
                                                >
                                                    {moment(dueDate).format(DATE_FRIENDLY)}
                                                </Typography>
                                                <Typography variant='caption' sx={{ ml: 1 }}>
                                                    {moment(newDueDate).format(DATE_FRIENDLY)}
                                                </Typography>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={3} pr={2} sx={alignCentreSx}>
                                        <DetailedExpectedPaymentStatusChips
                                            expectedPaymentStatus={edited ? ExpectedPaymentStatus.DEFERRED : status}
                                            expectedPayments={edited ? [] : expectedPayments}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        container
                                        direction='row'
                                        justifyContent='flex-end'
                                        alignItems='center'
                                    >
                                        <PaymentAmount amount={amount} paidAmount={paidAmount} feeAmount={feeAmount} />
                                    </Grid>
                                </StyledGridItemContainer>
                            );
                        }
                    )}

                    {paymentHolidayFields.expectedPayments.length > defaultNumberOfPaymentsBeforeShowMore && (
                        <StyledGridFooterContainer>
                            <Grid item xs={12} pr={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Fab
                                        size='small'
                                        onClick={() => setShowMore(!showMore)}
                                        data-testid='show-more-scheduled-payments'
                                    >
                                        {showMore ? <ExpandLess /> : <ExpandMore />}
                                    </Fab>
                                </Box>
                            </Grid>
                        </StyledGridFooterContainer>
                    )}
                </Grid>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                    <Typography variant='subtitle2' component='span'>
                        Note:
                    </Typography>
                    <Typography variant='caption' sx={{ ml: 1 }}>
                        {paymentHolidayFields.note}
                    </Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: grey[100], p: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox id='confirm-payment-holiday' onChange={() => setConfirmed(!confirmed)} size='small' />
                    }
                    label='I confirm that the policyholder is aware of the payment holiday setup and they have agreed to the new scheduled payment dates.'
                />
            </Box>

            <Typography variant='caption'>{`${details.client.displayName} will receive an email notification with the arrangement details.`}</Typography>

            {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

            <Grid container>
                <Grid item xs={6}>
                    <Button onClick={handleBack} variant='outlined'>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        disabled={!confirmed}
                        onClick={onConfirmation}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        Confirm
                    </LoadingButton>
                </Grid>
            </Grid>
        </StepCard>
    );
}

const defaultNumberOfPaymentsBeforeShowMore = 6;
