import { Box } from '@mui/material';
import { InvoiceDetailsResponse } from '../../../apis/invoice';
import PolicyDetail from './PolicyDetail';
import { SellerProduct } from '../../../apis/sellerProduct';

export type PoliciesProps = {
    invoiceDetails: InvoiceDetailsResponse;
};

type Props = PoliciesProps & {
    data: SellerProduct;
};

export default function Policies({ invoiceDetails, data: sellerProduct }: Readonly<Props>) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {invoiceDetails.invoice.portfolio.policies.map((policy) => (
                    <PolicyDetail
                        key={policy.uuid}
                        policy={policy}
                        sellerProduct={sellerProduct}
                        invoiceDetails={invoiceDetails}
                        sellerProductLogo={invoiceDetails.invoice.productLogoUrl}
                    />
                ))}
            </Box>
        </Box>
    );
}
