import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { extractPermissions, noPermissions, Permissions } from '../../helpers/permission';
import { WritableDraft } from 'immer/dist/internal';
import { SESSION_TIME } from '../../components/SessionTimer';

export type UserSessionState = {
    userSession?: CognitoUserSession;
    permissions: Permissions;
    expirationDate?: Date;
};

export const userSessionSlice = createSlice({
    name: 'user-session',
    initialState: {
        permissions: noPermissions,
    } as UserSessionState,
    reducers: {
        setUserSession: (state: WritableDraft<UserSessionState>, action: PayloadAction<CognitoUserSession>) => {
            state.userSession = action.payload;
            state.permissions = extractPermissions(action.payload);
        },
        updateExpirationDate: (state: WritableDraft<UserSessionState>) => {
            const newDate = new Date(new Date().getTime() + SESSION_TIME);
            if (state.expirationDate?.getMinutes() !== newDate.getMinutes()) {
                state.expirationDate = newDate;
            }
        },
        clearUserSession: (state) => {
            state.userSession = undefined;
            state.permissions = noPermissions;
            state.expirationDate = undefined;
        },
    },
});

export const { setUserSession, clearUserSession, updateExpirationDate } = userSessionSlice.actions;

export default userSessionSlice.reducer;
