import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { Control, Controller, FieldErrors, FieldPath, UseFormRegister, useWatch } from 'react-hook-form';
import CovertNumberTextField from '../../CovertNumberTextField';
import { FormDetail, QuoteFields } from '../common';

type Props = {
    control: Control<QuoteFields>;
    register: UseFormRegister<QuoteFields>;
    errors: FieldErrors<QuoteFields>;
    itemIndex: number;
    handleQuestionnaireUpdate: () => void;
};

export default function ItemFields({
    control,
    itemIndex,
    register,
    errors,
    handleQuestionnaireUpdate,
}: Readonly<Props>) {
    const questionnaire = useWatch({
        control,
        name: `items.${itemIndex}.questionnaire`,
    });

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl fullWidth>
                <FormLabel htmlFor={`items.${itemIndex}.name`}>Name</FormLabel>
                <Controller
                    control={control}
                    name={`items.${itemIndex}.name`}
                    defaultValue={''}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            {...register(`items.${itemIndex}.name`)}
                            id={`items.${itemIndex}.name`}
                            fullWidth
                            autoComplete='no'
                            size='small'
                            error={!!errors.items?.[itemIndex]?.name}
                            helperText={errors.items?.[itemIndex]?.name?.message}
                        />
                    )}
                />
            </FormControl>
            {Object.keys(questionnaire).map((name) => (
                <QuestionField
                    key={name}
                    name={name}
                    question={questionnaire[name]}
                    itemIndex={itemIndex}
                    control={control}
                    register={register}
                    handleQuestionnaireUpdate={handleQuestionnaireUpdate}
                />
            ))}
        </Box>
    );
}

type QuestionFieldProps = {
    name: string;
    question: FormDetail;
    itemIndex: number;
    control: Control<QuoteFields>;
    register: UseFormRegister<QuoteFields>;
    handleQuestionnaireUpdate: () => void;
};

function QuestionField({
    name,
    question,
    itemIndex,
    control,
    register,
    handleQuestionnaireUpdate,
}: Readonly<QuestionFieldProps>) {
    const fieldName: FieldPath<QuoteFields> = `items.${itemIndex}.questionnaire.${name}.value`;

    useEffect(() => {
        if (question.errorMsg) {
            handleQuestionnaireUpdate();
        }
    }, [question.value]);

    if (question.type === 'number') {
        return (
            <FormControl fullWidth required={question.required}>
                <FormLabel htmlFor={fieldName}>{question.label}</FormLabel>
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={'' as unknown as undefined}
                    render={({ field }) => (
                        <CovertNumberTextField
                            {...field}
                            {...register(fieldName)}
                            id={fieldName}
                            fullWidth
                            autoComplete='no'
                            size='small'
                            error={!!question.errorMsg}
                            helperText={question.errorMsg ?? question.description}
                            placeholder={question.placeholder}
                            className={question.className}
                        />
                    )}
                />
            </FormControl>
        );
    }

    return (
        <FormControl fullWidth error={!!question.errorMsg} variant='standard' required={question.required}>
            <FormLabel component='legend' htmlFor={fieldName}>
                {question.label}
            </FormLabel>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={'' as unknown as FormDetail}
                render={({ field }) => (
                    <RadioGroup {...field} id={fieldName}>
                        {question.values.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio size='small' />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                )}
            />
            {question.errorMsg && <FormHelperText>{question.errorMsg}</FormHelperText>}
        </FormControl>
    );
}
