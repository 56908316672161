import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import BreakpointReducer from './reducer/BreakpointReducer';
import ClientLeadReducer from './reducer/ClientLeadReducer';
import ConfigReducer from './reducer/ConfigReducer';
import FederatedSsoConfigurationReducer from './reducer/FederatedSsoConfigurationReducer';
import InvoiceDetailsReducer from './reducer/InvoiceDetailsReducer';
import LoanReducer from './reducer/LoanReducer';
import SellerConfigReducer from './reducer/SellerConfigReducer';
import UserSessionReducer from './reducer/UserSessionReducer';

const configConfig = {
    key: 'config',
    storage,
};

const persistedConfigReducer = persistReducer(configConfig, ConfigReducer);

export const Store = configureStore({
    reducer: {
        BreakpointReducer,
        UserSessionReducer,
        InvoiceDetailsReducer,
        ClientLeadReducer,
        LoanReducer,
        persistedConfigReducer,
        SellerConfigReducer,
        FederatedSsoConfigurationReducer,
    },
    middleware: [thunk],
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

export const persistor = persistStore(Store);
