import { Box, Button, Chip, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { InvoiceDetailsResponse, LoanStatus, Policy } from '../../apis/invoice';
import { PolicyVersion, QuoteStatus } from '../../apis/quotes';
import { DATE_COMPACT, DATE_FRIENDLY } from '../../util/dateUtils';
import CreateQuoteSteps from './CreateQuoteSteps';
import { SellerProduct } from '../../apis/sellerProduct';
import { useState } from 'react';

type Props = {
    sellerProduct: SellerProduct;
    invoiceDetails: InvoiceDetailsResponse;
    policy: Policy;
    policyVersion: PolicyVersion;
};

const RESUMABLE_QUOTE_STATUSES = [QuoteStatus.DRAFT, QuoteStatus.QUOTED];

export default function QuoteTile({ invoiceDetails, policy, policyVersion, sellerProduct }: Readonly<Props>) {
    const [openQuoteModal, setOpenQuoteModal] = useState(false);
    const createdDate = moment.utc(policyVersion.createdDate).local().format(DATE_FRIENDLY);
    const modifiedDate = moment.utc(policyVersion.lastModifiedDate).local().format(DATE_FRIENDLY);
    const policyIsActive =
        moment(policy.endDate).startOf('day').isAfter(moment().startOf('day')) &&
        invoiceDetails?.loan?.status === LoanStatus.OPEN;

    return (
        <Paper
            variant='flat'
            sx={{
                p: 2,
                mb: 4,
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                alignItems: 'center',
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography variant='h6'>
                        {policy.number} {policyVersion.version}
                    </Typography>
                    <PolicyVersionChip policyVersion={policyVersion} />
                </Box>
                <Typography variant='body2'>
                    Created {createdDate}
                    {createdDate !== modifiedDate && ` (Last saved ${modifiedDate})`}
                </Typography>
                <Typography variant='body2'>
                    Effective date: {moment.utc(policyVersion.effectiveDate).local().format(DATE_FRIENDLY)}
                </Typography>
            </Box>
            <Box>
                {RESUMABLE_QUOTE_STATUSES.includes(policyVersion.quote!.status) && policyIsActive && (
                    <Button variant='outlined' onClick={() => setOpenQuoteModal(true)}>
                        Resume
                    </Button>
                )}
                <CreateQuoteSteps
                    policy={policy}
                    sellerProduct={sellerProduct}
                    open={openQuoteModal}
                    setClosed={() => setOpenQuoteModal(false)}
                    invoiceDetails={invoiceDetails}
                    policyVersion={policyVersion}
                />
            </Box>
        </Paper>
    );
}

type PolicyVersionChipProps = {
    policyVersion: PolicyVersion;
};
const PolicyVersionChip = ({ policyVersion }: Readonly<PolicyVersionChipProps>) => {
    switch (policyVersion.quote!.status) {
        case QuoteStatus.DRAFT:
            return <Chip variant='outlined' color='default' label='Not accepted' />;
        case QuoteStatus.QUOTED:
            return <Chip variant='outlined' color='primary' label='Quoted' />;
        case QuoteStatus.ACCEPTED:
            return (
                <Chip
                    variant='outlined'
                    color='success'
                    label={`Accepted on ${moment.utc(policyVersion.activeDate).local().format(DATE_COMPACT)}`}
                />
            );
        case QuoteStatus.CANCELLED:
            return <Chip variant='outlined' color='error' label='Declined' />;
    }
};
