import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormLabel,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { truncate } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { InvoiceDetailsResponse } from '../../../apis/invoice';
import CovertNumberTextField from '../../../components/CovertNumberTextField';
import StepCard from '../../../components/StepCard';
import {
    DATE_COMPACT,
    DATE_FRIENDLY,
    DATE_SERVER_FORMAT,
    asString,
    handleDatePickerChange,
    invalidDate,
} from '../../../util/dateUtils';
import { EndorsementFields } from './types';
import { detectNotProcessedEndorsementPolicy } from './utils';

type Props = {
    details: InvoiceDetailsResponse;
    endorsement?: EndorsementFields;
    setEndorsement: (endorsement: EndorsementFields) => void;
    cancel: () => void;
};

export default function InputDetailsStep({ endorsement, cancel, setEndorsement, details }: Readonly<Props>) {
    const theme = useTheme();
    const [errorMsg, setErrorMsg] = useState<string>();
    const minEffectiveDate = moment(details.invoice.term.firstPaymentDate, DATE_SERVER_FORMAT);
    const maxEffectiveDate = moment(details.invoice.term.finalPaymentDate, DATE_SERVER_FORMAT);

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
    } = useForm<EndorsementFields>({
        resolver: yupResolver(getSchema(minEffectiveDate, maxEffectiveDate)),
        defaultValues: {
            policyEndorsements: details.invoice.portfolio.policies.map((policy) => ({
                ...policy,
                premiums: policy.currentPolicyVersion.premiums,
                policyNumber: policy.number,
                startDate: policy.startDate as string,
                endDate: policy.endDate as string,
            })),
            ...endorsement,
        },
    });

    const { fields } = useFieldArray({
        control,
        name: 'policyEndorsements',
    });

    const policyEndorsements = useWatch({ control, name: 'policyEndorsements' });

    const onSubmit: SubmitHandler<EndorsementFields> = (data) => {
        setErrorMsg(undefined);

        const adjustedPolicy = data.policyEndorsements.find((policy) => policy.premiumsAdjustment);
        if (!adjustedPolicy) {
            setErrorMsg('You must adjust at least one policy.');
            return;
        }

        const endorsement = {
            ...data,
            policyEndorsements: data.policyEndorsements.map((policy) => ({
                ...policy,
                effectiveDate:
                    policy.effectiveDate == null ? undefined : moment(policy.effectiveDate).format(DATE_SERVER_FORMAT),
            })),
        };
        setEndorsement(endorsement);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, console.log)}>
            <StepCard>
                <Typography variant='h5' component='h2'>
                    Create endorsement
                </Typography>
                <Typography variant='caption'>
                    Modify the premium for {details.client.displayName}'s Insurance #{details.invoice.number} by
                    entering an adjustment amount for a policy and an effective date.
                </Typography>

                <Box>
                    <Grid
                        container
                        sx={{
                            border: grey[400],
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: theme.shape.borderRadius + 'px',
                        }}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            sx={{
                                borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                                backgroundColor: grey[200],
                            }}
                            p={2}
                        >
                            <Grid item xs={3} pr={2}>
                                <Typography variant='subtitle2'>Policy</Typography>
                            </Grid>
                            <Grid item xs={2} pr={2}>
                                <Typography variant='subtitle2'>Premium</Typography>
                            </Grid>
                            <Grid item xs={4} pr={2}>
                                <Typography variant='subtitle2'>Adjustment</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='subtitle2'>Effective date</Typography>
                            </Grid>
                        </Grid>
                        {fields.map((field, index) => {
                            const fieldErrors = errors?.policyEndorsements?.[index];
                            const policy = policyEndorsements[index];
                            const adjustmentValue = policy.premiumsAdjustment;
                            const adjustmentHint = adjustmentValue
                                ? `New premium: ${currencyFormat.format(field.premiums + Number(adjustmentValue))}`
                                : undefined;
                            const edited = !!adjustmentValue || !!policy.effectiveDate;
                            const alignCentreSx = {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            };
                            const notProcessedEndorsementPolicy = detectNotProcessedEndorsementPolicy(
                                policy.policyNumber,
                                details.invoice
                            );

                            const isNotProcessedEndorsementPolicyOverridden = edited && notProcessedEndorsementPolicy;
                            const isNotProcessedEndorsementPolicyNotOverridden =
                                !edited && notProcessedEndorsementPolicy;
                            const notProcessedAdjustmentHint = isNotProcessedEndorsementPolicyNotOverridden
                                ? `Premium adjustment: ${currencyFormat.format(notProcessedEndorsementPolicy.overrideEndorsementPolicy.premiumsAdjustment)}`
                                : undefined;
                            const notProcessedEffectiveDateHint = isNotProcessedEndorsementPolicyNotOverridden
                                ? `Pending action date: ${moment(notProcessedEndorsementPolicy.overrideEndorsementPolicy.effectiveDate).format(DATE_COMPACT)}`
                                : undefined;

                            return (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    key={field.uuid}
                                    p={2}
                                    sx={{
                                        border: grey[400],
                                        borderWidth: '0',
                                        borderTopWidth: '1px',
                                        borderStyle: 'solid',
                                    }}
                                >
                                    {isNotProcessedEndorsementPolicyOverridden && (
                                        <Alert
                                            severity='warning'
                                            sx={{ mb: 1, minWidth: '100%' }}
                                        >{`Overrides pending premium adjustment from endorsement '${truncate(notProcessedEndorsementPolicy.endorsementNumber, { length: 18 })}'`}</Alert>
                                    )}
                                    {isNotProcessedEndorsementPolicyNotOverridden && (
                                        <Alert
                                            severity='info'
                                            sx={{ mb: 1, minWidth: '100%' }}
                                        >{`An existing endorsement '${truncate(notProcessedEndorsementPolicy.endorsementNumber, { length: 18 })}' is pending on this policy. Any changes will replace it.`}</Alert>
                                    )}

                                    <Grid item xs={3} pr={2} sx={alignCentreSx}>
                                        <Typography variant='subtitle2'>#{policy.policyNumber}</Typography>
                                        <Typography variant='subtitle1'>
                                            {moment(policy.startDate).format(DATE_FRIENDLY)} -{' '}
                                            {moment(policy.endDate).format(DATE_FRIENDLY)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} pr={2} sx={alignCentreSx}>
                                        <Typography variant='caption'>
                                            {currencyFormat.format(field.premiums)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} pr={2}>
                                        <FormControl required={edited}>
                                            <Controller
                                                name={`policyEndorsements.${index}.premiumsAdjustment`}
                                                control={control}
                                                defaultValue={'' as unknown as number}
                                                render={({ field }) => (
                                                    <CovertNumberTextField
                                                        {...field}
                                                        id={`premiumsAdjustment_${index}`}
                                                        size='small'
                                                        value={field.value ?? ''}
                                                        onChange={(e) => {
                                                            if (e.target.value?.length < MAX_ADJUSTMENT_LENGTH) {
                                                                field.onChange(e);
                                                            }
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>$</InputAdornment>
                                                            ),
                                                        }}
                                                        error={!!fieldErrors?.premiumsAdjustment}
                                                        helperText={
                                                            fieldErrors?.premiumsAdjustment?.message ??
                                                            adjustmentHint ??
                                                            notProcessedAdjustmentHint
                                                        }
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} pr={2}>
                                        <FormControl required={edited}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <Controller
                                                    name={`policyEndorsements.${index}.effectiveDate`}
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            {...register(`policyEndorsements.${index}.effectiveDate`)}
                                                            data-testid={`policyEndorsements.${index}.effectiveDate`}
                                                            onChange={handleDatePickerChange(field)}
                                                            onAccept={handleDatePickerChange(field)}
                                                            value={
                                                                field.value
                                                                    ? moment(field.value, DATE_SERVER_FORMAT)
                                                                    : null
                                                            }
                                                            slotProps={{
                                                                textField: {
                                                                    id: 'startDate',
                                                                    fullWidth: true,
                                                                    size: 'small',
                                                                    helperText:
                                                                        fieldErrors?.effectiveDate?.message ??
                                                                        notProcessedEffectiveDateHint,
                                                                    error: !!fieldErrors?.effectiveDate,
                                                                    placeholder: undefined,
                                                                },
                                                            }}
                                                            format={DATE_COMPACT}
                                                            minDate={minEffectiveDate}
                                                            maxDate={maxEffectiveDate}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>

                <FormControl required sx={{ width: '260px' }}>
                    <FormLabel htmlFor='number' required={false}>
                        Reference
                    </FormLabel>
                    <Controller
                        name='number'
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id='number'
                                size='small'
                                inputProps={{ maxLength: 250 }}
                                error={!!errors?.number}
                                helperText={
                                    errors?.number?.message ?? 'E.g. endorsement number from a policy management system'
                                }
                            />
                        )}
                    />
                </FormControl>

                <FormControl required>
                    <FormLabel htmlFor='reason' required={false}>
                        Description
                    </FormLabel>
                    <Controller
                        name='reason'
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id='reason'
                                size='small'
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 250 }}
                                error={!!errors?.reason}
                                helperText={errors?.reason?.message ?? 'This will be visible to the client'}
                            />
                        )}
                    />
                </FormControl>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

                <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                        <Button onClick={cancel} variant='text' color='primary' size='large' sx={{ mr: 1 }}>
                            Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            size='large'
                            sx={{ minWidth: '160px' }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </StepCard>
        </form>
    );
}

const getSchema = (minEffectiveDate: moment.Moment, maxEffectiveDate: moment.Moment) => {
    return yup.object({
        number: yup.string().required('Reference required'),
        reason: yup.string().required('Reason required'),
        policyEndorsements: yup.array().of(
            yup.object({
                uuid: yup.string(),
                policyNumber: yup.string(),
                premiumsAdjustment: yup
                    .number()
                    .nullable()
                    .transform((curr) => (curr ? curr : null))
                    .test('checkNonNegPolicy', 'New policy value cannot be less than $0', (value, context) => {
                        if (!context.parent.effectiveDate) {
                            return true;
                        }

                        return context.parent.premiums + value >= 0;
                    })
                    .when('effectiveDate', (effectiveDate, schema) => {
                        if (effectiveDate) {
                            return schema.required('Adjustment required');
                        }
                        return schema;
                    }),
                effectiveDate: yup
                    .date()
                    .nullable()
                    .transform((curr) => (invalidDate(curr) ? null : curr))
                    .test(
                        'checkDate',
                        `Effective date must be between ${minEffectiveDate.format(DATE_FRIENDLY)} and ${maxEffectiveDate.format(DATE_FRIENDLY)}`,
                        (value, context) => {
                            if (!context.parent.premiumsAdjustment) {
                                return true;
                            }

                            if (invalidDate(value)) {
                                return false;
                            }

                            const date = moment(value);
                            return (
                                date.isSameOrAfter(asString(minEffectiveDate)) &&
                                date.isSameOrBefore(asString(maxEffectiveDate))
                            );
                        }
                    ),
            })
        ),
    });
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

const MAX_ADJUSTMENT_LENGTH = 12;
