import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Chip, Fab, useTheme } from '@mui/material';
import { lowerCase, sortBy, upperFirst } from 'lodash';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Moment from 'react-moment';
import { DishonourReason, DishonourReasonSource, Loan, Transaction, TransactionType } from '../../../apis/invoice';
import DetailedPaymentAmount from '../../../components/DetailedPaymentAmount';
import { DATE_TIME_FRIENDLY } from '../../../util/dateUtils';
import { customStyles, initialDisplayAmount } from './tableStyles';
import NoRecords from '../../../components/NoRecords';

type Props = {
    loan: Loan;
};

export default function Transactions({ loan }: Readonly<Props>) {
    const theme = useTheme();

    const [showMore, setShowMore] = useState<boolean>(false);
    const transactions = sortBy(loan.paymentTransactions, 'transactionDate');
    return (
        <Box>
            <DataTable
                data={showMore ? transactions : transactions.slice(0, initialDisplayAmount)}
                columns={columns}
                striped={true}
                customStyles={customStyles(theme)}
                noDataComponent={<NoRecords />}
            />
            {transactions.length > initialDisplayAmount && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                    <Fab size='small' onClick={() => setShowMore(!showMore)} data-testid='show-more-payments'>
                        {showMore ? <ExpandLess /> : <ExpandMore />}
                    </Fab>
                </Box>
            )}
        </Box>
    );
}

const PaymentStatusChip = ({ transaction }: { transaction: Transaction }) => {
    if (transaction.transactionType === TransactionType.EXTERNAL_TRANSACTION) {
        return <Chip label={transaction.amount < 0 ? 'Paid externally' : 'Refunded externally'} color='success' />;
    }

    if (!transaction.dishonourDate && !transaction.confirmationDate) {
        return <Chip label='Pending' color='info' />;
    }

    if (!transaction.dishonourDate) {
        return <Chip label='Paid' color='success' />;
    }

    if (!transaction.dishonourReason || transaction.dishonourReason.source === DishonourReasonSource.INTERNAL) {
        return <Chip label='Declined' color='error' />;
    }

    return <Chip label={getDishonourDisplayLabel(transaction.dishonourReason)} color='error' />;
};

const PaymentDateCell = ({ transaction }: { transaction: Transaction }) => {
    return (
        <>
            <Moment format={DATE_TIME_FRIENDLY} style={{ marginRight: '8px' }}>
                {transaction.transactionDate}
            </Moment>
            <PaymentStatusChip transaction={transaction} />
        </>
    );
};

const columns: TableColumn<Transaction>[] = [
    {
        name: 'Payment Date',
        cell: (transaction) => <PaymentDateCell transaction={transaction} />,
    },
    {
        name: 'Amount',
        cell: (transaction) => (
            <DetailedPaymentAmount
                transactionType={transaction.transactionType}
                transactionDate={transaction.transactionDate}
                amount={-transaction.amount - transaction.feeAmount}
                paidAmount={-transaction.amount}
                feeAmount={transaction.feeAmount}
                expectedPaymentTransactionMapping={transaction.expectedPaymentTransactionMapping}
                arrangementTransactionMapping={transaction.arrangementTransactionMapping}
                declinedResult={
                    transaction.dishonourDate ? getDishonourDisplayLabel(transaction.dishonourReason) : undefined
                }
            />
        ),
        right: true,
    },
];

const getDishonourDisplayLabel = (dishonourReason?: DishonourReason): string => {
    if (!dishonourReason?.code) {
        return 'Declined';
    }

    switch (dishonourReason.code) {
        case 'AVS_FAILED':
            return 'Declined: AVS failed';
        case 'DISHONOUR-03':
        case 'INSUFFICIENT_FUND':
            return 'Declined: Insufficient funds';
        case 'CVN_NOT_MATCH':
            return 'Declined: CVS does not match';
        case 'STOLEN_LOST_CARD':
            return 'Declined: Stolen/lost card';
        case 'INVALID_CVN':
            return 'Declined: Invalid CVN';
        case 'CV_FAILED':
            return 'Declined: CV failed';
        case 'DECISION_PROFILE_REJECT':
            return 'Declined: Decision profile rejected';
        case 'ACH_VERIFICATION_FAILED':
            return 'Declined: ACH verification failed';
        case 'DISHONOUR-01':
            return 'Declined: Unauthorised';
        case 'DISHONOUR-02':
            return 'Declined: No account';
        case 'DISHONOUR-04':
            return 'Declined: Payment stopped';
        case 'DISHONOUR-05':
            return 'Declined: Authority cancelled';
        case 'DISHONOUR-06':
            return 'Declined: Account closed';
        case 'DISHONOUR-07':
            return 'Declined: Account transferred';
        case 'DISHONOUR-08':
            return 'Declined: Payment limit exceeded';
        case 'DISHONOUR-99':
        default:
            return dishonourReason.source === DishonourReasonSource.CYBERSOURCE
                ? `Declined: ${upperFirst(lowerCase(dishonourReason.code))}`
                : 'Declined';
    }
};
