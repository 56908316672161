import axios from 'axios';
import { CancellationReason, Loan } from './invoice';

export type CancellationRequest = {
    effectiveDate: string;
    reason: CancellationReason;
    note: string;
};

export const querySettlementAmount = async (invoiceUuid: string): Promise<number> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/_query/settlement-amount`)
        .then(({ data }) => data);
};

export const confirmCancellation = async (
    invoiceUuid: string,
    cancellationRequest: CancellationRequest
): Promise<Loan> => {
    const { effectiveDate, reason, note } = cancellationRequest; // ignore the preview loan to avoid be blocked by WAF body size limitation rule
    return await axios
        .patch(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/request-cancellation`, {
            effectiveDate,
            reason,
            note,
        })
        .then(({ data }) => data);
};

export const previewCancellation = async (invoiceUuid: string, effectiveDate: string): Promise<Loan> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/preview-cancellation`, {
            params: {
                effectiveDate,
            },
        })
        .then(({ data }) => data);
};
