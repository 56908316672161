import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ClientLeadResult, isBankTransfer } from '../../../apis/clientLead';
import { BaseClient, CreateBusinessFields, CreateIndividualFields, CreateTrustFields } from '../../../apis/clients';
import InvoiceMatcher from '../Invoice/InvoiceMatcher';
import ClientMatcher from '../MatchClient/ClientMatcher';
import BankTransferPaymentInformation from './BankTransferPaymentInformation';
import MatchOutcome, { MatchFormFields, MatchOutcomeType } from '../MatchOutcome';
import ExistingClient from '../MatchClient/ExistingClient';
import { castLeadToBaseClient } from '../../../util/insuredUtils';
import { BaseInvoiceFormFields } from '../../CreateInvoice/invoiceValidation';

type Props = {
    result: ClientLeadResult;
    cancel: () => void;
    handleSave: (
        matchOutcome: MatchFormFields,
        invoice: BaseInvoiceFormFields,
        client: CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient
    ) => void;
    handleVoid: (matchOutcome: MatchFormFields) => void;
    error?: string;
};

export default function ClientLeadMatcher({ result, cancel, handleSave, error, handleVoid }: Readonly<Props>) {
    const [saveCounter, setSaveCounter] = useState(0);
    const [matchOutcome, setMatchOutcome] = useState<MatchFormFields | null>(null);
    const [matchType, setMatchType] = useState<MatchOutcomeType>(MatchOutcomeType.APPROVE);
    const [invoiceFields, setInvoiceFields] = useState<BaseInvoiceFormFields | null>(null);
    const [client, setClient] = useState<
        CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient | null
    >(null);

    useEffect(() => {
        if (invoiceFields && client && matchOutcome?.type !== MatchOutcomeType.VOID) {
            handleSave(matchOutcome!, invoiceFields, client);
        }
    }, [matchOutcome, invoiceFields, client]);

    useEffect(() => {
        if (matchOutcome?.type === MatchOutcomeType.VOID) {
            handleVoid(matchOutcome);
        }
    }, [matchOutcome]);

    const onSubmit = () => {
        setMatchOutcome(null);
        setInvoiceFields(null);
        setClient(null);
        setSaveCounter(saveCounter + 1);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {isBankTransfer(result.paymentMethod) && (
                <BankTransferPaymentInformation bankTransferPaymentMethod={result.paymentMethod} />
            )}
            <MatchOutcome
                result={result}
                save={setMatchOutcome}
                saveCounter={saveCounter}
                setMatchType={setMatchType}
            />
            {matchType !== MatchOutcomeType.VOID ? (
                <>
                    <InvoiceMatcher invoice={result.invoice} saveCounter={saveCounter} save={setInvoiceFields} />
                    <ClientMatcher result={result} saveCounter={saveCounter} save={setClient} />
                </>
            ) : (
                <Box>
                    <Typography variant='subtitle2' mb={2}>
                        Client details
                    </Typography>
                    <ExistingClient client={result.client ?? castLeadToBaseClient(result.clientLead)} />
                </Box>
            )}
            {error && (
                <Grid item xs={12}>
                    <Alert severity='error'>{error}</Alert>
                </Grid>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button size='small' onClick={cancel}>
                    Cancel
                </Button>
                <Button size='small' variant='contained' onClick={onSubmit}>
                    Save
                </Button>
            </Box>
        </Box>
    );
}
