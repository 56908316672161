import { Paper, Typography, Link as MuiLink, Box } from '@mui/material';
import { RenewFromInvoice } from '../../../apis/invoice';
import { Link } from 'react-router-dom';
import { Receipt } from '@mui/icons-material';

type Props = {
    renewedFromInvoice: RenewFromInvoice;
};

export default function RenewalDetailsCardChild({ renewedFromInvoice }: Readonly<Props>) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Linked invoice
            </Typography>
            <Paper variant='flat' sx={{ p: 2 }}>
                <Typography variant='subtitle1' mt={1}>
                    Renewed from
                </Typography>

                <MuiLink
                    to={'/invoices/' + renewedFromInvoice.uuid}
                    component={Link}
                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                >
                    <Receipt />
                    {renewedFromInvoice.number}
                </MuiLink>
            </Paper>
        </Box>
    );
}
