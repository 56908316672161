import { Address } from '../types/Types';

interface FormattedAddressProps {
    address?: Address;
}

const FormattedAddress = (props: FormattedAddressProps) => {
    const address = props.address;

    return (
        <>
            {address && (
                <>
                    {address.addressLine1 && <>{address.addressLine1}</>}
                    {address.suburb && <>, {address.suburb}</>}
                    {(address.city || address.postCode) && <br />}
                    {address.city} {address.postCode}
                </>
            )}
        </>
    );
};

export default FormattedAddress;
