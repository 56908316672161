import { ReportData, ReportValue } from '../../apis/reports';
import { Paper, Typography, useTheme } from '@mui/material';
import { Line } from 'react-chartjs-2';
import ReportTable from './ReportTable';
import { map } from 'lodash';
import { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { DATE_COMPACT, DATE_FRIENDLY } from '../../util/dateUtils';

interface SettlementReportDisplayProps {
    reportData: ReportData;
}

export default (props: SettlementReportDisplayProps) => {
    const { reportData } = props;
    const theme = useTheme();

    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
    };

    const chartData = {
        labels: map(Object.values(reportData.values.map((x: ReportValue) => x.date)), (date) =>
            moment(date).format(DATE_FRIENDLY)
        ),
        datasets: [
            {
                label: 'Value $',
                borderColor: theme.palette.blue?.['100'],
                backgroundColor: theme.palette.blue?.['60'],
                borderWidth: 1,
                data: Object.values(reportData.values.map((x) => Number(x.value.substring(1).replaceAll(',', '')))),
            },
        ],
    };

    const tableColumns: TableColumn<ReportValue>[] = [
        {
            name: 'Date',
            selector: (row) => moment(row.date).format(DATE_COMPACT),
        },
        {
            name: 'Value',
            selector: (row) => row.value,
        },
        {
            name: 'Reference',
            selector: (row) => row.reference,
        },
    ];

    return (
        <Paper variant='flat' sx={{ mb: 2, p: 2 }}>
            <Typography variant={'subtitle2'}>{reportData.title}</Typography>
            <Line datasetIdKey='id' data={chartData} style={{ maxHeight: '184px', width: '100%' }} options={options} />
            <ReportTable reportData={reportData} tableColumns={tableColumns} />
        </Paper>
    );
};
