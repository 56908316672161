import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Middle mouse button id.
const MIDDLE_MOUSE_BUTTON_ID = 1;
// This name is for react data table's body class name.
const MUI_TABLE_BODY_CLASS_NAME = '.rdt_TableBody';

export const useOpenRow = () => {
    const navigate = useNavigate();
    const [url, setUrl] = useState<string | null>(null);
    const [middleMouseClicked, setMiddleMouseClicked] = useState<boolean>(false);

    const detectNewMiddleMouseClick = (mouseEvent: MouseEvent) => {
        // Find closest target element with class name given. The purpose is to narrow down the area of event listener to be applied;
        if ((mouseEvent.target as Element).closest(MUI_TABLE_BODY_CLASS_NAME) == null) {
            return;
        }
        if (MIDDLE_MOUSE_BUTTON_ID != mouseEvent.button) {
            return;
        }
        mouseEvent.preventDefault();
        mouseEvent.stopPropagation();
        setMiddleMouseClicked(true);
    };

    const rowClick = (link: string, e: React.MouseEvent) => {
        if (e.ctrlKey) {
            window.open(link);
        } else {
            navigate(link);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', detectNewMiddleMouseClick);
        return () => {
            document.removeEventListener('mousedown', detectNewMiddleMouseClick);
        };
    }, []);

    useEffect(() => {
        if (middleMouseClicked) {
            setMiddleMouseClicked(false);
            if (url) {
                window.open(url);
            }
        }
    }, [middleMouseClicked]);

    return {
        rowClick,
        setUrl,
    };
};
