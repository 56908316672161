import { Alert, Box, Grid, Typography } from '@mui/material';
import { BankTransferPaymentMethod } from '../../../apis/clientLead';

type Props = {
    bankTransferPaymentMethod: BankTransferPaymentMethod;
};

export default function BankTransferPaymentInformation({ bankTransferPaymentMethod }: Readonly<Props>) {
    return (
        <Box>
            <Typography variant='subtitle2' mb={2}>
                Payment Information
            </Typography>
            <Alert severity='info' sx={{ mb: 2 }}>
                Payment was made via bank transfer, but the reference did not match with any existing invoice at the
                time of payment.
            </Alert>
            <Grid container>
                <Grid item xs={2}>
                    <Typography variant='caption'>Particulars</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='caption'>{bankTransferPaymentMethod.particulars ?? '-'}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant='caption'>Code</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='caption'>{bankTransferPaymentMethod.code ?? '-'}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant='caption'>Reference</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='caption'>{bankTransferPaymentMethod.reference ?? '-'}</Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
