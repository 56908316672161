import { useState } from 'react';
import StepsDrawer from '../../../components/StepsDrawer';
import { Invoice, Loan, fetchLoan } from '../../../apis/invoice';
import { useAppDispatch, useAppSelector } from '../../../store/reducer/Hooks';
import { FetchStateType, isSuccess } from '../../../hooks/useFetch';
import { cloneDeep } from 'lodash';
import { setInvoiceDetailsState } from '../../../store/reducer/InvoiceDetailsReducer';
import InputDetailsStep from './InputDetailsStep';
import { EndorsementFields } from './types';
import ReviewStep from './ReviewStep';
import PageLoading from '../../../components/PageLoading';
import DoneStep from './DoneStep';
import { setLoanState } from '../../../store/reducer/LoanReducer';

type Props = {
    open: boolean;
    setClosed: () => void;
};

export default function CreateEndorsementSteps({ open, setClosed }: Readonly<Props>) {
    const {
        permissions: { accountAllowed },
    } = useAppSelector((state) => state.UserSessionReducer);
    const [step, setStep] = useState<Steps>(Steps.INPUT_DETAILS);
    const [endorsement, setEndorsement] = useState<EndorsementFields>();
    const [invoice, setInvoice] = useState<Invoice>();
    const [loan, setLoan] = useState<Loan>();

    const { state: invoiceState } = useAppSelector((root) => root.InvoiceDetailsReducer);
    const dispatch = useAppDispatch();

    const handleBackToEditStep = () => {
        setStep(Steps.INPUT_DETAILS);
    };

    const handleSetEndorsement = (endorsementReq: EndorsementFields) => {
        setEndorsement(endorsementReq);
        setStep(Steps.REVIEW);
    };

    const setUpdatedInvoice = async (updatedInvoice: Invoice) => {
        setInvoice(updatedInvoice);
        if (accountAllowed) {
            await fetchLoan(updatedInvoice.uuid).then(setLoan);
        }

        setStep(Steps.DONE);
    };

    const handleClose = () => {
        if (isSuccess(invoiceState) && invoice) {
            const updatedStateValue = cloneDeep(invoiceState);
            updatedStateValue.value.invoice = invoice;
            dispatch(setInvoiceDetailsState(updatedStateValue));
        }
        if (loan) {
            dispatch(setLoanState({ value: loan, type: FetchStateType.SUCCESS }));
        }
        setEndorsement(undefined);
        setInvoice(undefined);
        setLoan(undefined);
        setStep(Steps.INPUT_DETAILS);
        setClosed();
    };

    if (!isSuccess(invoiceState)) {
        return (
            <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
                <PageLoading />
            </StepsDrawer>
        );
    }

    return (
        <StepsDrawer open={open} setClosed={handleClose} step={step} stepNames={stepNames}>
            {step === Steps.INPUT_DETAILS && (
                <InputDetailsStep
                    endorsement={endorsement}
                    cancel={handleClose}
                    setEndorsement={handleSetEndorsement}
                    details={invoiceState.value}
                />
            )}
            {step === Steps.REVIEW && (
                <ReviewStep
                    cancel={handleClose}
                    handleBack={handleBackToEditStep}
                    endorsement={endorsement!}
                    setUpdatedInvoice={setUpdatedInvoice}
                    details={invoiceState.value}
                />
            )}
            {step === Steps.DONE && <DoneStep handleClose={handleClose} />}
        </StepsDrawer>
    );
}

const stepNames = ['Edit', 'Review', 'Done'];
enum Steps {
    INPUT_DETAILS,
    REVIEW,
    DONE,
}
