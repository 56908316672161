import { Button, ButtonProps, CircularProgress } from '@mui/material';

export type LoadingButtonProps = ButtonProps & {
    loading?: boolean;
};

export const LoadingButton = (props: LoadingButtonProps) => {
    const { loading, disabled, startIcon, endIcon, ...rest } = props;

    return (
        <Button
            {...rest}
            disabled={disabled || loading}
            startIcon={loading ? undefined : startIcon}
            endIcon={loading ? undefined : endIcon}
        >
            {loading && (
                <CircularProgress
                    color='inherit'
                    size={20}
                    sx={{ marginRight: 2, marginLeft: -1 }}
                    data-testid='loadingSpinner'
                />
            )}
            {props.children}
        </Button>
    );
};
