import { ReportTotal } from '../../apis/reports';
import { Box, Grid, Paper, Typography } from '@mui/material';

interface ReportTotalsProps {
    totals: ReportTotal[];
}

export default (props: ReportTotalsProps) => {
    const { totals } = props;

    const totalCard = (title: string, value: string) => (
        <Paper
            variant='flat'
            style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                height: '100px',
            }}
        >
            <Box sx={{ width: '100%' }}>
                <Typography variant='h6'>{title}</Typography>
                <Typography variant='h2'>{value}</Typography>
            </Box>
        </Paper>
    );

    return (
        <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
            {totals.map((total, index) => (
                <Grid item md={3} sm={6} key={index}>
                    {totalCard(total.title, total.value)}
                </Grid>
            ))}
        </Grid>
    );
};
